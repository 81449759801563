import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, CircularProgress, Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import { axiosWithAuth } from './authService';
import DOMPurify from 'dompurify';

// Utility Functions
export const parseEmailString = (emailInput) => {
  // Check if the input is a string
  if (typeof emailInput === 'string') {
    const trimmedEmailString = emailInput.trim();

    // Return an empty array if the string is empty
    if (trimmedEmailString === '') {
      return [];
    }

    // Split the string by commas, trim each email, and return as a list of emails
    return trimmedEmailString.split(',').map(email => email.trim());
  }

  // If the input is already an array, return it as is
  if (Array.isArray(emailInput)) {
    return emailInput;
  }

  // Return null if input is neither a string nor an array
  return null;
};

export const createEmailObject = (value) => {
  const trimmedValue = value.trim();
  return {
    emailAddress: {
      address: trimmedValue
    }
  };
};

export const applyInlineStyles = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const applyStyles = (element, styles) => {
    // Apply styles directly to the element's style attribute
    Object.entries(styles).forEach(([property, value]) => {
      element.style[property] = value;
      // Also set !important for critical styles
      if (property === 'lineHeight' || property === 'fontSize' || property === 'fontFamily' || property === 'marginBottom') {
        element.style.setProperty(property, value, 'important');
      }
    });
  };

  // Define consistent styles
  const defaultStyles = {
    margin: '0',
    padding: '0',
    lineHeight: '1.5',
    fontSize: '14px',
    fontFamily: 'Arial, sans-serif',
  };

  // Apply styles to all elements
  doc.body.querySelectorAll('*').forEach(el => {
    applyStyles(el, defaultStyles);
  });

  // Specific styles for paragraphs
  doc.body.querySelectorAll('p').forEach(p => {
    applyStyles(p, {
      margin: '0',
      padding: '0',
      marginBottom: '0.15em', // Reduced spacing between paragraphs
    });
  });

  // Remove extra line breaks
  doc.body.innerHTML = doc.body.innerHTML.replace(/<br\s*\/?>\s*<br\s*\/?>/gi, '<br>');

  // Create wrapper with consistent styles
  const wrapper = doc.createElement('div');
  applyStyles(wrapper, {
    ...defaultStyles,
    lineHeight: '1.5',
    fontSize: '14px',
    fontFamily: 'Arial, sans-serif',
  });

  // Ensure all content is wrapped
  while (doc.body.firstChild) {
    wrapper.appendChild(doc.body.firstChild);
  }
  doc.body.appendChild(wrapper);

  return doc.documentElement.outerHTML;
};

export function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const removeSignature = (body) => {
  const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const imgRegex = /<img\b[^>]*>/gi;
  const signaturePhrases = [
    'best\\s+regards',
    'venlig\\s+hilsen',
    'med\\s+venlig\\s+hilsen',
    'kind\\s+regards',
    'regards',
    'sincerely',
    'yours\\s+truly',
    'cheers',
    'mvh'
  ];
  const signatureRegex = new RegExp(`(<[^>]*>)*\\s*(${signaturePhrases.join('|')}).*$`, 'i');
  
  let cleanBody = body.replace(imgRegex, '');
  let decodedBody = decodeHTMLEntities(cleanBody);
  
  const match = decodedBody.match(signatureRegex);
  if (match && match.index !== undefined) {
    cleanBody = cleanBody.substring(0, match.index);
    cleanBody = cleanBody.replace(/(\s*<p>\s*<\/p>\s*)*$/g, '').trim();
  }
  
  return cleanBody;
};

export const appendSignatureIfMissing = (body, EMAIL_SIGNATURE) => {
  const cleanSignature = DOMPurify.sanitize(EMAIL_SIGNATURE);
  const styledSignature = `
    <div style="
      margin-top: 100px;
      padding-top: 100px;
      border-top: 1px solid #e0e0e0;
      font-size: 0.9em;
      line-height: 1.2;
      color: #555;
    ">
    <br><br>
      ${cleanSignature}
    </div>
  `;

  if (!body.includes(styledSignature)) {
    return `${body}${styledSignature}`;
  }
  return body;
};

export const VersionControl = ({ bodyVersions, currentVersionIndex, navigateVersion }) => {
  if (bodyVersions.length <= 1) return null;

  return (
    <Box display="flex" alignItems="center">
      <IconButton 
        onClick={() => navigateVersion(-1)} 
        disabled={currentVersionIndex === 0}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </IconButton>
      <Typography variant="caption" sx={{ mx: 1 }}>
        Version {currentVersionIndex + 1}/{bodyVersions.length}
      </Typography>
      <IconButton 
        onClick={() => navigateVersion(1)} 
        disabled={currentVersionIndex === bodyVersions.length - 1}
      >
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};