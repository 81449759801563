import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography, Popover, Divider, Tooltip, SvgIcon, CircularProgress, IconButton, Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SubjectIcon from '@mui/icons-material/Subject';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { axiosWithAuth } from './authService';
import AttachmentPopover from './AttachmentPopover';
import AttachmentPreviewDialog from './AttachmentPreviewDialog';
import { useNavigate } from 'react-router-dom';


// Move helper functions outside component
const getTextWidth = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

const formatDateTime = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  
  const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const compareDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  
  const diffTime = nowDate - compareDate;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) ;

  if (diffDays === 0) {
    return `Today ${timeString}`;
  } else if (diffDays === 1) {
    return `Yesterday ${timeString}`;
  } else if (diffDays > 1 && diffDays <= 7) {
    return `${date.toLocaleDateString('en-US', { weekday: 'long' })} ${timeString}`;
  } else if (diffDays > 7 && diffDays <= 365) {
    return `${date.toLocaleDateString('en-US', { day: 'numeric', month: 'long' })}, ${timeString}`;
  } else {
    return `${date.toLocaleDateString('en-US', { year: 'numeric', day: 'numeric', month: 'long' })}, ${timeString}`;
  }
};

const canPreviewAttachment = (attachment) => {
  const previewableTypes = [
    'image/', 
    'application/pdf', 
    'text/'
  ];
  
  const previewableExtensions = [
    '.pdf', 
    '.txt', 
    '.jpg', 
    '.jpeg', 
    '.png', 
    '.gif'
  ];
  
  const contentType = attachment.contentType || '';
  const fileName = attachment.name || '';
  const fileExtension = '.' + fileName.split('.').pop().toLowerCase();

  const canPreview = 
    previewableTypes.some(type => contentType.startsWith(type)) ||
    previewableExtensions.includes(fileExtension);

  console.log('Can preview attachment?', canPreview, 'Content type:', contentType, 'File name:', fileName);
  return canPreview;
};

// Add userInfo helper function near the top with other helper functions
const getUserInfo = () => {
  try {
    const userInfoString = localStorage.getItem('userInfo');
    return userInfoString ? JSON.parse(userInfoString) : {};
  } catch (error) {
    console.error('Error parsing userInfo from localStorage:', error);
    return {};
  }
};

// Add styles object outside component to prevent recreation
const getStyles = (theme) => ({
  label: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
    fontWeight: 'bold',
    color: theme.palette.ui.textLight,
    letterSpacing: '0.5px',
    marginBottom: '8px'
  },
  recipient: {
    fontSize: '0.85rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
    color: theme.palette.ui.textDark,
    lineHeight: '1.5',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    marginBottom: '4px',
    wordBreak: 'break-all',
    '& span': {
      display: 'inline-block',
      width: '100%',
    }
  },
  text: {
    fontSize: '0.85rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
    color: theme.palette.ui.textLight,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%', // Add maximum width constraint
  },
  icon: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    color: theme.palette.ui.textLight,
    marginRight: '8px', // Changed from marginLeft to marginRight
    flexShrink: 0,
  }
});

const ChatHeader = React.memo(({ 
  messageId, 
  fromName = '', 
  fromEmail = null,
  receiveDate = '', 
  recipients = [], 
  cc = [], 
  bcc = [], 
  subject = '', 
  attachments = [],
  isChatView = false,
  isEmbedded = false,
  isExpanded = true,
  onToggleExpand = () => {},
  onNameClick,
  setIsSidebarOpen,
  handleClearThread,
  setSelectedEmail,
  setMessages,
  setThreadId,
  setIsLoading,
  setIsSummarizing,
  setIsLoadingSummaries,
  setIsStreaming,
  toggleEmailListCollapse,
  focusInputField = () => {},
  handleNewChat,
  handleEmailListToggle,
  isEmailListCollapsed
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [shortcutKey, setShortcutKey] = useState('');
  const [newChatShortcutKey, setNewChatShortcutKey] = useState('');
  
  const [recipientsAnchorEl, setRecipientsAnchorEl] = useState(null);
  const [attachmentsAnchorEl, setAttachmentsAnchorEl] = useState(null);
  const [visibleRecipients, setVisibleRecipients] = useState([]);
  const [hiddenRecipients, setHiddenRecipients] = useState({ to: [], cc: [], bcc: [] });
  const recipientsRef = useRef(null);
  const subjectRef = useRef(null);
  const [isSubjectOverflowing, setIsSubjectOverflowing] = useState(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState({});
  const previousDimensionsRef = useRef({ width: 0, recipients: 0 });

  const allRecipients = useMemo(() => {
    const formattedRecipients = recipients.map(r => `${r}`);
    const formattedCC = cc.map(r => `CC: ${r}`);
    const formattedBCC = bcc.map(r => `BCC: ${r}`);
    return [...formattedRecipients, ...formattedCC, ...formattedBCC];
  }, [recipients, cc, bcc]);

  const formattedDateTime = useMemo(() => formatDateTime(receiveDate), [receiveDate]);

  const recipientLabels = {
    to: "Recipients",
    cc: "Cc",
    bcc: "Bcc"
  };

  const updateVisibleRecipients = useCallback(() => {
    if (recipientsRef.current) {
      const containerWidth = recipientsRef.current.offsetWidth;
      const previousWidth = previousDimensionsRef.current.width;
      const previousRecipientsCount = previousDimensionsRef.current.recipients;

      // Only update if there's a significant change in width or recipients count
      if (
        Math.abs(containerWidth - previousWidth) > 5 ||
        allRecipients.length !== previousRecipientsCount
      ) {
        let totalWidth = 0;
        let visibleCount = 0;

        const moreButtonWidth = 80;
        const availableWidth = containerWidth - moreButtonWidth;

        for (let i = 0; i < allRecipients.length; i++) {
          const recipientWidth = getTextWidth(allRecipients[i], '0.85em Arial') + 20;
          if (totalWidth + recipientWidth > availableWidth && visibleCount >= 1) {
            break;
          }
          totalWidth += recipientWidth;
          visibleCount++;
        }

        setVisibleRecipients(allRecipients.slice(0, Math.max(visibleCount, 1)));
        setHiddenRecipients({
          to: recipients.slice(Math.max(visibleCount, 0)),
          cc: cc,
          bcc: bcc,
        });

        // Update the previous dimensions ref
        previousDimensionsRef.current = {
          width: containerWidth,
          recipients: allRecipients.length,
        };
      }
    }
  }, [allRecipients]);

  useEffect(() => {
    updateVisibleRecipients();
    window.addEventListener('resize', updateVisibleRecipients);

    return () => {
      window.removeEventListener('resize', updateVisibleRecipients);
    };
  }, [updateVisibleRecipients]);

  useEffect(() => {
    const checkSubjectOverflow = () => {
      if (subjectRef.current) {
        const isOverflowing = subjectRef.current.scrollWidth > subjectRef.current.clientWidth;
        setIsSubjectOverflowing(isOverflowing);
      }
    };
  
    checkSubjectOverflow();
    window.addEventListener('resize', checkSubjectOverflow);
  
    return () => {
      window.removeEventListener('resize', checkSubjectOverflow);
    };
  }, [subject]);

  const renderRecipientList = useCallback((type, recipients) => {
    if (recipients.length === 0) return null;
    return (
      <>
        <Typography variant="subtitle2" sx={styles.label}>
          {recipientLabels[type]}
        </Typography>
        {recipients.map((recipient, index) => (
          <Typography key={index} variant="body2" sx={styles.recipient}>
            <span>{recipient}</span>
          </Typography>
        ))}
      </>
    );
  }, []);

  const downloadAttachment = useCallback((attachment) => {
    if (!attachment || !attachment.id) {
      console.error('Invalid attachment object:', attachment);
      return;
    }
    
    const downloadUrl = process.env.REACT_APP_API_URL + `/download-attachment/${messageId}/${attachment.id}`;

    setDownloadProgress(prev => ({ ...prev, [attachment.id]: 0 }));

    axiosWithAuth.get(downloadUrl, { 
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setDownloadProgress(prev => ({ ...prev, [attachment.id]: percentCompleted }));
      }
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.name || 'attachment');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
        
        // Clear the progress after a short delay
        setTimeout(() => {
          setDownloadProgress(prev => {
            const newProgress = { ...prev };
            delete newProgress[attachment.id];
            return newProgress;
          });
        }, 1000);
      })
      .catch(error => {
        console.error('Error downloading the file:', error);
        setDownloadProgress(prev => {
          const newProgress = { ...prev };
          delete newProgress[attachment.id];
          return newProgress;
        });
        // You might want to show an error message to the user here
      });
  }, [messageId]);

  const handleAttachmentClick = useCallback((attachment) => {
    console.log('Attachment clicked:', attachment);
    if (canPreviewAttachment(attachment)) {
      console.log('Can preview attachment:', attachment.id);
      setSelectedAttachmentId(attachment.id);
      setIsPreviewDialogOpen(true);
    } else {
      console.log('Cannot preview, downloading attachment:', attachment.id);
      downloadAttachment(attachment);
    }
    // Removed handleAttachmentsClose() to keep the popover open
  }, [downloadAttachment]);

  const handleClosePreviewDialog = useCallback(() => {
    console.log('Closing preview dialog');
    setIsPreviewDialogOpen(false);
    setSelectedAttachmentId(null);
  }, []);

  const handleRecipientsClick = useCallback((event) => {
    setRecipientsAnchorEl(event.currentTarget);
  }, []);

  const handleRecipientsClose = useCallback(() => {
    setRecipientsAnchorEl(null);
  }, []);

  const handleAttachmentsClick = useCallback((event) => {
    setAttachmentsAnchorEl(event.currentTarget);
  }, []);

  const handleAttachmentsClose = useCallback(() => {
    setAttachmentsAnchorEl(null);
  }, []);

  const attachmentsOpen = Boolean(attachmentsAnchorEl);
  const attachmentsId = attachmentsOpen ? 'attachments-popover' : undefined;

  const recipientsOpen = Boolean(recipientsAnchorEl);
  const recipientsId = recipientsOpen ? 'recipients-popover' : undefined;

  const userInfo = useMemo(() => getUserInfo(), []); // Add this line

  // Modify getAssistantName to use the userInfo from localStorage
  const getAssistantName = useCallback((fromName) => {
    if (fromName?.toLowerCase() !== 'lexi') return fromName;
    const companyName = typeof userInfo?.company === 'string' ? userInfo.company : 
                       userInfo?.company?.name || userInfo?.company?.label;
    
    return 'Assistant';
  }, [userInfo]);

  const renderFullHeader = useMemo(() => {
    
    console.log('rendering full header for ', fromName);

    return (
    <>
      {/* Left Side */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        width: '50%', 
        overflow: 'hidden',
        borderRadius: '5px',
        minWidth: 0,
      }}>
        {/* Date */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
          <AccessTimeIcon sx={{ ...styles.icon, marginLeft: 0, marginRight: '8px', flexShrink: 0 }} />
          <Typography
            variant="subtitle2"
            component="div"
            sx={{
              ...styles.text,
              color: '#333',
              flex: 1,
              minWidth: 0,
            }}
          >
            {formattedDateTime}
          </Typography>
        </Box>
        {/* Attachments */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          marginBottom: '4px',
        }}>
          {attachments && attachments.length > 0 ? (
            <Typography
              onClick={handleAttachmentsClick}
              sx={{
                ...styles.text,
                cursor: 'pointer',
                userSelect: 'none',
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <AttachFileIcon sx={{ ...styles.icon, marginLeft: 0 }} />
              {attachments.length} {attachments.length === 1 ? 'attachment' : 'attachments'}
            </Typography>
          ) : (
            <Typography sx={{
              ...styles.text,
              display: 'flex',
              alignItems: 'center',
            }}>
              <AttachFileIcon sx={{ ...styles.icon, marginLeft: 0 }} />
              No attachments
            </Typography>
          )}
        </Box>
      </Box>

      {/* Right Side - Modified to show recipients */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-end', 
        width: '50%',
        overflow: 'hidden',
        minWidth: 0,
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          marginLeft: '8px',
        },
      }}>
        {/* From Name */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '4px',
          width: '100%',
          justifyContent: 'flex-end',
        }}>
          <Typography
            variant="subtitle2"
            component="div"
            onClick={(e) => {
              e.stopPropagation();
              onNameClick && onNameClick();
            }}
            sx={{
              ...styles.text,
              fontWeight: 'bold',
              color: '#333',
              marginRight: '8px',
              cursor: 'pointer',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              direction: 'ltr',
              maxWidth: '100%',
              // '&:hover': {
              //   textDecoration: 'underline',
              // },
            }}
          >
            <Tooltip title={fromEmail} placement="left">
              <span>{fromName}</span>
            </Tooltip>
          </Typography>
          <PersonIcon sx={{ ...styles.icon, flexShrink: 0, marginRight: '4px' }} />
        </Box>
        {/* Recipients Section */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
        }}> 
          <Box 
            ref={recipientsRef}
            onClick={allRecipients.length > visibleRecipients.length ? handleRecipientsClick : undefined}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: allRecipients.length > visibleRecipients.length ? 'pointer' : 'default',
              marginRight: '8px',
              maxWidth: '100%',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="subtitle2"
              component="span"
              sx={{
                ...styles.text,
                textAlign: 'right',
              }}
            >
              {visibleRecipients[0]}
              {allRecipients.length > 1 && (
                <Typography
                  component="span"
                  sx={{
                    ...styles.text,
                    fontWeight: 'bold',
                    display: 'inline',
                  }}
                >
                  {` +${allRecipients.length - 1} more`}
                </Typography>
              )}
            </Typography>
          </Box>
          <CallReceivedIcon sx={{ ...styles.icon, flexShrink: 0, marginRight: '4px' }} />
        </Box>
      </Box>
    </>
  )}, [fromName, recipients, visibleRecipients, handleRecipientsClick, getAssistantName, onNameClick, formattedDateTime]);

  const renderSimplifiedHeader = useMemo(() => {
    console.log('rendering simplified header for ', fromName);

    const newChatEmail = {
      id: "thread_newLexiChat",
      fromName: "Lexi", 
      date: new Date().toISOString(),
      subject: "",
      snippet: "Start a new conversation here.",
      messages: []
    };

    return (
    <>
      {/* Left Side - Assistant Name */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        width: '50%', 
        overflow: 'hidden',
        minWidth: 0,
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '0px',
          overflow: 'hidden',
          minWidth: 0,
        }}>
          {isMobile && setIsSidebarOpen && (
            <IconButton
              onClick={() => setIsSidebarOpen(true)}
              sx={{ 
                color: theme.palette.text.primary,
                padding: '2px',
                marginRight: '4px',
              }}
            >
              <MenuIcon sx={{ fontSize: '1.1rem' }} />
            </IconButton>
          )}
          {!isMobile && (
            <Tooltip title={isEmailListCollapsed ? "Collapse chat window" : "Expand chat window"} placement="right">
              <IconButton
                onClick={handleEmailListToggle}
              sx={{
                color: theme.palette.text.primary,
                padding: '2px',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isEmailListCollapsed ? 
                <KeyboardDoubleArrowRightIcon sx={{ fontSize: '1.2rem' }} /> : 
                <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '1.2rem' }} />
              }
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      
      {/* Right Side - Clear and New buttons */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        minWidth: 0,
        flexWrap: 'nowrap',
      }}>
        {/* Clear button */}
        <Button
          variant="text"
          size="small"
          endIcon={<ClearAllIcon sx={{ opacity: 0.6, flexShrink: 0 }} />}
          onClick={handleClearThread}
          sx={{
            marginRight: 1,
            padding: '0px 6px',
            color: theme.palette.accent.primary,
            textTransform: 'none',
            fontWeight: 'medium',
            whiteSpace: 'nowrap',
            minWidth: 'auto',
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.75rem',
              padding: '0px 4px',
              marginRight: 1,
            },
          }}
        >
          Clear&nbsp;chat
        </Button>
        
        {/* New button */}
        <Button
          variant="text"
          size="small"
          endIcon={
            isMobile ? 
              <AddIcon sx={{ opacity: 0.6, flexShrink: 0, fontSize: '1rem' }} /> : 
              (newChatShortcutKey && (
                <Box 
                  component="span" 
                  sx={{ 
                    display: 'inline-flex',
                    alignItems: 'center',
                    ml: 0.5,
                  }}
                >
                  <Typography 
                    component="span" 
                    variant="caption"
                    sx={{ 
                      fontSize: '0.75rem',
                      opacity: 0.7,
                      lineHeight: 1,
                    }}
                  >
                    {newChatShortcutKey}
                  </Typography>
                </Box>
              ))
          }
          onClick={handleNewChat}
          sx={{
            padding: '0px 6px',
            color: theme.palette.accent.primary,
            textTransform: 'none',
            fontWeight: 'medium',
            whiteSpace: 'nowrap',
            minWidth: 'auto',
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.75rem',
              padding: '0px 4px',
            },
          }}
        >
          New&nbsp;chat
        </Button>
      </Box>
    </>
  )}, [fromName, handleClearThread, setIsSidebarOpen, isMobile, setSelectedEmail, setMessages, setThreadId, setIsLoading, setIsSummarizing, setIsLoadingSummaries, setIsStreaming, toggleEmailListCollapse, isEmailListCollapsed, focusInputField, handleNewChat, newChatShortcutKey]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        const currentPath = window.location.pathname;
        if (currentPath === '/home') {
          event.preventDefault();
          handleClearThread();
        }
      }
      
      // Add handler for new chat shortcut (⌘J or Ctrl+J)
      if ((event.metaKey || event.ctrlKey) && event.key === 'j') {
        const currentPath = window.location.pathname;
        if (currentPath === '/home') {
          event.preventDefault();
          handleNewChat();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    setShortcutKey(isMac ? '⌘K' : 'Ctrl+K');
    setNewChatShortcutKey(isMac ? '⌘J' : 'Ctrl+J');

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClearThread, handleNewChat]);

  return (
    <Box
      sx={{
        backgroundColor: isEmbedded ? theme.palette.accent.secondary : theme.palette.primary.light,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',  // Updated shadow
        padding: isChatView ? '13px 16px' : '8px 16px 6px',
        display: 'flex',
        flexDirection: 'column', // Changed to column
        borderRadius: '5px',
        zIndex: 1,
        cursor: isEmbedded ? 'pointer' : 'default',
      }}
      onClick={isEmbedded ? onToggleExpand : undefined}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '5px'
      }}>
        {(isChatView || fromName?.toLowerCase() === 'lexi') ? renderSimplifiedHeader : renderFullHeader}
        {isEmbedded && (
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        )}
      </Box>

      {/* Existing Popovers */}
      <Popover
        id={recipientsId}
        open={recipientsOpen}
        anchorEl={recipientsAnchorEl}
        onClose={handleRecipientsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            maxWidth: '80%',
            maxHeight: '80%',
            padding: '16px',
            maxWidth: 300,
            backgroundColor: theme.palette.accent.secondary,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Box sx={{ overflowY: 'auto' }}>
          {renderRecipientList('to', hiddenRecipients.to)}
          {(hiddenRecipients.cc.length > 0 && hiddenRecipients.to.length > 0) && <Divider sx={{ my: 2 }} />}
          {renderRecipientList('cc', hiddenRecipients.cc)}
          {(hiddenRecipients.bcc.length > 0 && (hiddenRecipients.to.length > 0 || hiddenRecipients.cc.length > 0)) && <Divider sx={{ my: 2 }} />}
          {renderRecipientList('bcc', hiddenRecipients.bcc)}
        </Box>
      </Popover>
      
      <AttachmentPopover
        id={attachmentsId}
        open={attachmentsOpen}
        anchorEl={attachmentsAnchorEl}
        onClose={handleAttachmentsClose}
        attachments={attachments}
        downloadProgress={downloadProgress}
        onAttachmentClick={handleAttachmentClick}
      />
      
      {isPreviewDialogOpen && (
        <AttachmentPreviewDialog
          open={isPreviewDialogOpen}
          onClose={handleClosePreviewDialog}
          messageId={messageId}
          attachments={attachments}
          initialAttachmentId={selectedAttachmentId}
        />
      )}
    </Box>
  );
});

export default ChatHeader;