import React, { useEffect, useState, useRef } from 'react';
import { Container, Paper, Typography, Button, Box, CircularProgress, Divider, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { axiosWithAuth, saveAccountData } from './authService';
import { isEmailProcessing, setEmailProcessingStatus, clearEmailProcessingStatus } from '../utils/emailProcessingUtil';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import LinearProgress from '@mui/material/LinearProgress';
import { alpha } from '@mui/material/styles';
import axios from 'axios';

const AuthPage = ({ setIsAuthenticated, setIsSubscribed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const processEmailRef = useRef(false);
  const mountedRef = useRef(false);  // Add this line
  const timeoutRef = useRef(null);  // Add timeout ref
  const apiCallsRef = useRef({
    photoFetched: false,
    signatureFetched: false,
    emailsProcessed: false
  });

  // Add cleanup function
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Reset API call flags when component unmounts
      apiCallsRef.current = {
        photoFetched: false,
        signatureFetched: false,
        emailsProcessed: false
      };
    };
  }, []);

  const fetchUserPhoto = async () => {
    if (apiCallsRef.current.photoFetched) return;
    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + '/get-user-photo');
      if (response.data && response.data.photo) {
        localStorage.setItem('userPhoto', response.data.photo);
        console.log('Photo fetched and stored');
      }
      apiCallsRef.current.photoFetched = true;
    } catch (error) {
      console.error('Error fetching user photo:', error);
    }
  };

  const initializeUserData = async (userInfo, accessToken) => {
    try {
      // Create a temporary axios instance with the new token
      const tempAxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      // Use the temporary instance for API calls
      const photoPromise = tempAxiosInstance.get('/get-user-photo');
      const signaturePromise = tempAxiosInstance.get('/get-email-signature', {
        params: { user_email: userInfo.userPrincipalName }
      });
      const emailCountsPromise = tempAxiosInstance.get('/email-status-counts', {
        params: { user_email: userInfo.userPrincipalName }
      });
      const processEmailPromise = tempAxiosInstance.post('/process-emails', {
        userInfo: userInfo, 
        headers: {
          'Content-Type': 'application/json'
        }
      });

      await Promise.all([
        photoPromise.then(response => {
          if (response.data && response.data.photo) {
            localStorage.setItem('userPhoto', response.data.photo);
          }
        }).catch(error => console.error('Error fetching photo:', error)),
        
        signaturePromise.then(response => {
          if (response.data && response.data.signature) {
            localStorage.setItem('userSignature', response.data.signature);
          }
        }).catch(error => console.error('Error fetching signature:', error)),
        
        emailCountsPromise.then(response => {
          if (response.data.counts) {
            const apiCounts = response.data.counts;
            const mappedCounts = {
              completed: apiCounts.COMPLETED || 0,
              failed: apiCounts.FAILED || 0,
              pending: apiCounts.PENDING || 0,
              processing: apiCounts.PROCESSING || 0,
            };
            const total = Object.values(mappedCounts).reduce((a, b) => a + b, 0);
            localStorage.setItem('emailCounts', JSON.stringify({
              ...mappedCounts,
              total,
              lastUpdated: Date.now()
            }));
          }
        }).catch(error => console.error('Error fetching email counts:', error)),
        
        processEmailPromise.then(response => {
          if (response.data.job_id) {
            localStorage.setItem('emailProcessingJobId', response.data.job_id);
          }
        }).catch(error => console.error('Error processing emails:', error))
      ]);

      return {
        userPhoto: localStorage.getItem('userPhoto'),
        userSignature: localStorage.getItem('userSignature')
      };
    } catch (error) {
      console.error('Error initializing user data:', error);
      return {};
    }
  };

  const handleAuth = async (accessToken, userInfoEncoded, isSubscribed) => {
    setIsLoading(true);
    try {
      const decodedAccessToken = atob(accessToken);
      const userInfo = JSON.parse(atob(userInfoEncoded));
      
      if (!decodedAccessToken || !userInfo) {
        throw new Error('Invalid authentication data');
      }

      // Check if this is an additional account
      const urlParams = new URLSearchParams(window.location.search);
      const isAddingAccount = urlParams.get('add_account') === 'true' || localStorage.getItem('adding_account') === 'true';
      
      // Clear the adding_account flag
      localStorage.removeItem('adding_account');

      if (isAddingAccount) {
        // Clear previous user photo when adding new account
        
        // Check if account already exists
        const existingAccounts = JSON.parse(localStorage.getItem('accounts') || '[]');
        const accountExists = existingAccounts.some(acc => acc.email === userInfo.userPrincipalName);
        
        if (accountExists) {
          // Find the existing account data
          const existingAccount = existingAccounts.find(acc => acc.email === userInfo.userPrincipalName);
          
          // Initialize user data to get latest photo and signature
          const userData = await initializeUserData(userInfo, decodedAccessToken);
          
          // Update the existing account with new data
          const updatedAccount = {
            ...existingAccount,
            accessToken: decodedAccessToken,
            userInfo,
            isSubscribed,
            userPhoto: userData.userPhoto,
            userSignature: userData.userSignature,
          };
          
          // Update the accounts list
          const updatedAccounts = existingAccounts.map(acc => 
            acc.email === userInfo.userPrincipalName ? updatedAccount : acc
          );
          localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
          
          // Update localStorage with the updated account's data - don't store accessToken at root
          localStorage.setItem('isSubscribed', isSubscribed);
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('userSignature', updatedAccount.userSignature);
          localStorage.setItem('userPhoto', updatedAccount.userPhoto);
          localStorage.setItem('currentAccount', JSON.stringify(updatedAccount));
          
          // Update auth states
          setIsAuthenticated(true);
          setIsSubscribed(isSubscribed);
          
          // Get current URL parameters, excluding auth-specific ones
          const currentParams = new URLSearchParams();
          urlParams.forEach((value, key) => {
            if (!['access_token', 'is_subscribed', 'user_info', 'error', 'code', 'state', 'forward_params', 'original_params'].includes(key)) {
              currentParams.append(key, value);
            }
          });

          // Navigate to inbox with the appropriate parameters
          const finalPath = currentParams.toString() ? `/inbox?${currentParams.toString()}` : '/inbox';
          
          // Clear stored parameters as they're no longer needed
          localStorage.removeItem('auth_redirect_params');
          
          navigate(finalPath, { replace: true });
          return;
        }

        // Initialize user data for the new account and get the fetched data
        const userData = await initializeUserData(userInfo, decodedAccessToken);

        // Save the new account data with the fetched user data
        const accountData = {
          accessToken: decodedAccessToken,
          userInfo,
          isSubscribed,
          email: userInfo.userPrincipalName,
          name: `${userInfo.givenName} ${userInfo.surname}`,
          userSignature: userData.userSignature,
          userPhoto: userData.userPhoto,
        };
        
        // Save the account data and set up current session
        await saveAccountData(accountData);
        
        // Set up current session data - don't store accessToken at root
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('isSubscribed', isSubscribed);
        if (userData.userPhoto) localStorage.setItem('userPhoto', userData.userPhoto);
        if (userData.userSignature) localStorage.setItem('userSignature', userData.userSignature);
        localStorage.setItem('currentAccount', JSON.stringify(accountData));
        
        // Update auth states before redirecting
        setIsAuthenticated(true);
        setIsSubscribed(isSubscribed);
        
        // Get current URL parameters, excluding auth-specific ones
        const currentParams = new URLSearchParams();
        urlParams.forEach((value, key) => {
          if (!['access_token', 'is_subscribed', 'user_info', 'error', 'code', 'state', 'forward_params', 'original_params'].includes(key)) {
            currentParams.append(key, value);
          }
        });

        // Navigate to inbox with the appropriate parameters
        const finalPath = currentParams.toString() ? `/inbox?${currentParams.toString()}` : '/inbox';
        
        // Clear stored parameters as they're no longer needed
        localStorage.removeItem('auth_redirect_params');
        
        navigate(finalPath, { replace: true });
        return;
      }

      // Normal login flow for first account - don't store accessToken at root
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      localStorage.setItem('isSubscribed', isSubscribed);

      // Initialize user data and get the fetched data
      const userData = await initializeUserData(userInfo, decodedAccessToken);

      // Save as first account with photo
      const accountData = {
        accessToken: decodedAccessToken,
        userInfo,
        isSubscribed,
        email: userInfo.userPrincipalName,
        name: `${userInfo.givenName} ${userInfo.surname}`,
        userPhoto: userData.userPhoto,
        userSignature: userData.userSignature,
      };

      // Save the account data
      await saveAccountData(accountData);

      // Update auth states
      setIsAuthenticated(true);
      setIsSubscribed(isSubscribed);

      // Clear any existing processing state when logging in
      localStorage.removeItem('emailProcessingJobId');

      // Only initialize user data if subscribed
      if (isSubscribed) {
        if (userData.userPhoto) localStorage.setItem('userPhoto', userData.userPhoto);
        if (userData.userSignature) localStorage.setItem('userSignature', userData.userSignature);
        
        // Get current URL parameters, excluding auth-specific ones
        const currentParams = new URLSearchParams();
        urlParams.forEach((value, key) => {
          if (!['access_token', 'is_subscribed', 'user_info', 'error', 'code', 'state', 'forward_params', 'original_params'].includes(key)) {
            currentParams.append(key, value);
          }
        });

        // Navigate to inbox with the appropriate parameters
        const finalPath = currentParams.toString() ? `/inbox?${currentParams.toString()}` : '/inbox';
        
        // Clear stored parameters as they're no longer needed
        localStorage.removeItem('auth_redirect_params');
        
        navigate(finalPath, { replace: true });
      } else {
        // Clear any existing data if not subscribed
        sessionStorage.clear();
        localStorage.removeItem('emailProcessingJobId');
        navigate('/auth', { replace: true });
      }

    } catch (err) {
      console.error('Auth error:', err);
      setError('Authentication failed. Please try again.');
      setIsAuthenticated(false);
      setIsSubscribed(false);
      
      // Save the auth_redirect_params before clearing localStorage
      const storedParams = localStorage.getItem('auth_redirect_params');
      
      // Clear localStorage
      localStorage.clear();
      
      // Restore the auth_redirect_params if they existed
      if (storedParams) {
        localStorage.setItem('auth_redirect_params', storedParams);
      }
      
      sessionStorage.clear();
      navigate('/auth', { replace: true });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const accessToken = urlParams.get('access_token');
    const isSubscribed = urlParams.get('is_subscribed') === 'True';
    const userInfoEncoded = urlParams.get('user_info');
    const error = urlParams.get('error');

    // If we have new auth params, process them
    if (accessToken && userInfoEncoded) {
      // Get stored URL parameters and original URL from before the auth redirect
      const storedParams = localStorage.getItem('auth_redirect_params');
      
      // Add the stored parameters to the current URL params
      if (storedParams) {
        const storedParamsObj = new URLSearchParams(storedParams);
        
        // Add all original parameters except auth-specific ones
        storedParamsObj.forEach((value, key) => {
          // Skip auth-specific parameters
          if (!['access_token', 'is_subscribed', 'user_info', 'error', 'code', 'state', 'forward_params', 'original_params'].includes(key)) {
            urlParams.set(key, value);
          }
        });
      }
      
      // Clear URL params after reading
      if (window.history.replaceState) {
        // If we have stored params, restore them after auth
        if (storedParams) {
          // Create a new URL with the merged parameters
          const newUrl = `/auth?${urlParams.toString()}`;
          window.history.replaceState({}, document.title, newUrl);
        } else {
          window.history.replaceState({}, document.title, '/auth');
        }
      }
      
      handleAuth(accessToken, userInfoEncoded, isSubscribed);
      return;
    }

    // Handle error or reset loading state
    if (error) {
      // Get stored URL parameters from before the auth redirect
      const storedParams = localStorage.getItem('auth_redirect_params');
      
      // If we have stored params, restore them after auth error
      if (storedParams && window.history.replaceState) {

        const newUrl = `/auth?${storedParams}`;
        window.history.replaceState({}, document.title, newUrl);

      }
      
      setError(decodeURIComponent(error));
    }

    // Handle existing session only if no new auth attempt
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    const existingToken = currentAccount.accessToken;
    const existingSubscription = localStorage.getItem('isSubscribed') === 'true';
    
    if (existingToken) {
      setIsAuthenticated(true);
      setIsSubscribed(existingSubscription);
      
      if (existingSubscription) {
        // Get any preserved URL parameters that should be forwarded
        const forwardParams = urlParams.get('forward_params');
        if (forwardParams) {
          navigate(`/inbox?${forwardParams}`, { replace: true });
        } else {
          navigate('/inbox', { replace: true });
        }
        return;
      }
    }

    // Handle error or reset loading state
    setIsLoading(false);
  }, [location.search, navigate, setIsAuthenticated, setIsSubscribed]);

  const handleMicrosoftLogin = () => {
    try {
      setIsLoading(true);
      const baseUrl = process.env.REACT_APP_API_URL;
      if (!baseUrl) {
        throw new Error('API URL not configured');
      }

      // Capture all current URL parameters
      const currentUrlParams = new URLSearchParams(window.location.search);
      
      // Store all current URL parameters in localStorage
      if (currentUrlParams.toString()) {
        // Store the raw URL parameters string
        localStorage.setItem('auth_redirect_params', currentUrlParams.toString());
      }

      // Check if we're adding a new account
      const isAddingAccount = currentUrlParams.get('add_account') === 'true';
      const promptParam = currentUrlParams.get('prompt');

      // Get environment from REACT_APP_ENVIRONMENT
      const environment = process.env.REACT_APP_ENVIRONMENT || 'production';

      // Build the redirect URI with appropriate parameters
      let redirectUri = `${baseUrl}/login`;
      const params = [];
      
      // Add environment parameter
      params.push(`environment=${environment}`);
      
      if (isAddingAccount) {
        params.push('add_account=true');
      }
      
      if (promptParam) {
        params.push(`prompt=${promptParam}`);
      }
      
      // Add the original URL parameters as a single encoded parameter
      if (currentUrlParams.toString()) {
        params.push(`original_params=${encodeURIComponent(currentUrlParams.toString())}`);
      }
      
      if (params.length > 0) {
        redirectUri += `?${params.join('&')}`;
      }
      
      // If adding account, store the flag
      if (isAddingAccount) {
        localStorage.setItem('adding_account', 'true');
      }

      window.location.href = redirectUri;
    } catch (err) {
      console.error('Login error:', err);
      setError('Unable to initiate login. Please try again.');
      setIsLoading(false);
    }
  };

  const renderContent = () => (
    <Paper elevation={3} sx={{ 
      padding: 4, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      maxWidth: '400px', 
      width: '100%',
      backgroundColor: theme => theme.palette.accent.secondary,
    }}>
      <Typography component="h1" variant="h5" gutterBottom>
        {isLoading ? 'Logging In' : 'Welcome Back'}
      </Typography>
      <Typography variant="body2" align="center" sx={{ mb: 3 }}>
        {isLoading ? 'Please wait while we connect you to Microsoft' : 'Sign in to access your account and stay updated'}
      </Typography>
      
      <Box sx={{ width: '100%', mb: 3 }}>
        {isLoading ? (
          <LinearProgress 
          sx={{ 
            height: 4,
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme => theme.palette.accent.primary
            }
          }} 
        />
        ) : (
          <Button
            fullWidth
            variant="contained"
            startIcon={<MicrosoftIcon />}
            onClick={handleMicrosoftLogin}
            sx={{
              backgroundColor: '#2f2f2f',
              color: 'white',
              '&:hover': {
                backgroundColor: '#1f1f1f',
              },
            }}
          >
            Sign in with Microsoft
          </Button>
        )}
      </Box>
      
      {error && (
        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Typography>
      )}
      
      <Divider sx={{ width: '100%', mb: 3 }}>
        </Divider>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <AutoFixHighIcon color="action" />
            <Typography variant="body2">
              Your emails are automatically summarized
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <SmartButtonIcon color="action" />
            <Typography variant="body2">
              Generate instant replies with 1 click
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <ManageSearchIcon color="action" />
            <Typography variant="body2">
              Search your entire inbox with a few words
            </Typography>
          </Box>
        </Box> 
      </Paper>
  );

  return (
    <Container component="main" disableGutters maxWidth={false} sx={{ 
      height: '100vh', 
      width: '100vw', 
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bgcolor: theme => theme.palette.primary.light
    }}>
      {renderContent()}
    </Container>
  );
};

export default AuthPage;