import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDrop, useDragLayer } from 'react-dnd';
import { Box, List, ListItem, ListItemIcon, Tooltip, Divider, IconButton, useMediaQuery, Menu, MenuItem, Avatar, Badge, CircularProgress, Collapse } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import FlagIcon from '@mui/icons-material/Flag';
import SendIcon from '@mui/icons-material/Send';
import DraftsIcon from '@mui/icons-material/Drafts';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import ContactsIcon from '@mui/icons-material/Contacts';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { switchAccount, axiosWithAuth } from './authService';
import axios from 'axios';

const DragLayer = () => {
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));

  return isDragging;
};

// Add CustomDragLayer component for visual feedback during drag
const CustomDragLayer = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const theme = useTheme();

  if (!isDragging || !currentOffset) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: currentOffset.x + 15,
        top: currentOffset.y + 15,
        backgroundColor: `${theme.palette.accent.primary}CC`,
        color: 'white',
        padding: '4px 8px',
        borderRadius: '5px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
    >
      {item?.ids?.length} email{item?.ids?.length !== 1 ? 's' : ''}
    </div>
  );
};

const DroppableFolderItem = ({ item, isSelected, onClick, children, currentFolder }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'EMAIL',
    drop: (draggedItem) => {
      // Return folder info for the drop target, including folder ID if available
      return { 
        folder: item.key,
        folderId: item.id // Include the folder's actual ID
      };
    },
    canDrop: (draggedItem) => {
      // Only allow dropping if:
      // 1. Not dropping on conversations folder
      // 2. Not dropping on home folder
      // 3. Not dropping on the same folder the email is already in
      return item.key !== 'conversations' && 
             item.key !== draggedItem.sourceFolder;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const isDragging = DragLayer();
  const isDroppable = item.key !== 'conversations' && item.key !== 'home' && item.key !== currentFolder;
  const theme = useTheme();

  return (
    <div 
      ref={drop} 
      style={{ 
        backgroundColor: isOver && canDrop && isDroppable ? `${theme.palette.accent.darkGreen}26` : 'transparent',
        opacity: isDragging && !isDroppable ? 0.5 : 1,
        cursor: isDroppable ? 'pointer' : 'default',
        transition: 'all 0.2s ease',
        borderRadius: '6px',
      }}
    >
      {/* <Tooltip title={item.text} placement="right"> */}
        <Box sx={{ width: '100%' }}>
          {children}
        </Box>
      {/* </Tooltip> */}
    </div>
  );
};

const getGradientColor = (name) => {
  // Handle undefined or empty name
  if (!name) {
    return `linear-gradient(135deg, 
      hsl(220, 70%, 50%) 0%, 
      hsl(200, 65%, 45%) 100%
    )`;
  }

  // Generate a unique hue based on the name
  const nameHash = name.split('').reduce((acc, char, i) => acc + char.charCodeAt(0) * (i + 1), 0);
  const hue = nameHash % 360; // Get a value between 0-359 for the hue
  
  // Create complementary hues for the gradient
  const hue2 = (hue + 20) % 360;
  
  // Use vibrant colors with higher saturation and medium lightness
  const saturation = 70 + (name.length % 15); // 70-85%
  const lightness = 35 + (name.length % 15); // 35-50% - darker to ensure visibility

  return `linear-gradient(135deg, 
    hsl(${hue}, ${saturation}%, ${lightness}%) 0%, 
    hsl(${hue2}, ${saturation - 5}%, ${lightness - 5}%) 100%
  )`;
};

const AccountSwitcher = ({ accounts, currentAccount, onAccountChange, onAddAccount, isMobile }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMailbox, setSelectedMailbox] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract shared mailboxes from current account's userInfo
  useEffect(() => {
    if (currentAccount?.userInfo) {
      // Check if a mailbox is selected in the URL
      const searchParams = new URLSearchParams(location.search);
      const mailboxParam = searchParams.get('mailbox');
      if (mailboxParam) {
        setSelectedMailbox(mailboxParam);
      } else {
        setSelectedMailbox(null);
      }
    }
  }, [currentAccount, location.search]);

  // Reorder accounts to put current account first
  const orderedAccounts = useMemo(() => {
    if (!currentAccount) return accounts;
    return [
      currentAccount,
      ...accounts.filter(account => account.email !== currentAccount.email)
    ];
  }, [accounts, currentAccount]);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAccountSelect = async (account) => {
    if (account.email === currentAccount?.email) {
      setIsExpanded(false);
      return;
    }

    setIsLoading(true);
    try {
      await switchAccount(account);
      onAccountChange(account);
      setIsExpanded(false);
    } catch (error) {
      console.error('Failed to switch account:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMailboxSelect = async (mailbox) => {
    setIsExpanded(false);
    setIsLoading(true);
    
    try {
      // Clear all URL parameters when changing mailboxes
      if (mailbox) {
        // Create a new clean search params object with only the mailbox parameter
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('mailbox', typeof mailbox === 'string' ? mailbox : mailbox.email);
        setSelectedMailbox(typeof mailbox === 'string' ? mailbox : mailbox.email);
        
        // Update URL with the mailbox parameter
        const url = `?${newSearchParams.toString()}`;
        window.history.pushState({}, '', url);
      } else {
        // Clear all URL parameters
        setSelectedMailbox(null);
        window.history.pushState({}, '', '/');
      }
      
      // Clear folder cache
      sessionStorage.removeItem('userFolders');
      sessionStorage.removeItem('foldersFetched');
      
      // Refresh the page to reinitialize with new mailbox
      window.location.reload();
    } catch (error) {
      console.error('Failed to switch mailbox:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Styles for both mobile and desktop
  const styles = {
    container: {
      py: 0.75,
      px: 2,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 1.5,
      borderRadius: '6px',
      mx: 0,
      mb: 1,
      '&:hover': {
        backgroundColor: `${theme.palette.accent.secondary}CC`,
      },
    },
    accountInfo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexGrow: 1,
      minWidth: 0,
    },
    name: {
      fontSize: '0.85rem',
      fontWeight: 600,
      color: theme.palette.ui.textLight,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    email: {
      fontSize: '0.75rem',
      color: theme.palette.text.secondary,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    mailboxBadge: {
      fontSize: '0.7rem',
      color: theme.palette.accent.darkGreen,
      backgroundColor: `${theme.palette.accent.darkGreen}15`,
      padding: '2px 6px',
      borderRadius: '4px',
      marginLeft: '4px',
    },
  };

  const renderAvatar = (account, index = 0) => (
    <Avatar
      src={account.userPhoto}
      alt={account.name}
      sx={{
        width: 28,
        height: 28,
        backgroundImage: !account.userPhoto ? getGradientColor(account.name) : undefined,
        color: !account.userPhoto ? '#fff' : undefined,
        fontWeight: 600,
        fontSize: '0.85rem',
      }}
    >
      {!account.userPhoto && account.name?.charAt(0)}
    </Avatar>
  );

  const renderMailboxAvatar = (mailbox) => (
    <Avatar
      sx={{
        width: 24,
        height: 24,
        backgroundImage: getGradientColor(mailbox),
        color: '#fff',
        fontWeight: 600,
        fontSize: '0.75rem',
      }}
    >
      {mailbox.charAt(0).toUpperCase()}
    </Avatar>
  );

  // Get shared mailboxes for an account
  const getAccountSharedMailboxes = (account) => {
    if (!account?.userInfo?.departments) return [];
    
    const mailboxes = [];
    account.userInfo.departments.forEach(department => {
      if (department.shared_mail_boxes) {
        const boxes = Array.isArray(department.shared_mail_boxes) 
          ? department.shared_mail_boxes 
          : [department.shared_mail_boxes];
        
        boxes.forEach(mailbox => {
          if (mailbox) {
            mailboxes.push({
              email: mailbox,
              departmentName: department.name || 'Shared Mailbox'
            });
          }
        });
      }
    });
    return mailboxes;
  };

  // Add helper function to get department name for selected mailbox
  const getSelectedMailboxInfo = () => {
    for (const account of orderedAccounts) {
      const mailboxes = getAccountSharedMailboxes(account);
      const found = mailboxes.find(m => m.email === selectedMailbox);
      if (found) return found;
    }
    return null;
  };

  if (currentAccount) {
    const selectedMailboxInfo = selectedMailbox ? getSelectedMailboxInfo() : null;

    return (
      <Box sx={{ 
        width: '100%',
        display: 'flex',
        ...styles.container,
        cursor: 'pointer',
        backgroundColor: 'transparent',
      }}
      onClick={handleExpandClick}
      >
        {selectedMailbox ? renderMailboxAvatar(selectedMailbox) : renderAvatar(currentAccount)}
        <Box sx={styles.accountInfo}>
          <Box sx={styles.name}>
            {selectedMailboxInfo ? selectedMailboxInfo.departmentName : currentAccount.name}
          </Box>
          <Box sx={styles.email}>{selectedMailbox || currentAccount.email}</Box>
        </Box>
        {isLoading && (
          <CircularProgress size={16} sx={{ color: theme.palette.accent.darkGreen }} />
        )}
        <Menu
          anchorEl={isExpanded ? document.body : null}
          open={isExpanded}
          onClose={() => setIsExpanded(false)}
          PaperProps={{
            sx: {
              width: '250px',
              mt: 1,
              backgroundColor: theme.palette.accent.secondary,
              '& .MuiList-root': {
                py: 1,
              },
            },
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {orderedAccounts.map((account, index) => {
            const accountMailboxes = getAccountSharedMailboxes(account);
            const isCurrentAccount = account.email === currentAccount.email;
            
            return (
              <React.Fragment key={account.email}>
                {index === 0 && (
                  <Box sx={{ px: 2, py: 0.5, fontSize: '0.7rem', color: theme.palette.text.secondary, fontWeight: 600 }}>
                    CURRENT ACCOUNT
                  </Box>
                )}
                {index === 1 && orderedAccounts.length > 1 && (
                  <Box sx={{ px: 2, py: 0.5, mt: 1, fontSize: '0.7rem', color: theme.palette.text.secondary, fontWeight: 600 }}>
                    OTHER ACCOUNTS
                  </Box>
                )}
                <MenuItem
                  onClick={() => isCurrentAccount ? handleMailboxSelect(null) : handleAccountSelect(account)}
                  sx={{
                    py: 1,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    backgroundColor: isCurrentAccount && !selectedMailbox ? `${theme.palette.accent.secondary}CC` : 'transparent',
                    '&:hover': {
                      backgroundColor: `${theme.palette.accent.secondary}CC`,
                    },
                  }}
                >
                  {renderAvatar(account)}
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minWidth: 0,
                  }}>
                    <Box sx={{
                      fontSize: '0.85rem',
                      fontWeight: isCurrentAccount && !selectedMailbox ? 600 : 400,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>{account.name}</Box>
                    <Box sx={{
                      fontSize: '0.75rem',
                      color: theme.palette.text.secondary,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>{account.email}</Box>
                  </Box>
                </MenuItem>

                {/* Only show shared mailboxes for the current account */}
                {isCurrentAccount && accountMailboxes.length > 0 && (
                  <Box sx={{ pl: 2 }}>
                    {accountMailboxes.map((mailbox) => (
                      <MenuItem
                        key={mailbox.email}
                        onClick={() => handleMailboxSelect(mailbox)}
                        sx={{
                          py: 0.75,
                          px: 2,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1.5,
                          backgroundColor: selectedMailbox === mailbox.email ? `${theme.palette.accent.secondary}CC` : 'transparent',
                          '&:hover': {
                            backgroundColor: `${theme.palette.accent.secondary}CC`,
                          },
                        }}
                      >
                        {renderMailboxAvatar(mailbox.email)}
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          flexGrow: 1,
                          minWidth: 0,
                        }}>
                          <Box sx={{
                            fontSize: '0.8rem',
                            fontWeight: selectedMailbox === mailbox.email ? 600 : 400,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>{mailbox.departmentName}</Box>
                          <Box sx={{
                            fontSize: '0.7rem',
                            color: theme.palette.text.secondary,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}>{mailbox.email}</Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Box>
                )}
              </React.Fragment>
            );
          })}

          {/* Add account option */}
          <Divider sx={{ my: 1 }} />
          <MenuItem
            onClick={() => {
              setIsExpanded(false);
              onAddAccount();
            }}
            sx={{
              py: 1,
              px: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Avatar sx={{
              width: 28,
              height: 28,
              border: `1px dashed ${theme.palette.text.secondary}`,
              color: theme.palette.text.secondary,
              backgroundColor: 'transparent',
            }}>
              <AddIcon sx={{ fontSize: 14 }} />
            </Avatar>
            <Box sx={{ fontSize: '0.85rem' }}>Add another account</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  }

  return null;
};

// FolderItem component to handle both regular and parent folders
const FolderItem = ({ 
  item, 
  currentFolder, 
  handleItemClick, 
  level = 0, 
  isMobile, 
  setIsSidebarOpen,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = item.childFolders && item.childFolders.length > 0;
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const isViewingSingleEmail = !!searchParams.get('mailthreadid');
  const mailboxParam = searchParams.get('mailbox');
  const isSharedMailbox = !!mailboxParam;
  
  // Hide conversations item when shared mailbox is selected
  if (item.key === 'conversations' && isSharedMailbox) {
    return null;
  }
  
  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  
  // Create the folder item content
  console.log('currentFolder from FolderItem', currentFolder);
  const folderItemContent = (
    <>
      <ListItem
        button
        onClick={() => {
          // Create a new clean search params object
          const newSearchParams = new URLSearchParams();
          
          // Only preserve mailbox parameter if it exists
          const mailbox = searchParams.get('mailbox');
          if (mailbox) {
            newSearchParams.set('mailbox', mailbox);
          }
          
          // Add the folder parameter, ensuring inbox defaults to inbox_focused
          const folderValue = item.folderType === 'inbox' ? 'inbox_focused' : item.key;
          newSearchParams.set('folder', folderValue);
          
          // Navigate with only the necessary parameters
          navigate(`?${newSearchParams.toString()}`);
          
          if (isMobile) setIsSidebarOpen(false);
        }}
        sx={{
          height: level > 0 ? '36px' : '40px', // Make child folders smaller
          borderRadius: '6px',
          py: 0.5,
          px: level > 0 ? 1 : 3,
          ml: level > 0 ? 3 : 0, // Increased left margin for child folders
          color: (!isViewingSingleEmail && (currentFolder === item.key || (item.folderType === 'inbox' && currentFolder === 'inbox_focused')))
            ? theme.palette.accent.primary 
            : theme.palette.ui.textLight,
          backgroundColor: (!isViewingSingleEmail && (currentFolder === item.key || (item.folderType === 'inbox' && currentFolder === 'inbox_focused')))
            ? `${theme.palette.accent.primary}15`
            : 'transparent',
          '&:hover': {
            backgroundColor: (!isViewingSingleEmail && (currentFolder === item.key || (item.folderType === 'inbox' && currentFolder === 'inbox_focused')))
              ? `${theme.palette.accent.primary}25`
              : 'rgba(0, 0, 0, 0.04)',
          },
          transition: 'all 0.2s ease',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: level > 0 ? '0.8rem' : '0.85rem', // Smaller font for child folders
          width: '100%', // Ensure full width
          maxWidth: level > 0 ? 'calc(100% - 3px)' : '100%', // Adjust width for nested folders
          overflow: 'hidden', // Prevent overflow
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          minWidth: 0, 
          flexGrow: 1,
          maxWidth: level > 0 ? 'calc(100% - 24px)' : '100%', // Adjust width for nested folders
          overflow: 'hidden', // Prevent overflow
        }}>
          <ListItemIcon
            sx={{
              minWidth: level > 0 ? '32px' : '36px', // Smaller icon area for child folders
              color: 'inherit',
              flexShrink: 0, // Prevent icon from shrinking
            }}
          >
            {/* <Badge
              badgeContent={item.unreadItems || 0}
              max={99}
              color="error"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: theme.palette.accent.darkGreen,
                  color: 'white',
                  fontWeight: 600,
                  fontSize: '0.55rem',
                  minWidth: '16px',
                  height: '16px',
                  padding: '0 4px',
                  borderRadius: '8px',
                  display: item.unreadItems && item.unreadItems > 0 ? 'flex' : 'none', // Only show badge when unread count > 0
                  transform: 'translate(-50%, -50%)',
                },
              }}
            > */}
              {React.cloneElement(item.icon, {
                style: { 
                  fontSize: level > 0 ? '18px' : '20px', // Smaller icon for child folders
                  transition: 'transform 0.2s ease',
                  transform: currentFolder === item.key ? 'scale(1.1)' : 'scale(1)'
                }
              })}
            {/* </Badge> */}
          </ListItemIcon>
          <ListItemText 
            primary={item.text}
            sx={{
              margin: 0,
              overflow: 'hidden', // Add overflow hidden to the container
              flexGrow: 1,
              minWidth: 0, // Important for text truncation
              maxWidth: level > 0 ? 'calc(100% - 8px)' : '100%', // Adjust width for nested folders
              '& .MuiListItemText-primary': {
                color: 'inherit',
                fontWeight: (!isViewingSingleEmail && (currentFolder === item.key || (item.folderType === 'inbox' && currentFolder === 'inbox_focused'))) ? 600 : 400,
                fontSize: level > 0 ? '0.8rem' : '0.85rem', // Smaller text for child folders
                letterSpacing: '0.01em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%', // Ensure the text takes full width of container
                display: 'block', // Ensure block display for proper truncation
              },
            }}
          />
        </Box>
        
        {hasChildren && (
          <Box 
            onClick={handleExpandClick}
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 0.5,
              color: 'inherit',
              cursor: 'pointer',
              flexShrink: 0, // Prevent shrinking
              ml: 0.5, // Add margin to separate from text
            }}
          >
            {isExpanded ? <ExpandMoreIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </Box>
        )}
      </ListItem>
      
      {hasChildren && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit sx={{ 
          width: '100%', 
          overflow: 'hidden',
          paddingRight: level > 0 ? '8px' : '0px', // Add padding for nested folders
        }}>
          <List component="div" disablePadding sx={{ 
            width: '100%', 
            overflow: 'hidden',
            '& .MuiListItem-root': {
              width: '100%',
              maxWidth: '100%',
              boxSizing: 'border-box',
            }
          }}>
            {item.childFolders.map((childItem) => (
              <FolderItem
                key={childItem.key || childItem.id}
                item={childItem}
                currentFolder={currentFolder}
                handleItemClick={handleItemClick}
                level={level + 1}
                isMobile={isMobile}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
  
  // Only wrap with DroppableFolderItem if it's a valid drop target
  // Exclude special folders like conversations and home, and the current folder
  const isValidDropTarget = item.key !== 'conversations';
  
  if (isValidDropTarget) {
    return (
      <DroppableFolderItem 
        item={item} 
        isSelected={currentFolder === item.key} 
        onClick={() => {
          handleItemClick(item.key);
          if (isMobile) setIsSidebarOpen(false);
        }}
        currentFolder={currentFolder}
      >
        {folderItemContent}
      </DroppableFolderItem>
    );
  }
  
  // For non-droppable folders, return the content directly
  return folderItemContent;
};

const Sidebar = ({ onFolderChange, onOpenProfileDialog, currentFolder, isSidebarOpen = false, setIsSidebarOpen, folderEmails = {} }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const emailId = searchParams.get('mailthreadid');
  const mailboxParam = searchParams.get('mailbox');
  const isViewingSingleEmail = !!emailId;
  const [loading, setLoading] = useState(false);
  const [userFolders, setUserFolders] = useState([]);
  const [error, setError] = useState(null);
  
  // Define refreshFolders function at component level with useCallback
  const refreshFolders = useCallback(async () => {
    // Clear folder cache
    sessionStorage.removeItem('userFolders');
    sessionStorage.removeItem('foldersFetched');
    
    // Set loading state
    setLoading(true);
    // Don't set error state here anymore
    
    try {
      // Get current account info for the access token
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const accessToken = currentAccount.accessToken;
      
      if (!accessToken) {
        throw new Error('No access token found');
      }
      
      // Check for mailbox parameter in URL
      const searchParams = new URLSearchParams(location.search);
      const mailboxParam = searchParams.get('mailbox');
      
      // Prepare request headers
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      };
      
      // Add mailbox to headers if present
      if (mailboxParam) {
        headers['userEmail'] = mailboxParam;
        console.log(`Fetching folders for shared mailbox: ${mailboxParam}`);
      }
      
      const response = await axiosWithAuth.get(`${process.env.REACT_APP_API_URL}/get-user-folders`, {
        headers: headers
      });
      
      if (response.data && Array.isArray(response.data.folders)) {
        setUserFolders(response.data.folders);
        
        // Store folder data in session storage
        const folderData = response.data.folders;
        sessionStorage.setItem('userFolders', JSON.stringify(folderData));
        
        // Mark that folders have been fetched
        sessionStorage.setItem('foldersFetched', 'true');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error refreshing user folders:', error);
      // Only set error state if we've tried multiple times
      const retryCount = sessionStorage.getItem('folderRetryCount') || '0';
      const newRetryCount = parseInt(retryCount) + 1;
      sessionStorage.setItem('folderRetryCount', newRetryCount.toString());
      
      if (newRetryCount >= 3) { // After 3 attempts, show the error
        setError('Failed to refresh folders');
        setUserFolders([]);
      } else {
        // Don't show error yet, just set empty folders
        setUserFolders([]);
      }
    } finally {
      setLoading(false);
    }
  }, [location.search]);
  
  // Add this useEffect to sync with URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const folderParam = searchParams.get('folder');
    
    if (folderParam && folderParam !== currentFolder) {
      onFolderChange(folderParam);
    }
  }, [location.search, currentFolder, onFolderChange]);

  // Fetch user folders from API - modified to only fetch once
  useEffect(() => {
    // Create a flag to track if folders have been fetched
    const foldersFetched = sessionStorage.getItem('foldersFetched');
    
    const fetchUserFolders = async () => {
      // Check if we already have folders in session storage
      const cachedFolders = sessionStorage.getItem('userFolders');
      
      if (cachedFolders) {
        // Use cached data if available
        setUserFolders(JSON.parse(cachedFolders));
        setLoading(false);
        return;
      }
      
      // If no cached data, fetch from API
      await refreshFolders();
      // Mark that folders have been fetched in this session
      sessionStorage.setItem('foldersFetched', 'true');
    };
    
    // Only fetch if not already fetched in this session
    if (!foldersFetched) {
      fetchUserFolders();
    } else if (sessionStorage.getItem('userFolders')) {
      // If already fetched but we need to set the state from cache
      const folders = JSON.parse(sessionStorage.getItem('userFolders'));
      // Add keys to the folders if they don't exist
      const foldersWithKeys = folders.map(folder => {
        if (folder.key) return folder;
        const folderKey = folder.displayName;
        return { ...folder, key: folderKey };
      });
      setUserFolders(foldersWithKeys);
      sessionStorage.setItem('userFolders', JSON.stringify(foldersWithKeys));
    }
    
    // This effect should only run once on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add event listener for folder updates
  useEffect(() => {
    const handleFolderUpdates = () => {
      // When explicitly asked to refresh folders, we should do so
      // regardless of whether they've been fetched before
      refreshFolders();
    };
    
    window.addEventListener('refreshFolders', handleFolderUpdates);
    
    return () => {
      window.removeEventListener('refreshFolders', handleFolderUpdates);
    };
  }, [refreshFolders]); // Only re-attach if refreshFolders changes

  // Helper function to find folder ID by display name
  const findFolderIdByDisplayName = useCallback((displayName, folders) => {
    if (!folders || !Array.isArray(folders)) return null;

    // Recursive function to search through folder hierarchy
    const searchFolders = (folderList) => {
      for (const folder of folderList) {
        if (folder.displayName === displayName) {
          return folder.id;
        }
        
        if (folder.childFolders && Array.isArray(folder.childFolders) && folder.childFolders.length > 0) {
          const childResult = searchFolders(folder.childFolders);
          if (childResult) return childResult;
        }
      }
      return null;
    };
    
    return searchFolders(folders);
  }, []);

  // Add this to detect if running in Electron
  const isElectron = () => {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
      return true;
    }
    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
    }
    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
      return true;
    }
    return false;
  };

  console.log('folderEmails sidebar', folderEmails);
  

  const handleItemClick = (item) => {
    if (item === 'profile') {
      onOpenProfileDialog();
    } else {
      // Create a new clean search params object
      const newSearchParams = new URLSearchParams();
      
      // Add the folder parameter
      newSearchParams.set('folder', item);
      
      // Preserve only the mailbox parameter if it exists
      const mailboxParam = searchParams.get('mailbox');
      if (mailboxParam) {
        newSearchParams.set('mailbox', mailboxParam);
      }
      
      // Update URL with only necessary parameters
      navigate(`?${newSearchParams.toString()}`);
      onFolderChange(item);
    }
  };

  // Map folder types to icons
  const getFolderIcon = (folderType) => {
    const iconMap = {
      'inbox': <InboxIcon />,
      'drafts': <DraftsIcon />,
      'sent': <SendIcon />,
      'archive': <ArchiveIcon />,
      'trash': <DeleteIcon />,
      'junk': <DeleteIcon />,
      'conversations': <QuestionAnswerIcon />,
    };
    
    return iconMap[folderType] || <FolderIcon />;
  };

  // Helper function to create a folder item from API data
  const createFolderItem = (folder, parentId = null) => {
    const folderType = folder.folderType || 'other';
    const folderId = folder.id;
    const displayName = folder.displayName;
    
    // Map folder types to standardized keys expected by MailView
    let folderKey;

      // For all other folders, use the display name directly
    folderKey = displayName;

    return {
      text: displayName,
      icon: getFolderIcon(folderType),
      key: folderKey,
      id: folderId,
      parentFolderId: parentId || folder.parentFolderId,
      folderType,
      childFolderCount: folder.childFolderCount || 0,
      unreadItems: folder.unreadItems || 0,
      childFolders: []
    };
  };

  // Organize folders into categories with hierarchy
  const organizedFolders = useMemo(() => {
    if (userFolders.length === 0) {
      // Fallback to default folders if API fails or returns empty
      return [
        { 
          category: 'MAIL',
          items: [
            { text: 'Inbox', icon: <InboxIcon />, key: 'inbox_focused', folderType: 'inbox' },            
            { text: 'Drafts', icon: <DraftsIcon />, key: 'drafts', folderType: 'drafts' },
            { text: 'Sent', icon: <SendIcon />, key: 'sentitems', folderType: 'sent' },
          ]
        },
        {
          category: 'MANAGEMENT',
          items: [
            { text: 'Archive', icon: <ArchiveIcon />, key: 'archive', folderType: 'archive' },
            { text: 'Trash', icon: <DeleteIcon />, key: 'deleteditems', folderType: 'trash' },
            { text: 'Conversations', icon: <QuestionAnswerIcon />, key: 'conversations', folderType: 'conversations' },
          ]
        }
      ];
    }
    
    // First, create a map of folder IDs to their folder objects
    const folderMap = new Map();
    const rootFolders = [];
    
    // Process all main folders first
    userFolders.forEach(folder => {
      const folderItem = createFolderItem(folder);
      folderMap.set(folder.id, folderItem);
      
      // Process child folders that come directly from the API
      if (folder.childFolders && Array.isArray(folder.childFolders) && folder.childFolders.length > 0) {
        folder.childFolders.forEach(childFolder => {
          const childFolderItem = createFolderItem(childFolder, folder.id);
          folderMap.set(childFolder.id, childFolderItem);
        });
      }
    });
    
    // Build the folder hierarchy
    folderMap.forEach(folder => {
      if (folder.parentFolderId && folderMap.has(folder.parentFolderId)) {
        // This is a child folder, add it to its parent
        const parentFolder = folderMap.get(folder.parentFolderId);
        parentFolder.childFolders.push(folder);
      } else {
        // This is a root folder
        rootFolders.push(folder);
      }
    });
    
    // Organize root folders into categories
    const mainFolders = [];
    const managementFolders = [];
    const otherFolders = [];
    
    rootFolders.forEach(folder => {
      // Sort child folders alphabetically
      if (folder.childFolders.length > 0) {
        folder.childFolders.sort((a, b) => a.text.localeCompare(b.text));
      }
      
      if (['inbox', 'flagged', 'drafts', 'sent'].includes(folder.folderType)) {
        mainFolders.push(folder);
      } else if (['archive', 'trash', 'junk', 'conversations'].includes(folder.folderType)) {
        managementFolders.push(folder);
      } else {
        otherFolders.push(folder);
      }
    });
    
    // Add conversations if not included in the API response
    if (!managementFolders.some(folder => folder.key === 'conversations')) {
      managementFolders.push({ 
        text: 'Chat', 
        icon: <QuestionAnswerIcon />, 
        key: 'conversations',
        childFolders: [],
        folderType: 'conversations'
      });
    }
    
    // Sort folders within each category
    mainFolders.sort((a, b) => {
      const order = { 'inbox': 1, 'flagged': 2, 'drafts': 3, 'sent': 4 };
      return (order[a.folderType] || 99) - (order[b.folderType] || 99);
    });
    
    managementFolders.sort((a, b) => {
      const order = { 'archive': 1, 'trash': 2, 'junk': 3, 'conversations': 4 };
      return (order[a.folderType] || 99) - (order[b.folderType] || 99);
    });
    
    // Sort other folders alphabetically
    otherFolders.sort((a, b) => a.text.localeCompare(b.text));
    
    const result = [];
    
    if (mainFolders.length > 0) {
      result.push({
        category: 'MAIL',
        items: mainFolders
      });
    }
    
    if (managementFolders.length > 0) {
      result.push({
        category: 'MANAGEMENT',
        items: managementFolders
      });
    }
    
    if (otherFolders.length > 0) {
      result.push({
        category: 'OTHER FOLDERS',
        items: otherFolders
      });
    }
    
    return result;
  }, [userFolders]);

  // Add these states for account management
  const [accounts, setAccounts] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);

  useEffect(() => {
    // Initialize with accounts from localStorage
    const storedAccounts = JSON.parse(localStorage.getItem('accounts') || '[]');
    const currentAccountData = JSON.parse(localStorage.getItem('currentAccount') || 'null');
    
    if (storedAccounts.length > 0) {
      setAccounts(storedAccounts);
      setCurrentAccount(currentAccountData || storedAccounts[0]);
    } else {
      // If no accounts, initialize with current user's account only if not already stored
      const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
      if (userInfo && userInfo.userPrincipalName) {
        const userEmail = userInfo.userPrincipalName;
        
        // Check if this account was previously stored
        const wasAccountStored = storedAccounts.some(acc => acc.email === userEmail);
        
        if (!wasAccountStored) {
          const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
          const mainAccount = {
            name: `${userInfo.givenName} ${userInfo.surname}`,
            email: userEmail,
            isActive: true,
            accessToken: currentAccount.accessToken,
            userInfo: userInfo,
            isSubscribed: localStorage.getItem('isSubscribed') === 'true',
            userSignature: localStorage.getItem('userSignature'),
            userPhoto: localStorage.getItem('userPhoto'),
          };
          setAccounts([mainAccount]);
          setCurrentAccount(mainAccount);
          
          // Store the account data
          localStorage.setItem('accounts', JSON.stringify([mainAccount]));
          localStorage.setItem('currentAccount', JSON.stringify(mainAccount));
        }
      }
    }

    // Add event listener for account updates
    const handleAccountsUpdated = (event) => {
      const { accounts: updatedAccounts, currentAccount: updatedCurrentAccount } = event.detail;
      setAccounts(updatedAccounts);
      setCurrentAccount(updatedCurrentAccount);
    };

    window.addEventListener('accountsUpdated', handleAccountsUpdated);

    // Cleanup
    return () => {
      window.removeEventListener('accountsUpdated', handleAccountsUpdated);
    };
  }, []);

  const handleAccountChange = async (account) => {
    try {
      setCurrentAccount(account);
      
      // Clear folder cache when switching accounts
      sessionStorage.removeItem('userFolders');
      sessionStorage.removeItem('foldersFetched');
      
      // Switch to the selected account
      await switchAccount(account);
      
      // Clear URL parameters by navigating to root path
      window.history.pushState({}, '', '/');
      
      // Refresh the page to reinitialize with new account
      window.location.reload();
    } catch (error) {
      console.error('Error switching account:', error);
    }
  };

  console.log('accounts', accounts);
  console.log('currentAccount', currentAccount);

  const handleAddAccount = async () => {
    try {
      // First, clear all session-specific data including folder cache
      sessionStorage.removeItem('userFolders');
      sessionStorage.removeItem('foldersFetched');
      sessionStorage.clear();
      
      // Store accounts data before clearing localStorage
      const accounts = localStorage.getItem('accounts');
      const currentAccount = localStorage.getItem('currentAccount');

      // Clear all localStorage data except accounts
      localStorage.clear();
      
      // Restore accounts data
      if (accounts) localStorage.setItem('accounts', accounts);
      if (currentAccount) localStorage.setItem('currentAccount', currentAccount);
      
      // Set a flag to indicate we're adding a new account
      localStorage.setItem('adding_account', 'true');
      
      // Redirect to login with prompt=select_account to force account selection
      const environment = process.env.REACT_APP_ENVIRONMENT;
      window.location.href = `${process.env.REACT_APP_API_URL}/login?add_account=true&prompt=select_account&environment=${environment}`;
    } catch (error) {
      console.error('Error adding account:', error);
    }
  };

  // Render sidebar content
  const renderSidebarContent = () => (
    <>
      {/* Mac window controls space */}
      {isElectron() && (
        <Box
          sx={{
            height: '24px',
            width: '100%',
            WebkitAppRegion: 'drag',
            backgroundColor: 'transparent',
            position: isMobile ? 'absolute' : 'relative',
            top: isMobile ? 0 : 'auto',
            left: isMobile ? 0 : 'auto',
            zIndex: isMobile ? 1 : 'auto',
          }}
        />
      )}
      
      <Box 
        sx={{ 
          height: '100%',
          overflow: isMobile ? 'auto' : 'auto',
          display: 'flex',
          flexDirection: 'column',
          pt: isElectron() && isMobile ? '24px' : 0, // Add padding top when electron window controls are present in mobile
        }}
      >
        <Box sx={{ 
          px: 0, 
          display: 'flex', 
          flexDirection: 'column', 
          height: isMobile ? 'min-content' : '100%',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'rgba(0,0,0,.1) transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '3px',
          },
        }}>
          <AccountSwitcher
            accounts={accounts}
            currentAccount={currentAccount}
            onAccountChange={handleAccountChange}
            onAddAccount={handleAddAccount}
            isMobile={isMobile}
          />
          
          <Box sx={{ pt: 0.5, display: 'flex', flexDirection: 'column', flexGrow: isMobile ? 0 : 1 }}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress size={24} color="inherit" />
              </Box>
            ) : error ? (
              <Box sx={{ px: 2, py: 2, color: 'error.main', fontSize: '0.85rem' }}>
                {error}
              </Box>
            ) : (
              organizedFolders.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {/* Category Label */}
                  <Box 
                    sx={{ 
                      px: 2, 
                      py: 0.75, 
                      fontSize: '0.7rem', 
                      fontWeight: 600, 
                      color: theme.palette.text.secondary,
                      letterSpacing: '0.05em',
                      opacity: 0.8,
                      mt: groupIndex > 0 ? 2 : 0
                    }}
                  >
                    {group.category}
                  </Box>
                  
                  {/* Remove the special handling for OTHER FOLDERS section and treat all sections the same */}
                  <List sx={{ 
                    p: 0,
                    width: '100%',
                    overflow: 'hidden',
                    '& .MuiListItem-root + .MuiListItem-root': {
                      marginTop: '2px',
                    }
                  }}>
                    {group.items.map((item) => (
                      <FolderItem
                        key={item.key}
                        item={item}
                        currentFolder={currentFolder}
                        handleItemClick={handleItemClick}
                        isMobile={isMobile}
                        setIsSidebarOpen={setIsSidebarOpen}
                      />
                    ))}
                  </List>
                </React.Fragment>
              ))
            )}
            
            {/* Settings/Profile section */}
            <Divider sx={{ my: 2 }} />
            <List sx={{ p: 0, px: 1, mb: 1 }}>
              <ListItem
                button
                onClick={() => {
                  handleItemClick('profile');
                  if (isMobile) setIsSidebarOpen(false);
                }}
                sx={{
                  height: '40px',
                  borderRadius: '6px',
                  py: 0.5,
                  color: theme.palette.ui.textLight,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  transition: 'all 0.2s ease',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '36px',
                    color: 'inherit',
                  }}
                >
                  <SettingsIcon style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText 
                  primary="Settings"
                  sx={{
                    margin: 0,
                    '& .MuiListItemText-primary': {
                      color: 'inherit',
                      fontSize: '0.85rem',
                      letterSpacing: '0.01em',
                    },
                  }}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );

  // For mobile: use a drawer
  if (isMobile) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '250px',
            backgroundColor: theme.palette.accent.secondary,
            paddingTop: '8px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden', // Prevent double scrollbars
          },
        }}
      >
        {renderSidebarContent()}
        <CustomDragLayer />
      </Drawer>
    );
  }

  // For desktop: use a fixed sidebar
  return (
    <Box
      sx={{
        width: '250px',
        height: '100%',
        backgroundColor: theme.palette.accent.secondary,
        paddingTop: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
      }}
    >
      {renderSidebarContent()}
      <CustomDragLayer />
    </Box>
  );
};

export default Sidebar;