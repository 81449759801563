import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Divider,
  IconButton,
  Tooltip,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  PlayArrow as PlayArrowIcon,
  Code as CodeIcon,
  Save as SaveIcon,
  Description as DescriptionIcon,
  ExpandMore as ExpandMoreIcon,
  CloudDownload as CloudDownloadIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import axios from 'axios';

const NoCodeAutomationUI = () => {
  const theme = useTheme();
  const [automations, setAutomations] = useState([]);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [savedAutomations, setSavedAutomations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showApiConfig, setShowApiConfig] = useState(false);
  
  // New automation state
  const [newAutomation, setNewAutomation] = useState({
    name: '',
    description: '',
    endpoint: '',
    method: 'GET',
    headers: [{ key: '', value: '' }],
    queryParams: [{ key: '', value: '' }],
    bodyParams: [{ key: '', value: '' }],
    useBodyParams: false,
    requiresAuth: false,
    authType: 'bearer',
    schedule: {
      enabled: false,
      frequency: 'daily',
      time: '09:00'
    }
  });

  // Mock fetch saved automations
  useEffect(() => {
    const fetchSavedAutomations = () => {
      // This would normally be an API call
      const storedAutomations = localStorage.getItem('savedAutomations');
      if (storedAutomations) {
        setSavedAutomations(JSON.parse(storedAutomations));
      }
    };
    
    fetchSavedAutomations();
  }, []);

  const saveAutomation = () => {
    if (!newAutomation.name) {
      alert('Please provide a name for your automation');
      return;
    }
    
    const automationToSave = { ...newAutomation, id: Date.now().toString() };
    const updatedAutomations = [...savedAutomations, automationToSave];
    
    setSavedAutomations(updatedAutomations);
    localStorage.setItem('savedAutomations', JSON.stringify(updatedAutomations));
    
    // Reset form
    setNewAutomation({
      name: '',
      description: '',
      endpoint: '',
      method: 'GET',
      headers: [{ key: '', value: '' }],
      queryParams: [{ key: '', value: '' }],
      bodyParams: [{ key: '', value: '' }],
      useBodyParams: false,
      requiresAuth: false,
      authType: 'bearer',
      schedule: {
        enabled: false,
        frequency: 'daily',
        time: '09:00'
      }
    });
    
    setIsCreatingNew(false);
  };

  const handleFieldChange = (field, value) => {
    setNewAutomation(prev => ({ ...prev, [field]: value }));
  };

  const handleArrayFieldChange = (arrayName, index, field, value) => {
    setNewAutomation(prev => {
      const updatedArray = [...prev[arrayName]];
      updatedArray[index] = { ...updatedArray[index], [field]: value };
      return { ...prev, [arrayName]: updatedArray };
    });
  };

  const addArrayItem = (arrayName) => {
    setNewAutomation(prev => {
      return {
        ...prev,
        [arrayName]: [...prev[arrayName], { key: '', value: '' }]
      };
    });
  };

  const removeArrayItem = (arrayName, index) => {
    setNewAutomation(prev => {
      const updatedArray = prev[arrayName].filter((_, i) => i !== index);
      return { ...prev, [arrayName]: updatedArray };
    });
  };

  const testAutomation = async () => {
    setIsTesting(true);
    setTestResults(null);
    
    try {
      // In a real implementation, this would be a call to your backend
      // which would then make the API call according to the configuration
      
      const config = {
        method: newAutomation.method,
        url: newAutomation.endpoint,
        headers: newAutomation.headers.reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value;
          }
          return acc;
        }, {}),
        params: newAutomation.queryParams.reduce((acc, param) => {
          if (param.key && param.value) {
            acc[param.key] = param.value;
          }
          return acc;
        }, {})
      };
      
      if (newAutomation.useBodyParams && (newAutomation.method === 'POST' || newAutomation.method === 'PUT' || newAutomation.method === 'PATCH')) {
        config.data = newAutomation.bodyParams.reduce((acc, param) => {
          if (param.key && param.value) {
            acc[param.key] = param.value;
          }
          return acc;
        }, {});
      }
      
      // This is just a simulation - in a real app you'd call your backend
      // which would handle the actual API call
      setTimeout(() => {
        const mockResponse = {
          status: 200,
          data: {
            success: true,
            message: "This is a simulated response. In a real implementation, this would show the actual API response.",
            requestConfig: config
          }
        };
        
        setTestResults(mockResponse);
        setIsTesting(false);
      }, 1500);
      
    } catch (error) {
      setTestResults({
        error: true,
        message: error.message,
        details: error.response?.data || 'Unknown error'
      });
      setIsTesting(false);
    }
  };

  const deleteAutomation = (id) => {
    const updatedAutomations = savedAutomations.filter(automation => automation.id !== id);
    setSavedAutomations(updatedAutomations);
    localStorage.setItem('savedAutomations', JSON.stringify(updatedAutomations));
  };

  const selectAutomation = (automation) => {
    setSelectedAutomation(automation);
    setNewAutomation(automation);
    setIsCreatingNew(true);
  };

  const renderSavedAutomations = () => {
    if (savedAutomations.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="body1" color="text.secondary">
            You haven't created any automations yet.
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {savedAutomations.map((automation) => (
          <Card 
            key={automation.id}
            sx={{ 
              backgroundColor: theme.palette.primary.light,
              '&:hover': { boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Typography variant="h6" color={theme.palette.accent.primary}>
                    {automation.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    {automation.method} - {automation.endpoint}
                  </Typography>
                  {automation.description && (
                    <Typography variant="body2">
                      {automation.description}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => selectAutomation(automation)}>
                      <CodeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Run">
                    <IconButton 
                      onClick={() => {
                        setSelectedAutomation(automation);
                        setNewAutomation(automation);
                        testAutomation();
                      }}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => deleteAutomation(automation.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  };

  const renderAutomationForm = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h6" sx={{ color: theme.palette.accent.primary }}>
          {selectedAutomation ? 'Edit Automation' : 'Create New Automation'}
        </Typography>
        
        {/* Basic Info */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Automation Name"
            fullWidth
            value={newAutomation.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.primary.light,
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.accent.primary,
                }
              }
            }}
          />
          
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={2}
            value={newAutomation.description}
            onChange={(e) => handleFieldChange('description', e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: theme.palette.primary.light,
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.accent.primary,
                }
              }
            }}
          />
        </Box>
        
        {/* API Configuration */}
        <Accordion 
          expanded={showApiConfig} 
          onChange={() => setShowApiConfig(!showApiConfig)}
          sx={{ backgroundColor: theme.palette.primary.light }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: 'bold' }}>API Configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl sx={{ width: '30%' }}>
                  <InputLabel>Method</InputLabel>
                  <Select
                    value={newAutomation.method}
                    onChange={(e) => handleFieldChange('method', e.target.value)}
                    label="Method"
                  >
                    <MenuItem value="GET">GET</MenuItem>
                    <MenuItem value="POST">POST</MenuItem>
                    <MenuItem value="PUT">PUT</MenuItem>
                    <MenuItem value="DELETE">DELETE</MenuItem>
                    <MenuItem value="PATCH">PATCH</MenuItem>
                  </Select>
                </FormControl>
                
                <TextField
                  label="Endpoint URL"
                  fullWidth
                  value={newAutomation.endpoint}
                  onChange={(e) => handleFieldChange('endpoint', e.target.value)}
                  required
                  placeholder="https://api.example.com/data"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: theme.palette.primary.light,
                    }
                  }}
                />
              </Box>
              
              <FormControlLabel
                control={
                  <Switch
                    checked={newAutomation.requiresAuth}
                    onChange={(e) => handleFieldChange('requiresAuth', e.target.checked)}
                  />
                }
                label="Requires Authentication"
              />
              
              {newAutomation.requiresAuth && (
                <FormControl fullWidth>
                  <InputLabel>Authentication Type</InputLabel>
                  <Select
                    value={newAutomation.authType}
                    onChange={(e) => handleFieldChange('authType', e.target.value)}
                    label="Authentication Type"
                  >
                    <MenuItem value="bearer">Bearer Token</MenuItem>
                    <MenuItem value="basic">Basic Auth</MenuItem>
                    <MenuItem value="apiKey">API Key</MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        
        {/* Headers */}
        <Accordion sx={{ backgroundColor: theme.palette.primary.light }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: 'bold' }}>Headers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {newAutomation.headers.map((header, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <TextField
                    label="Header Name"
                    value={header.key}
                    onChange={(e) => handleArrayFieldChange('headers', index, 'key', e.target.value)}
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Value"
                    value={header.value}
                    onChange={(e) => handleArrayFieldChange('headers', index, 'value', e.target.value)}
                    sx={{ flex: 1 }}
                  />
                  <IconButton onClick={() => removeArrayItem('headers', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={() => addArrayItem('headers')}
                sx={{ alignSelf: 'flex-start' }}
              >
                Add Header
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        
        {/* Query Parameters */}
        <Accordion sx={{ backgroundColor: theme.palette.primary.light }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: 'bold' }}>Query Parameters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {newAutomation.queryParams.map((param, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <TextField
                    label="Parameter Name"
                    value={param.key}
                    onChange={(e) => handleArrayFieldChange('queryParams', index, 'key', e.target.value)}
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    label="Value"
                    value={param.value}
                    onChange={(e) => handleArrayFieldChange('queryParams', index, 'value', e.target.value)}
                    sx={{ flex: 1 }}
                  />
                  <IconButton onClick={() => removeArrayItem('queryParams', index)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={() => addArrayItem('queryParams')}
                sx={{ alignSelf: 'flex-start' }}
              >
                Add Query Parameter
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        
        {/* Body Parameters (for POST/PUT) */}
        {(newAutomation.method === 'POST' || newAutomation.method === 'PUT' || newAutomation.method === 'PATCH') && (
          <>
            <FormControlLabel
              control={
                <Switch
                  checked={newAutomation.useBodyParams}
                  onChange={(e) => handleFieldChange('useBodyParams', e.target.checked)}
                />
              }
              label="Add Body Parameters"
            />
            
            {newAutomation.useBodyParams && (
              <Accordion sx={{ backgroundColor: theme.palette.primary.light }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ fontWeight: 'bold' }}>Body Parameters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {newAutomation.bodyParams.map((param, index) => (
                      <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <TextField
                          label="Parameter Name"
                          value={param.key}
                          onChange={(e) => handleArrayFieldChange('bodyParams', index, 'key', e.target.value)}
                          sx={{ flex: 1 }}
                        />
                        <TextField
                          label="Value"
                          value={param.value}
                          onChange={(e) => handleArrayFieldChange('bodyParams', index, 'value', e.target.value)}
                          sx={{ flex: 1 }}
                        />
                        <IconButton onClick={() => removeArrayItem('bodyParams', index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => addArrayItem('bodyParams')}
                      sx={{ alignSelf: 'flex-start' }}
                    >
                      Add Body Parameter
                    </Button>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
        
        {/* Schedule */}
        <Accordion sx={{ backgroundColor: theme.palette.primary.light }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: 'bold' }}>Schedule</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={newAutomation.schedule.enabled}
                    onChange={(e) => setNewAutomation(prev => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        enabled: e.target.checked
                      }
                    }))}
                  />
                }
                label="Enable Scheduling"
              />
              
              {newAutomation.schedule.enabled && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <InputLabel>Frequency</InputLabel>
                    <Select
                      value={newAutomation.schedule.frequency}
                      onChange={(e) => setNewAutomation(prev => ({
                        ...prev,
                        schedule: {
                          ...prev.schedule,
                          frequency: e.target.value
                        }
                      }))}
                      label="Frequency"
                    >
                      <MenuItem value="hourly">Hourly</MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <TextField
                    label="Time"
                    type="time"
                    value={newAutomation.schedule.time}
                    onChange={(e) => setNewAutomation(prev => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        time: e.target.value
                      }
                    }))}
                    InputLabelProps={{ shrink: true }}
                    sx={{ flex: 1 }}
                  />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
        
        {/* Action Buttons */}
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsCreatingNew(false);
              setSelectedAutomation(null);
            }}
            sx={{ 
              borderColor: theme.palette.accent.primary,
              color: theme.palette.accent.primary,
            }}
          >
            Cancel
          </Button>
          
          <Button
            variant="contained"
            onClick={testAutomation}
            startIcon={<PlayArrowIcon />}
            disabled={!newAutomation.endpoint || isTesting}
            sx={{ 
              backgroundColor: theme.palette.accent.secondary,
              '&:hover': {
                backgroundColor: theme.palette.accent.secondaryDark,
              }
            }}
          >
            {isTesting ? 'Testing...' : 'Test'}
          </Button>
          
          <Button
            variant="contained"
            onClick={saveAutomation}
            startIcon={<SaveIcon />}
            disabled={!newAutomation.name || !newAutomation.endpoint}
            sx={{ 
              backgroundColor: theme.palette.accent.primary,
              '&:hover': {
                backgroundColor: theme.palette.accent.primaryDark,
              }
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  const renderTestResults = () => {
    if (!testResults) return null;
    
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2, color: theme.palette.accent.primary }}>
          Test Results
        </Typography>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 2, 
            backgroundColor: testResults.error ? '#FFF4F4' : '#F4FFF4',
            border: '1px solid',
            borderColor: testResults.error ? '#FFD6D6' : '#D6FFD6',
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
            {testResults.error ? 'Error' : 'Success'}
          </Typography>
          
          {testResults.error ? (
            <Typography variant="body1" color="error">
              {testResults.message}
            </Typography>
          ) : (
            <>
              <Typography variant="body1">
                Status: {testResults.status || 200}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                Response:
              </Typography>
              <Paper
                sx={{
                  p: 2,
                  backgroundColor: '#f7f7f7',
                  maxHeight: '200px',
                  overflowY: 'auto',
                  '& pre': {
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }
                }}
              >
                <pre>{JSON.stringify(testResults.data, null, 2)}</pre>
              </Paper>
            </>
          )}
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {isCreatingNew ? (
        <Box>
          {renderAutomationForm()}
          {renderTestResults()}
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
            <Typography variant="h5" sx={{ color: theme.palette.accent.primary }}>
              API Automations
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setIsCreatingNew(true)}
              sx={{ 
                backgroundColor: theme.palette.accent.primary,
                '&:hover': {
                  backgroundColor: theme.palette.accent.primaryDark,
                }
              }}
            >
              Create New
            </Button>
          </Box>
          
          {renderSavedAutomations()}
        </Box>
      )}
    </Box>
  );
};

export default NoCodeAutomationUI; 