import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { Box, Typography, Paper, Avatar, IconButton, Button, Modal, Slide, Dialog, DialogContent, Grid, CircularProgress } from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import EditIcon from '@mui/icons-material/Edit';
import ReactMarkdown from 'react-markdown';
import MessageInput from './MessageInput';
import botImage from './botImage.png';
import ChatHeader from './ChatHeader';
import EmailPreviewCard from './EmailPreviewCard'; 
import { axiosWithAuth } from './authService';
import FileComponent from './FileComponent';
import EmailPreviewComponent from './EmailPreviewComponent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { keyframes, margin, padding } from '@mui/system';
import ShimmerEffect from './ShimmerEffectChat';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import EmptyStateContent from './EmptyStateContent';  // Import the new component
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
  
import { isToday, isThisWeek, format } from 'date-fns';
import CalendarEventPreview from './CalendarEventPreview'; // Import the new component
import DraftEmailPreview from './DraftEmailPreview'; // Add this import
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as WordIcon } from '../icons/word-icon.svg';
import { ReactComponent as ExcelIcon } from '../icons/excel-icon.svg';
import { ReactComponent as PowerPointIcon } from '../icons/powerpoint-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from '@mui/material/Zoom';
import { v4 as uuidv4 } from 'uuid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandTransition = React.forwardRef(function ExpandTransition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const ChatWindow = React.memo(({ selectedEmail, isLoading, isSummarizing, setIsLoading, setIsSummarizing, isStreaming, setIsStreaming, setSelectedEmail, isMobile, onNameClick, setIsSidebarOpen, toggleEmailListCollapse }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const messagesContainerRef = useRef(null);
  const messageInputRef = useRef(null); // Add this line to reference the input field
  const shouldScrollRef = useRef(false);  
  const userScrolledRef = useRef(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedPreviewEmail, setSelectedPreviewEmail] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const chatWindowRef = useRef(null);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const abortControllerRef = useRef(null);
  const [emptyResponse, setEmptyResponse] = useState(false);
  const [imageContents, setImageContents] = useState({});
  const [imageDataState, setImageDataState] = useState({});
  const [expandedReasoningIds, setExpandedReasoningIds] = useState(new Set());
  const imageCache = useRef({});
  const [zoomedImage, setZoomedImage] = useState(null);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo') || '{}')
  );
  const [botAvatarCache, setBotAvatarCache] = useState({});
  const latestEmailIdRef = useRef(selectedEmail?.id);
  const [emailStatusCounts, setEmailStatusCounts] = useState(null);
  const [threadSummaries, setThreadSummaries] = useState(null);
  const [isLoadingSummaries, setIsLoadingSummaries] = useState(false);
  const [messageInputValue, setMessageInputValue] = useState('');
  const [streamingMessageId, setStreamingMessageId] = useState(null);
  const [knowledgeBaseReferences, setKnowledgeBaseReferences] = useState({});
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);
  const [isKnowledgeBaseModalOpen, setIsKnowledgeBaseModalOpen] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isEmailListCollapsed, setIsEmailListCollapsed] = useState(false);
  
  const [quickReplies] = useState([
    { 
      "id": "email-3", 
      "title": "Bekræft modtagelse af mailen",
      "subline": "Send en bekræftelse til afsender om at du har modtaget mailen",
      "text": "Formuler et kortfattet, professionelt svar der anerkender modtagelsen af emailen. Hvis der er spørgsmål der kan besvares med det samme, gør dette kort.", 
      "type": "chat" 
    },
    { 
      "id": "email-4", 
      "title": "Informer om at du vender tilbage",
      "subline": "Informer afsender om at du vender tilbage",
      "text": "Formuler et kortfattet, professionelt svar der forklarer tydeligt at jeg har brug for tid til at behandle indholdet, indsamle nødvendige oplysninger eller konsultere med relevante kolleger. Udvis taknemmelighed for deres tålmodighed og forståelse. Hvis der er spørgsmål der kan besvares med det samme, gør dette kort.", 
      "type": "draft" 
    },
    { 
      "id": "email-6", 
      "title": "Bed om mere information",
      "subline": "Bed afsender om mere information",
      "text": "Skriv et høfligt og professionelt svar, hvor du beder om yderligere information vedrørende emailen. Hvis der er spørgsmål der kan besvares med det samme, gør dette kort.", 
      "type": "draft" 
    },
    { 
      "id": "email-5", 
      "title": "Identificer handlingspunkter",
      "subline": "Identificer handlingspunkter og deadlines",
      "text": "Gennemgå venligst denne e-mail og udtræk kun de påkrævede handlinger: List alle handlingspunkter der kræver et svar eller en handling fra min side. Inkluder præcise deadlines for hver handling (hvis angivet). Bevar navne, datoer og specifikke detaljer eksakt som skrevet i originalen. Formatér listen så den er nem at skimme, med de mest presserende handlinger først. Fremhæv kritiske deadlines med fed skrift.", 
      "type": "chat" 
    },
  ]);

  const currentStreamingEmailId = useRef(null);
  const lastSuccessfulEmailId = useRef(null);  // Add this ref to track last successful thread
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedMessageText, setEditedMessageText] = useState('');

  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const lastFetchedEmailId = useRef(null);  // Add this line to track last fetched email ID
  const [isWritingEmail, setIsWritingEmail] = useState(false);

  // effect to setUserInfo when userInfo in local storage changes
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      setUserInfo(userInfo);
    }
  }, [setUserInfo]);

  const fetchBotAvatar = useCallback(async () => {
    if (!userInfo?.userPrincipalName) return;

    // Extract domain from email
    const domain = userInfo.userPrincipalName.split('@')[1];
    if (!domain) return;

    // Check session storage first
    const cachedAvatar = sessionStorage.getItem(`botAvatar:${domain}`);
    if (cachedAvatar && cachedAvatar !== 'null') {
      setBotAvatarCache(prev => ({ ...prev, [domain]: cachedAvatar }));
      return;
    }

    try {
      // Create a dummy email with the domain to match backend expectations
      const dummyEmail = `company@${domain}`;
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/batch-get-sender-photos', {
        senderEmails: [dummyEmail]
      });

      if (response.data && response.data.photos && response.data.photos[dummyEmail]) {
        const photoData = response.data.photos[dummyEmail];
        // Cache in session storage
        sessionStorage.setItem(`botAvatar:${domain}`, photoData);
        // Update state
        setBotAvatarCache(prev => ({ ...prev, [domain]: photoData }));
      } else {
        // Cache the failure
        sessionStorage.setItem(`botAvatar:${domain}`, 'null');
      }
    } catch (error) {
      console.error('Error fetching bot avatar:', error);
      // If failed, cache the failure to prevent repeated attempts
      sessionStorage.setItem(`botAvatar:${domain}`, 'null');
    }
  }, [userInfo?.userPrincipalName]);

  // Add effect to fetch bot avatar when userInfo changes
  useEffect(() => {
    fetchBotAvatar();
  }, [fetchBotAvatar]);

  const botName = useMemo(() => {
    return userInfo?.company?.name ? `${userInfo.company.name} Email Assistant` : 'Email Assistant';
  }, [userInfo?.company?.name]);

  const botImageUrl = useMemo(() => {
    if (!userInfo?.userPrincipalName) return botImage;
    
    const domain = userInfo.userPrincipalName.split('@')[1];

    // First try company logo from userInfo
    if (userInfo?.company?.logo) return userInfo.company.logo;
    // Then try cached domain logo
    if (botAvatarCache[domain]) return botAvatarCache[domain];
    // Finally fallback to default
    return botImage;
  }, [userInfo?.company?.logo, userInfo?.userPrincipalName, botAvatarCache]);

  useEffect(() => {
    latestEmailIdRef.current = selectedEmail?.id;
  }, [selectedEmail]);

  const handleImageZoom = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  // New function to batch fetch images
  const batchFetchImages = useCallback(async (fileIds) => {
    const uncachedIds = fileIds.filter(id => !imageCache.current[id]);
    if (uncachedIds.length === 0) return;

    try {
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/batch-get-image-content', { fileIds: uncachedIds });
      const newImageContents = { ...imageCache.current, ...response.data };
      setImageContents(newImageContents);
      imageCache.current = newImageContents;
    } catch (error) {
      console.error('Error fetching image contents:', error);
    }
  }, []);

  // Add scroll event listener
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (!messagesContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainer;
      const scrolledPosition = scrollHeight - scrollTop - clientHeight;
      const isNearBottom = scrolledPosition < 5; // Increased threshold for better UX
      userScrolledRef.current = !isNearBottom;
    };

    messagesContainer.addEventListener('scroll', handleScroll);
    return () => messagesContainer.removeEventListener('scroll', handleScroll);
  }, []);

  // Modify scrollToBottom to respect user scroll
  const scrollToBottom = useCallback(() => {
    const messagesContainer = messagesContainerRef.current;
    if (!messagesContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = messagesContainer;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 5;
    
    // Only auto-scroll if user is already near the bottom
    if (isNearBottom || !userScrolledRef.current) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, []);

  useEffect(() => {
    const fileIds = messages
      .filter(message => message.component === 'File Component Thread' && message.file.purpose === 'vision')
      .map(message => message.file.file_id);

    if (fileIds.length > 0) {
      batchFetchImages(fileIds);
    }
  }, [messages, batchFetchImages]);

  const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const ShimmerEffectImage = () => (
  <Box
    sx={{
      width: '300px',
      height: '300px',
      borderRadius: '5px',
      background: 'linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%)',
      backgroundSize: '800px 104px',
      animation: `${shimmer} 1.2s ease-in-out infinite`,
    }}
  />
);


const renderFileComponent = useCallback((message) => {
  if (message.component === 'File Component Thread' && message.file?.purpose === 'vision') {
    const imageContent = imageContents[message.file.file_id];
    return (
      <Box sx={{ maxWidth: '300px', margin: '10px 0', position: 'relative' }}>
        {imageContent ? (
          <>
            <img 
              src={`data:image/jpeg;base64,${imageContent}`}
              alt="Previously uploaded image"
              style={{ 
                width: '100%', 
                height: 'auto',
                maxHeight: '300px',
                objectFit: 'contain',
                borderRadius: '5px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                cursor: 'pointer'
              }} 
              loading="lazy"
              onDoubleClick={() => handleImageZoom(`data:image/jpeg;base64,${imageContent}`)}
            />
            <IconButton
              sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 9)',
                },
              }}
              onClick={() => handleImageZoom(`data:image/jpeg;base64,${imageContent}`)}
            >
              <ZoomOutMapIcon sx={{fontSize: '8px'}}/>
            </IconButton>
          </>
        ) : (
          <ShimmerEffectImage />
        )}
      </Box>
    );
  } else if (message.isImage || (message.file && isImageFile(message.file))) {
    // For regular image uploads
    const imageData = message.imageData || (message.file && imageDataState[message.file.name]) || imageContents[message.file.file_id];
    if (imageData) {
      return (
        <Box sx={{ maxWidth: '300px', margin: '10px 0', position: 'relative' }}>
          <img 
            src={imageData}
            alt={message.file ? message.file.name : 'Uploaded image'}
            style={{ 
              width: '100%', 
              height: 'auto',
              maxHeight: '300px',
              objectFit: 'contain',
              borderRadius: '5px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              cursor: 'pointer'
            }} 
            onDoubleClick={() => handleImageZoom(imageData)}
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              },
            }}
            onClick={() => handleImageZoom(imageData)}
          >
            <ZoomOutMapIcon sx={{fontSize: '8px'}}/>
          </IconButton>
        </Box>
      );
    }
    return <Typography color="error">Error: Image data not found</Typography>;
  } else if (message.file) {
    return <FileComponent file={message.file} />;
  }
  return <Typography color="error">Error: Unable to display file</Typography>;
}, [imageContents, imageDataState, handleImageZoom]);

const cancelOngoingRequests = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    currentStreamingEmailId.current = null;
    // Don't cache messages when canceling
    shouldScrollRef.current = false;
}, []);

const handleCancelStream = useCallback(() => {
  cancelOngoingRequests();
  setMessages(prevMessages => prevMessages.filter(msg => msg.component !== 'shimmerEffect'));
  setIsStreaming(false);
  setIsLoading(false);
  setIsSummarizing(false);
}, [cancelOngoingRequests, setIsStreaming, setIsLoading, setIsSummarizing]);

const createNewThread = useCallback(async () => {
  try {
    const requestBody = {
      user_email: userInfo.userPrincipalName,
      vector_store_id: userInfo.vector_store_id,
    };
    
    const response = await axiosWithAuth.post(
      process.env.REACT_APP_API_URL + '/create-thread', 
      requestBody
    );

    if (response.status === 200) {
      // Extract thread_id from the response
      const responseThreadId = response.data.thread_id || response.data.id;
      
      latestEmailIdRef.current = responseThreadId;
      currentStreamingEmailId.current = responseThreadId;
      
      return {
        threadId: responseThreadId, // Return the thread_id directly
        updateState: () => {
          setSelectedEmail(prev => ({ 
            ...prev, 
            id: responseThreadId,  // Set id to thread_id
            thread_id: responseThreadId // Add thread_id to selectedEmail
          }));
        }
      };
    } else {
      throw new Error('Failed to create new thread');
    }
  } catch (error) {
    console.error('Error creating new thread:', error);
    throw error;
  }
}, [userInfo, setSelectedEmail]);

const isImageFile = (file) => {
  return file && file.type && file.type.startsWith('image/');
};

const handleDragOver = useCallback((e) => {
  e.preventDefault();
  // Always allow propagation to inner components
  setIsDragging(true);
}, []);

const handleDragLeave = useCallback((e) => {
  e.preventDefault();
  if (!chatWindowRef.current.contains(e.relatedTarget)) {
    setIsDragging(false);
  }
}, []);

const handleDrop = useCallback((e) => {
  e.preventDefault();
  setIsDragging(false);
  // Check if the drop occurred on the inner component
  const innerComponent = document.getElementById('email-component-drop-zone');
  if (innerComponent && innerComponent.contains(e.target)) {
    // If dropped on inner component, don't process here
    return;
  }
  const files = Array.from(e.dataTransfer.files);
  setAttachedFiles(prevFiles => [...prevFiles, ...files]);
}, []);

const handleFileAttach = (files) => {
  setAttachedFiles(prevFiles => [...prevFiles, ...Array.from(files)]);
};

const handleRemoveFile = (index) => {
  setAttachedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
};

const handleSendMessage = (messageText, selectedOption) => {
  if ((messageText.trim() || attachedFiles.length > 0) && !isStreaming && !isLoading && !isSummarizing) {
    sendMessageToBot(messageText, attachedFiles, false, null, selectedOption);
    setAttachedFiles([]);
    setMessageInputValue(''); // Reset message input value after sending
  }
};

const handleOpenPreview = useCallback(async (emailId) => {
  try {
    const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-single-email/${emailId}`);
    setSelectedPreviewEmail(response.data);
    setIsPreviewDialogOpen(true);
  } catch (error) {
    console.error('Error fetching email preview data:', error);
    // Optionally, show an error message to the user
  }
}, []);

const handleClosePreview = useCallback(() => {
  setIsPreviewDialogOpen(false);
  setSelectedPreviewEmail(null);
}, []);


  useEffect(() => {
    if (messages.length > 0 && selectedEmail?.id) {
      // Function to strip out large image data
      const stripImageData = (message) => {
        if (typeof message !== 'object' || message === null) {
          console.warn('Invalid message object:', message);
          return message;
        }

        if (message.isImage || (message.file && isImageFile(message.file))) {
          return {
            ...message,
            file: message.file ? { 
              name: message.file.name, 
              type: message.file.type 
            } : null,
            imageData: null,
            hadImageData: true
          };
        }
        return message;
      };

      // Create a copy of messages with image data stripped
      const messagesToStore = messages.map(stripImageData);

      try {
        // sessionStorage.setItem(`messages_${threadId}`, JSON.stringify(messagesToStore));
      } catch (error) {
        console.error('Failed to save messages to sessionStorage:', error);
      }
  
      if (shouldScrollRef.current) {
        scrollToBottom();
        shouldScrollRef.current = false;
      }      
    }
    console.log('Messages updated:', messages);
  }, [messages, scrollToBottom]);

  // Cache helper functions
  const cacheKey = useMemo(() => (selectedEmail?.id ? `thread_messages_${selectedEmail?.id}` : null), [selectedEmail?.id]);

  const getCachedMessages = useCallback((emailId) => {
    if (!emailId) return null;
    const threadCacheKey = `thread_messages_${emailId}`;
    
    try {
      const cached = sessionStorage.getItem(threadCacheKey);
      console.log('cached:', cached);
      if (cached) {
        const parsedMessages = JSON.parse(cached);
        return parsedMessages.map(message => {
          if (message.component === 'File Component Thread' && message.file?.purpose === 'vision') {
            // Restore vision files (images)
            return {
              ...message,
              file: {
                ...message.file,
                // Image content will be fetched by batchFetchImages
              }
            };
          } else if (message.isImage || (message.file && message.imageData)) {
            // Restore regular image uploads
            return {
              ...message,
              file: message.file,
              imageData: message.imageData,
              isImage: true
            };
          } else if (message.file && !isImageFile(message.file)) {
            // Restore non-image file components
            return {
              ...message,
              file: {
                name: message.file.name,
                type: message.file.type,
                size: message.file.size,
                lastModified: message.file.lastModified,
                purpose: message.file.purpose,
                file_id: message.file.file_id,
                content: message.file.content
              }
            };
          }
          return message;
        });
      }
    } catch (error) {
      console.error('Error retrieving cached messages:', error);
    }
    return null;
  }, []);

  const cacheMessages = useCallback((messages, emailId) => {
    if (!emailId || !messages || !messages.length) return;
    const threadCacheKey = `thread_messages_${emailId}`;
    
    try {
      // Filter out shimmer effects before caching
      const messageToCache = messages.filter(message => !message.component || message.component !== 'shimmerEffect');
      
      if (!messageToCache.length) return;

      const cacheable = messageToCache.map(message => {
        if (message.isImage || (message.file && isImageFile(message.file))) {
          return {
            ...message,
            file: message.file ? {
              name: message.file.name,
              type: message.file.type,
              lastModified: message.file.lastModified
            } : null,
            imageData: message.imageData,
            isImage: true
          };
        } else if (message.file && !isImageFile(message.file)) {
          return {
            ...message,
            file: {
              name: message.file.name,
              type: message.file.type,
              size: message.file.size,
              lastModified: message.file.lastModified,
              purpose: message.file.purpose,
              file_id: message.file.file_id,
              content: message.file.content
            }
          };
        }
        return message;
      });
      
      sessionStorage.setItem(threadCacheKey, JSON.stringify(cacheable));
    } catch (error) {
      console.error('Error caching messages:', error);
    }
  }, []);

  console.log('selectedEmail chatwindow:', selectedEmail);

  // Modify fetchThreadMessages to use cache
  const fetchThreadMessages = useCallback(async (email_id) => {
    console.log('fetchThreadMessages started:', email_id);
    
    // Try to get cached messages first
    const cachedMessages = getCachedMessages(email_id);
    if (cachedMessages && cachedMessages.length > 0) {
      console.log('cachedMessages found:', cachedMessages);
      
      // Check if the cached messages contain thread_id information
      const cachedThreadId = cachedMessages[0]?.thread_id;
      if (cachedThreadId) {
        console.log('Setting threadId from cached messages:', cachedThreadId);
        setThreadId(cachedThreadId);
        
        // Update selectedEmail with the thread_id from cache
        setSelectedEmail(prevEmail => ({
          ...(prevEmail || { id: email_id }),
          thread_id: cachedThreadId
        }));
      } else {
        console.warn('Cached messages found but no thread_id in cache');
        // Fall back to using email_id as thread_id temporarily until API responds
        if (!threadId) {
          setThreadId(email_id);
        }
      }
      
      setMessages(cachedMessages);
      lastSuccessfulEmailId.current = email_id;
      lastFetchedEmailId.current = email_id;
      setIsStreaming(false);
      setIsSummarizing(false);
      return;
    }

    // Only cancel ongoing requests if we're fetching for a different email
    if (lastSuccessfulEmailId.current !== email_id) {
      console.log('Cancelling previous requests for new email:', email_id);
      cancelOngoingRequests();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    try {
      // Create a fallback email object if selectedEmail is null
      const emailToUse = selectedEmail || { id: email_id };
      
      const body = {
        email_id: email_id,
        user_email: userInfo?.userPrincipalName,
        userInfo: JSON.stringify(userInfo),
        selected_email: JSON.stringify(emailToUse)
      };

      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + `/get-thread-messages/${email_id}`, body, {
        signal: controller.signal,
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      // Check if the request was cancelled after we got the response
      if (controller.signal.aborted) {
        console.log('Request completed but was cancelled:', email_id);
        return;
      }

      let parsedMessages = response.data.messages;
      const newThreadId = response.data.thread_id;
      setThreadId(newThreadId);
      
      // Immediately update selectedEmail with the thread_id from the response
      setSelectedEmail(prevEmail => ({
        ...(prevEmail || { id: email_id }),
        thread_id: newThreadId
      }));

      setMessages(parsedMessages);
      lastSuccessfulEmailId.current = email_id;
      lastFetchedEmailId.current = email_id;
      cacheMessages(parsedMessages, email_id);
      shouldScrollRef.current = true;
      
      // Update the thread_id in the session storage cache if needed
      const threadCacheKey = `thread_messages_${email_id}`;
      try {
        const cachedData = sessionStorage.getItem(threadCacheKey);
        if (cachedData) {
          const parsedCache = JSON.parse(cachedData);
          // Update the cached data with the new thread_id
          const updatedCache = parsedCache.map(msg => {
            // Add thread_id to each message if needed
            return { ...msg, thread_id: newThreadId };
          });
          sessionStorage.setItem(threadCacheKey, JSON.stringify(updatedCache));
        }
      } catch (error) {
        console.error('Error updating thread_id in cache:', error);
      }
      
      // Update UI states
      setIsStreaming(false);
      setIsSummarizing(false);
    } catch (error) {
      if (error.name === 'AbortError' || error.name === 'CanceledError' || error.message === 'Request cancelled') {
        console.log('Fetch aborted or cancelled for email:', email_id);
        return;
      }
      
      // If we get an error related to missing thread_id, try to recover
      if (error.response?.data?.error?.includes('thread_id')) {
        console.error('Error with thread_id:', error.response?.data);
        
        // If we have a valid email_id but no thread_id, try to use email_id as fallback
        if (email_id && email_id !== "thread_newLexiChat") {
          console.log('Attempting to recover by setting threadId to email_id');
          setThreadId(email_id);
          
          // Update selectedEmail to maintain consistency
          setSelectedEmail(prev => ({ 
            ...prev, 
            thread_id: email_id
          }));
        }
      }
      
      console.error('Error fetching thread messages:', error);
      setMessages([]);
      
      // Update UI states
      setIsStreaming(false);
      setIsSummarizing(false);
    }
  }, [userInfo, getCachedMessages, cancelOngoingRequests, cacheMessages, setSelectedEmail, setIsStreaming, setIsSummarizing, selectedEmail]);

  const removeFileMarkers = (text) => {
    if (typeof text !== 'string') return text;
    const regex = /【.*?】/gs;
    return text.replace(regex, '');
  };

  const updateEmailMessage = useCallback((messageId, updatedEmailData, sendStatus) => {
    setMessages(prevMessages =>
      prevMessages.map(msg =>
        msg.id === messageId
          ? { ...msg, emailData: updatedEmailData, sendStatus }
          : msg
      )
    );
    scrollToBottom();
  }, []);

  const refreshToken = async () => {
    const serverUrl = process.env.REACT_APP_API_URL;
    try {
      // Get current account info
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const urlParams = new URLSearchParams(window.location.search);
      const currentEmail = currentAccount.email;
      const userEmail = currentAccount.email;

      if (!userEmail) {
        throw new Error('No current account email found');
      }

      const response = await fetch(`${serverUrl}/refresh-token`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'userEmail': userEmail
        }
      });
      
      if (response.status === 200) {
        const data = await response.json();
        const { accessToken, userEmail } = data;

        // Verify the token is for the correct account
        if (userEmail !== currentEmail) {
          throw new Error('Token mismatch');
        }

        // Update token in session storage
        sessionStorage.setItem(`accessToken_${currentEmail}`, accessToken);
        
        // Update the token in the accounts list
        const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
        const updatedAccounts = accounts.map(account => {
          if (account.email === currentEmail) {
            return { ...account, accessToken };
          }
          return account;
        });
        localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
        
        // Update current account
        const updatedCurrentAccount = { ...currentAccount, accessToken };
        localStorage.setItem('currentAccount', JSON.stringify(updatedCurrentAccount));

        return accessToken;
      }
      throw new Error('Failed to refresh token');
    } catch (error) {
      console.error('Token refresh failed:', error);
      
      // Get current account info for cleanup
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const currentEmail = currentAccount.email;
      
      if (currentEmail) {
        // Remove only the failed account from accounts list
        const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
        const updatedAccounts = accounts.filter(account => account.email !== currentEmail);
        localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
        
        // If there are other accounts, switch to the first available one
        if (updatedAccounts.length > 0) {
          const nextAccount = updatedAccounts[0];
          localStorage.setItem('currentAccount', JSON.stringify(nextAccount));
          localStorage.setItem('userInfo', JSON.stringify(nextAccount.userInfo));
          window.location.reload();
          return null;
        }
      }
      
      // If no accounts left, clear everything
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/auth';
      return null;
    }
  };

  const getStoredAccessToken = () => {
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    const currentEmail = currentAccount.email;
    

    
    // Only return the token from the current account
    return currentAccount.accessToken;
  };

  const fetchWithToken = async (url, options) => {
    let accessToken = getStoredAccessToken();
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    const currentEmail = currentAccount.email;
    const urlParams = new URLSearchParams(window.location.search);
    const mailboxParam = urlParams.get('mailbox');

    let userEmail;

    userEmail = mailboxParam || currentEmail;

    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${accessToken}`,
      'userEmail': userEmail
    };

    let response = await fetch(url, options);

    if (response.status === 401) {
      try {
        accessToken = await refreshToken();
        if (accessToken) {
          options.headers['Authorization'] = `Bearer ${accessToken}`;
          response = await fetch(url, options);
        } else {
          throw new Error('Authentication required');
        }
      } catch (error) {
        console.error('Token refresh failed:', error);
        window.location.href = '/auth';
        throw error;
      }
    }

    return response;
  };

  // Function to handle opening the knowledge base modal
  const handleOpenKnowledgeBaseModal = (referenceId, doc_text) => {
    setIsLoadingContent(true);
    const reference = knowledgeBaseReferences[referenceId];
    if (reference) {
      setSelectedKnowledgeBase({
        id: referenceId,
        ...reference,
        doc_text: doc_text // Store the doc_text in the selected knowledge base (can be array or string)
      });
      setIsPreviewDialogOpen(true);
      setIsLoadingContent(false);
    } else {
      // If content is not yet loaded, fetch it first
      fetchKnowledgeBaseContent(referenceId).then(data => {
        if (data) {
          setSelectedKnowledgeBase({
            id: referenceId,
            ...data,
            doc_text: doc_text // Store the doc_text in the selected knowledge base (can be array or string)
          });
          setIsPreviewDialogOpen(true);
        }
        setIsLoadingContent(false);
      });
    }
  };
  
  // Function to handle closing the knowledge base modal
  const handleCloseKnowledgeBaseModal = () => {
    setIsKnowledgeBaseModalOpen(false);
    setIsPreviewDialogOpen(false);
    setSelectedKnowledgeBase(null);
  };

  // Function to get the appropriate icon based on file type
  const getFileTypeIcon = (fileType, fileName) => {
    const iconStyle = { fontSize: '1.2rem' };
    
    if (fileType.includes('pdf')) {
      return <PictureAsPdfIcon sx={iconStyle} />;
    } else if (fileType.includes('image') || fileName.match(/\.(jpeg|jpg|png|gif|bmp|svg)$/)) {
      return <ImageIcon sx={iconStyle} />;
    } else if (fileType.includes('text/plain') || fileName.match(/\.(txt|md)$/)) {
      return <DescriptionIcon sx={iconStyle} />;
    } else if (fileName.match(/\.(doc|docx)$/)) {
      return <SvgIcon component={WordIcon} sx={iconStyle} viewBox="0 0 48 48" />;
    } else if (fileName.match(/\.(xls|xlsx)$/)) {
      return <SvgIcon component={ExcelIcon} sx={iconStyle} viewBox="0 0 48 48" />;
    } else if (fileName.match(/\.(ppt|pptx)$/)) {
      return <SvgIcon component={PowerPointIcon} sx={iconStyle} viewBox="0 0 48 48" />;
    } else if (fileName.match(/\.(js|ts|jsx|tsx|html|css|py|java|c|cpp|php|rb)$/)) {
      return <CodeIcon sx={iconStyle} />;
    } else {
      return <InsertDriveFileIcon sx={iconStyle} />; // Default document icon
    }
  };

  // Function to fetch knowledge base content
  const fetchKnowledgeBaseContent = async (fileId) => {
    if (knowledgeBaseReferences[fileId]) {
      return knowledgeBaseReferences[fileId]; // Return cached content if available
    }

    try {
      const currentUserEmail = userInfo.userPrincipalName || '';
      const serverUrl = process.env.REACT_APP_API_URL;
      const selectedDocumentNamespace = localStorage.getItem('selectedDocumentNamespace');
      const response = await fetchWithToken(`${serverUrl}/get-pinecone-content?fileId=${fileId}&namespace=${selectedDocumentNamespace}`, {
        method: 'GET',
        headers: {
          'userEmail': currentUserEmail
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch knowledge base content');
      }

      const data = await response.json();
      
      if (data.success) {
        // Cache the content
        setKnowledgeBaseReferences(prev => ({
          ...prev,
          [fileId]: {
            content: data.content,
            metadata: data.metadata
          }
        }));
        
        return {
          content: data.content,
          metadata: data.metadata
        };
      } else {
        throw new Error(data.error || 'Failed to fetch knowledge base content');
      }
    } catch (error) {
      console.error('Error fetching knowledge base content:', error);
      return null;
    }
  };

const sendMessageToBot = useCallback(async (messageText, files, isRetry = false, customMessages = null, selectedOption = null) => {
  if (!isRetry && ((messageText.trim() === '' && files.length === 0) || isStreaming || isLoading || isSummarizing)) return;

  cancelOngoingRequests();
  const streamingEmailId = latestEmailIdRef.current;
  currentStreamingEmailId.current = streamingEmailId;

  // Set streaming state at the start
  setIsStreaming(true);
  setIsWaitingForResponse(true);
  setEmptyResponse(false);

  // Generate botMessageId at the top level so it's accessible throughout the function
  const botMessageId = `bot-${Date.now()}`;

  try {
    const fileMessages = await Promise.all(files.map(async file => {
      const isImage = isImageFile(file);
      let imageData = null;
      if (isImage) {
        imageData = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
        setImageDataState(prev => ({ ...prev, [file.name]: imageData }));
      }
      return {
        id: `file-${Date.now()}-${file.name}`,
        file: file,
        sender: 'User',
        createdAt: new Date(),
        isFile: true,
        isImage: isImage,
        imageData: null,
      };
    }));

    const textMessage = messageText.trim() !== '' ? {
      id: `user-${Date.now()}`,
      text: messageText,
      sender: 'User',
      createdAt: new Date(),
      isEmail: false,
    } : null;

    // Use customMessages if provided, otherwise use current messages state
    const currentMessages = customMessages || messages;

    // Add initial messages including shimmer effect with no label
    if (!isRetry) {
      setMessages(prevMessages => [
        ...prevMessages,
        ...fileMessages,
        ...(textMessage ? [textMessage] : []),
        {
          id: 'shimmer-placeholder',
          component: 'shimmerEffect',
          sender: 'Bot',
          createdAt: new Date(),
          isEmail: false,
          toolCall: null
        }
      ]);
    } else {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          id: 'shimmer-placeholder',
          component: 'shimmerEffect',
          sender: 'Bot',
          createdAt: new Date(),
          isEmail: false,
          toolCall: null
        }
      ]);
    }

    shouldScrollRef.current = true;
    setIsWaitingForResponse(true);
    setIsStreaming(true);
    setEmptyResponse(false);    

    let currentEmailId = streamingEmailId;
    let updateStateFunction;
    let generatedThreadId = null;

    // Create a new thread if we're starting a fresh conversation
    if (selectedEmail?.id === "thread_newLexiChat" && currentMessages.length === 0) {
      const { threadId: newThreadId, updateState } = await createNewThread();
      currentEmailId = newThreadId;
      currentStreamingEmailId.current = newThreadId;
      updateStateFunction = updateState;
      generatedThreadId = newThreadId;
      
      // Set the threadId immediately to avoid undefined issues
      if (newThreadId) {
        setThreadId(newThreadId);
      }
    }       

    const formData = new FormData();
    formData.append('email_id', currentEmailId);

    // Use a more robust approach to ensure thread_id is included
    // Priority: 1) Newly generated threadId, 2) State threadId, 3) selectedEmail.thread_id
    const effectiveThreadId = generatedThreadId || threadId || selectedEmail?.thread_id;
    
    if (effectiveThreadId) {
      formData.append('thread_id', effectiveThreadId);
      console.log('Using thread_id:', effectiveThreadId);
    } else {
      console.warn('No thread_id available for message:', currentEmailId);
    }
    
    formData.append('message', messageText);
    
    // Ensure userInfo is synced with localStorage before sending
    const latestUserInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    // Update component state with latest userInfo
    if (JSON.stringify(latestUserInfo) !== JSON.stringify(userInfo)) {
      setUserInfo(latestUserInfo);
    }

    let namespace = localStorage.getItem('selectedDocumentNamespace');
    if (!namespace) {
      namespace = userInfo.userPrincipalName.split('@')[1];
    }
    
    formData.append('namespace', namespace);

    formData.append('userPrincipalName', latestUserInfo?.userPrincipalName);
    formData.append('userInfo', JSON.stringify(latestUserInfo));
        
    // Add the selected option to the request if it exists
    if (selectedOption) {
      formData.append('chatMode', selectedOption);
    }
    
    // Add current messages to the request using the customMessages if provided
    const messagesToSend = currentMessages.filter(msg => !msg.component || msg.component !== 'shimmerEffect')
                                        .map(msg => ({
                                          sender: msg.sender,
                                          text: msg.text,
                                          // Include email content if it exists
                                          ...(msg.emailContent && { text: msg.emailContent })
                                        }));
    formData.append('messages', JSON.stringify(messagesToSend));
    
    if (selectedEmail) {
      formData.append('selected_email', JSON.stringify(selectedEmail));
    }


    files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const serverUrl = process.env.REACT_APP_API_URL;
    const response = await fetchWithToken(serverUrl + '/chat', {
      method: 'POST',
      body: formData,
      signal: abortControllerRef.current.signal
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    if (updateStateFunction) {
      updateStateFunction();
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let isFirstChunk = true;
    let accumulatedResponse = "";
    let hasReceivedContent = false;
    let currentBotMessage = {
      id: botMessageId,
      text: "",
      sender: 'Bot',
      createdAt: new Date(),
      isEmail: false,
      emailReferences: [],
      knowledgeBaseReferences: [], // Add array for knowledge base references
      calendarComponent: null,
      emailContent: "" // Add this field to store accumulated email content
    };

    // Set the streaming message ID when starting
    setStreamingMessageId(botMessageId);
    setExpandedReasoningIds(prev => new Set([...prev, botMessageId]));

    while (true) {
      const { done, value } = await reader.read();
      
      if (currentStreamingEmailId.current !== currentEmailId) {
        console.log('Discarding response for outdated email thread');
        reader.cancel();
        break;
      }

      if (done) {
        console.log('Stream completed, clearing streaming state');
        setIsStreaming(false);
        setStreamingMessageId(null);
        break;
      }

      const chunk = decoder.decode(value, { stream: true });
      accumulatedResponse += chunk;

      const lines = accumulatedResponse.split('\n\n');
      let processedLines = 0;

      for (const line of lines) {
        if (currentStreamingEmailId.current !== currentEmailId) {
          break;
        }

        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data.trim() === '[DONE]') break;

          try {
            const parsedData = JSON.parse(data);
            hasReceivedContent = true;

            if (parsedData.tool_call) {
              // Update the shimmer effect with the new tool call
              setMessages(currentMessages => {
                const updatedMessages = [...currentMessages];
                const lastMessageIndex = updatedMessages.length - 1;
                if (updatedMessages[lastMessageIndex]?.component === 'shimmerEffect') {
                  updatedMessages[lastMessageIndex] = {
                    ...updatedMessages[lastMessageIndex],
                    toolCall: parsedData.tool_call
                  };
                }
                return updatedMessages;
              });
            } else if (parsedData.component) {
              // Handle component data
              if (parsedData.component.type === 'calendar_event_preview') {
                currentBotMessage.calendarComponent = parsedData.component;
              } else if (parsedData.component.type === 'draft_email_preview') {
                currentBotMessage.component = parsedData.component;
                // If there's email content in the component, accumulate it
                if (parsedData.component.email_content) {
                  currentBotMessage.emailContent = (currentBotMessage.emailContent || '') + parsedData.component.email_content;
                }
              } else if (typeof parsedData.component === 'string') {
                try {
                  const componentData = JSON.parse(parsedData.component);
                  if (componentData.type === 'calendar_event_preview') {
                    currentBotMessage.calendarComponent = componentData;
                  } else if (componentData.type === 'draft_email_preview') {
                    currentBotMessage.component = componentData;
                    // If there's email content in the component, accumulate it
                    if (componentData.email_content) {
                      currentBotMessage.emailContent = (currentBotMessage.emailContent || '') + componentData.email_content;
                    }
                  }
                } catch (error) {
                  console.error('Error parsing component data:', error);
                }
              }
              // Replace shimmer with actual message if this is the first content
              if (isFirstChunk) {
                setMessages(currentMessages => {
                  const updatedMessages = [...currentMessages];
                  const lastMessageIndex = updatedMessages.length - 1;
                  updatedMessages[lastMessageIndex] = currentBotMessage;
                  return updatedMessages;
                });
                isFirstChunk = false;
              }
            } else if (parsedData.content || parsedData.reasoning_content || parsedData.email_content) {
              if (parsedData.reasoning_content) {
                currentBotMessage.reasoningContent = (currentBotMessage.reasoningContent || '') + parsedData.reasoning_content;
              } else if (parsedData.email_content) {
                currentBotMessage.emailContent = (currentBotMessage.emailContent || '') + parsedData.email_content;
              } else if (parsedData.content) {
                const cleanContent = removeFileMarkers(parsedData.content);
                currentBotMessage.text = (currentBotMessage.text || '') + cleanContent;
              }

              // Replace shimmer with actual message if this is the first content
              if (isFirstChunk) {
                setMessages(currentMessages => {
                  const updatedMessages = [...currentMessages];
                  const lastMessageIndex = updatedMessages.length - 1;
                  updatedMessages[lastMessageIndex] = currentBotMessage;
                  return updatedMessages;
                });
                isFirstChunk = false;
              } else {
                // Update existing message with new content
                setMessages(currentMessages => {
                  const updatedMessages = [...currentMessages];
                  const lastMessageIndex = updatedMessages.length - 1;
                  if (updatedMessages[lastMessageIndex]?.sender === 'Bot') {
                    updatedMessages[lastMessageIndex] = currentBotMessage;
                  }
                  return updatedMessages;
                });
              }
            } else if (parsedData.email_reference) {
              currentBotMessage.emailReferences.push(parsedData.email_reference);
              setMessages(currentMessages => {
                const updatedMessages = [...currentMessages];
                const lastMessageIndex = updatedMessages.length - 1;
                if (updatedMessages[lastMessageIndex]?.sender === 'Bot') {
                  updatedMessages[lastMessageIndex] = currentBotMessage;
                }
                return updatedMessages;
              });
            } else if (parsedData.knowledge_base_reference) {
              // Handle knowledge base reference
              const referenceId = parsedData.knowledge_base_reference;
              // Store both the reference ID and the doc_text if available
              const referenceData = {
                id: referenceId,
                doc_text: parsedData.doc_text || null
              };
              currentBotMessage.knowledgeBaseReferences.push(referenceData);
              
              // Fetch the content asynchronously
              fetchKnowledgeBaseContent(referenceId);
              
              // Update the message with the new reference
              setMessages(currentMessages => {
                const updatedMessages = [...currentMessages];
                const lastMessageIndex = updatedMessages.length - 1;
                if (updatedMessages[lastMessageIndex]?.sender === 'Bot') {
                  updatedMessages[lastMessageIndex] = currentBotMessage;
                }
                return updatedMessages;
              });
            }

            shouldScrollRef.current = true;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
        processedLines++;
      }

      accumulatedResponse = lines.slice(processedLines).join('\n\n');
    }

    if (!hasReceivedContent) {
      setEmptyResponse(true);
    }

    lastSuccessfulEmailId.current = currentEmailId;

  } catch (error) {
    if (currentStreamingEmailId.current === streamingEmailId) {
      console.error('Error sending message:', error);
      
      if (error.name === 'AbortError') {
        setMessages(currentMessages => 
          currentMessages.filter(msg => msg.id !== 'shimmer-placeholder')
        );
      } else {
        setMessages(currentMessages => {
          const filteredMessages = currentMessages.filter(msg => msg.id !== 'shimmer-placeholder');
          return [
            ...filteredMessages,
            {
              id: 'error-message',
              text: 'Something went wrong. Please try again.',
              sender: 'Bot',
              createdAt: new Date(),
              isEmail: false,
              isError: true,
              originalMessage: messageText,
            }
          ];
        });
      }
      shouldScrollRef.current = true;
    }
  } finally {
    console.log('currentStreamingEmailId.current', currentStreamingEmailId.current);
    console.log('streamingEmailId', streamingEmailId);
    if (currentStreamingEmailId.current === streamingEmailId) {
      console.log('Cleaning up streaming state');
      setIsStreaming(false);
      setStreamingMessageId(null);
      currentStreamingEmailId.current = null;
      
      // Remove any remaining shimmer effects
      setMessages(prevMessages => 
        prevMessages.filter(msg => !msg.component || msg.component !== 'shimmerEffect')
      );
    }
  }
}, [messages, isStreaming, isLoading, isSummarizing, setIsStreaming, cancelOngoingRequests, userInfo, createNewThread, selectedEmail, latestEmailIdRef, threadId]);

const handleTryAgain = useCallback((originalMessage) => {
  setMessages(currentMessages => currentMessages.filter(msg => msg.id !== 'error-message'));
  sendMessageToBot(originalMessage, [], true, null, null); // Pass true for isRetry
}, [sendMessageToBot]);

const handleEditMessage = useCallback((messageId, messageText) => {
  setEditingMessageId(messageId);
  setEditedMessageText(messageText);
}, []);

const handleSaveEdit = useCallback(async (messageId) => {
  if (!editedMessageText.trim()) {
    setEditingMessageId(null);
    return;
  }

  // Find the index of the edited message
  const messageIndex = messages.findIndex(msg => msg.id === messageId);
  if (messageIndex === -1) return;

  // Get the current email ID
  const currentEmailId = latestEmailIdRef.current || selectedEmail?.id;
  if (!currentEmailId) {
    console.error('No email ID found');
    return;
  }

  // Remove all messages after the edited message
  const updatedMessages = messages.slice(0, messageIndex);
  
  // Add the edited message to the messages
  const editedMessage = {
    id: `user-${Date.now()}`,
    text: editedMessageText,
    sender: 'User',
    createdAt: new Date(),
    isEmail: false,
  };

  // For UI, show all messages including the edited one
  const uiMessages = [...updatedMessages, editedMessage];
  setMessages(uiMessages);
  setEditingMessageId(null);
  setEditedMessageText('');
  
  // Send delete request in parallel without awaiting
  axiosWithAuth.get(process.env.REACT_APP_API_URL + `/delete-thread-messages/${messageIndex}`, {
    params: {
      thread_id: threadId,
      email_id: currentEmailId
    }
  }).catch(error => {
    console.error('Error deleting messages from server:', error);
  });
  
  // Set streaming state and send the message
  setIsStreaming(true);
  currentStreamingEmailId.current = currentEmailId;
  // Send only the previous messages to the chat endpoint, as the edited message will be sent separately
  sendMessageToBot(editedMessageText, [], true, updatedMessages, null);
}, [messages, editedMessageText, sendMessageToBot, selectedEmail, setIsStreaming]);

const handleCancelEdit = useCallback(() => {
  setEditingMessageId(null);
  setEditedMessageText('');
}, []);

  const handleQuickMessage = (message) => {
    console.log('Quick Reply clicked:', {
      message,
      isStreaming,
      isLoading,
      isSummarizing,
      currentStreamingEmailId: currentStreamingEmailId.current
    });
    
    if (isStreaming || isLoading || isSummarizing) {
      console.log('Quick Reply blocked due to state:', {
        isStreaming,
        isLoading,
        isSummarizing
      });
      return;
    }

    console.log('message', message);
    
    // Send message with "draft" as the chatMode instead of using /replyall prefix
    handleSendMessage(message.text, message.type);
  };

  const QuickMessageButton = React.memo(({ title, subline, text, type, isLoading }) => {
    const handleClick = useCallback(() => {
      if (!isStreaming && !isLoading && !isSummarizing && !isLoading) {
        handleQuickMessage({ title, subline, text, type });
      }
    }, [title, subline, text, type, isStreaming, isLoading, isSummarizing]);
  
    const loadingAnimation = keyframes`
      0% {
        background-position: -200px 0;
      }
      100% {
        background-position: 200px 0;
      }
    `;
  
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleClick}
        disabled={isStreaming || isLoading || isSummarizing || isLoading}
        sx={{
          backgroundColor: theme.palette.accent.secondary,
          color: theme.palette.accent.primary,
          boxShadow: '0px 2px 8px rgba(0,0,0,0.08)',
          backdropFilter: 'blur(10px)',
          '&:hover': {
            backgroundColor: alpha(theme.palette.accent.secondary, 0.8),
            boxShadow: '0px 4px 12px rgba(0,0,0,0.12)',
            transform: 'translateY(-2px)',
          },
          '&:active': {
            transform: 'translateY(0px)',
          },
          '&:disabled': {
            backgroundColor: alpha(theme.palette.background.paper, 0.4),
            color: alpha(theme.palette.accent.primary, 0.4),
          },
          textTransform: 'none',
          mx: 0.5,
          my: 0,
          px: 1.5,
          py: 1.5,
          minWidth: '180px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          transition: 'all 0.2s ease-in-out',
          borderRadius: '12px',
          border: `1px solid ${alpha(theme.palette.accent.primary, 0.08)}`,
          flexShrink: 0,
          ...(isLoading && {
            background: 'linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%)',
            backgroundSize: '800px 104px',
            animation: `${loadingAnimation} 1.2s ease-in-out infinite`,
          }),
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography 
            component="span" 
            sx={{ 
              fontSize: '0.9rem', 
              fontWeight: 500,
              mb: 0.5,
              color: isLoading ? 'transparent' : theme.palette.accent.primary,
              height: isLoading ? '1em' : 'auto',
              width: isLoading ? '70%' : 'auto',
              backgroundColor: isLoading ? alpha(theme.palette.accent.primary, 0.05) : 'transparent',
              borderRadius: '4px',
            }}
          >
            {title || '\u00A0'}
          </Typography>
          <Typography 
            component="span" 
            sx={{ 
              fontSize: '0.75rem', 
              lineHeight: 1.2,
              color: isLoading ? 'transparent' : alpha(theme.palette.accent.primary, 0.7),
              height: isLoading ? '1.5em' : 'auto',
              width: isLoading ? '90%' : 'auto',
              backgroundColor: isLoading ? alpha(theme.palette.accent.primary, 0.03) : 'transparent',
              borderRadius: '4px',
            }}
          >
            {subline || '\u00A0'}
          </Typography>
        </Box>
      </Button>
    );
  });

  const memoizedQuickReplies = useMemo(() => {
    // Only show quick replies if there are no ongoing operations and it's not a Lexi chat
    const shouldShowQuickReplies = !isStreaming && !isLoading && !isSummarizing && selectedEmail?.id !== "thread_newLexiChat" && selectedEmail?.fromName !== "Lexi" && messages.length < 2;

    // Create loading placeholders
    const loadingPlaceholders = Array(4).fill(null).map((_, index) => ({
      id: `loading-${index}`,
      title: '',
      subline: '',
      text: '',
      type: 'loading'
    }));

    // Determine which quick replies to show
    let allQuickReplies = [];
    
    if (selectedEmail?.id === "thread_newLexiChat" || !selectedEmail) {
      // For new chat, show default quick replies
      allQuickReplies = [];
    } else if (isSuggestionsLoading) {
      // Show loading placeholders while loading
      allQuickReplies = loadingPlaceholders;
    } else {
      // Show fetched suggestions and default quick replies
      allQuickReplies = [...suggestions, ...quickReplies];
    }

    return shouldShowQuickReplies && Array.isArray(allQuickReplies) && (
      <Box 
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowX: 'auto',
          overflowY: 'hidden',
          width: '100%',
          pb: 1,
          mb: 0.5,
          '&::-webkit-scrollbar': {
            height: '2px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: alpha(theme.palette.text.primary, 0.1),
            borderRadius: '1px',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            background: alpha(theme.palette.text.primary, 0.2),
          },
          scrollbarWidth: isMobile ? 'none' : 'thin',
          scrollbarColor: `${alpha(theme.palette.text.primary, 0.1)} transparent`,
        }}
      >
        <Box sx={{ 
          display: 'flex',
          gap: 0.5,
          minWidth: 'min-content',
          px: 0.5,
        }}>
          {allQuickReplies.map((reply) => (
            <QuickMessageButton          
              key={reply.id}
              title={reply.title}
              subline={reply.subline}
              text={reply.text}
              type={reply.type}
              isLoading={reply.type === 'loading'}
            />
          ))}
        </Box>
      </Box>
    );
  }, [quickReplies, suggestions, isSuggestionsLoading, selectedEmail, isMobile, isStreaming, isLoading, isSummarizing, theme.palette.accent.primary, messages]);

  const memoizedMessageInput = useMemo(() => (
    <MessageInput
      ref={messageInputRef}
      onSend={handleSendMessage}
      onStop={handleCancelStream}
      disabled={isStreaming || isLoading || isSummarizing}
      attachedFiles={attachedFiles}
      onFileAttach={handleFileAttach}
      onRemoveFile={handleRemoveFile}
      isMobile={isMobile}
      autoFocus={selectedEmail?.id === "thread_newLexiChat"}
      hasSelectedEmail={selectedEmail?.id !== "thread_newLexiChat"}
      emailProcessingStatus={emailStatusCounts}
      onMessageInputChange={(value) => setMessageInputValue(value)}
      setMessage={setMessageInputValue}
      message={messageInputValue}
      selectedEmail={selectedEmail}
    />
  ), [handleSendMessage, handleCancelStream, isStreaming, isLoading, isSummarizing, attachedFiles, isMobile, selectedEmail, emailStatusCounts, messageInputValue]);

  
  const renderShimmerEffect = () => (
    <Box sx={{ width: '100%' }}> 
      <Typography
        variant="caption"
        sx={{
          fontWeight: 'bold',
          mb: 2,
          display: 'block',
          fontSize: '0.85rem', 
          color: theme.palette.ui.textDark,                    
        }}
      >
        {botName}
      </Typography>                      
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        width: '100%',  // Ensure full width
        overflow: 'visible', // Add this
        minWidth: 'fit-content', // Add this
      }}>
        <ShimmerEffect 
          toolCall={messages[messages.length - 1]?.toolCall} 
        />
      </Box>
          </Box>
  );

  const extractEmailIds = (text) => {
    const regex = /【[^†]*†temp_email_([^.]+)\.txt】/g;
    const matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push(match[1]); // Get the captured group (email ID)
    }
    return matches;
  };

  const handleToggleReasoning = useCallback((messageId) => {
    // Don't allow manual toggling for streaming message
    if (messageId === streamingMessageId) return;
    
    setExpandedReasoningIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(messageId)) {
        newSet.delete(messageId);
      } else {
        newSet.add(messageId);
      }
      return newSet;
    });
  }, [streamingMessageId]);

  const fetchThreadSummaries = useCallback(async () => {
    console.log('fetching thread summaries');
    setIsLoadingSummaries(true);

    try {

      // Create a cache key using the most recent email ID as it represents the thread
      const threadSummaryCacheKey = `thread_summary_${selectedEmail.conversationId}`;
      
      // Try to get cached summary first
      const cachedSummary = sessionStorage.getItem(threadSummaryCacheKey);
      if (cachedSummary) {
        setThreadSummaries(JSON.parse(cachedSummary));
        setIsLoadingSummaries(false);
        return;
      }

      console.log('fetching thread summaries',  selectedEmail.conversationId);
      const summaryResponse = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/get-thread-summary', {
        conversationId: selectedEmail.conversationId,
        userInfo: userInfo,
        user_email: userInfo?.userPrincipalName,
      });

      if (summaryResponse.status === 200 && summaryResponse.data.summary) {
        // Cache the summary response
        sessionStorage.setItem(threadSummaryCacheKey, JSON.stringify(summaryResponse.data.summary));
        setThreadSummaries(summaryResponse.data.summary);
      }
    } catch (error) {
      console.error('Error fetching thread summaries:', error);
    } finally {
      setIsLoadingSummaries(false);
    }
  }, [selectedEmail, userInfo?.userPrincipalName]);

  // Helper function to escape special characters in a string for use in a regular expression
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  // Helper function to get unique knowledge base references by filename
  const getUniqueKnowledgeBaseReferences = (referenceIds) => {
    const uniqueReferences = new Map();
    
    referenceIds.forEach(referenceData => {
      // Handle both old format (string) and new format (object)
      const referenceId = typeof referenceData === 'string' ? referenceData : referenceData.id;
      const docText = typeof referenceData === 'object' ? referenceData.doc_text : null;
      
      const reference = knowledgeBaseReferences[referenceId];
      if (reference) {
        const fileName = reference.metadata?.filename || 'Document';
        // If we haven't seen this filename yet, or if this is the first reference with this filename
        if (!uniqueReferences.has(fileName)) {
          uniqueReferences.set(fileName, { id: referenceId, doc_text: docText });
        }
      }
    });
    
    return Array.from(uniqueReferences.values());
  };

  const renderMessage = useCallback((message, index) => {    
    if (message?.sender === 'Bot') {
      console.log('Bot message state:', {
        messageId: message.id,
        isStreaming,
        streamingMessageId,
        hasReasoningContent: !!message.reasoningContent,
        hasEmailContent: !!message.emailContent,
        isCurrentlyStreaming: isStreaming && message.id === streamingMessageId,
        expandedReasoning: expandedReasoningIds.has(message.id)
      });

      const emailIds = extractEmailIds(message.text);
      const cleanText = message.text.replace(/【[^】]+】/g, ''); // Remove the markers
      const isFirstMessage = index === 0;

      return (
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              mb: -2,
              display: 'block',
              fontSize: '0.85rem', 
              color: theme.palette.ui.textDark,                    
            }}
          >
            {botName}
          </Typography>
          <Box sx={{ color: theme.palette.ui.textDark, width: '100%', mt: 3 }}>
            {/* Only show reasoning expander if there's reasoning_content or email_content */}
            {(message.reasoningContent || message.emailContent || 
              (isStreaming && message.id === streamingMessageId && 
               (message.component?.type === 'draft_email_preview' || message.reasoningContent))) && (
              <Box
                onClick={() => handleToggleReasoning(message.id)}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  p: 1,
                  mb: 1.5,
                  width: '100%',
                  minWidth: '100%',
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  width: '100%',
                  minWidth: '100%',
                  position: 'relative',
                }}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 1,
                      backgroundColor: alpha(theme.palette.background.paper, 0.5),
                      borderRadius: '6px',
                      padding: '4px 8px',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: 500,
                        color: alpha(theme.palette.text.primary, 0.6),
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      Collecting information
                      {isStreaming && message.id === streamingMessageId && 
                       !message.emailContent && message.reasoningContent ? ( 
                        <CircularProgress size={12} thickness={6} sx={{ color: 'inherit' }} />
                      ) : message.reasoningContent && (
                        <CheckIcon sx={{ fontSize: '12px', color: theme.palette.success.main }} />
                      )}
                    </Typography>
                    {(message.emailContent || 
                      (isStreaming && message.id === streamingMessageId && 
                       message.component?.type === 'draft_email_preview')) && (
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          color: alpha(theme.palette.text.primary, 0.6),
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Writing email
                        {isStreaming && message.id === streamingMessageId && 
                         (message.emailContent || message.component?.type === 'draft_email_preview') ? (
                          <CircularProgress size={12} thickness={6} sx={{ color: 'inherit' }} />
                        ) : message.component?.type === 'draft_email_preview' && (
                          <CheckIcon sx={{ fontSize: '12px', color: theme.palette.success.main }} />
                        )}
                      </Typography>
                    )}
                  </Box>
                  
                  {/* Render reasoning content directly below the label when expanded */}
                  {expandedReasoningIds.has(message.id) && (
                    <Box 
                      sx={{ 
                        mt: 1,
                        pt: 1,
                        borderTop: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
                        fontSize: '0.75rem',
                        opacity: 0.75,
                        lineHeight: 1.3,
                        width: '100%',
                        minWidth: '100%',
                        '& code': {
                          fontSize: '0.7rem',
                          padding: '0.2em 0.4em',
                          backgroundColor: alpha(theme.palette.background.paper, 0.5),
                          borderRadius: '3px',
                        }
                      }}
                    >
                      {message.reasoningContent ? (
                        <ReactMarkdown>{message.reasoningContent}</ReactMarkdown>
                      ) : (
                        <Typography variant="body2" sx={{ fontStyle: 'italic', fontSize: '0.75rem' }}>
                          No reasoning content available.
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            <Box sx={{ position: 'relative' }}>
              <ReactMarkdown>
                {cleanText}
              </ReactMarkdown>
              
              {/* {isFirstMessage && (
              <Box sx={{ mt: 2, mb: 2 }}>
                {threadSummaries ? (
                  <Box sx={{ 
                    fontSize: '0.85em',
                    opacity: 0.75,
                    mt: 2,
                    mb: 6,
                    pl: 1.5,
                    borderLeft: `2px solid ${alpha(theme.palette.divider, 0.2)}`,
                    '& ul': {
                      paddingLeft: 0,
                      marginTop: 0,
                      marginBottom: 0,
                      listStyle: 'none',
                      '& li': {
                        marginBottom: '0.5rem',
                        marginLeft: '-0rem',
                        '&:last-child': {
                          marginBottom: 0
                        }
                      }
                    }
                  }}>
                    <ReactMarkdown>
                      {threadSummaries}
                    </ReactMarkdown>
                  </Box>
                ) : (
                  <Button
                    onClick={fetchThreadSummaries}
                    disabled={isLoadingSummaries}
                    startIcon={isLoadingSummaries ? <CircularProgress size={16} thickness={4}  sx={{ color: theme.palette.accent.primary }}/> : null}
                    sx={{
                      color: theme.palette.accent.primary,
                      textTransform: 'none',
                      fontSize: '0.85rem',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.accent.primary, 0.1)
                      },
                      '&.Mui-disabled': {
                        color: alpha(theme.palette.accent.primary, 0.6)
                      }
                    }}
                  >
                    {isLoadingSummaries ? 'Summarizing...' : 'Summarize thread'}
                  </Button>
                )}
                </Box>
              )} */}
            </Box>
          </Box>
          {message.isError && (
            <Button
              variant="outlined"
              size="small"
              sx={{ 
                color: theme.palette.accent.primary, 
                borderColor: 'transparent',
                backgroundColor: 'white',
                mt: 0,
                boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
                '&:hover': {
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                }
              }}
              onClick={() => handleTryAgain(message.originalMessage)}
            >
              Try again
            </Button>
          )}
          {message.calendarComponent && (
            <Box sx={{ mt: 2 }}>
              <CalendarEventPreview eventData={message.calendarComponent} />
            </Box>
          )}
          {message.component?.type === 'draft_email_preview' && (
            console.log('rendering draft preview', message.component),
            <Box sx={{ mt: 2, width: '100%' }}>
              {/* Format messages for DraftEmailPreview */}
              {(() => {
                const messagesToSend = messages
                  .slice(0, index + 1)  // Only include messages up to current index
                  .filter(msg => !msg.component || msg.component !== 'shimmerEffect')
                  .map(msg => ({
                    sender: msg.sender,
                    text: msg.text
                  }));

                  console.log('message.emailContent', message.emailContent);


                return (
                  <DraftEmailPreview 
                    draftData={JSON.stringify({
                      ...(typeof message.component.content === 'string' 
                        ? JSON.parse(message.component.content) 
                        : message.component.content),
                      ...(selectedEmail && selectedEmail?.id !== "thread_newLexiChat" 
                        ? Object.fromEntries(Object.entries(selectedEmail).filter(([key]) => key !== 'body'))
                        : {})                      
                    })}
                    reasoningContent={message.reasoningContent}
                    email_content={message.emailContent}
                    isLoading={false} 
                    isMobile={isMobile}
                    messageIndex={index}
                    thread_id={selectedEmail?.thread_id || null}
                    messages={messagesToSend}
                    selectedEmailFromEmailList={selectedEmail}
                  />
                );
              })()}
            </Box>
          )}
          {/* Render email preview cards for extracted email IDs */}
          {emailIds.length > 0 && (
            <Box 
              mt={2} 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: 2,
                width: '100%',
                maxWidth: '800px',
                overflowX: 'hidden',
                pb: 1,
                pl: 2,
                pr: 2,
              }}
            >
              {emailIds.map((emailId) => (
                <Box key={emailId}>
                  <EmailPreviewCard
                    emailId={emailId}
                    onOpenPreview={() => handleOpenPreview(emailId)}
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
          {message.emailReferences && message.emailReferences.length > 0 && (
            <Box 
              mt={2} 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: 2,
                width: '100%',
                maxWidth: '800px',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  height: '2px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                  borderRadius: '1px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0,0,0,0.1)',
                  borderRadius: '1px',
                  '&:hover': {
                    background: 'rgba(0,0,0,0.2)',
                  },
                },
                scrollbarWidth: 'thin',
                scrollbarColor: 'rgba(0,0,0,0.1) transparent',
                pb: 1,
                pl: 2,
                pr: 2,
              }}
            >
              {message.emailReferences.map((emailId) => (
                <Box key={emailId}>
                  <EmailPreviewCard
                    emailId={emailId}
                    onOpenPreview={() => handleOpenPreview(emailId)}
                    sx={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
          
          {/* Knowledge Base References */}
          {message.knowledgeBaseReferences && message.knowledgeBaseReferences.length > 0 && (
            <Box 
              mt={1.5} 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 1,
                width: '100%',
                maxWidth: '800px',
                pb: 0.5,
                pl: 2,
              }}
            >
              {getUniqueKnowledgeBaseReferences(message.knowledgeBaseReferences).length > 0 && (
                <Typography variant="caption" sx={{ color: theme.palette.text.secondary, mr: 1 }}>
                  Sources:
                </Typography>
              )}
              {getUniqueKnowledgeBaseReferences(message.knowledgeBaseReferences).map((referenceData) => {
                const reference = knowledgeBaseReferences[referenceData.id];
                const fileName = reference?.metadata?.filename || 'Document';
                const fileType = reference?.metadata?.file_type || '';
                
                return (
                  <Box 
                    key={referenceData.id}
                    component="span"
                    onClick={() => {
                      // Open the knowledge base modal
                      handleOpenKnowledgeBaseModal(referenceData.id, referenceData.doc_text);
                    }}
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 0.5,
                      backgroundColor: alpha(theme.palette.accent.primary, 0.1),
                      borderRadius: '4px',
                      padding: '2px 8px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.accent.primary, 0.2),
                      }
                    }}
                  >
                    {getFileTypeIcon(fileType, fileName)}
                    <Typography variant="caption" sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {fileName}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      );
    } else {
      return (
        <Typography 
          component="div" 
          sx={{ 
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
          }}
        >
          {message.text}
        </Typography>
      );
    }
  }, [isStreaming, streamingMessageId, expandedReasoningIds, updateEmailMessage, handleTryAgain, handleOpenPreview, botName, theme.palette, fetchThreadSummaries, isLoadingSummaries, threadSummaries, knowledgeBaseReferences, fetchKnowledgeBaseContent, getFileTypeIcon, handleOpenKnowledgeBaseModal, getUniqueKnowledgeBaseReferences]);

  console.log('messages:', messages);

  // Update useEffect for messages to handle caching
  useEffect(() => {
    if (messages.length > 0 && lastSuccessfulEmailId.current && !isStreaming) {
      // Only cache if we're not streaming and have no shimmer effects
      const hasShimmerEffect = messages.some(msg => msg.component === 'shimmerEffect');
      if (!hasShimmerEffect) {
        cacheMessages(messages, lastSuccessfulEmailId.current);
      }
      
      if (shouldScrollRef.current) {
        scrollToBottom();
        shouldScrollRef.current = false;
      }
    }
  }, [messages, selectedEmail?.id, cacheMessages, scrollToBottom, isStreaming]);

  // Modify handleClearThread to clear cache
  const handleClearThread = useCallback(async () => {
    // Cancel any ongoing stream requests first
    cancelOngoingRequests();
    setIsStreaming(false);
    setIsLoading(false);
    setIsSummarizing(false);
    
    if (selectedEmail?.id) {
      if (selectedEmail.fromName === "Lexi") {
        setMessages([]);
        // Clear cache
        if (cacheKey) {
          sessionStorage.removeItem(cacheKey);
        }
      } else {
        const currentMessages = messages;
        const updatedMessages = currentMessages.length > 0 ? [currentMessages[0]] : [];
        setMessages(updatedMessages);
        // Update cache
        cacheMessages(updatedMessages, selectedEmail?.id);

        if (selectedEmail?.id) {
          try {
            await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/delete-thread-messages/1?email_id=${selectedEmail?.id}${selectedEmail?.thread_id ? `&thread_id=${selectedEmail?.thread_id}` : ''}`);
          } catch (error) {
            console.error('Error clearing thread:', error);
          }
        }
        shouldScrollRef.current = true;
      }
    }
  }, [selectedEmail, messages, cacheKey, cacheMessages, cancelOngoingRequests, setIsStreaming, setIsLoading, setIsSummarizing]);

    // Function to handle email list toggle
    const handleEmailListToggle = () => {
      setIsEmailListCollapsed(!isEmailListCollapsed);
      if (toggleEmailListCollapse) {
        toggleEmailListCollapse();
      }
    };

  // Add function to focus message input
  const focusInputField = useCallback(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);
  
  // Add function to handle creating a new chat
  const handleNewChat = useCallback(() => {
    // Cancel any ongoing stream requests first
    cancelOngoingRequests();
    
    const newChatEmail = {
      id: "thread_newLexiChat",
      fromName: "Lexi", 
      date: new Date().toISOString(),
      subject: "",
      snippet: "Start a new conversation here.",
      messages: []
    };
    
    setSelectedEmail(newChatEmail);
    setMessages([]);
    setThreadId(null);
    setIsLoading(false);
    setIsSummarizing(false);
    setIsLoadingSummaries(false);
    setIsStreaming(false);
    
    // Collapse the email list when creating a new chat if we're not on mobile
    if (toggleEmailListCollapse && !isMobile) {
      // If the email list is expanded, collapse it
      if (!isEmailListCollapsed) {
        handleEmailListToggle();
      }
    }
    
    // Focus the input field after creating a new chat
    setTimeout(() => {
      focusInputField();
    }, 0);
  }, [cancelOngoingRequests, setSelectedEmail, setMessages, setThreadId, setIsLoading, setIsSummarizing, setIsLoadingSummaries, setIsStreaming, focusInputField, toggleEmailListCollapse, isMobile, isEmailListCollapsed, handleEmailListToggle]);

  // Add keyboard shortcut for new chat (cmd + k or alt + k)
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for option/alt + k
      if ((event.altKey || event.metaKey) && event.key === 'j') {
        event.preventDefault(); // Prevent default browser behavior
        handleNewChat();
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleNewChat]);

  // Add keyboard shortcut for toggling email list (cmd + arrow left/right)
  // useEffect(() => {
  //   const handleToggleShortcut = (event) => {
  //     // Check for cmd + arrow left/right
  //     if (event.metaKey && (event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
  //       event.preventDefault(); // Prevent default browser behavior
  //       handleEmailListToggle();
  //     }
  //   };

  //   // Add event listener
  //   window.addEventListener('keydown', handleToggleShortcut);

  //   // Clean up
  //   return () => {
  //     window.removeEventListener('keydown', handleToggleShortcut);
  //   };
  // }, [handleEmailListToggle]);

  const avatarStyle = {
    width: 30,
    height: 30,
    objectFit: 'cover',
    borderRadius: '50%',
    marginRight: 1.5,
    alignSelf: 'flex-start', // This ensures the avatar aligns with the top
  };

  // Add cache helper functions for quick replies
  const getQuickRepliesFromCache = useCallback((emailId) => {
    if (!emailId) return null;
    const cacheKey = `quick_replies_${emailId}`;
    try {
      const cached = sessionStorage.getItem(cacheKey);
      if (cached) {
        return JSON.parse(cached);
      }
    } catch (error) {
      console.error('Error retrieving cached quick replies:', error);
    }
    return null;
  }, []);

  const cacheQuickReplies = useCallback((replies, emailId) => {
    if (!emailId) return;
    const cacheKey = `quick_replies_${emailId}`;
    try {
      sessionStorage.setItem(cacheKey, JSON.stringify(replies));
    } catch (error) {
      console.error('Error caching quick replies:', error);
    }
  }, []);

  const fetchSuggestions = useCallback(async (email) => {
    // Early return if no email or it's a new chat
    if (!email || email.id === "thread_newLexiChat") {
      console.log('No email or new chat, skipping suggestions fetch');
      setSuggestions([]);
      return;
    }

    // Early return if we already fetched for this email
    if (lastFetchedEmailId.current === email.id) {
      console.log('Skipping duplicate suggestions fetch for email:', email.id);
      return;
    }

    // Check cache first
    const cachedReplies = getQuickRepliesFromCache(email.id);
    if (cachedReplies && cachedReplies.length > 0) {
      console.log('Using cached suggestions for email:', email.id);
      setSuggestions(cachedReplies);
      lastFetchedEmailId.current = email.id;
      return;
    }

    // Check for existing quick replies in the email
    if (email.quick_replies && Array.isArray(email.quick_replies)) {
      console.log('Found quick_replies in email:', email.quick_replies.length);
      
      // Only process if there are actual quick replies
      if (email.quick_replies.length > 0) {
        console.log('Using existing quick replies from email:', email.id);
        const formattedReplies = email.quick_replies.map((reply, index) => ({
          id: `suggestion-${index}`,
          title: reply.command,
          subline: reply.command_description,
          type: 'email'
        }));
        // Cache the formatted replies
        cacheQuickReplies(formattedReplies, email.id);
        setSuggestions(formattedReplies);
        lastFetchedEmailId.current = email.id;
        return;
      } else {
        console.log('quick_replies array is empty, proceeding with fetch');
      }
    } else {
      console.log('No quick_replies found in email, proceeding with fetch');
    }

    // Only fetch if we don't have cached replies or existing quick replies
    console.log('Fetching new suggestions for email:', email.id);
    setIsSuggestionsLoading(true);
    try {
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + `/get-email-suggestions`, {
        email_body: email?.body,
        selectedEmail: email,
        userInfo: userInfo
      });
      if (response.data && response.data.suggestions) {
        const formattedSuggestions = response.data.suggestions.map((suggestion, index) => ({
          id: `suggestion-${index}`,
          title: suggestion.action,
          subline: suggestion.short_description,
          type: 'email'
        }));
        // Cache the suggestions
        cacheQuickReplies(formattedSuggestions, email.id);
        setSuggestions(formattedSuggestions);
        lastFetchedEmailId.current = email.id;
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    } finally {
      setIsSuggestionsLoading(false);
    }
  }, [getQuickRepliesFromCache, cacheQuickReplies, userInfo]);

  // Keep the thread messages cleanup
  useEffect(() => {
    const clearCacheOnRefresh = () => {
      // Only clear thread messages, preserve quick replies
      Object.keys(sessionStorage).forEach(key => {
        if (key.startsWith('thread_messages_')) {
          sessionStorage.removeItem(key);
        }
      });
      // Reset last fetched email ID
      lastFetchedEmailId.current = null;
    };

    // Clear thread messages cache when component mounts (page refresh)
    clearCacheOnRefresh();

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', clearCacheOnRefresh);

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', clearCacheOnRefresh);
      lastFetchedEmailId.current = null;  // Reset on unmount
    };
  }, []);

  console.log('check 0:', selectedEmail);  

  // Add effect to fetch messages when selectedEmail changes
  useEffect(() => {
    if (selectedEmail?.id) {
      if (selectedEmail.id === "thread_newLexiChat") {
        console.log('thread messages check 1');
        if (!currentStreamingEmailId.current) {
          console.log('thread messages check 2');
          setMessages([]);
          setThreadId(null);
          setIsLoading(false);
          setIsSummarizing(false);
          setIsStreaming(false);
          lastSuccessfulEmailId.current = "thread_newLexiChat";
          lastFetchedEmailId.current = "thread_newLexiChat";
          setThreadSummaries(null);
          setIsLoadingSummaries(false);
        }
      } else if (currentStreamingEmailId.current && currentStreamingEmailId.current === selectedEmail.id) {
        console.log('thread messages check 3');
        lastSuccessfulEmailId.current = selectedEmail.id;        
      } else { 
        // Set loading states
        console.log('thread messages check 4');
        setIsStreaming(true);
        setIsSummarizing(true);
        setThreadSummaries(null);
        setIsLoadingSummaries(false);
        
        // Fetch messages
        fetchThreadMessages(selectedEmail.id)
          .catch((error) => {
            console.error('Error in useEffect fetching thread messages:', error);
            setIsStreaming(false);
            setIsSummarizing(false);
          });

        // Fetch suggestions separately
        // fetchSuggestions(selectedEmail);
      }
    } else if (latestEmailIdRef.current && latestEmailIdRef.current !== "thread_newLexiChat") {
      // Handle case where selectedEmail is null but we have a valid email_id in the ref
      console.log('Using latestEmailIdRef as fallback:', latestEmailIdRef.current);
      setIsStreaming(true);
      setIsSummarizing(true);
      setThreadSummaries(null);
      setIsLoadingSummaries(false);
      
      fetchThreadMessages(latestEmailIdRef.current)
        .catch((error) => {
          console.error('Error in useEffect fetching thread messages with ref:', error);
          setIsStreaming(false);
          setIsSummarizing(false);
        });
    }
  }, [selectedEmail, fetchThreadMessages]);

  // Ensure threadId state is synced with selectedEmail.thread_id
  useEffect(() => {
    // Check if we have a valid selectedEmail
    if (selectedEmail) {
      if (selectedEmail.thread_id) {
        // If selectedEmail has a thread_id, use it
        setThreadId(selectedEmail.thread_id);
        console.log('Setting threadId from selectedEmail:', selectedEmail.thread_id);
      } else if (selectedEmail.id && selectedEmail.id !== "thread_newLexiChat") {
        // If we have an email ID but no thread_id, it might be because thread_id wasn't set properly
        // Log this case to help with debugging
        console.warn('selectedEmail has ID but no thread_id:', selectedEmail.id);
        
        // For existing emails, the ID could be used as thread_id if needed
        // This is a fallback only for emails (not for new chats)
        const fallbackThreadId = selectedEmail.id;
        if (fallbackThreadId && !threadId) {
          console.log('Using fallback threadId:', fallbackThreadId);
          setThreadId(fallbackThreadId);
          
          // Update selectedEmail with the fallback thread_id to maintain consistency
          setSelectedEmail(prev => ({ 
            ...prev, 
            thread_id: fallbackThreadId
          }));
        }
      } else if (selectedEmail.id === "thread_newLexiChat" && threadId) {
        // For new chat, if threadId is set (from createNewThread) but not in selectedEmail,
        // update selectedEmail to include the threadId
        console.log('Updating new chat with existing threadId:', threadId);
        setSelectedEmail(prev => ({ 
          ...prev, 
          thread_id: threadId
        }));
      }
    } else if (threadId) {
      // If threadId is set but selectedEmail is null, clear threadId to avoid inconsistency
      console.log('Clearing threadId because selectedEmail is null');
      setThreadId(null);
    }
  }, [selectedEmail, threadId, setSelectedEmail]);

  // Update latestEmailIdRef when selectedEmail changes
  useEffect(() => {
    if (selectedEmail?.id) {
      latestEmailIdRef.current = selectedEmail?.id;
      console.log('Updated latestEmailIdRef:', latestEmailIdRef.current);
    }
  }, [selectedEmail]);


  return (
    <Box 
      ref={chatWindowRef}
      display="flex" 
      flexDirection="column" 
      height="100%" 
      position="relative"
      // onDragOver={handleDragOver}
      // onDragLeave={handleDragLeave}
      // onDrop={handleDrop}
      sx={{ 
        width: '100%',
        flex: 1,
      }}
    >
      <Box sx={{ display: 'flex', height: '100%', position: 'relative', width: '100%', flex: 1 }}>
        <Paper 
          elevation={3} 
          sx={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            overflow: 'hidden',
            borderRadius: '5px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <ChatHeader 
            fromName={selectedEmail?.fromName || ''}
            fromEmail={selectedEmail?.fromEmail || 'Unknown Sender Email'}
            receiveDate={selectedEmail?.date || new Date().toISOString()}
            recipients={selectedEmail?.recipients || []}
            subject={selectedEmail?.subject || ''}
            attachments={selectedEmail?.attachments}
            messageId={selectedEmail?.id} 
            cc={selectedEmail?.ccRecipients}            
            handleClearThread={handleClearThread}
            handleNewChat={handleNewChat}
            handleEmailListToggle={handleEmailListToggle}
            isEmailListCollapsed={isEmailListCollapsed}
            bcc={selectedEmail?.bccRecipients}
            isChatView={true}
            onNameClick={onNameClick}
            setSelectedEmail={setSelectedEmail}
            isMobile={isMobile}
            setIsSidebarOpen={setIsSidebarOpen}
            setMessages={setMessages}
            setThreadId={setThreadId}
            setIsLoading={setIsLoading}
            setIsSummarizing={setIsSummarizing}
            setIsLoadingSummaries={setIsLoadingSummaries}
            setIsStreaming={setIsStreaming}
            toggleEmailListCollapse={toggleEmailListCollapse}
            messageInputRef={messageInputRef}
            focusInputField={focusInputField}
          />
          <Box
            ref={messagesContainerRef}
            sx={{
              flex: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              bgcolor: theme.palette.background.default,
              position: 'relative',
              pl: isMobile ? 2 : 4,
              pt: isMobile ? 3 : 5,
              pr: isMobile ? 2 : 4,
              pb: '140px',
              backgroundColor: theme.palette.primary.light,
              '&::-webkit-scrollbar': {
                width: '0.4em'
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '1px solid slategrey'
              },
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(0,0,0,.1) transparent',
              lineHeight: 2,
            }}
          >
            {messages.length === 0 && !isLoading && !isSummarizing ? (
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: 1, mt: 0 }}>
                  <EmptyStateContent botName={botName} botImageUrl={botImageUrl} username={userInfo?.displayName || userInfo?.name || 'there'} />
                </Box>
              </Box>
            ) : (
              <Box>
                {isSummarizing ? (
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start', 
                    mb: 2,
                    maxWidth: '900px',
                    margin: '0 auto',
                  }}>
                    <Avatar 
                      src={botImageUrl} 
                      alt={botName} 
                      sx={{ ...avatarStyle }} 
                    />
                    <Box sx={{ flexGrow: 1, maxWidth: '100%', overflow: 'visible' }}>
                      {renderShimmerEffect()}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
                    {messages.map((message, index) => {
                      const isLastMessage = index === messages.length - 1;
                      const isFileComponent = message.isFile || message.component === 'File Component Thread';
                      const showShimmer = (isStreaming || isWaitingForResponse || isSummarizing) && message.component === 'shimmerEffect';
    
                      if (message.component === 'shimmerEffect' && !showShimmer) {
                        return null;
                      }
                      
                      return (
                        <Box
                          key={message.id}
                          display="flex"
                          justifyContent={message?.sender === 'User' ? 'flex-end' : 'flex-start'}
                          alignItems="flex-start"
                          mb={isLastMessage ? 
                            (!messageInputValue && selectedEmail?.id !== "thread_newLexiChat" ? 
                              (isMobile ? 8 : 10) : 
                              (isMobile ? 4 : 6)) : 
                            (isMobile ? 2 : 3)}
                          sx={{ 
                            position: 'relative',
                            pt: isMobile ? 1 : 2, // Add consistent top padding for all messages
                            '&:hover': {
                              '& .edit-button': {
                                opacity: 0.7,
                              }
                            }
                          }}
                        >
                          {message?.sender === 'Bot' && (
                            <Avatar 
                              src={botImageUrl} 
                              alt={botName} 
                              sx={{ 
                                ...avatarStyle,
                                width: isMobile ? 25 : 30,
                                height: isMobile ? 25 : 30,
                                marginRight: 1.5,
                              }}
                            />
                          )}
                          {isFileComponent ? (
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: message?.sender === 'User' ? 'flex-end' : 'flex-start' }}>
                              {renderFileComponent(message)}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: message?.sender === 'User' ? theme.palette.accent.secondary : 'transparent',
                                boxShadow: message?.sender === 'User' ? '0px 2px 4px rgba(0,0,0,0.1)' : 'none',
                                padding: message?.sender === 'User' ? (isMobile ? '8px 12px' : '10px 20px') : '0',
                                minWidth: message?.sender === 'User' && editingMessageId === message.id ? '85%' : 'auto',
                                maxWidth: '85%',
                                wordBreak: 'break-word',
                                borderRadius: message?.sender === 'User' ? '15px' : '0',
                                borderTopRightRadius: message?.sender === 'User' ? '0' : '0',
                                fontSize: isMobile ? '0.9rem' : '1rem',
                                position: 'relative',
                                pr: message?.sender === 'Bot' ? (isMobile ? 2 : 4) : 2,
                              }}
                            >
                              {message?.sender === 'User' && editingMessageId === message.id ? (
                                <Box sx={{ 
                                  display: 'flex', 
                                  flexDirection: 'column', 
                                  gap: 1, 
                                  width: '100%'
                                }}>
                                  <textarea
                                    value={editedMessageText}
                                    onChange={(e) => setEditedMessageText(e.target.value)}
                                    style={{
                                      width: '100%',
                                      padding: '12px',
                                      borderRadius: '8px',
                                      border: '1px solid rgba(0,0,0,0.1)',
                                      fontSize: 'inherit',
                                      minHeight: '120px',
                                      resize: 'vertical',
                                      fontFamily: 'inherit',
                                      lineHeight: '1.5',
                                      boxSizing: 'border-box'
                                    }}
                                    autoFocus
                                  />
                                  <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                                    <Button
                                      size="small"
                                      onClick={() => handleCancelEdit()}
                                      sx={{ 
                                        minWidth: '60px',
                                        color: theme.palette.accent.primary,
                                        '&:hover': {
                                          backgroundColor: 'rgba(0,0,0,0.04)'
                                        }
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => handleSaveEdit(message.id)}
                                      sx={{ 
                                        minWidth: '60px',
                                        color: theme.palette.accent.secondary,
                                        backgroundColor: theme.palette.accent.darkGreen,
                                        '&:hover': {
                                          backgroundColor: alpha(theme.palette.accent.darkGreen, 0.8),
                                        }
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </Box>
                                </Box>
                              ) : (
                                <>
                                  {showShimmer ? renderShimmerEffect() : renderMessage(message, index)}
                                  {message?.sender === 'User' && (
                                    <IconButton
                                      className="edit-button"
                                      size="small"
                                      onClick={() => handleEditMessage(message.id, message.text)}
                                      sx={{
                                        position: 'absolute',
                                        right: '8px',
                                        top: '-20px',
                                        opacity: 0,
                                        transition: 'opacity 0.2s',
                                        backgroundColor: 'white',
                                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                                        padding: '4px',
                                        '&:hover': {
                                          backgroundColor: 'white',
                                          opacity: '1 !important',
                                        },
                                        '.MuiSvgIcon-root': {
                                          fontSize: '14px',
                                        }
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  )}
                                </>
                              )}
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box 
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: isMobile ? 1 : 2,
              paddingLeft: isMobile ? 2 : 5,
              paddingRight: isMobile ? 2 : 5,
              // paddingBottom: isMobile ? 'calc(8px + env(safe-area-inset-bottom))' : 2,
              background: `linear-gradient(to top, ${theme.palette.primary.light} 85%, rgba(255,255,255,0) 100%)`,
              zIndex: 10,
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
          >
            <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
              {memoizedQuickReplies}
              {memoizedMessageInput}
            </Box>
          </Box>
        </Paper>
        {isDragging && !document.getElementById('email-component-drop-zone') && (
          <Box
            sx={{
              className:"drag-overlay",
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              zIndex: 1000,
            }}
          >
            <IconButton
              sx={{
                fontSize: '64px',
                mb: 2,
              }}
            >
              <CloudUploadIcon fontSize="inherit" />
            </IconButton>
            <Typography 
              variant="body1" 
              component="div" 
              gutterBottom
              sx={{ color: theme.palette.ui.textDark }}
            >
              Drop files here to attach
            </Typography>
          </Box>
        )}
      </Box>
      <Dialog
        open={isPreviewDialogOpen}
        onClose={handleClosePreview}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        fullScreen={isMobile}
        PaperProps={{
          sx: { 
            height: isMobile ? '80vh' : '90vh',
            maxHeight: isMobile ? '80vh' : '90vh',
            minWidth: isMobile ? '100vw' : { sm: '800px', md: '1000px' },
            width: isMobile ? '100vw' : '90vw',
            m: isMobile ? 0 : 2,
            borderRadius: isMobile ? '16px 16px 0 0' : '8px',
            overflow: 'hidden',
            bgcolor: theme.palette.primary.light,
            position: isMobile ? 'fixed' : 'relative',
            bottom: isMobile ? 0 : 'auto',
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
          },
        }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleClosePreview();
          }
        }}
      >
        <Box 
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isMobile && (
            <Box
              sx={{
                height: '4px',
                width: '36px',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '2px',
                position: 'absolute',
                top: '8px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
              }}
            />
          )}
          <DialogContent 
            sx={{ 
              p: 0,
              flex: 1,
              overflow: 'auto',
              ...(isMobile && {
                mt: '20px',
              }),
            }}
          >
            {selectedPreviewEmail && (
              <EmailPreviewComponent
                selectedEmail={selectedPreviewEmail}
                onSwap={handleClosePreview}
                isMobile={isMobile}
              />
            )}
            {selectedKnowledgeBase && (
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  p: 3,
                  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`
                }}>
                  <Typography variant="h6" sx={{ color: theme.palette.accent.primary }}>
                    {selectedKnowledgeBase?.metadata?.filename || 'File Preview'}
                  </Typography>
                  <IconButton
                    onClick={handleClosePreview}
                    sx={{
                      color: theme.palette.ui.textLight,
                      '&:hover': {
                        color: theme.palette.accent.primary,
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                
                {isLoadingContent ? (
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100%' 
                  }}>
                    <CircularProgress sx={{ color: theme.palette.accent.primary }} />
                  </Box>
                ) : (
                  <Box sx={{ 
                    flex: 1, 
                    overflowY: 'auto',
                    bgcolor: theme.palette.background.paper,
                    m: 3,
                    borderRadius: 1,
                    p: 3,
                    fontFamily: 'monospace',
                    whiteSpace: 'pre-wrap',
                    fontSize: '0.875rem',
                    lineHeight: 1.5
                  }}>
                    {selectedKnowledgeBase?.doc_text && selectedKnowledgeBase?.content ? (
                      <div dangerouslySetInnerHTML={{
                        __html: (() => {
                          let highlightedContent = selectedKnowledgeBase.content;
                          
                          // Helper function to normalize text for comparison
                          const normalizeText = (text) => {
                            return text.replace(/\s+/g, '\\s+');
                          };
                          
                          // Check if doc_text is an array
                          if (Array.isArray(selectedKnowledgeBase.doc_text)) {
                            // Highlight each text item in the array
                            selectedKnowledgeBase.doc_text.forEach(textItem => {
                              if (textItem && typeof textItem === 'string') {
                                // Escape special regex characters but allow flexible whitespace matching
                                const escapedText = escapeRegExp(textItem.trim());
                                // Create a regex that allows for flexible whitespace matching
                                const flexibleRegex = new RegExp(normalizeText(escapedText), 'g');
                                
                                try {
                                  highlightedContent = highlightedContent.replace(
                                    flexibleRegex,
                                    match => `<span style="background-color: lightgreen;">${match}</span>`
                                  );
                                } catch (error) {
                                  console.error('Error highlighting text:', error);
                                  // Fallback to exact matching if regex fails
                                  const exactText = escapeRegExp(textItem.trim());
                                  highlightedContent = highlightedContent.replace(
                                    new RegExp(exactText, 'g'),
                                    match => `<span style="background-color: lightgreen;">${match}</span>`
                                  );
                                }
                              }
                            });
                          } else if (selectedKnowledgeBase.doc_text && typeof selectedKnowledgeBase.doc_text === 'string') {
                            // Fallback for backward compatibility with string doc_text
                            const escapedText = escapeRegExp(selectedKnowledgeBase.doc_text.trim());
                            const flexibleRegex = new RegExp(normalizeText(escapedText), 'g');
                            
                            try {
                              highlightedContent = highlightedContent.replace(
                                flexibleRegex,
                                match => `<span style="background-color: yellow;">${match}</span>`
                              );
                            } catch (error) {
                              console.error('Error highlighting text:', error);
                              // Fallback to exact matching if regex fails
                              highlightedContent = highlightedContent.replace(
                                new RegExp(escapedText, 'g'),
                                match => `<span style="background-color: yellow;">${match}</span>`
                              );
                            }
                          }
                          
                          return highlightedContent;
                        })()
                      }} />
                    ) : (
                      selectedKnowledgeBase?.content || 'No content available'
                    )}
                  </Box>
                )}
              </Box>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
});

export default ChatWindow;