import React from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { alpha } from '@mui/system';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  TimeScale
);

const DashboardTab = ({
  dashboardMetrics,
  analyzedUserEmail,
  lastUpdated,
  isLoadingMetrics,
  fetchDashboardMetrics,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Add chart data preparation for response time trend
  const chartData = React.useMemo(() => {
    if (!dashboardMetrics?.daily_averages) return null;

    const dates = Object.keys(dashboardMetrics.daily_averages).sort();
    const data = dates.map(date => ({
      x: new Date(date),
      y: dashboardMetrics.daily_averages[date]
    }));

    return {
      labels: data.map(point => point.x),
      datasets: [
        {
          label: 'Avg. Response Time',
          data: data.map(point => point.y),
          borderColor: theme.palette.accent.primary,
          backgroundColor: alpha(theme.palette.accent.primary, 0.2),
          fill: true,
          tension: 0.4,
          pointRadius: 2,
          pointHoverRadius: 5
        }
      ]
    };
  }, [dashboardMetrics, theme.palette.accent.primary]);

  // Add chart options 
  const chartOptions = React.useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM d, yyyy',
          displayFormats: {
            day: 'MMM d'
          }
        },
        title: {
          display: false
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: false
        },
        grid: {
          color: alpha('#000', 0.05)
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        displayColors: false,
        padding: 8
      }
    },
    interaction: {
      mode: 'index',
      intersect: false
    }
  }), [theme]);

  // Render heatmap for busiest hours
  const renderHeatmap = (data, color) => {
    if (!data || data.length === 0) {
      return (
        <Typography variant="body2" color="text.secondary">
          No data available
        </Typography>
      );
    }

    // Find the maximum value to normalize the color intensity
    const maxValue = Math.max(
      ...data.flatMap(hourData => 
        ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => hourData[day])
      )
    );

    // Only show hours from 0 to 23 (24 hours)
    const filteredData = data.slice(0, 24);

    // Function to format hour for display
    const formatHour = (hour) => {
      const hourNum = parseInt(hour);
      if (isNaN(hourNum)) return hour;
      const ampm = hourNum >= 12 ? 'PM' : 'AM';
      const displayHour = hourNum % 12 || 12;
      return `${displayHour}${ampm}`;
    };

    return (
      <Box sx={{ 
        overflowX: 'auto', 
        overflowY: 'hidden',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        pb: 0,
        px: isMobile ? 2 : 3,
        WebkitOverflowScrolling: 'touch',
      }}>
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: 'auto repeat(7, 1fr)',
          gap: 0.5,
          minWidth: isMobile ? '480px' : 'auto',
          width: '100%'
        }}>
          {/* Header row with days */}
          <Box sx={{ gridColumn: '1 / 2', gridRow: '1 / 2' }}></Box>
          {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
            <Box 
              key={`day-${index}`}
              sx={{ 
                gridColumn: `${index + 2} / ${index + 3}`, 
                gridRow: '1 / 2',
                textAlign: 'center'
              }}
            >
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                {day}
              </Typography>
            </Box>
          ))}

          {/* Data grid */}
          {filteredData.map((hourData, hourIndex) => (
            <React.Fragment key={`hour-${hourIndex}`}>
              {/* Hour label */}
              <Box sx={{ 
                gridColumn: '1 / 2', 
                gridRow: `${hourIndex + 2} / ${hourIndex + 3}`,
                textAlign: 'right',
                pr: 1
              }}>
                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }}>
                  {formatHour(hourData.hour)}
                </Typography>
              </Box>
              
              {/* Day cells */}
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, dayIndex) => {
                const value = hourData[day] || 0;
                const intensity = maxValue > 0 ? value / maxValue : 0;
                return (
                  <Box 
                    key={`cell-${hourIndex}-${dayIndex}`}
                    sx={{ 
                      gridColumn: `${dayIndex + 2} / ${dayIndex + 3}`, 
                      gridRow: `${hourIndex + 2} / ${hourIndex + 3}`,
                      width: isMobile ? 30 : 40,
                      height: isMobile ? 16 : 20,
                      bgcolor: alpha(color, intensity * 0.8),
                      borderRadius: 0.5,
                      position: 'relative',
                      '&:hover::after': {
                        content: `'${value} emails'`,
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        fontSize: '0.7rem',
                        padding: '2px 4px',
                        borderRadius: 1,
                        boxShadow: 1,
                        zIndex: 1,
                        whiteSpace: 'nowrap'
                      }
                    }}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  };

  // Render line chart
  const renderLineChart = (data, lineColor, fillColor) => {
    if (!data || data.length === 0) {
      return (
        <Typography variant="body2" color="text.secondary">
          No data available
        </Typography>
      );
    }

    // Format data for Chart.js
    const chartData = {
      labels: data.map(item => new Date(item.date)),
      datasets: [
        {
          label: 'Messages',
          data: data.map(item => item.count),
          borderColor: lineColor,
          backgroundColor: fillColor,
          fill: true,
          tension: 0.4,
          pointRadius: 2,
          pointHoverRadius: 5
        }
      ]
    };

    // Chart options
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: 'MMM d, yyyy',
            displayFormats: {
              day: 'MMM d'
            }
          },
          title: {
            display: false
          },
          grid: {
            display: false
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: alpha('#000', 0.05)
          },
          ticks: {
            precision: 0
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: theme.palette.background.paper,
          titleColor: theme.palette.text.primary,
          bodyColor: theme.palette.text.secondary,
          borderColor: theme.palette.divider,
          borderWidth: 1
        }
      }
    };

    return (
      <Box sx={{ 
        width: '100%', 
        height: '200px',
        pt: 1
      }}>
        <Line data={chartData} options={options} />
      </Box>
    );
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 3, marginBottom: '20px' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 1
      }}>
        <Typography variant="body2" color="text.secondary">
          Based on data from the past 30 days for {analyzedUserEmail}
          {/* <br />
          {lastUpdated ? `Last updated: ${new Date(lastUpdated).toLocaleString()}` : ''} */}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => fetchDashboardMetrics(true)}
          disabled={isLoadingMetrics}
          startIcon={isLoadingMetrics ? <CircularProgress size={20} sx={{ color: theme.palette.accent.primary }} /> : null}
          sx={{
            color: theme.palette.accent.primary,
            borderColor: alpha(theme.palette.accent.primary, 0.2),
            '&:hover': {
              backgroundColor: alpha(theme.palette.accent.primary, 0.04),
              borderColor: alpha(theme.palette.accent.primary, 0.2),
            },
          }}
        >
          {isLoadingMetrics ? 'Refreshing...' : 'Refresh Metrics'}
        </Button>
      </Box>

      {isLoadingMetrics && !dashboardMetrics ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress sx={{ color: theme.palette.accent.primary }} />
        </Box>
      ) : dashboardMetrics ? (
        <>
          {/* Summary Cards */}
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)', 
            gap: 2 
          }}>
            {[
              {
                title: 'Average Response Time',
                value: dashboardMetrics.average_response_time,
                color: theme.palette.accent.primary,
                lightColor: theme.palette.accent.lighter
              },
              {
                title: 'Total Sent Emails',
                value: dashboardMetrics.total_sent || '0',
                color: theme.palette.success.main,
                lightColor: theme.palette.success.lighter
              },
              {
                title: 'Total Received Emails',
                value: dashboardMetrics.total_received || '0',
                color: theme.palette.info.main,
                lightColor: theme.palette.info.lighter
              }
            ].map((card, index) => (
              <Box
                key={index}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  bgcolor: card.lightColor,
                  boxShadow: 1,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: 3
                  }
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {card.title}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    mt: 1,
                    color: card.color
                  }}
                >
                  {card.value}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Email Analytics Section */}
          {dashboardMetrics.emailAnalytics && (
            <>
              {/* Busiest Hours Heatmaps */}
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)', 
                gap: 2,
                mb: 2 // Add consistent margin between sections
              }}>
                {/* Sent Messages Heatmap */}
                <Box sx={{ 
                  p: 3,
                  pb: 1, // Reduce bottom padding
                  borderRadius: 2,
                  bgcolor: theme.palette.background.paper,
                  boxShadow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Typography variant="h6" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#4caf50', mr: 1 }}></Box>
                    Sent Emails
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0, pb: 0, fontSize: isMobile ? '0.75rem' : '0.875rem', textAlign: isMobile ? 'center' : 'left', px: isMobile ? 1 : 0 }}>
                    {dashboardMetrics.emailAnalytics.productivity_insight}
                  </Typography>
                  {renderHeatmap(dashboardMetrics.emailAnalytics.busiest_hours_sent, theme.palette.success.main)}
                </Box>

                {/* Received Messages Heatmap */}
                <Box sx={{ 
                  p: 3,
                  pb: 1, // Reduce bottom padding
                  borderRadius: 2,
                  bgcolor: theme.palette.background.paper,
                  boxShadow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Typography variant="h6" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: '#2196f3', mr: 1 }}></Box>
                    Received Emails
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0, pb: 0, fontSize: isMobile ? '0.75rem' : '0.875rem', textAlign: isMobile ? 'center' : 'left', px: isMobile ? 1 : 0 }}>
                    {dashboardMetrics.emailAnalytics.reception_insight}
                  </Typography>
                  {renderHeatmap(dashboardMetrics.emailAnalytics.busiest_hours_received, theme.palette.info.main)}
                </Box>
              </Box>

              {/* Messages by Day Line Charts */}
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)', 
                gap: 2,
                mt: 0 // Remove top margin
              }}>
                {/* Sent Messages Line Chart */}
                <Box sx={{ 
                  p: 3,
                  borderRadius: 2,
                  bgcolor: theme.palette.background.paper,
                  boxShadow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>Emails Sent by Day</Typography>
                  {renderLineChart(
                    dashboardMetrics.emailAnalytics.messages_by_day_sent,
                    '#4caf50',
                    'rgba(76, 175, 80, 0.2)'
                  )}
                </Box>

                {/* Received Messages Line Chart */}
                <Box sx={{ 
                  p: 3,
                  borderRadius: 2,
                  bgcolor: theme.palette.background.paper,
                  boxShadow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>Emails Received by Day</Typography>
                  {renderLineChart(
                    dashboardMetrics.emailAnalytics.messages_by_day_received,
                    '#2196f3',
                    'rgba(33, 150, 243, 0.2)'
                  )}
                </Box>
              </Box>
            </>
          )}

          {/* Response Time Trend Chart */}
          <Box sx={{ 
            flex: 1,
            minHeight: '400px',
            p: 3,
            borderRadius: 2,
            bgcolor: theme.palette.background.paper,
            boxShadow: 1,
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Response Time Trend</Typography>
            {chartData ? (
              <Box sx={{ flex: 1, minHeight: 300 }}>
                <Line data={chartData} options={chartOptions} />
              </Box>
            ) : (
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Not enough data to display response time trend
                </Typography>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant="body1" color="text.secondary">
            No analytics data available. Please check back later.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DashboardTab; 