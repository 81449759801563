import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, IconButton, useTheme, useMediaQuery, BottomNavigation, BottomNavigationAction, Typography, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EmailList from './EmailList';
import ChatWindow from './ChatWindow';
import { axiosWithAuth, tokenChangeEmitter } from './authService';
import EmailPreviewComponent from './EmailPreviewComponent';
import EmailComponent from './EmailComponent';
import LoadingAnimation from './LoadingAnimation';
import WebSocketManager from './WebSocketManager';
import NotesPanel from './NotesPanel';

// Add a function to get folder ID from userFolders in session storage
const getFolderIdFromMapping = (folderKey) => {
  try {
    // Get the userFolders from session storage
    const userFolders = JSON.parse(sessionStorage.getItem('userFolders') || '[]');
    
    // Recursive function to search through the folder hierarchy
    const findFolderIdByDisplayName = (displayName, folders) => {
      if (!folders || !Array.isArray(folders)) return null;
      
      for (const folder of folders) {
        if (folder.displayName === displayName) {
          return folder.id;
        }
        
        if (folder.childFolders && Array.isArray(folder.childFolders) && folder.childFolders.length > 0) {
          const childResult = findFolderIdByDisplayName(displayName, folder.childFolders);
          if (childResult) return childResult;
        }
      }
      return null;
    };
    
    // Normalize inbox_focused and inbox_other to inbox
    const normalizedFolderKey = folderKey === 'inbox_focused' || folderKey === 'inbox_other' ? 'inbox' : folderKey;
    
    // Find the folder ID by display name
    const folderId = findFolderIdByDisplayName(normalizedFolderKey, userFolders);
    
    // Return the folder ID if found
    if (folderId) {
      return folderId;
    }
    
    // If the folder key is not found, log a warning and return the key itself
    console.warn(`Folder ID not found for key: ${folderKey}`);
    return folderKey;
  } catch (error) {
    console.error('Error getting folder ID from user folders:', error);
    return folderKey;
  }
};

const MailView = ({  
  selectedEmail, 
  setSelectedEmail, 
  searchQuery, 
  clearSearchQuery, 
  isSidebarOpen,
  selectedEmailIds,
  setSelectedEmailIds,
  lastSelectedEmailId,
  setLastSelectedEmailId,
  focusedEmailId,
  setFocusedEmailId,
  unreadEmails,
  setUnreadEmails,
  areSelectedEmailsFlagged,
  folderEmails,
  showEmailList,
  setShowEmailList,
  updateEmailsStateAndCache,
  handleEmailMove,
  getCachedEmails,
  getCacheKey,
  setIsSearching,
  activeFilters,
  setActiveFilters,
  onFilterChange,
  handleActionComplete
}) => {
  // Derive 'currentFolder' from URL and handle inference filters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);      
  const urlFolder = location.pathname === '/contacts'
      ? 'contacts'
      : (searchParams.get('folder') || 'inbox_focused');
  const emailId = searchParams.get('mailthreadid');
  const [inferenceFilter, setInferenceFilter] = useState('focused');
  const [isViewingSingleEmail, setIsViewingSingleEmail] = useState(false);
  const [isFetchingSingleEmail, setIsFetchingSingleEmail] = useState(false);
  // Combine folder and filter for frontend rendering
  const currentFolder = urlFolder === 'inbox' ? `inbox_${inferenceFilter}` : urlFolder;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [composeMode, setComposeMode] = useState(null);

  console.log('currentFolder mailview', currentFolder);

  // Then use it in state initialization
  const [isLoading, setIsLoading] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [nextSearchLink, setNextSearchLink] = useState(null);
  const lastFetchTime = useRef({});
  const isFetchingByFolderRef = useRef({});
  const fetchPromisesByFolderRef = useRef({});
  const prevSearchQueryRef = useRef('');
  const searchFolderRef = useRef('');
  const lastSearchRef = useRef({ query: '', folder: '' });
  const searchRequestInProgress = useRef(false);
  const [expandedThreads, setExpandedThreads] = useState(new Set());
  const [prefetchComplete, setPrefetchComplete] = useState(false);
  const [isPrefetching, setIsPrefetching] = useState(false);
  const [socket, setSocket] = useState(null);
  const [mobileView, setMobileView] = useState('email'); // Default to 'email'
  const [isFetchingEmails, setIsFetchingEmails] = useState(false);

  const fetchErrorsRef = useRef({});
  const fetchRetriesRef = useRef({});
  
  const [isEmailListCollapsed, setIsEmailListCollapsed] = useState(false);

// Add function to wait for userFolders initialization
const waitForFolderMapping = async () => {
  setIsFetchingEmails(true);
  const maxAttempts = 10;
  const delayMs = 500;
  let attempts = 0;

  while (attempts < maxAttempts) {
    const userFolders = sessionStorage.getItem('userFolders');
    const foldersFetched = sessionStorage.getItem('foldersFetched');
    
    if (userFolders && foldersFetched === 'true') {
      return true;
    }
    
    await new Promise(resolve => setTimeout(resolve, delayMs));
    attempts++;
  }
  setIsFetchingEmails(false);
  console.warn('Timed out waiting for folder data initialization');
  return false;
};

  // Add a function to toggle email list collapse
  const toggleEmailListCollapse = () => {
    setIsEmailListCollapsed(!isEmailListCollapsed);
  };

  const getStoredThreadEmails = useCallback((conversationId) => {
    try {
      // Get current account email
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const accountEmail = currentAccount.email;
      
      // Create a cache key for this conversation
      const cacheKey = `thread_${conversationId}_${accountEmail}`;
      
      // Retrieve the thread emails from session storage
      const storedThreads = sessionStorage.getItem(cacheKey);
      if (storedThreads) {
        return JSON.parse(storedThreads);
      }
    } catch (error) {
      console.error('Error retrieving thread emails:', error);
    }
    return null;
  }, []);

  // Add a function to store and retrieve thread emails from session storage
  const storeThreadEmails = useCallback((conversationId, threadEmails) => {
    try {
      // Get current account email
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const accountEmail = currentAccount.email;
      
      // Create a cache key for this conversation
      const cacheKey = `thread_${conversationId}_${accountEmail}`;
      
      // Store the thread emails in session storage
      sessionStorage.setItem(cacheKey, JSON.stringify(threadEmails));
    } catch (error) {
      console.error('Error storing thread emails:', error);
    }
  }, []);

  // Add new state for sender emails
  const [senderEmails, setSenderEmails] = useState([]);
  const [isFetchingSenderEmails, setIsFetchingSenderEmails] = useState(false);
  const [senderEmailsNextLink, setSenderEmailsNextLink] = useState(null);
  const currentSenderEmailRef = useRef(null);

  // Add new function to fetch all emails from a sender
  const fetchSenderEmails = useCallback(async (senderEmail, conversationId) => {
    if (!senderEmail) {
      console.log('No sender email provided, cannot fetch sender emails');
      return [];
    }
    
    if (currentSenderEmailRef.current === senderEmail) {
      console.log(`Already fetched emails for sender ${senderEmail}, skipping`);
      return [];
    }
    
    currentSenderEmailRef.current = senderEmail;
    setIsFetchingSenderEmails(true);
    
    console.log(`Fetching emails from sender: ${senderEmail}`);
    
    try {
      // URL encode the sender email to handle special characters
      const encodedSenderEmail = encodeURIComponent(senderEmail);
      
      // Add the conversation ID to exclude the current thread
      const excludeParam = conversationId ? `&exclude_thread_id=${conversationId}` : '';
      
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/fetch-sender-emails/${encodedSenderEmail}?limit=10${excludeParam}`
      );
      
      if (response.data && response.data.emails) {
        console.log(`Fetched ${response.data.emails.length} emails from sender: ${senderEmail}`);
        
        // Process emails - add date objects
        const processedEmails = response.data.emails.map(email => ({
          ...email,
          date: new Date(email.date),
          isFromSender: true // Add flag to identify these as sender emails
        }));
        
        setSenderEmailsNextLink(response.data.nextLink || null);
        // Return processed emails instead of setting state directly
        return processedEmails;
      } else {
        console.log('No emails returned from sender emails API');
        setSenderEmailsNextLink(null);
        return [];
      }
    } catch (error) {
      console.error('Error fetching sender emails:', error);
      setSenderEmailsNextLink(null);
      return [];
    } finally {
      setIsFetchingSenderEmails(false);
    }
  }, []);



  // Update the fetchEmails function to wait for folder mapping
  const fetchEmails = useCallback(async (folder, skip = 0, placeHolder = false, isLoadMore = false, skipCache = false, skipThreadFetch = false, filters = {}, options = {}) => {
    // Early return if there's an emailId in the URL or we're viewing/fetching a single email
    const urlParams = new URLSearchParams(window.location.search);
    const mailThreadId = urlParams.get('mailthreadid');

    
    if (mailThreadId) {
      console.log('Skipping folder fetch - mailthreadid in URL or viewing/fetching single email');
      return { data: [], skipped: true };
    }
    
    // Check if this is an auto-refresh
    const isAutoRefresh = options.isAutoRefresh === true;
    // Check if we should skip thread cache (added parameter)
    const skipThreadCache = options.skipThreadCache === true;
    
    // Wait for folder mapping to be initialized
    // Only set loading state when not auto-refreshing
    if (!isAutoRefresh) {
      await waitForFolderMapping();
    }
    
    // if folder is inbox_focused or inbox_other, set folder to inbox and add the classification to the folder
    let classification = null;
    let originalFolder = folder; // Store the original folder name

    if (folder === 'inbox_focused') {
      classification = 'focused';
      folder = 'inbox';
    } else if (folder === 'inbox_other') {
      classification = 'other';
      folder = 'inbox';
    }    

    const folderId = getFolderIdFromMapping(folder);

    // Create a cache key that includes filter parameters
    const filterKey = Object.keys(filters).length > 0 
      ? `_${Object.entries(filters).map(([k, v]) => `${k}=${v}`).join('_')}` 
      : '';
    const accountFolderKey = getCacheKey(originalFolder) + filterKey;

    // Check if this folder is already being fetched
    if (isFetchingByFolderRef.current[accountFolderKey]) {
      console.log(`Folder ${accountFolderKey} is already being fetched, skipping duplicate fetch`);
      return fetchPromisesByFolderRef.current[accountFolderKey] || { data: [], error: 'Fetch in progress' };
    }

    // Check for cached emails before setting loading state
    let cachedEmails = null;
    cachedEmails = !isLoadMore && !skipCache && getCachedEmails(originalFolder + filterKey);
    if (cachedEmails && cachedEmails.length > 0) {
      console.log(`Using cached emails for ${originalFolder} with filters:`, filters, cachedEmails.length);
      await updateEmailsStateAndCache(originalFolder, cachedEmails); 
      return { data: cachedEmails };
    }
    
    // Only set loading state if we don't have cached emails and it's the first batch
    // AND it's not an auto-refresh
    if ((!cachedEmails || cachedEmails.length === 0) && skip === 0 && !isLoadMore && !isAutoRefresh) {
      setIsFetchingEmails(true);
    }
    
    isFetchingByFolderRef.current[accountFolderKey] = true;
    
    const now = Date.now();
    const BATCH_SIZE = 10;
    const MAX_BATCHES = 1;

    try {
      fetchPromisesByFolderRef.current[accountFolderKey] = (async () => {
        try {
          let allEmails = [];
          let hasMore = true;
          let batchCount = 0;
          let processedEmailIds = new Set();
          let isFirstBatch = true;
          let lastBatchEmails = []; // Add variable to store last batch

          while (hasMore && batchCount < MAX_BATCHES) {
            const currentSkip = skip + (batchCount * BATCH_SIZE);
            
            // Use folder ID instead of folder name in the API call
            let url = process.env.REACT_APP_API_URL + `/fetch-emails/${folderId}?skip=${currentSkip}`;
            
            // Add classification parameter if it exists
            if (classification) {
              url += `&classification=${classification}`;
              console.log(`Fetching ${folder} emails with classification: ${classification}`);
            }

            // Add filter parameters if they exist
            if (filters.flagged) {
              url += `&flagged=true`;
            }
            if (filters.unread) {
              url += `&unread=true`;
            }
            if (filters.hasAttachments) {
              url += `&hasAttachments=true`;
            }
            if (filters.senders && filters.senders.length > 0) {
              url += `&sender=${encodeURIComponent(filters.senders[0])}`;
            }

            console.log(`Making API request to: ${url}`);
            const response = await axiosWithAuth.get(url);
            const batchEmails = response.data.map(email => ({
              ...email,
              date: new Date(email.date)
            }));

            console.log('batchEmails:', batchEmails);

            // Store the last batch of emails
            lastBatchEmails = batchEmails;

            // Check for duplicates and only add new emails
            const newEmails = batchEmails.filter(email => !processedEmailIds.has(email?.id));
            newEmails.forEach(email => processedEmailIds.add(email?.id));
            
            allEmails = [...allEmails, ...newEmails];
            
            // Update state and cache based on whether this is a load more operation
            const updateOptions = {
              isAppend: isLoadMore || !isFirstBatch,
              appendOperation: isLoadMore, // Explicitly mark load more as append
              skipCache: isLoadMore && skip > 0, // Only skip cache if truly loading more
              cacheKey: originalFolder + filterKey, // Use a cache key that includes filter parameters
              ...options, // Pass through any additional options
              operationType: isLoadMore ? 'loadMore' : options.isFilterChange ? 'filter' : 'initial'
            };
            
            console.log(`Updating emails with options:`, updateOptions);
            
            await updateEmailsStateAndCache(originalFolder, newEmails, updateOptions);
            
            isFirstBatch = false;
            hasMore = batchEmails.length === BATCH_SIZE;
            batchCount++;

            if (hasMore && batchCount < MAX_BATCHES) {
              await new Promise(resolve => setTimeout(resolve, 100));
            }
          }

          // Reset error tracking on successful fetch
          delete fetchErrorsRef.current[accountFolderKey];
          delete fetchRetriesRef.current[accountFolderKey];
          lastFetchTime.current[accountFolderKey] = now;

          // Mark all states as done after main emails are fetched
          // Only update loading states if this is not an auto-refresh
          if (!isAutoRefresh) {
            setIsFetchingEmails(false);
            setIsLoading(false);
          }
          isFetchingByFolderRef.current[accountFolderKey] = false;

          // Make sure to update the state with the emails we have so far
          // This ensures emails are displayed before thread fetching begins
          // Only do a full replace if this is not a load more operation
          await updateEmailsStateAndCache(originalFolder, allEmails, {
            isAppend: isLoadMore,
            appendOperation: isLoadMore,
            skipCache: false,
            cacheKey: originalFolder + filterKey,
            ...options,
            operationType: isLoadMore ? 'loadMoreFinal' : options.isFilterChange ? 'filterFinal' : 'initialFinal'
          });

          // After fetching all emails, fetch thread emails for emails with conversationId
          // Now allowing thread fetching for both initial and load more operations
          if (allEmails.length > 0 && !skipThreadFetch) {
            // Use setTimeout to make thread fetching non-blocking
            setTimeout(async () => {
              try {
                // Extract unique conversation IDs from fetched emails
                // During load more, only get conversation IDs from the new batch
                const conversationIds = [...new Set(
                  (isLoadMore ? lastBatchEmails : allEmails)  // Use lastBatchEmails instead of batchEmails
                    .filter(email => email?.conversationId)
                    .map(email => email.conversationId)
                )];

                console.log('Conversation IDs:', conversationIds);
                
                // First, check if we already have thread emails in session storage
                // Skip this check if skipThreadCache is true
                const storedThreads = {};
                let missingConversationIds = [];
                
                if (skipThreadCache) {
                  // If skipThreadCache is true, fetch all threads regardless of cache
                  console.log('Skipping thread cache, will fetch all threads fresh');
                  missingConversationIds = [...conversationIds];
                } else {
                  // Otherwise check each conversation ID for stored thread emails
                  conversationIds.forEach(conversationId => {
                    const threadEmails = getStoredThreadEmails(conversationId);
                    if (threadEmails) {
                      // For stored threads, add them directly to folderEmails
                      const processedThreadEmails = threadEmails.map(thread => ({
                        ...thread,
                        conversationId: conversationId,
                        date: new Date(thread.date) // Ensure date is a Date object
                      }));
                      
                      // Update state and cache with stored thread emails as flat entries
                      updateEmailsStateAndCache(originalFolder, processedThreadEmails, {
                        isAppend: true, // Use append to add to existing emails
                        skipCache: false,
                        cacheKey: originalFolder + filterKey,
                        ...options
                      });
                      
                      storedThreads[conversationId] = threadEmails;
                    } else {
                      missingConversationIds.push(conversationId);
                    }
                  });
                }
                
                // If we have missing conversation IDs, fetch them
                if (missingConversationIds.length > 0) {
                  console.log(`Fetching thread emails for ${missingConversationIds.length} conversations`);
                  
                  // Create a map to store fetched thread emails
                  const threadEmails = {};
                  
                  // Sort conversation IDs by their corresponding email dates (most recent first)
                  const sortedConversationIds = missingConversationIds.sort((a, b) => {
                    // Find the most recent email for each conversation ID
                    const emailA = allEmails.find(email => email.conversationId === a);
                    const emailB = allEmails.find(email => email.conversationId === b);
                    
                    // If both emails exist, compare their dates (most recent first)
                    if (emailA && emailB) {
                      return emailB.date - emailA.date;
                    }
                    
                    // If only one email exists, prioritize the one that exists
                    return emailA ? -1 : 1;
                  });
                  
                  // Fetch each conversation thread individually, starting with most recent
                  for (const conversationId of sortedConversationIds) {
                    let retries = 0;
                    const maxRetries = 2;
                    let success = false;
                    
                    while (retries <= maxRetries && !success) {
                      try {
                        console.log(`Fetching thread emails for conversation: ${conversationId}${retries > 0 ? ` (retry ${retries})` : ''}`);
                        
                        // Call the fetch-threads endpoint for a single conversation ID using POST
                        const threadResponse = await axiosWithAuth.post(
                          `${process.env.REACT_APP_API_URL}/fetch-threads`,
                          { conversationId: conversationId }
                        );
                        
                        if (threadResponse.data?.threadEmails) {
                          // Get the threads from the response
                          const threads = threadResponse.data.threadEmails[conversationId];
                          
                          // Store the thread emails in session storage for future use
                          if (threads && threads.length > 0) {
                            storeThreadEmails(conversationId, threads);
                            // Also add to storedThreads for immediate use
                            storedThreads[conversationId] = threads;
                            // Add to threadEmails map
                            threadEmails[conversationId] = threads;
                            success = true;
                            
                            // Instead of updating parent emails with thread information
                            // Add thread emails directly to folderEmails as flat entries
                            // First, add the conversationId to each thread email
                            const processedThreadEmails = threads.map(thread => ({
                              ...thread,
                              conversationId: conversationId,
                              date: new Date(thread.date) // Ensure date is a Date object
                            }));
                            
                            // Update state and cache with all thread emails as flat entries
                            await updateEmailsStateAndCache(originalFolder, processedThreadEmails, {
                              isAppend: true, // Use append to add to existing emails
                              skipCache: false,
                              cacheKey: originalFolder + filterKey,
                              ...options
                            });
                            
                            console.log(`Added ${threads.length} thread emails for conversation: ${conversationId} as flat entries`);
                          } else {
                            console.log(`No thread emails found for conversation: ${conversationId}`);
                            success = true; // Consider this a success even if no threads found
                          }
                        } else {
                          console.warn(`Invalid response format for conversation: ${conversationId}`, threadResponse.data);
                          retries++;
                        }
                      } catch (error) {
                        console.error(`Error fetching thread for conversation ${conversationId}:`, error);
                        retries++;
                        if (retries <= maxRetries) {
                          // Add exponential backoff delay
                          const delay = 100 * Math.pow(2, retries);
                          console.log(`Retrying in ${delay}ms...`);
                          await new Promise(resolve => setTimeout(resolve, delay));
                        }
                      }
                    }
                    
                    // Add a small delay between requests to avoid overwhelming the server
                    await new Promise(resolve => setTimeout(resolve, 50));
                  }
                }
                
                console.log('Finished thread fetching - threads added as flat entries');
              } catch (threadError) {
                console.error('Error fetching thread emails:', threadError);
                // Continue with the emails we have even if thread fetching fails
              }
            }, 0);
          }

          // Return the emails we have so far, without waiting for thread fetching
          return { data: allEmails };
        } catch (error) {
          console.error('Error fetching emails:', error);

          fetchErrorsRef.current[accountFolderKey] = Date.now();
          fetchRetriesRef.current[accountFolderKey] = (fetchRetriesRef.current[accountFolderKey] || 0) + 1;

          const cachedData = getCachedEmails(originalFolder + filterKey);
          if (cachedData) {
            console.log(`Using cached data for ${accountFolderKey}`);
            await updateEmailsStateAndCache(originalFolder, cachedData);
            return { data: cachedData, error: error.message, fromCache: true };
          }

          // For filter requests that return no results, indicate this specifically
          if (Object.keys(filters).length > 0) {
            return { data: [], error: error.message, emptyFilterResults: true };
          }
          
          return { data: [], error: error.message };
        } finally {
          fetchPromisesByFolderRef.current[accountFolderKey] = null;
        }
      })();

      return await fetchPromisesByFolderRef.current[accountFolderKey];
    } catch (error) {
      console.error('Error in outer try-catch:', error);
      // Only update loading states if this is not an auto-refresh
      if (!options.isAutoRefresh) {
        setIsFetchingEmails(false);
      }
      isFetchingByFolderRef.current[accountFolderKey] = false;
      fetchPromisesByFolderRef.current[accountFolderKey] = null;
      return { data: [], error: error.message };
    }
  }, [getCachedEmails, getCacheKey, updateEmailsStateAndCache, getStoredThreadEmails, storeThreadEmails, setIsLoading]);

  const searchEmails = useCallback(async (query, folder, nextLink = null) => {
    if (searchRequestInProgress.current || (!query && !nextLink)) return;
    if (!nextLink && query === lastSearchRef.current.query && currentFolder === lastSearchRef.current.folder) {
      console.log('Skipping duplicate search');
      return;
    }
    try {
      searchRequestInProgress.current = true;
      let response;
      console.log('Initiating search with:', { query, folder, nextLink });
      
      // Get the folder ID from session storage
      const folderId = getFolderIdFromMapping(folder);
      
      if (nextLink) {
        response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/search-emails', { query, folder: folderId, next_link: nextLink });
      } else {
        response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/search-emails', { query, folder: folderId });
      }
  
      if (!response.data?.emails) {
        console.error('No emails data in response:', response);
        throw new Error('Invalid response format');
      }
  
      const formattedEmails = response.data.emails
        .filter(email => email != null)
        .map(email => ({
          ...email,
          date: email.date ? new Date(email.date) : new Date()
        }));
      
      console.log('Search results received:', {
        count: formattedEmails.length,
        hasNextLink: !!response.data.next_link
      });
      
      setSearchResults(formattedEmails);
      
      // Update state and cache using the common function
      await updateEmailsStateAndCache(currentFolder, formattedEmails, {
        isAppend: !!nextLink,
        skipCache: true // Skip cache for search results
      });
      
      setNextSearchLink(response.data.next_link || null);
      searchFolderRef.current = folder;
      lastSearchRef.current = { query, folder };
      
      return { data: formattedEmails, hasMore: !!response.data.next_link };
    } catch (error) {
      console.error('Error searching emails:', error);
      setSearchResults([]);
      setNextSearchLink(null);
      return { data: [], hasMore: false, error: error.message };
    } finally {
      searchRequestInProgress.current = false;
    }
  }, [currentFolder, updateEmailsStateAndCache]);

  // get current folder based on url params
  useEffect(() => {
    let isMounted = true;
  
    const handleInitialLoad = async () => {
      // Wait until we know if we're viewing a single email
      if (!isMounted || !currentFolder) return;
      
      // If there's an emailId in the URL, we're viewing a single email
      // Skip folder loading completely
      if (emailId) {
        console.log('Email ID in URL, skipping folder load');
        return;
      }

      // Skip initial load if we're already viewing or fetching a single email
      if (isViewingSingleEmail || isFetchingSingleEmail) {
        console.log('Skipping initial folder load - viewing or fetching single email');
        return;
      }

      clearSearchQuery();
      setSearchResults([]);
      setNextSearchLink(null);
      setSelectedEmail(null);
      prevSearchQueryRef.current = '';
      lastSearchRef.current = { query: '', folder: '' };

      // Check if we need to fetch current folder data
      if (currentFolder === 'inbox_focused') {
        const hasPrefetched = sessionStorage.getItem('prefetchComplete') === 'true';
        const cachedEmails = getCachedEmails(currentFolder);
        
        if (cachedEmails?.length > 0) {
          console.log('Using cached emails for inbox_focused');
          await updateEmailsStateAndCache(currentFolder, cachedEmails);
        } else if (!hasPrefetched) {
          // Don't trigger prefetch here - it's now handled by the dedicated effect
          console.log('Fetching only inbox_focused (prefetch will be handled separately)');
          await fetchEmails(currentFolder, 0, false);
        } else {
          console.log('Fetching only inbox_focused (prefetch already done)');
          await fetchEmails(currentFolder, 0, false);
        }
        return;
      }
      
      // For other folders, try cache first
      const cachedEmails = getCachedEmails(currentFolder);
      if (cachedEmails?.length > 0) {
        console.log(`Using cached emails for ${currentFolder}`);
        await updateEmailsStateAndCache(currentFolder, cachedEmails);
        return;
      }

      // If no cache, fetch fresh
      if (!isFetchingByFolderRef.current[currentFolder]) {
        isFetchingByFolderRef.current[currentFolder] = true;
        try {
          await fetchEmails(currentFolder, 0, false);
        } finally {
          isFetchingByFolderRef.current[currentFolder] = false;
        }
      }
    };
  
    handleInitialLoad();

    return () => {
      isMounted = false;
      // Clear fetching flag for the current folder on cleanup
      if (currentFolder) {
        isFetchingByFolderRef.current[currentFolder] = false;
      }
    };
  }, [clearSearchQuery, getCachedEmails, fetchEmails, setSelectedEmail, currentFolder, updateEmailsStateAndCache, isViewingSingleEmail, emailId, isFetchingSingleEmail]);

  useEffect(() => {
    const handleSearch = async () => {  
      // Skip search handling if we're viewing a single email from URL or fetching a single email
      if (emailId || isFetchingSingleEmail || isViewingSingleEmail) {
        return;
      }

      if (searchQuery && (searchQuery !== lastSearchRef.current.query || currentFolder !== lastSearchRef.current.folder)) {
        setIsSearching(true); 
        await searchEmails(searchQuery, currentFolder);
        setIsSearching(false);
      } else if (!searchQuery && lastSearchRef.current.query) {
        // Instantly clear search results and reset state
        console.log('Search cleared, resetting state');
        setSearchResults([]);
        setNextSearchLink(null);
        lastSearchRef.current = { query: '', folder: '' };
        

        // Then restore folder emails from cache or fetch fresh
        const cachedEmails = getCachedEmails(currentFolder);
        if (cachedEmails?.length > 0) {
          console.log('Restoring cached folder emails:', {
            folder: currentFolder,
            emailCount: cachedEmails.length
          });
          await updateEmailsStateAndCache(currentFolder, cachedEmails);
        } else {
          // If no cache, fetch fresh emails with skip=0
          console.log('No cache found, fetching fresh emails from start');
          await fetchEmails(currentFolder, 0, false);
        }
      }
    };

    handleSearch();
  }, [searchQuery, currentFolder, searchEmails, folderEmails, fetchEmails, updateEmailsStateAndCache, getCachedEmails, isFetchingSingleEmail, isViewingSingleEmail]);

  const handleThreadExpand = useCallback((threadId) => {
    setExpandedThreads(prev => {
      const newSet = new Set(prev);
      if (newSet.has(threadId)) {
        newSet.delete(threadId);
      } else {
        newSet.add(threadId);
      }
      return newSet;
    });
  }, []);

  // Add effect to handle mobileView state based on emaillist presence
  useEffect(() => {
    if (isMobile) {
      if (showEmailList) {
        setMobileView('email');
      }
    }
  }, [isMobile, showEmailList]);

  const MobileViewBar = () => {
    const isLexiChat = selectedEmail?.fromName === 'Lexi';
    
    return (
    <Box  
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderTop: 1,
        borderColor: 'divider',
        backgroundColor: theme.palette.accent.secondary,
        zIndex: 1000,
        marginTop: 0,
        pb: 'env(safe-area-inset-bottom, 0px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      <Box
        onClick={() => {
          if (showEmailList) {
            // When list is shown, clicking Email shows the list
            setMobileView('email');
          } else if (!isLexiChat) {
            // When list is hidden and not a Lexi chat, clicking Email shows the preview
            setMobileView('email');
          } else {
            // When it's a Lexi chat, show the email list
            setShowEmailList(true);
            setMobileView('email');
          }
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1.5,
          px: 2,
          flex: 1,
          gap: 1,
          color: (showEmailList || (!isLexiChat && mobileView === 'email')) ? theme.palette.accent.primary : theme.palette.ui.textLight,
          cursor: 'pointer',
          transition: 'color 0.2s ease',
        }}
      >
        <EmailIcon sx={{ fontSize: '1.25rem' }} />
        <span style={{ fontSize: '0.75rem', fontWeight: 600 }}>
          {showEmailList || isLexiChat ? 
            (currentFolder.startsWith('inbox_') ? 'Inbox' : 
             currentFolder.charAt(0).toUpperCase() + currentFolder.slice(1)) 
            : 'Email'}
        </span>
      </Box>
      <Box
        onClick={() => {
          if (showEmailList) {
            // When list is shown, clicking Chat hides the list and shows chat
            setShowEmailList(false);
            setMobileView('chat');
          } else {
            // When list is hidden, clicking Chat shows the chat view
            setMobileView('chat');
          }
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 1.5,
          px: 2,
          flex: 1,
          gap: 1,
          color: (!showEmailList && mobileView === 'chat') ? theme.palette.accent.primary : theme.palette.ui.textLight,
          cursor: 'pointer',
          transition: 'color 0.2s ease',
        }}
      >
        <ChatIcon sx={{ fontSize: '1.25rem' }} />
        <span style={{ fontSize: '0.75rem', fontWeight: 600 }}>Chat</span>
      </Box>
    </Box>
    );
  };

  const renderRightComponent = () => {
    // Handle conversations view
    if (currentFolder === 'conversations') {
      return (
        <Box sx={{ 
          height: '100%', 
          position: 'relative', 
          display: 'flex', 
          flexDirection: 'column',
          width: '100%', 
          overflow: 'hidden',
        }}>
          <Box sx={{ 
            width: '100%',
            height: '100%',
            overflowY: 'hidden',
            padding: isMobile ? '0' : '0px 20px 15px 20px',
          }}>
            <ChatWindow
              selectedEmail={selectedEmail}
              setSelectedEmail={setSelectedEmail}
              isLoading={isLoading}
              isSummarizing={isSummarizing}
              setIsLoading={setIsLoading}
              setIsSummarizing={setIsSummarizing}
              isStreaming={isStreaming}
              setIsStreaming={setIsStreaming}
              currentFolder={currentFolder}
              isMobile={isMobile}
              toggleEmailListCollapse={toggleEmailListCollapse}
            />
          </Box>
        </Box>
      );
    }

    // Handle chat folders - updated to check both folder and selectedEmail's folder
    const shouldShowChat = (currentFolder.startsWith('inbox_focused') || currentFolder === 'flagged' || selectedEmail?.fromName === 'Lexi') 
      && (!selectedEmail?.subitem); // Disable chat for thread subitems
    
    if (!shouldShowChat) {
      if (currentFolder.toLowerCase() === 'drafts' && selectedEmail) {
        return (
          <Box sx={{ 
            height: '100%', 
            display: 'flex',
            width: '100%',
          }}>
            <Box sx={{ 
              flex: '1 1 auto',
              height: '100%',
              overflow: 'auto',
              padding: isMobile ? '0' : '0px 20px 15px 20px',
              WebkitOverflowScrolling: 'touch',
            }}>
              {selectedEmail && ( 
                <EmailComponent
                  emailData={selectedEmail}
                  isDraft={true}
                  key={selectedEmail?.id}
                  fetchEmails={fetchEmails}
                  isFetchingEmails={isFetchingEmails}
                />
              )}
            </Box>
            {/* Notes panel for drafts */}
            {/* {!isMobile && (
              <Box sx={{ width: '280px', flexShrink: 0, height: '100%' }}>
                <NotesPanel sender={selectedEmail} />
              </Box>
            )} */}
          </Box>
        );
      }
      return (
        <Box sx={{ 
          height: '100%',
          display: 'flex',
          width: '100%',
        }}>
          <Box sx={{ 
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto',
            padding: isMobile ? '0' : '0px 20px 15px 20px',
            WebkitOverflowScrolling: 'touch',
          }}>
            <EmailPreviewComponent 
              selectedEmail={selectedEmail} 
              isMobile={isMobile}
              isFetchingEmails={isFetchingEmails}
              onComposeModeChange={setComposeMode}
            />
          </Box>
          {/* Notes panel for email preview */}
          {/* {!isMobile && (
            <Box sx={{ width: '280px', flexShrink: 0, height: '100%' }}>
              <chat sender={selectedEmail} />
            </Box>
          )} */}
        </Box>
      );
    }

    // Only show mobile view bar for inbox and flagged folders, not for Lexi chats or conversations
    const showMobileViewBar = isMobile && 
      !composeMode && 
      currentFolder !== 'conversations';  // Hide when in compose mode or conversations folder

    const content = isMobile ? (
      <>
        <Box sx={{ 
          width: '100%',
          height: '100%',
          padding: showMobileViewBar ? '0 0 44px 0' : '0',
          overflowY: 'auto',
          display: (!showEmailList && mobileView === 'chat') ? 'block' : 'none',
        }}>
          <ChatWindow
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
            isLoading={isLoading}
            isSummarizing={isSummarizing}
            setIsLoading={setIsLoading}
            setIsSummarizing={setIsSummarizing}
            isStreaming={isStreaming}
            setIsStreaming={setIsStreaming}
            currentFolder={currentFolder}
            isMobile={isMobile}
            toggleEmailListCollapse={toggleEmailListCollapse}
          />
        </Box>
        <Box sx={{ 
          width: '100%',
          height: '100%',
          padding: showMobileViewBar ? '0 0 44px 0' : '0',
          overflowY: 'auto',
          display: (!showEmailList && mobileView === 'email' && selectedEmail?.fromName !== 'Lexi') ? 'block' : 'none',
        }}>
          <EmailPreviewComponent 
            selectedEmail={selectedEmail} 
            isMobile={isMobile}
            isFetchingEmails={isFetchingEmails}
            onComposeModeChange={setComposeMode}
          />
        </Box>
        {showMobileViewBar && <MobileViewBar />}
      </>
    ) : (
      <Box sx={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
      }}>
        <Box sx={{ 
          flex: '1 1 auto',
          height: '100%', 
          padding: '0px 20px 15px 20px',
          overflowY: 'hidden',
        }}>
          <ChatWindow
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
            isLoading={isLoading}
            isSummarizing={isSummarizing}
            setIsLoading={setIsLoading}
            setIsSummarizing={setIsSummarizing}
            isStreaming={isStreaming}
            setIsStreaming={setIsStreaming}
            currentFolder={currentFolder}
            isMobile={isMobile}
            toggleEmailListCollapse={toggleEmailListCollapse}
          />
        </Box>
        {/* Notes panel for chat */}
        {/* <Box sx={{ width: '300px', flexShrink: 0, height: '100%', paddingBottom: '15px' }}>
          <NotesPanel sender={selectedEmail} />
        </Box> */}
      </Box>
    );

    return (
      <Box sx={{ 
        height: '100%', 
        position: 'relative', 
        display: 'flex', 
        flexDirection: 'column',
        width: '100%', 
        overflow: 'hidden',
      }}>
        {content}
      </Box>
    );
  };

  // Update the handleEmailNotification function to properly handle incoming email notifications and update both state and cache
  // const handleEmailNotification = useCallback((newEmail) => {
  //   console.log('Received email notification:', newEmail);

  //   if (!newEmail) {
  //     console.warn('Received empty email notification');
  //     return;
  //   }
    
  //   try {
  //     // Format the email date
  //     const formattedEmail = {
  //       ...newEmail,
  //       date: new Date(newEmail.date)
  //     };
      
  //     // Update inbox_focused folder with the new email
  //     updateEmailsStateAndCache('inbox_focused', [formattedEmail], {
  //       isAppend: true,
  //     });
      
  //     // Increment unread count if needed
  //     if (formattedEmail.isRead === false) {
  //       setUnreadEmails(prev => ({
  //         ...prev,
  //         inbox_focused: (prev.inbox_focused || 0) + 1
  //       }));
  //     }
      
  //     // Play notification sound if enabled (can be implemented later)
  //     // showNotification(formattedEmail);
      
  //     console.log('Successfully processed email notification');
  //   } catch (error) {
  //     console.error('Error processing email notification:', error);
  //   }
  // }, [updateEmailsStateAndCache, setUnreadEmails]);

  // Add a new effect to reset view on route changes
  useEffect(() => {
    if (isMobile) {
      setShowEmailList(true);
    }
  }, [currentFolder, isMobile]);

  // Add effect to reset view when component mounts
  useEffect(() => {
    if (isMobile) {
      setShowEmailList(true);
    }
  }, [isMobile]);

  // Add cleanup on unmount
  useEffect(() => {
    return () => {
      fetchErrorsRef.current = {};
      fetchRetriesRef.current = {};
    };
  }, []);


  // Modify the fetchSingleEmail function to also fetch sender emails
  const fetchSingleEmail = useCallback(async (id) => {
    console.log(`Fetching single email with ID: ${id}`);
    setIsFetchingEmails(true);
    setIsFetchingSingleEmail(true);
    
    // Reset sender emails when fetching a new email
    setSenderEmails([]);
    setSenderEmailsNextLink(null);
    currentSenderEmailRef.current = null;
    
    // Set a flag in the fetching ref to prevent folder fetches
    const accountFolderKey = getCacheKey(currentFolder);
    isFetchingByFolderRef.current[accountFolderKey] = true;
    
    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-single-email/${id}`);
      
      // Handle both single email and thread responses
      const emails = Array.isArray(response.data) ? response.data : [response.data];
      
      // Convert dates and sort by date descending to get most recent first
      const processedEmails = emails.map(email => ({
        ...email,
        date: new Date(email.date)
      })).sort((a, b) => b.date - a.date);
      
      // Set the most recent email as selected
      const mostRecentEmail = processedEmails[0];
      
      if (!selectedEmail || selectedEmail?.fromName === 'Lexi') {
        setSelectedEmail(mostRecentEmail);
        console.log('Selected email in fetchSingleEmail:', mostRecentEmail);
      }
      
      // If we have a sender email, fetch all emails from this sender
      let senderEmailsData = [];
      
      // Use the original single email for the sender info rather than the mostRecentEmail
      const singleEmail = processedEmails.find(email => email.id === id);
      
      if (singleEmail) {
        // Get the current mailbox from URL
        const urlParams = new URLSearchParams(window.location.search);
        const mailbox = urlParams.get('mailbox');
        console.log('singleEmail', singleEmail);
        
        let senderEmail = singleEmail.fromEmail;
        const conversationId = singleEmail.conversationId;
        
        // If the sender email matches the current mailbox, use the receiver email instead
        if (mailbox && senderEmail && senderEmail.toLowerCase() === mailbox.toLowerCase() && singleEmail.receipients) {
          console.log(`Sender email ${senderEmail} matches current mailbox ${mailbox}, using receiver email instead`);
          senderEmail = singleEmail.receipients[0];
        }

        console.log('using senderEmail to fetch sender emails', senderEmail);
        
        if (senderEmail) {
          senderEmailsData = await fetchSenderEmails(senderEmail, conversationId);
          // Set sender emails state for UI components that need it separately
          setSenderEmails(senderEmailsData);
        }
      }
      
      // Update emails state and cache for the current folder with all thread emails AND sender emails
      await updateEmailsStateAndCache(currentFolder, [...processedEmails, ...senderEmailsData], {
        isAppend: false,
        skipCache: true
      });
      
      console.log(`Successfully fetched and processed single email with ID: ${id}`);
    } catch (error) {
      console.error('Error fetching single email:', error);
    } finally {
      setIsFetchingSingleEmail(false);
      setIsFetchingEmails(false);
      // Clear the fetching flag
      isFetchingByFolderRef.current[accountFolderKey] = false;
    }
  }, [currentFolder, setSelectedEmail, updateEmailsStateAndCache, getCacheKey, fetchSenderEmails]);

  const handleRefreshFolder = useCallback(async () => {
    // search for emailId in the url
    const urlParams = new URLSearchParams(window.location.search);
    const mailThreadId = urlParams.get('mailthreadid');
    const isAutoRefresh = true;  // Flag to indicate this is an auto-refresh
    console.log('mailThreadId from url', mailThreadId);
    
    if (mailThreadId) {
      // If viewing a single email, refresh that specific email
      console.log(`Refreshing single email with ID: ${mailThreadId}`);
      await fetchSingleEmail(mailThreadId);
    } else {
      // Otherwise refresh the current folder
      console.log(`Refreshing folder ${currentFolder} with cache skipped`);
      
      // For auto-refresh, we never want to set the loading state
      // skipThreadFetch=false to ensure threads are refreshed
      // skipThreadCache=true to skip thread cache and fetch fresh threads
      await fetchEmails(
        currentFolder, 
        0,                // skip
        false,            // shouldProcess
        false,            // isLoadMore
        true,             // skipCache - ensure we get fresh data, not cached
        false,            // skipThreadFetch - ensure threads are refreshed
        {},               // filters
        { 
          isAutoRefresh: true,  // indicate this is auto-refresh
          skipThreadCache: true // skip thread cache and fetch fresh threads
        }
      );
    }
  }, [currentFolder, fetchEmails, fetchSingleEmail]);

  // auto refresh emails using handleRefreshFolder every 3 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshFolder();
    }, 180000); // 3 minutes in milliseconds
    return () => clearInterval(interval);
  }, [handleRefreshFolder]);

  // Modify the email selection handling
  const handleEmailSelection = (email) => {
    setSelectedEmail(email);
    if (isMobile && email) {
      setShowEmailList(false);
    }
  };

  // Add effect to handle email ID in URL
  useEffect(() => {
    // Set viewing state immediately when emailId changes
    setIsViewingSingleEmail(!!emailId);
    
    if (emailId) {
      console.log(`Email ID detected in URL: ${emailId}, setting viewing single email mode`);
      fetchSingleEmail(emailId);
    } else {
      console.log('No email ID in URL, exiting single email mode');
    }
  }, [emailId, fetchSingleEmail]);

  // Add effect to listen for refreshFolders event (triggered when switching shared mailboxes)
  useEffect(() => {
    const handleRefreshFoldersEvent = () => {
      console.log('Received refreshFolders event, refreshing emails');
      // Clear any cached data
      sessionStorage.removeItem('userFolders');
      sessionStorage.removeItem('foldersFetched');
      
      // Force a fresh fetch of emails for the current folder
      handleRefreshFolder();
    };
    
    // Add event listener for the refreshFolders event
    window.addEventListener('refreshFolders', handleRefreshFoldersEvent);
    
    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('refreshFolders', handleRefreshFoldersEvent);
    };
  }, [handleRefreshFolder]);

  // Handle filter change
  const handleFilterChange = useCallback((newFilters) => {
    // Update local state if needed
    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  }, [onFilterChange]);

  // Early return if no folder is selected to prevent unnecessary renders
  if (!currentFolder) {
    return null;
  }

  return (
    <>
      <Box sx={{ 
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        position: 'relative',
        overflow: 'hidden',
        ...(isMobile && {
          height: 'calc(100% - env(safe-area-inset-bottom, 0px))',
        })
      }}>
        {/* <WebSocketManager onEmailNotification={handleEmailNotification} /> */}
        {(() => {
          // const hasEmails = currentFolder && (
          //   folderEmails?.[currentFolder]?.length > 0 || 
          //   getCachedEmails(currentFolder)
          // );
          
          // if ((isFetchingEmails || (isFetchingByFolderRef.current[currentFolder])) && !hasEmails || 
          //     (isPrefetching && currentFolder === 'inbox_focused' && !hasEmails)) {
          //   return (
          //     <>
          //       <LoadingAnimation />
          //       {isMobile && <MobileViewBar />}
          //     </>
          //   );
          // }
          
          return (
            <>
              <Box sx={{ 
                height: { xs: showEmailList ? '100%' : 0, md: '100%' },
                display: { 
                  xs: showEmailList ? 'block' : 'none',
                  md: 'flex'
                },
                width: { 
                  xs: '100%', 
                  md: isEmailListCollapsed ? '0px' : '40%' 
                },
                flexShrink: 0,
                overflow: 'visible',
                position: 'relative',
                transition: 'width 0.3s ease',
              }}>
                {/* Add collapse button for desktop */}
                {/* {!isMobile && (
                  <IconButton
                    onClick={toggleEmailListCollapse}
                    sx={{
                      position: 'absolute',
                      right: isEmailListCollapsed ? '-32px' : '-32px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      zIndex: 1200,
                      backgroundColor: theme.palette.accent.secondary,
                      boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                      width: '24px',
                      height: '24px',
                      minWidth: '24px',
                      padding: '4px',
                      '&:hover': {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                  >
                    {isEmailListCollapsed ? <ChevronRightIcon sx={{ fontSize: '1rem' }} /> : <ChevronLeftIcon sx={{ fontSize: '1rem' }} />}
                  </IconButton>
                )} */}
                <Box sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'auto',
                  visibility: isEmailListCollapsed ? 'hidden' : 'visible',
                  ...(isMobile && {
                    paddingBottom: '32px', // Added padding for mobile devices
                  }),
                }}>
                  <EmailList
                    selectedEmail={selectedEmail}
                    setSelectedEmail={handleEmailSelection}
                    isLoading={isLoading}
                    isSummarizing={isSummarizing}
                    isStreaming={isStreaming}
                    currentFolder={currentFolder}
                    fetchEmails={fetchEmails}
                    isSearchActive={searchResults.length > 0}
                    searchQuery={searchQuery}
                    expandedThreads={expandedThreads}
                    onThreadExpand={handleThreadExpand}
                    isSidebarOpen={isSidebarOpen}
                    onRefresh={handleRefreshFolder}
                    isMobile={isMobile}
                    selectedEmailIds={selectedEmailIds}
                    setSelectedEmailIds={setSelectedEmailIds}
                    lastSelectedEmailId={lastSelectedEmailId}
                    setLastSelectedEmailId={setLastSelectedEmailId}
                    focusedEmailId={focusedEmailId}
                    setFocusedEmailId={setFocusedEmailId}
                    unreadEmails={unreadEmails}
                    setUnreadEmails={setUnreadEmails}
                    folderEmails={folderEmails}
                    updateEmailsStateAndCache={updateEmailsStateAndCache}
                    areSelectedEmailsFlagged={areSelectedEmailsFlagged}
                    handleEmailMove={handleEmailMove}
                    searchEmails={searchEmails}
                    nextSearchLink={nextSearchLink}
                    isFetchingSingleEmail={isFetchingSingleEmail}     
                    isViewingSingleEmail={isViewingSingleEmail}
                    isFetchingEmails={isFetchingEmails}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    onFilterChange={handleFilterChange}
                    handleActionComplete={handleActionComplete}
                    senderEmails={senderEmails}
                    isFetchingSenderEmails={isFetchingSenderEmails}
                  />
                </Box>
              </Box>

              <Box sx={{ 
                height: { xs: showEmailList ? 0 : '100%', md: '100%' },
                display: { 
                  xs: showEmailList ? 'none' : 'flex',
                  md: 'flex'
                },
                flexDirection: 'column',
                width: { 
                  xs: '100%', 
                  md: isEmailListCollapsed ? 'calc(100% - 60px)' : '60%' 
                },
                flexGrow: 1,
                overflow: 'auto',
                position: 'relative',
                transition: 'width 0.3s ease',
                // borderLeft: `1px solid ${theme.palette.divider}`
              }}>
                {(
                  renderRightComponent()
                )}
              </Box>
            </>
          );
        })()}
      </Box>
      {isMobile && showEmailList && currentFolder !== 'conversations' && <MobileViewBar />}
    </>
  );
};

export default MailView;