import { format } from 'date-fns';
import { isToday, isThisWeek } from 'date-fns';
import { Box, Avatar, ListItem, ListItemButton, ListItemText, ListItemIcon, ListItemAvatar, Typography, IconButton, Collapse } from '@mui/material';
import {
  Event,
  AttachFile,
  ExpandLess,
  ExpandMore,
  DeleteOutline as DeleteIcon,
  Flag as FlagIcon
} from '@mui/icons-material';
import botImage from './botImage.png'; // Update this path to where your bot image is stored

const normalizeDate = (dateInput) => {
  if (!dateInput) return '';
  const date = new Date(dateInput);
  return date.toISOString();
};

export const groupEmailsByDate = (emails) => {
    if (!emails || !Array.isArray(emails) || emails.length === 0) {
      return { today: [], yesterday: [], thisWeek: [], earlier: {}, earlierMonths: [] };
    }
  
    // Check if we're dealing with conversations folder by checking the first email
    const isConversationsFolder = emails[0]?.folderName === 'conversations';
    
    let processedEmails;
    if (isConversationsFolder) {
      // For conversations, just sort by date and use as is
      processedEmails = emails.sort((a, b) => new Date(normalizeDate(b?.date)) - new Date(normalizeDate(a?.date)));
    } else {
      // Group all emails by conversationId
      const conversationMap = new Map();
      const processedKeys = new Set(); // Track processed unique keys
      
      // First pass: Process all emails and create thread structure based on conversationId
      emails.forEach(email => {
        if (!email?.conversationId) return; // Skip emails without conversationId
        
        const normalizedDate = normalizeDate(email.date);
        const unique_key = `${email.conversationId}_${normalizedDate}`;
        if (processedKeys.has(unique_key)) return; // Skip if already processed
        processedKeys.add(unique_key);
        
        if (!conversationMap.has(email.conversationId)) {
          // Initialize new thread
          conversationMap.set(email.conversationId, {
            parent: email,
            children: [],
            processed: new Set([unique_key]),
            latestDate: new Date(normalizedDate)
          });
        } else {
          const thread = conversationMap.get(email.conversationId);
          if (!thread?.processed.has(unique_key)) {
            const currentDate = new Date(normalizedDate);
            
            if (currentDate > thread.latestDate) {
              // Move current parent to children
              thread.children.push(thread.parent);
              // Set current email as new parent
              thread.parent = email;
              thread.latestDate = currentDate;
            } else {
              thread.children.push(email);
            }
            thread?.processed.add(unique_key);
          }
        }
      });
    
      // Filter and sort threads
      processedEmails = Array.from(conversationMap.values()).map(thread => {
        // Remove duplicates and sort children by date
        const uniqueChildren = Array.from(new Set(thread.children.map(c => 
          `${thread.parent.conversationId}_${normalizeDate(c?.date)}`
        )))
          .map(key => thread.children.find(c => 
            `${thread.parent.conversationId}_${normalizeDate(c?.date)}` === key
          ))
          .filter(child => child && child?.id !== thread.parent?.id)
          .sort((a, b) => new Date(normalizeDate(b?.date)) - new Date(normalizeDate(a?.date)));
    
        return {
          ...thread.parent,
          hasThread: uniqueChildren.length > 0,
          threadEmails: uniqueChildren
        };
      }).sort((a, b) => new Date(normalizeDate(b?.date)) - new Date(normalizeDate(a?.date)));
    }
  
    // Group by date periods
    const result = {
      today: [],
      yesterday: [],
      thisWeek: [],
      earlier: {}, // Changed to object to hold month-year groups
      earlierMonths: [] // Array to maintain the order of month-year keys
    };
  
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
  
    // Now group the processed emails by date
    processedEmails.forEach(email => {
      const emailDate = new Date(normalizeDate(email?.date));
      
      if (isToday(emailDate)) {
        result.today.push(email);
      } else if (
        emailDate.getDate() === yesterday.getDate() &&
        emailDate.getMonth() === yesterday.getMonth() &&
        emailDate.getFullYear() === yesterday.getFullYear()
      ) {
        result.yesterday.push(email);
      } else if (isThisWeek(emailDate)) {
        result.thisWeek.push(email);
      } else {
        // For earlier emails, group by month and year
        const monthYearKey = format(emailDate, 'MMMM yyyy'); // e.g. "January 2023"
        
        if (!result.earlier[monthYearKey]) {
          result.earlier[monthYearKey] = [];
          result.earlierMonths.push(monthYearKey);
        }
        
        result.earlier[monthYearKey].push(email);
      }
    });
    
    // Sort the earlierMonths array by date (most recent first)
    result.earlierMonths.sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB - dateA;
    });
  
    return result;
};
  
export const formattedDate = (date) => {
    if (!date) return '';
    
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    if (isToday(date)) {
      return format(date, 'HH:mm');
    } else if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Yesterday';
    } else if (isThisWeek(date)) {
      return format(date, 'EEEE'); // Day name
    } else {
      return format(date, 'dd.MM.yyyy');
    }
  };
  
export const getRandomGreenColor = (theme) => {
    // Get the base dark green color from theme
    const baseGreen = theme.palette.accent.darkGreen;
    
    // Generate slight variations of the base color
    const r = Math.floor(Math.random() * 20);      // Small red variation
    const g = Math.floor(Math.random() * 30 + 40); // Larger green variation
    const b = Math.floor(Math.random() * 20);      // Small blue variation
    
    // Generate random alpha value for some variety in opacity
    const a = Math.random() * 0.3 + 0.7; // Range: 0.7-1
    
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };
  
export const getInitials = (name) => {
    if (!name) return ''; // Return empty string if name is null or undefined    
    const names = name.split(' ');
    return names.length > 1
      ? `${names[0][0]}${names[1][0]}`
      : name[0];
  };
  
export const getSenderColor = (senderName, senderColors, setSenderColors, theme) => {
    if (!senderColors[senderName]) {
      setSenderColors(prevColors => ({
        ...prevColors,
        [senderName]: getRandomGreenColor(theme)
      }));
    }
    return senderColors[senderName];
  };
  
export const highlightSearchQuery = (text, query) => {
    if (!query || !text) return text; 
  
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) => 
          part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };
      
export const getCompanyInfo = () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      
      // Extract domain from email
      const domain = userInfo?.userPrincipalName?.split('@')[1];

      // get bot image from botAvatar:modstroem.dk session storage
      const botImage = sessionStorage.getItem(`botAvatar:${domain}`);
      
      // Return company info from userInfo or default values
      return {
        logo: userInfo?.company?.logo || botImage,
        name: userInfo?.company?.name || 'Lexi'
      };
    } catch (error) {
      console.error('Error parsing userInfo:', error);
      return {
        logo: botImage,
        name: 'Lexi'
      };
    }
  };
  
export const renderEmailItem = ({
  email,
  formattedDate,
  isThreadParent = false,
  threadEmails = [],
  theme,
  selectedEmailIds,
  hoveredEmailId,
  expandedThreads,
  currentFolder,
  listItemRefs,
  senderColors,
  setSenderColors,
  searchQuery,
  DraggableEmailItem,
  handleEmailMove,
  handleSelectEmail,
  setHoveredEmailId,
  handleDoubleClick,
  onThreadExpand,
  handleDeleteThread,
  senderPhotos
}) => {
    if (!email || !email?.id) return null;  // Add early return for null/invalid emails
    
    const isUnread = !email?.read;
    const isSelected = selectedEmailIds.includes(email?.id);
    const isHovered = hoveredEmailId === email?.id;
    const initials = getInitials(email?.fromName);
    const avatarColor = getSenderColor(email?.fromName, senderColors, setSenderColors, theme);
    const isFlagged = email?.flagged;
    const hasThread = email?.hasThread;
    const isExpanded = expandedThreads.has(email?.id);
  
    // Get the sender's photo from the senderPhotos object
    const senderPhoto = senderPhotos?.[email?.fromEmail];
  
    // Add icon visibility logic
    const showCalendarIcon = email?.isCalendarInvite;
    const showAttachmentIcon = email?.attachments && email?.attachments.length > 0;
    const showActionIcon = email?.action_required;
    const showUrgentIcon = email?.urgent;
    const showSentimentIcon = email?.positive !== undefined;
  
    // With flat structure, thread sub-item icons will be determined by renderEmailGroup
    // where we have access to all emails with the same conversationId
    const displayCalendarIcon = showCalendarIcon;
    const displayAttachmentIcon = showAttachmentIcon;
    const displayActionIcon = showActionIcon;
    const displayUrgentIcon = showUrgentIcon;
    const displaySentimentIcon = showSentimentIcon;
  
    return (
      <>
        <DraggableEmailItem 
          key={email?.id} 
          email={email} 
          selectedEmailIds={selectedEmailIds} 
          currentFolder={currentFolder}
          handleEmailMove={handleEmailMove}
        >
          <ListItem
            button
            key={email?.id}
            ref={(el) => listItemRefs.current[email?.id] = el}
            onClick={(event) => handleSelectEmail(email, false, event)}
            onMouseEnter={() => setHoveredEmailId(email?.id)}
            onMouseLeave={() => setHoveredEmailId(null)}
            onDoubleClick={(event) => handleDoubleClick(email, event)}
            disableRipple
            sx={{
              backgroundColor: isSelected ? `${theme.palette.accent.primary}15` : `${theme.palette.primary .secondary}`,
              '&:hover': {
                backgroundColor: isSelected ? `${theme.palette.accent.primary}15` : 'rgba(0, 0, 0, 0.04)',
              },
              '&:focus': {
                backgroundColor: isSelected ? `${theme.palette.accent.primary}15` : 'rgba(0, 0, 0, 0.08)',
                outline: 'none',
              },
              transition: 'none !important',
              mb: 0.5,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100px',
              pl: isThreadParent ? 2 : 5,  // Adjust padding based on whether it's a parent or thread item
              pr: 2,
              borderLeft: hasThread ? `3px solid ${theme.palette.accent.primary}` : 'none',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {currentFolder === 'conversations' ? (
                <Avatar
                  src={getCompanyInfo().logo}
                  sx={{
                    width: 35,
                    height: 35,
                    marginRight: 2,
                    borderRadius: '5px',
                  }}
                />
              ) : (
                <Avatar
                  src={senderPhoto}
                  imgProps={{
                    onError: (e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = ''; // Clear the src to show fallback
                    }
                  }}
                  sx={{
                    bgcolor: !senderPhoto ? avatarColor : undefined,
                    color: '#FFFFFF',
                    width: 35,
                    height: 35,
                    marginRight: 2,
                    fontSize: '0.8rem', 
                    fontWeight: 'bold',
                    borderRadius: '5px',
                  }}
                >
                  {!senderPhoto && initials}
                </Avatar>
              )}
              {isFlagged && (
                <FlagIcon 
                  sx={{ 
                    color: theme.palette.accent.primary, 
                    fontSize: '1.2rem', 
                    marginRight: 2,
                    flexShrink: 0,
                  }} 
                />
              )}
              <ListItemText 
                primary={
                  <>
                    <Typography 
                      variant="body1" 
                      component="span" 
                      style={{
                        fontWeight: isUnread ? 'bold' : '500',
                        display: 'inline-block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '90%',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {email.fromName?.toLowerCase() === 'lexi' ? 
                        getCompanyInfo().name + ' Email Assistant': 
                        highlightSearchQuery(email.fromName, searchQuery)}
                    </Typography>
                    <br />
                    <Box sx={{ maxWidth: '90%' }}>
                      <Typography 
                        variant="body2" 
                        component="span" 
                        style={{ 
                          fontWeight: '300',
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {highlightSearchQuery(email.subject, searchQuery)}
                      </Typography>
                    </Box>
                  </>
                }
                sx={{ flex: 1, minWidth: 0 }}
              />
  <Box sx={{ 
  display: 'flex', 
  alignItems: 'center', 
  marginLeft: 'auto',
  minWidth: '120px',
  justifyContent: 'flex-end'
  }}>
  {isHovered ? (
    currentFolder === 'conversations' ? (
      <IconButton 
        disableRipple
        size="small" 
        sx={{
          '&:hover': {
            color: 'black',
            backgroundColor: 'transparent',
          }
        }}                    
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteThread(email?.id);
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0.5, gap: '4px' }}>
          {displayCalendarIcon && (
            <Event
              sx={{
                fontSize: '1.1rem',
                color: 'text.secondary',
              }}
            />
          )}
          {displayAttachmentIcon && (
            <AttachFile
              sx={{
                fontSize: '1.1rem',
                color: 'text.secondary',
                transform: 'rotate(45deg)',
              }}
            />
          )}
          {/* {displayActionIcon && (
            <PlaylistAddCheck
              sx={{
                fontSize: '1.1rem',
                color: theme.palette.accent.primary,
              }}
            />
          )}
          {displayUrgentIcon && (
            <AccessTime
              sx={{
                fontSize: '1.1rem',
                color: '#ff4444',
              }}
            />
          )}
          {displaySentimentIcon && (
            email?.positive || threadSubItems.some(subItem => subItem?.positive) ? (
              <SentimentSatisfiedAlt
                sx={{
                  fontSize: '1.1rem',
                  color: '#4CAF50',
                }}
              />
            ) : (
              <SentimentVeryDissatisfied
                sx={{
                  fontSize: '1.1rem',
                  color: '#ff9800',
                }}
              />
            )
          )} */}
        </Box>
        <Typography 
          variant="caption" 
          component="span" 
          style={{ fontWeight: 'normal' }}
          color="textSecondary" 
          sx={{ whiteSpace: 'nowrap' }}
        >
          {formattedDate}
        </Typography>
      </Box>
    )
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0.5, gap: '4px' }}>
        {displayCalendarIcon && (
          <Event
            sx={{
              fontSize: '1.1rem',
              color: 'text.secondary',
            }}
          />
        )}
        {displayAttachmentIcon && (
          <AttachFile
            sx={{
              fontSize: '1.1rem',
              color: 'text.secondary',
              transform: 'rotate(45deg)',
            }}
          />
        )}
        {/* {displayActionIcon && (
          <PlaylistAddCheck
            sx={{
              fontSize: '1.1rem',
              color: theme.palette.accent.primary,
            }}
          />
        )}
        {displayUrgentIcon && (
          <AccessTime
            sx={{
              fontSize: '1.1rem',
              color: '#ff4444',
            }}
          />
        )}
        {displaySentimentIcon && (
          email?.positive || threadSubItems.some(subItem => subItem?.positive) ? (
            <SentimentSatisfiedAlt
              sx={{
                fontSize: '1.1rem',
                color: '#4CAF50',
              }}
            />
          ) : (
            <SentimentVeryDissatisfied
              sx={{
                fontSize: '1.1rem',
                color: '#ff9800',
              }}
            />
          )
        )} */}
      </Box>
      <Typography 
        variant="caption" 
        component="span" 
        style={{ fontWeight: 'normal' }}
        color="textSecondary" 
        sx={{ whiteSpace: 'nowrap' }}
      >
        {formattedDate}
      </Typography>
    </Box>
  )}
  <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
  {isUnread && (
  <Box
  sx={{
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: theme.palette.accent.primary,
  marginRight: 1,
  flexShrink: 0,
  }}
  />
  )}
  </Box>
  {hasThread && (
  <IconButton
  onClick={(e) => {
  e.stopPropagation();
  onThreadExpand(email?.id);
  }}
  disableRipple
  sx={{
    color: 'text.secondary',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black'
    }
  }}
  >
  {isExpanded ? <ExpandLess /> : <ExpandMore />}
  </IconButton>
  )}  
  </Box>
            </Box>
          </ListItem>
        </DraggableEmailItem>
  
        <Collapse in={hasThread && isExpanded} timeout={300}>
          {hasThread && threadEmails
            .filter(threadEmail => threadEmail?.id !== email?.id)
            .map((threadEmail) => {
              const threadIsUnread = !threadEmail?.read;
              const threadIsSelected = threadEmail?.id && selectedEmailIds.includes(threadEmail?.id);
              const threadIsHovered = threadEmail?.id && hoveredEmailId === threadEmail?.id;
              const threadInitials = getInitials(threadEmail?.fromName);
              const threadAvatarColor = getSenderColor(threadEmail?.fromName, senderColors, setSenderColors, theme);
              const threadIsFlagged = threadEmail?.flagged;
              const threadSenderPhoto = senderPhotos?.[threadEmail?.fromEmail];
  
              // Add subitem property to the thread email object
              threadEmail = { ...threadEmail, subitem: true };
  
              // Format the thread email's date using the formattedDate function
              const threadDate = threadEmail?.date ? format(
                new Date(threadEmail.date),
                isToday(new Date(threadEmail.date))
                  ? 'HH:mm'
                  : isThisWeek(new Date(threadEmail.date))
                    ? 'EEEE'
                    : 'dd.MM.yyyy'
              ) : '';
  
              // Add thread-specific icon visibility logic
              const threadShowCalendarIcon = threadEmail?.isCalendarInvite;
              const threadShowAttachmentIcon = threadEmail?.attachments && threadEmail?.attachments.length > 0;
              const threadShowActionIcon = threadEmail?.action_required;
              const threadShowUrgentIcon = threadEmail?.urgent;
              const threadShowSentimentIcon = threadEmail?.positive !== undefined;
  
              return (
                <DraggableEmailItem 
                  key={threadEmail?.id}
                  email={threadEmail}
                  selectedEmailIds={selectedEmailIds} 
                  currentFolder={currentFolder}
                  handleEmailMove={handleEmailMove}
                >
                  <ListItem
                    button
                    key={threadEmail?.id}
                    ref={(el) => listItemRefs.current[threadEmail?.id] = el}
                    onClick={(event) => handleSelectEmail(threadEmail, false, event)}
                    onMouseEnter={() => setHoveredEmailId(threadEmail?.id)}
                    onMouseLeave={() => setHoveredEmailId(null)}
                    onDoubleClick={(event) => handleDoubleClick(threadEmail, event)}
                    disableRipple
                    sx={{
                      backgroundColor: threadIsSelected ? `${theme.palette.accent.primary}15` : `${theme.palette.primary.secondary}`,
                      '&:hover': {
                        backgroundColor: threadIsSelected ? `${theme.palette.accent.primary}15` : 'rgba(0, 0, 0, 0.04)',
                      },
                      '&:focus': {
                        backgroundColor: threadIsSelected ? `${theme.palette.accent.primary}15` : 'rgba(0, 0, 0, 0.08)',
                        outline: 'none',
                      },
                      transition: 'none !important',
                      mb: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      height: '80px',
                      pl: 9,
                      pr: 2,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {threadIsFlagged && (
                        <FlagIcon 
                          sx={{ 
                            color: 'rgba(0, 0, 0, 0.8)',
                            fontSize: '1.2rem', 
                            marginRight: 2,
                            flexShrink: 0,
                            ml: -1
                          }} 
                        />
                      )}
                      <ListItemText 
                        primary={
                          <>
                            <Typography 
                              variant="body1" 
                              component="span" 
                              style={{
                                fontWeight: threadIsUnread ? 'bold' : '500',
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: '90%',
                                textOverflow: 'ellipsis',
                                fontSize: '0.9rem'
                              }}
                            >
                              {highlightSearchQuery(threadEmail.fromName, searchQuery)}
                            </Typography>
                            <br />
                            <Box sx={{ maxWidth: '90%' }}>
                              <Typography 
                                variant="body2" 
                                component="span" 
                                style={{ 
                                  fontWeight: '300',
                                  display: 'block',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  fontSize: '0.85rem'
                                }}
                              >
                                {highlightSearchQuery(threadEmail.subject, searchQuery)}
                              </Typography>
                            </Box>
                          </>
                        }
                        sx={{ flex: 1, minWidth: 0 }}
                      />
  <Box sx={{ 
  display: 'flex', 
  alignItems: 'center', 
  marginLeft: 'auto',
  minWidth: '120px',
  justifyContent: 'flex-end'
  }}>
  {threadIsHovered ? (
    currentFolder === 'conversations' ? (
      <IconButton 
        disableRipple
        size="small" 
        sx={{
          '&:hover': {
            color: 'black',
            backgroundColor: 'transparent',
          }
        }}                    
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteThread(threadEmail?.id);
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    ) : (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0.5, gap: '4px' }}>
          {threadShowCalendarIcon && (
            <Event
              sx={{
                fontSize: '1.1rem',
                color: 'text.secondary',
              }}
            />
          )}
          {threadShowAttachmentIcon && (
            <AttachFile
              sx={{
                fontSize: '1.1rem',
                color: 'text.secondary',
                transform: 'rotate(45deg)',
              }}
            />
          )}
          {/* {threadShowActionIcon && (
            <PlaylistAddCheck
              sx={{
                fontSize: '1.1rem',
                color: theme.palette.accent.primary,
              }}
            />
          )}
          {threadShowUrgentIcon && (
            <AccessTime
              sx={{
                fontSize: '1.1rem',
                color: '#ff4444',
              }}
            />
          )}
          {threadShowSentimentIcon && (
            threadEmail?.positive ? (
              <SentimentSatisfiedAlt
                sx={{
                  fontSize: '1.1rem',
                  color: '#4CAF50',
                }}
              />
            ) : (
              <SentimentVeryDissatisfied
                sx={{
                  fontSize: '1.1rem',
                  color: '#ff9800',
                }}
              />
            )
          )} */}
        </Box>
        <Typography 
          variant="caption" 
          component="span" 
          style={{ fontWeight: 'normal' }}
          color="textSecondary" 
          sx={{ whiteSpace: 'nowrap' }}
        >
          {threadDate}
        </Typography>
      </Box>
    )
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 0.5, gap: '4px' }}>
        {threadShowCalendarIcon && (
          <Event
            sx={{
              fontSize: '1.1rem',
              color: 'text.secondary',
            }}
          />
        )}
        {threadShowAttachmentIcon && (
          <AttachFile
            sx={{
              fontSize: '1.1rem',
              color: 'text.secondary',
              transform: 'rotate(45deg)',
            }}
          />
        )}
        {/* {threadShowActionIcon && (
          <PlaylistAddCheck
            sx={{
              fontSize: '1.1rem',
              color: theme.palette.accent.primary,
            }}
          />
        )}
        {threadShowUrgentIcon && (
          <AccessTime
            sx={{
              fontSize: '1.1rem',
              color: '#ff4444',
            }}
          />
        )}
        {threadShowSentimentIcon && (
          threadEmail?.positive ? (
            <SentimentSatisfiedAlt
              sx={{
                fontSize: '1.1rem',
                color: '#4CAF50',
              }}
            />
          ) : (
            <SentimentVeryDissatisfied
              sx={{
                fontSize: '1.1rem',
                color: '#ff9800',
              }}
            />
          )
        )} */}
      </Box>
      <Typography 
        variant="caption" 
        component="span" 
        style={{ fontWeight: 'normal' }}
        color="textSecondary" 
        sx={{ whiteSpace: 'nowrap' }}
      >
        {threadDate}
      </Typography>
    </Box>
  )}
  <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
  {threadIsUnread && (
  <Box
  sx={{
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)', // Changed from #AB27AE to black
  marginRight: 1,
  flexShrink: 0,
  }}
  />
  )}
  </Box>
  </Box>
                    </Box>
                  </ListItem>
                </DraggableEmailItem>
              );
            })}
        </Collapse>
      </>
    );
  };
  
export const renderEmailGroup = ({
  title,
  emails,
  theme,
  currentFolder,
  selectedEmailIds,
  hoveredEmailId,
  expandedThreads,
  listItemRefs,
  senderColors,
  setSenderColors,
  searchQuery,
  DraggableEmailItem,
  handleEmailMove,
  handleSelectEmail,
  setHoveredEmailId,
  handleDoubleClick,
  onThreadExpand,
  handleDeleteThread,
  senderPhotos
}) => {
    if (!emails || emails.length === 0) return null;
  
    // Group emails by threadId
    const threadMap = new Map();
    
    // First pass: Process all emails and create initial thread structure
    emails.forEach(email => {
      if (!email.threadId) {
        // Handle non-threaded emails
        threadMap.set(email?.id, {
          parent: email,
          children: [],
          processed: new Set()
        });
        return;
      }
  
      if (!threadMap.has(email.threadId)) {
        // Initialize new thread
        threadMap.set(email.threadId, {
          parent: email,
          children: [],
          processed: new Set([email?.id])
        });
      } else {
        const thread = threadMap.get(email?.threadId);
        if (!thread?.processed.has(email?.id)) {
          const currentDate = new Date(email?.date);
          const parentDate = new Date(thread?.parent?.date);
  
          if (currentDate > parentDate) {
            // Move current parent to children
            thread.children.push(thread.parent);
            // Set current email as new parent
            thread.parent = email;
          } else {
            thread.children.push(email);
          }
          thread?.processed.add(email?.id);
        }
      }
    });
  
    // Second pass: Process threadEmails from parent emails
    threadMap.forEach((thread) => {
      if (thread.parent.threadEmails) {
        // Filter thread emails based on folder rules
        const filteredThreadEmails = thread.parent.threadEmails.filter(threadEmail => {
          const folderName = threadEmail?.folderName && typeof threadEmail.folderName === 'string' ? threadEmail.folderName.toLowerCase() : '';
          if (folderName === 'deleted items' && currentFolder !== 'deleteditems') return false;
          if (folderName === 'drafts' && currentFolder !== 'drafts') return false;
          return true;
        });
  
        filteredThreadEmails.forEach(threadEmail => {
          if (!thread?.processed.has(threadEmail?.id)) {
            const threadDate = new Date(threadEmail?.date);
            const parentDate = new Date(thread?.parent?.date);
  
            if (threadDate > parentDate) {
              // Move current parent to children if the thread email is newer
              thread.children.push(thread.parent);
              thread.parent = threadEmail;
            } else {
              thread.children.push(threadEmail);
            }
            thread?.processed.add(threadEmail?.id);
          }
        });
      }
    });
  
    // Sort threads by most recent email (parent) date
    const sortedThreads = Array.from(threadMap.values())
      .sort((a, b) => new Date(b.parent.date) - new Date(a.parent.date));
  
    return (
      <>
        <Box sx={{ 
          backgroundColor: 'transparent', 
          py: 1, 
          px: 2, 
          mb: 0,
          mt: 2,
          ml: 0,
          pl: '3px', 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left', 
        }}>
          <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#000000', fontSize: '12px'}}>
            {title}
          </Typography>
        </Box>
  
        {sortedThreads.map(({parent, children}) => {
          // Remove any potential duplicates and sort children by date
          const uniqueChildren = Array.from(new Set(children.map(c => c.id)))
            .map(id => children.find(c => c.id === id))
            .filter(child => child && child?.id !== parent.id)
            .sort((a, b) => new Date(b?.date) - new Date(a?.date));
          
          // Set hasThread flag on parent but don't attach threadEmails property anymore
          // since we're using flat structure
          parent.hasThread = uniqueChildren.length > 0;
          
          return renderEmailItem({
            email: parent,
            formattedDate: formattedDate(new Date(parent.date)),
            isThreadParent: true,
            threadEmails: uniqueChildren, // Still pass threadEmails to renderEmailItem for rendering
            theme,
            selectedEmailIds,
            hoveredEmailId,
            expandedThreads,
            currentFolder,
            listItemRefs,
            senderColors,
            setSenderColors,
            searchQuery,
            DraggableEmailItem,
            handleEmailMove,
            handleSelectEmail,
            setHoveredEmailId,
            handleDoubleClick,
            onThreadExpand,
            handleDeleteThread,
            senderPhotos
          });
        })}
      </>
    );
  };