import React from 'react';
import { Box, Typography, Popover, CircularProgress } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { SvgIcon } from '@mui/material';

import { ReactComponent as WordIcon } from '../icons/word-icon.svg';
import { ReactComponent as ExcelIcon } from '../icons/excel-icon.svg';
import { ReactComponent as PowerPointIcon } from '../icons/powerpoint-icon.svg';

const AttachmentPopover = ({ id, open, anchorEl, onClose, attachments, downloadProgress, onAttachmentClick }) => {
  const getFileIcon = (attachment) => {
    const fileName = attachment.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();

    const iconStyle = { fontSize: 16 };

    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon sx={{ ...iconStyle, color: '#FF0000' }} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageIcon sx={{ ...iconStyle, color: '#4CAF50' }} />;
      case 'doc':
      case 'docx':
        return (
          <SvgIcon component={WordIcon} sx={{ ...iconStyle, color: '#2B579A' }} viewBox="0 0 48 48" />
        );
      case 'xls':
      case 'xlsx':
        return (
          <SvgIcon component={ExcelIcon} sx={{ ...iconStyle, color: '#217346' }} viewBox="0 0 48 48" />
        );
      case 'ppt':
      case 'pptx':
        return (
          <SvgIcon component={PowerPointIcon} sx={{ ...iconStyle, color: '#D24726' }} viewBox="0 0 48 48" />
        );
      case 'txt':
        return <DescriptionIcon sx={{ ...iconStyle, color: '#607D8B' }} />;
      default:
        return <InsertDriveFileIcon sx={iconStyle} />;
    }
  };

  const textStyle = {
    fontSize: '0.85rem',
    color: '#666',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box sx={{ padding: 0, maxWidth: 330, maxHeight: 300, minWidth: 200}}>
        {attachments && attachments.map((attachment) => (
          <Box 
            key={attachment.id} 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              padding: '14px',                
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
            onClick={() => onAttachmentClick(attachment)}
          >
            {downloadProgress[attachment.id] !== undefined ? (
              <CircularProgress
                variant="determinate"
                value={downloadProgress[attachment.id]}
                size={16}
              />
            ) : (
              getFileIcon(attachment)
            )}
            <Typography sx={{ ...textStyle, marginLeft: '8px' }}>
              {attachment.name || 'Unnamed attachment'}
            </Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default AttachmentPopover;