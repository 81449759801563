import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogContent, IconButton, Box, CircularProgress, Typography, useTheme, useMediaQuery } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import {axiosWithAuth} from './authService';

const AttachmentPreviewDialog = ({ open, onClose, messageId, attachments, initialAttachmentId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    if (open && messageId && attachments && attachments.length > 0) {
      const initialIndex = attachments.findIndex(att => att.id === initialAttachmentId);
      setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
      fetchAttachmentPreview(messageId, attachments[initialIndex !== -1 ? initialIndex : 0].id);
    }
  }, [open, messageId, attachments, initialAttachmentId]);

  const fetchAttachmentPreview = async (messageId, attachmentId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/attachments/${messageId}/${attachmentId}/preview`);
      setCurrentAttachment(response.data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching attachment preview:', err);
      setError('Failed to load attachment preview. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % attachments.length;
    setCurrentIndex(nextIndex);
    fetchAttachmentPreview(messageId, attachments[nextIndex].id);
  };

  const handlePrevious = () => {
    const previousIndex = (currentIndex - 1 + attachments.length) % attachments.length;
    setCurrentIndex(previousIndex);
    fetchAttachmentPreview(messageId, attachments[previousIndex].id);
  };

  const handleDownload = async () => {
    if (currentAttachment) {
      try {
        const blob = await fetch(`data:${currentAttachment.contentType};base64,${currentAttachment.content}`).then(res => res.blob());
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', currentAttachment.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
        setError('Failed to download the file. Please try again later.');
      }
    }
  };

  const renderPreview = () => {
    if (!currentAttachment) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <ErrorIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
          <Typography variant="body1" color="text.secondary">
            Preview not available for this file type.
          </Typography>
        </Box>
      );
    }

    switch (currentAttachment.type) {
      case 'image':
        return (
          <Box sx={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#f0f0f0'}}>
            <img 
              src={`data:${currentAttachment.contentType};base64,${currentAttachment.content}`} 
              alt={currentAttachment.name} 
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
              onError={(e) => {
                console.error('Error loading image:', e);
                e.target.onerror = null;
                e.target.src = 'path/to/fallback/image.png';
              }}
            />
          </Box>
        );
      case 'pdf':
        return (
          <Box sx={{ height: '100%', width: '100%' }}>
            <iframe
              src={`data:application/pdf;base64,${currentAttachment.content}`}
              title={currentAttachment.name}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </Box>
        );

      case 'powerpoint':    
        return (
          <Box sx={{ height: '100%', width: '100%' }}>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(`data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,${currentAttachment.content}`)}`}
              title={currentAttachment.name}
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </Box>
        );
      default:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <DownloadIcon sx={{ fontSize: 48, color: '#AB27AE', mb: 2 }} />
            <Typography variant="body1" color="text.primary">
              Preview not available for this file. Download the file to view it.
            </Typography>
          </Box>
        );
    } 
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isSwipe = Math.abs(distance) > 50;
    
    if (isSwipe && attachments.length > 1) {
      if (distance > 0) {
        handleNext();
      } else {
        handlePrevious();
      }
    }
    
    setTouchStart(null);
    setTouchEnd(null);
  }, [touchStart, touchEnd, attachments]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          height: isMobile ? '100%' : '95vh',
          maxWidth: isMobile ? '100%' : '65vw',
          width: isMobile ? '100%' : undefined,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          m: isMobile ? 0 : 2,
          position: 'fixed',
          top: isMobile ? 0 : undefined,
          left: isMobile ? 0 : undefined,
          right: isMobile ? 0 : undefined,
          bottom: isMobile ? 0 : undefined,
        },
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: isMobile ? 1 : 1.5,
        bgcolor: 'background.paper',
        borderBottom: 1,
        borderColor: 'divider',
        position: 'relative',
        zIndex: 2,
        minHeight: isMobile ? '56px' : '64px'
      }}>
        <Typography 
          variant={isMobile ? "body1" : "subtitle1"} 
          sx={{ 
            pl: 1, 
            fontWeight: 'bold', 
            maxWidth: '70%', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis', 
            whiteSpace: 'nowrap' 
          }}
        >
          {currentAttachment?.name || 'Attachment'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ mr: 2 }}>
            {`${currentIndex + 1}/${attachments.length}`}
          </Typography>
          <IconButton 
            onClick={handleDownload} 
            size={isMobile ? "medium" : "large"}
            sx={{ p: isMobile ? 1 : 1.5 }}
          >
            <DownloadIcon/> 
          </IconButton>
          <IconButton 
            onClick={onClose} 
            size={isMobile ? "medium" : "large"}
            sx={{ p: isMobile ? 1 : 1.5 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent 
        sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'row', 
          p: 0, 
          position: 'relative', 
          overflow: 'hidden',
          touchAction: 'pan-y pinch-zoom',
          WebkitOverflowScrolling: 'touch',
          height: isMobile ? 'calc(100% - 56px)' : undefined
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {attachments && attachments.length > 1 && !isMobile && (
          <Box sx={{ 
            width: '48px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            bgcolor: 'background.paper', 
            position: 'absolute', 
            left: 0, 
            top: 0, 
            bottom: 0, 
            zIndex: 1 
          }}>
            <IconButton onClick={handlePrevious}>
              <ArrowBackIosNewIcon />
            </IconButton>
          </Box>
        )}
        <Box sx={{ 
          flex: 1, 
          position: 'relative', 
          ml: (!isMobile && attachments && attachments.length > 1) ? '48px' : 0, 
          mr: (!isMobile && attachments && attachments.length > 1) ? '48px' : 0, 
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
              <CircularProgress sx={{ color: theme.palette.accent.darkGreen }} />
            </Box>
          ) : error ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', p: 2 }}>
              <ErrorIcon sx={{ fontSize: isMobile ? 36 : 48, color: 'error.main', mb: 2 }} />
              <Typography color="error" align="center" variant={isMobile ? "body2" : "body1"}>
                {error}
              </Typography>
            </Box>
          ) : (
            renderPreview()
          )}
        </Box>
        {attachments && attachments.length > 1 && !isMobile && (
          <Box sx={{ 
            width: '48px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            bgcolor: 'background.paper', 
            position: 'absolute', 
            right: 0, 
            top: 0, 
            bottom: 0, 
            zIndex: 1 
          }}>
            <IconButton onClick={handleNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentPreviewDialog;