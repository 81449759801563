import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Fade, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Import the JSON animation data
import animationData from './lottieAni.json';

const ToolCallText = styled(Typography)`
  @keyframes reelSpin {
    0% {
      transform: rotateX(0deg);
      opacity: 1;
    }
    50% {
      transform: rotateX(90deg);
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }

  @keyframes gradientMove {
    0% {
      background-position: 200% center;
    }
    100% {
      background-position: -200% center;
    }
  }
  
  ${props => props.$isLatest ? `
    background: linear-gradient(
      90deg,
      ${props.theme.palette.accent.secondary} 0%,
      ${props.theme.palette.accent.primary} 50%,
      ${props.theme.palette.accent.secondary} 100%
    );
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    animation: ${props.$isSpinning ? 'reelSpin 0.5s ease-in-out' : 'gradientMove 2s linear infinite'};
  ` : `
    color: ${props.theme.palette.accent.primary};
  `}
  transform-origin: center;
`;

const AnimatedLine = styled('span')`
  @keyframes growWidth {
    0% {
      width: 0;
    }
    100% {
      width: 25px;
    }
  }

  display: inline-block;
  height: 1.5px;
  background: ${props => props.theme.palette.accent.primary};
  margin: 0 8px;
  vertical-align: middle;
  margin-bottom: 0.45em;
  animation: growWidth 0.5s ease-out forwards;
`;

const ShimmerEffect = ({ fadeOut = false, onFadeOutComplete, toolCall = null }) => {
  const [currentToolCall, setCurrentToolCall] = React.useState(null);
  const [previousToolCall, setPreviousToolCall] = React.useState(null);
  const [isSpinning, setIsSpinning] = React.useState(false);

  React.useEffect(() => {
    if (toolCall !== currentToolCall) {
      if (!currentToolCall) {
        // First tool call
        setCurrentToolCall(toolCall);
      } else {
        // Subsequent tool calls
        setIsSpinning(true);
        setPreviousToolCall(currentToolCall);
        const timeout = setTimeout(() => {
          setCurrentToolCall(toolCall);
        }, 250);
        return () => clearTimeout(timeout);
      }
    }
  }, [toolCall, currentToolCall]);

  const handleSpinComplete = () => {
    setIsSpinning(false);
  };

  return (
    <Fade 
      in={!fadeOut}
      timeout={800}
      onExited={onFadeOutComplete}
    >
      <Box sx={{ 
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        minWidth: 'fit-content',
        overflow: 'visible'
      }}>
        <Box sx={{ 
          display: 'inline-block',
          width: '2em',
          height: '2em',
          verticalAlign: 'middle',
          marginLeft: -0.5,
          marginTop: -1
        }}>
          <Player
            autoplay
            loop
            src={animationData}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        {toolCall && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            minWidth: 'fit-content',
            whiteSpace: 'nowrap'
          }}>
            {previousToolCall && (
              <>
                <ToolCallText
                  $isSpinning={isSpinning}
                  $isLatest={false}
                  onAnimationEnd={handleSpinComplete}
                  sx={{
                    fontSize: '0.9rem',
                    fontStyle: 'italic',
                    marginBottom: '0.45em',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {previousToolCall}
                </ToolCallText>
                <AnimatedLine />
              </>
            )}
            <ToolCallText
              $isSpinning={isSpinning}
              $isLatest={true}
              onAnimationEnd={handleSpinComplete}
              sx={{
                fontSize: '0.9rem',
                fontStyle: 'italic',
                marginBottom: '0.45em',
                whiteSpace: 'nowrap'
              }}
            >
              {currentToolCall}
            </ToolCallText>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default ShimmerEffect;