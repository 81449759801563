import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, Collapse, Box, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';  // Add DialogTitle
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ContentLoader from 'react-content-loader';
import { format, isThisWeek, isToday, isSameWeek, startOfWeek, set } from 'date-fns';
import { axiosWithAuth } from './authService';
import EmailHeader from './EmailHeader';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import EmailPreviewComponent from './EmailPreviewComponent';
import { Visibility, KeyboardArrowDown, ContentPasteGoRounded, AttachFile, ExpandMore, ExpandLess, Event, Close } from '@mui/icons-material';
import { AccessTime, PlaylistAddCheck, SentimentSatisfiedAlt, SentimentVeryDissatisfied } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Avatar from '@mui/material/Avatar';
import { useDrag, useDragLayer } from 'react-dnd';
import botImage from './botImage.png';  // Make sure this path is correct
import { useTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';  // Add this import
import { groupEmailsByDate, renderEmailGroup } from './EmailListStyling';
import useEmailKeyboardNavigation from './useEmailKeyboardNavigation';
import { alpha } from '@mui/material/styles';

// Add spin animation keyframe
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const DraggableEmailItem = ({ email, children, selectedEmailIds, currentFolder, handleEmailMove }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'EMAIL',
    item: () => ({ 
      ids: selectedEmailIds.includes(email?.id) ? selectedEmailIds : [email?.id],
      type: 'EMAIL',
      sourceFolder: currentFolder
    }),
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const targetFolder = dropResult.folder;
        if (targetFolder !== 'conversations' && targetFolder !== currentFolder) {
          handleEmailMove(item.ids, targetFolder);
        }
      }
    },
    canDrag: () => currentFolder !== 'conversations',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children}
    </div>
  );
};

// Updated CustomDragLayer component
const CustomDragLayer = () => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const theme = useTheme();

  if (!isDragging || !currentOffset) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: currentOffset.x + 15,
        top: currentOffset.y + 15,
        backgroundColor: `${theme.palette.accent.primary}CC`,
        color: 'white',
        padding: '4px 8px',
        borderRadius: '5px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        fontSize: '14px',
        fontWeight: 'bold',
      }}
    >
      {item?.ids?.length} email{item?.ids?.length !== 1 ? 's' : ''}
    </div>
  );
};

const EmailList = ({
  selectedEmail,
  setSelectedEmail,
  isLoading,
  currentFolder,
  searchQuery,
  expandedThreads,
  onThreadExpand,
  onRefresh,
  isMobile,
  selectedEmailIds,
  setSelectedEmailIds,
  focusedEmailId,
  setFocusedEmailId,
  setLastSelectedEmailId,
  folderEmails,
  updateEmailsStateAndCache,
  fetchEmails,
  handleEmailMove,
  searchEmails,
  nextSearchLink,
  isViewingSingleEmail,
  isFetchingSingleEmail,
  isFetchingEmails,
  activeFilters: externalActiveFilters,
  setActiveFilters: externalSetActiveFilters,
  onFilterChange: externalOnFilterChange,
  handleActionComplete,
}) => {
  const theme = useTheme();
  const [selectionAnchor, setSelectionAnchor] = useState();
  const [hoveredEmailId, setHoveredEmailId] = useState(null);
  const listRef = useRef(null);
  const [isFocused, setIsFocused] = useState();
  const containerRef = useRef(null);  
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedEmailForPreview, setSelectedEmailForPreview] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [autoLoadCount, setAutoLoadCount] = useState(0);

  const [senderColors, setSenderColors] = useState({});
  const listItemRefs = useRef({});

  // Add new state variables for pull-to-refresh
  const [isPulling, setIsPulling] = useState(false);
  const [pullProgress, setPullProgress] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const touchStartY = useRef(0);
  const pullThreshold = 80; // The distance in pixels needed to trigger refresh

  // Add new state for forcing re-render
  const [forceUpdate, setForceUpdate] = useState(0);

  const [senderPhotos, setSenderPhotos] = useState({});
  const photoFetchTimeoutRef = useRef(null);
  const fetchedEmailsRef = useRef(new Set()); // Add a ref to track which emails we've already fetched

  // Modify filter state to use external state if provided
  const [localActiveFilters, setLocalActiveFilters] = useState({});
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  
  // Use either external or local filter state
  const activeFilters = externalActiveFilters || localActiveFilters;
  const setActiveFilters = externalSetActiveFilters || setLocalActiveFilters;

  // Add a ref to track previous filters for comparison
  const prevFiltersRef = useRef(JSON.stringify({}));

  // Get current emails from folderEmails with debug
  const currentEmails = useMemo(() => {
    const emails = folderEmails[currentFolder] || [];
    console.log('Current emails count:', emails.length);
    return emails;
  }, [folderEmails, currentFolder]);

  // Persist filters to localStorage when they change and restore on component mount
  useEffect(() => {
    // On mount, try to restore filters from localStorage for the current folder
    const savedFilters = localStorage.getItem(`emailFilters_${currentFolder}`);
    if (savedFilters) {
      try {
        const parsedFilters = JSON.parse(savedFilters);
        // Only restore if there are actual filters to apply
        if (Object.keys(parsedFilters).length > 0) {
          console.log('Restoring saved filters from localStorage:', parsedFilters);
          setActiveFilters(parsedFilters);
          prevFiltersRef.current = savedFilters; // Set the ref to match
        }
      } catch (error) {
        console.error('Error parsing saved filters:', error);
        localStorage.removeItem(`emailFilters_${currentFolder}`);
      }
    }
  }, [currentFolder, setActiveFilters]);

  // Save filters to localStorage when they change
  useEffect(() => {
    // Only save non-empty filter sets
    if (Object.keys(activeFilters).length > 0) {
      localStorage.setItem(`emailFilters_${currentFolder}`, JSON.stringify(activeFilters));
    } else {
      // Remove from storage if filters are cleared
      localStorage.removeItem(`emailFilters_${currentFolder}`);
    }
  }, [activeFilters, currentFolder]);

  // Define updateReadStatus before using it in the hook
  const updateReadStatus = useCallback(async (emailId, isUnread = false) => {
    try {
      // Save the current scroll position
      const scrollPosition = listRef.current?.scrollTop || 0;
      
      // Make API call to sync read status
      axiosWithAuth.post(process.env.REACT_APP_API_URL + '/sync-email-read', { email_id: emailId, is_unread: isUnread });      

      // Update folder emails state and cache
      const updatedEmails = (folderEmails[currentFolder] || []).map(email => {
        if (!email) return null;
        
        // Simply update the email if it matches the emailId
        if (email?.id === emailId) {
          return { ...email, read: !isUnread };
        } 
        
        return email;
      }).filter(Boolean);

      // Ensure we update the state immediately to reflect the change in the UI
      await updateEmailsStateAndCache(currentFolder, updatedEmails);

      // Update the API action completion state
      if (!isUnread) {
        // If marking as read, update the action completion state
        handleActionComplete?.('mark-read', [emailId]);
      } else {
        // If marking as unread, update the action completion state
        handleActionComplete?.('mark-unread', [emailId]);
      }

      // Preserve the selection state
      setSelectedEmailIds(prev => prev.includes(emailId) ? prev : [...prev, emailId]);
      setLastSelectedEmailId(emailId);
      setFocusedEmailId(emailId);
      
      // Force a re-render of the component to ensure the UI updates
      setForceUpdate(prev => prev + 1);
      
      // Restore the scroll position after state updates
      requestAnimationFrame(() => {
        if (listRef.current) {
          listRef.current.scrollTop = scrollPosition;
        }
      });
    } catch (error) {
      console.error('Error syncing email read status:', error);
    }
  }, [
    currentFolder, 
    folderEmails, 
    updateEmailsStateAndCache, 
    setSelectedEmailIds, 
    setLastSelectedEmailId, 
    setFocusedEmailId, 
    setForceUpdate,
    handleActionComplete
  ]);

  const handleDoubleClick = useCallback((email, event) => {
    // Only allow double click when inference filter is set to focused
    if (!email || !email?.id || email?.subitem) return; 
    
    // Prevent the double click from triggering a new selection
    event?.stopPropagation();
    event?.preventDefault();
    
    // Immediately open preview dialog
    setSelectedEmailForPreview(email);
    setIsPreviewDialogOpen(true);

    // Handle state updates after opening preview
    requestAnimationFrame(() => {
        setSelectedEmail(email);
        setSelectedEmailIds([email.id]);
        setFocusedEmailId(email.id);
        
        // Always update read status on double click, especially for parent emails in inbox_focused
        // This works together with the single-click behavior that doesn't mark these emails as read
        if (!email?.read) {
            updateReadStatus(email?.id, false)
                .catch(error => console.error('Error updating email status:', error));
        }
    });
  }, [updateReadStatus, setSelectedEmail, setSelectedEmailIds, setFocusedEmailId, setSelectedEmailForPreview, setIsPreviewDialogOpen]);

  const handleDeleteThread = useCallback(async (emailId) => {
    try {
      const response = axiosWithAuth.post(process.env.REACT_APP_API_URL + '/delete-lexi-thread', { email_id: emailId });
      // Instantly update UI by removing the deleted thread
      const updatedEmails = folderEmails[currentFolder].filter(email => email?.id !== emailId);
      await updateEmailsStateAndCache(currentFolder, updatedEmails);
      
      // Clear selection if the deleted thread was selected
      if (selectedEmailIds.includes(emailId)) {
        setSelectedEmailIds(prevIds => prevIds.filter(id => id !== emailId));
        if (selectedEmail?.id === emailId) {
          setSelectedEmail(null);
        }
      }
      
      // Update focused email if needed
      if (focusedEmailId === emailId) {
        setFocusedEmailId(null);
      }
      
      console.log('Thread deleted successfully');
    } catch (error) {
      console.error('Error deleting thread:', error);
    }
  }, [selectedEmailIds, selectedEmail?.id, focusedEmailId, currentFolder, folderEmails, updateEmailsStateAndCache]);

  // Add a debug flag
  const [debugKeyboardNav] = useState(false);

  // Integrate keyboard navigation hook
  const { 
    visibleEmails,
    keyboardShortcuts,
    isHelpDialogOpen,
    setIsHelpDialogOpen,
    focusEmailList,
    isEmailListFocused
  } = useEmailKeyboardNavigation({
    emails: currentEmails,
    focusedEmailId,
    setFocusedEmailId,
    selectedEmailIds,
    setSelectedEmailIds,
    setSelectedEmail,
    listItemRefs,
    expandedThreads,
    onThreadExpand,
    isViewingSingleEmail,
    updateReadStatus,
    currentFolder,
    handleDoubleClick,
    handleDeleteThread,
    isPreviewDialogOpen,
    setIsPreviewDialogOpen,
    setSelectedEmailForPreview,
    groupEmailsByDate, // Pass the groupEmailsByDate function directly
    emailListRef: listRef, // Pass the list reference for focus tracking
  });

  // Debug what's in the visible emails if debug flag is on
  useEffect(() => {
    if (debugKeyboardNav && visibleEmails?.length > 0) {
      console.log('Keyboard nav visible emails:',
        visibleEmails.map((e, i) => 
          `${i}: ${e.subitem ? '  ↳ ' : ''}${e.subject?.slice(0, 20)} (${e.id})`
        ).join('\n')
      );
    }
  }, [debugKeyboardNav, visibleEmails]);

  // Apply filters by fetching from server instead of client-side filtering
  useEffect(() => {
    const currentFiltersString = JSON.stringify(activeFilters);
    
    // Only apply filters if they've actually changed
    if (prevFiltersRef.current !== currentFiltersString) {
      console.log('Filters changed, applying new filters');
      prevFiltersRef.current = currentFiltersString;
      
      const applyServerFilters = async () => {
        setIsFilterLoading(true);
        
        try {
          // Prepare options for fetchEmails including preserveOnEmpty for filtered requests
          const hasActiveFilters = Object.keys(activeFilters).length > 0;
          
          // Capture current emails before the fetch
          const currentEmailsSnapshot = folderEmails[currentFolder] || [];
          console.log('Current emails before filter change:', currentEmailsSnapshot.length);
          
          // If no active filters, fetch without filter parameters to reset to original state
          const result = await fetchEmails(
            currentFolder, 
            0, // skip
            false, // shouldProcess
            false, // isLoadMore
            true, // skipCache
            false, // skipThreadFetch
            hasActiveFilters ? activeFilters : undefined,
            { 
              preserveOnEmpty: hasActiveFilters, // Pass option to preserve emails if filters return empty
              isFilterChange: true // Mark this as a filter change operation
            }
          );
          
          // If we have filters and empty results, preserve the existing emails
          if (hasActiveFilters && (!result.data || result.data.length === 0)) {
            console.log('Empty response with active filters - preserving existing emails');
            // Explicitly ensure we're not replacing emails in this case
            await updateEmailsStateAndCache(currentFolder, currentEmailsSnapshot, {
              skipCache: false,
              preserveExistingData: true,
              filterOperationWithNoResults: true
            });
          }
          
          console.log('Fetch result after filter change:', result);
          
          // Reset scroll position after applying filters
          if (listRef.current) {
            listRef.current.scrollTop = 0;
          }
          
          // Reset hasMore state when filters change
          setHasMore(true);
          setAutoLoadCount(0);
        } catch (error) {
          console.error('Error applying/clearing filters:', error);
        } finally {
          setIsFilterLoading(false);
        }
      };

      applyServerFilters();
    } else {
      console.log('Filters unchanged, skipping filter application');
    }
  }, [activeFilters, currentFolder, folderEmails, fetchEmails, updateEmailsStateAndCache]);

  // Reset filters when folder changes
  useEffect(() => {
    setActiveFilters({});
    prevFiltersRef.current = JSON.stringify({});
  }, [currentFolder, setActiveFilters]);

  console.log('folderEmails in emailList:', folderEmails);

  const handleRefresh = async () => {
    // if (isRefreshing) return;
    console.log('Refreshing inbox');
    setIsRefreshing(true);
    await onRefresh();
    setIsRefreshing(false);
  };

  // Handle filter change
  const handleFilterChange = (newFilters) => {
    console.log('Filter change in EmailList:', newFilters);
    
    // Only update if filters have changed
    const newFiltersString = JSON.stringify(newFilters);
    if (prevFiltersRef.current !== newFiltersString) {
      setActiveFilters(newFilters);
      // No need to update prevFiltersRef here, as the useEffect will handle it
      
      // Call external handler if provided
      if (externalOnFilterChange) {
        externalOnFilterChange(newFilters);
      }
    } else {
      console.log('Ignoring identical filter change');
    }
  };

  useEffect(() => {
    if (selectedEmail?.id && !selectedEmailIds.includes(selectedEmail.id)) {
      setSelectedEmailIds(prev => [...prev, selectedEmail.id]);
    }
  }, [selectedEmail, selectedEmailIds]);

  

  const handleSelectEmail = useCallback((email, isDoubleClick = false, event) => {
    if (!email || !email?.id) return;  // Add early return for null/invalid emails

    // Save the current scroll position
    const scrollPosition = listRef.current?.scrollTop || 0;

    // Skip selection handling if this was triggered by a double click
    if (isDoubleClick) return;

    // Automatically expand thread if this is a thread parent
    if (email?.hasThread && !expandedThreads.has(email?.id)) {
      onThreadExpand(email?.id);
    }
    
    // Get the visible emails array for multi-selection
    const emailsGroup = groupEmailsByDate(currentEmails);
    const visibleEmails = [
      ...emailsGroup.today,
      ...emailsGroup.yesterday,
      ...emailsGroup.thisWeek,
      ...Object.values(emailsGroup.earlier).flat()
    ].flatMap(parentEmail => {
      // With the flat structure, find all emails with the same conversationId as this parent
      // but ensure we don't include the parent itself
      const threadEmails = parentEmail?.conversationId ? 
        currentEmails.filter(email => 
          email?.conversationId === parentEmail.conversationId && 
          email?.id !== parentEmail.id
        ) : [];

      // Only include thread emails if this thread is expanded
      if (parentEmail?.hasThread && expandedThreads.has(parentEmail.id)) {
        // Sort thread emails by date, newest first
        const sortedThreadEmails = threadEmails.sort((a, b) => 
          new Date(b.date) - new Date(a.date)
        ).map(te => ({
          ...te,
          subitem: true,
          parentId: parentEmail.id
        }));
        
        return [parentEmail, ...sortedThreadEmails];
      }
      
      return [parentEmail];
    }).filter(Boolean);

    // Handle multi-selection with Shift key
    if (event?.shiftKey && selectedEmailIds.length > 0) {
      const lastSelectedId = selectedEmailIds[selectedEmailIds.length - 1];
      const lastSelectedIndex = visibleEmails.findIndex(e => e.id === lastSelectedId);
      const currentIndex = visibleEmails.findIndex(e => e.id === email.id);
      
      if (lastSelectedIndex >= 0 && currentIndex >= 0) {
        const startIdx = Math.min(lastSelectedIndex, currentIndex);
        const endIdx = Math.max(lastSelectedIndex, currentIndex);
        
        const emailsToSelect = visibleEmails
          .slice(startIdx, endIdx + 1)
          .map(e => e.id);
        
        setSelectedEmailIds(emailsToSelect);
      } else {
        setSelectedEmailIds([email.id]);
      }
    }
    // Handle multi-selection with Ctrl/Cmd key
    else if (event?.ctrlKey || event?.metaKey) {
      setSelectedEmailIds(prevSelected => {
        if (prevSelected.includes(email.id)) {
          return prevSelected.filter(id => id !== email.id);
        } else {
          return [...prevSelected, email.id];
        }
      });
    }
    // Normal selection: select only this email
    else {
      setSelectedEmailIds([email.id]);
    }

    // Always set focused email
    setFocusedEmailId(email.id);
    
    // Only update selected email for preview if this is a single selection
    if (!event?.shiftKey && !event?.ctrlKey && !event?.metaKey) {
      // Always use the setSelectedEmail function that was passed down
      // This ensures handleEmailSelection in MailView will be called
      setSelectedEmail(email);
    }

    // Update read status on selection with specific rules:
    // 1. For parent emails in inbox_focused on desktop: don't update on first click (only on double click)
    // 2. For parent emails in inbox_focused on mobile: update on first click
    // 3. For thread subitems: always update on first click
    // 4. For emails in other folders: always update on first click
    if (!email?.read) {
      const isParentEmailInFocused = currentFolder === 'inbox_focused' && !email.subitem;
      
      // Update read status if:
      // - Email is not a parent in inbox_focused, OR
      // - Email is a parent in inbox_focused but we're on mobile
      if (!isParentEmailInFocused || isMobile) {
        updateReadStatus(email?.id, false)
          .then(() => {
            // After updating read status, call setSelectedEmail again to ensure mobile view updates
            // This is important for emails that were just marked as read
            if (isMobile) {
              setSelectedEmail(email);
            }
          })
          .catch(error => console.error('Error updating email status:', error));
      }      
    }

    // Restore scroll position after all state updates
    requestAnimationFrame(() => {
      if (listRef.current) {
        listRef.current.scrollTop = scrollPosition;
      }
    });
  }, [
    expandedThreads,
    setSelectedEmail,
    onThreadExpand,
    updateReadStatus,
    setSelectedEmailIds,
    setFocusedEmailId,
    currentFolder,
    selectedEmailIds,
    currentEmails,
    groupEmailsByDate,
    isViewingSingleEmail,
    isMobile
  ]);

  // Add effect to handle Lexi thread selection
  useEffect(() => {
    if (selectedEmail?.id === 'thread_newLexiChat') {
      // Use a timeout to break the potential cycle and ensure this runs after other state updates
      const timeoutId = setTimeout(() => {
        setSelectedEmailIds(prev => {
          // Only update if needed to prevent infinite loops
          if (prev.length > 0) return [];
          return prev;
        });
        setFocusedEmailId(prev => {
          // Only update if needed to prevent infinite loops
          if (prev !== null) return null;
          return prev;
        });
      }, 0);
      
      return () => clearTimeout(timeoutId);
    }
  }, [selectedEmail?.id, setSelectedEmailIds, setFocusedEmailId]);

  const handleContainerClick = () => {
    setIsFocused(true);
    // Focus the email list to enable keyboard shortcuts
    focusEmailList();
  };

  // Add effect to reset scroll position on folder change
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [currentFolder]);


  // Update handleInferenceFilterChange
  const handleInferenceFilterChange = async (event, newFilter) => {
    if (newFilter !== null) {
      const [baseFolder] = currentFolder.split('_');
      if (baseFolder === 'inbox') {
        // Reset scroll position before changing filter
        if (listRef.current) {
          listRef.current.scrollTop = 0;
        }
        // Preserve existing URL parameters when changing filter
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('folder', `inbox_${newFilter}`);
        window.history.pushState({}, '', `?${searchParams.toString()}`);
        // Trigger a popstate event to handle the URL change
        window.dispatchEvent(new PopStateEvent('popstate'));
        // Force a re-render after a short delay
        setTimeout(() => {
          setForceUpdate(prev => prev + 1);
        }, 100);
      }
    }
  };

  // Add effect to handle filter changes
  useEffect(() => {
    if (isMobile && currentFolder.startsWith('inbox_')) {
      // Force a re-render when the folder changes on mobile
      setForceUpdate(prev => prev + 1);
      
      // Reset scroll position
      if (listRef.current) {
        listRef.current.scrollTop = 0;
      }
    }
  }, [currentFolder, isMobile]);

  // Add handleLoadMore function for the Load More button
  const handleLoadMore = useCallback(async () => {
    if (isLoadingMore || isLoading) {
      console.log('Load more blocked:', { 
        isLoadingMore, 
        isLoading
      });
      return;
    }

    console.log('Loading more emails');
    setIsLoadingMore(true);
    try {
      if (searchQuery) {
        // Handle search results pagination
        const response = await searchEmails(searchQuery, currentFolder, nextSearchLink);
        if (!response?.data?.length) {
          console.log('No more search results to load');
          setHasMore(false);
        }
      } else {
        // Handle regular folder loading
        const skip = currentEmails.length;
        console.log('Fetching more emails, skip:', skip);
        
        const hasActiveFilters = Object.keys(activeFilters).length > 0;
        
        // Pass active filters to fetchEmails
        const response = await fetchEmails(
          currentFolder, 
          skip, 
          false, 
          true, // This is isLoadMore flag
          false, 
          false, 
          hasActiveFilters ? activeFilters : undefined,
          { 
            preserveOnEmpty: hasActiveFilters, // Preserve emails if filtered request returns empty
            isAppend: true, // Add explicit flag to indicate this is an append operation
            skipThreadCache: true // skip thread cache and fetch fresh threads
          }
        );
        
        // Always work with the most current folderEmails state
        const existingEmails = folderEmails[currentFolder] || [];
        
        if (response?.data?.length) {
          const newEmails = response.data;

          // Create a Set of existing IDs for faster lookup
          const existingIds = new Set(existingEmails.map(email => email?.id));
          
          // Filter out duplicates from new emails
          const uniqueNewEmails = newEmails.filter(email => !existingIds.has(email?.id));
          console.log('Unique new emails to add:', uniqueNewEmails.length);

          if (uniqueNewEmails.length === 0) {
            console.log('No new unique emails to add');
            setHasMore(false);
            return;
          }

          // Combine and sort
          const combinedEmails = [...existingEmails, ...uniqueNewEmails]
            .sort((a, b) => new Date(b?.date) - new Date(a?.date));

          console.log('Total emails after combining:', combinedEmails.length, 
                     'Existing:', existingEmails.length, 
                     'New unique:', uniqueNewEmails.length);

          // Update state with explicit append flag
          await updateEmailsStateAndCache(currentFolder, combinedEmails, { 
            skipCache: false,
            isAppend: true, // Explicitly mark as append operation
            cacheKey: hasActiveFilters ? 
              currentFolder + '_' + Object.entries(activeFilters).map(([k, v]) => `${k}=${v}`).join('_') : 
              undefined,
            preserveOnEmpty: hasActiveFilters, // Preserve existing emails if no new ones
            appendOperation: true // Additional flag to reinforce append behavior
          });
        } else {
          console.log('No more emails to load');
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error('Error loading more items:', error);
      setHasMore(false);
    } finally {
      setIsLoadingMore(false);
    }
  }, [
    currentFolder, 
    currentEmails, 
    isLoadingMore, 
    isLoading, 
    searchQuery, 
    fetchEmails, 
    folderEmails, 
    updateEmailsStateAndCache, 
    searchEmails, 
    nextSearchLink,
    activeFilters
  ]);

  // Reset states when folder changes
  useEffect(() => {
    console.log('Folder changed, resetting states:', currentFolder);
    setHasMore(true);
    setIsLoadingMore(false);
    setAutoLoadCount(0);  // Reset auto-load count
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [currentFolder]);

  // Add effect to reset hasMore when search changes
  useEffect(() => {
    console.log('Search changed, resetting load more state');
    setHasMore(true);
    setIsLoadingMore(false);
    setAutoLoadCount(0);  // Reset auto-load count
  }, [searchQuery]);


  const fetchSenderPhotos = useCallback(async (emails) => {
    try {
      // Extract unique sender emails
      const uniqueSenderEmails = [...new Set(
        emails.flatMap(email => email.fromEmail).filter(Boolean)
      )];

      // Skip if no emails to process
      if (uniqueSenderEmails.length === 0) return;

      // Filter out emails we already have photos for or have already tried to fetch
      const emailsToFetch = uniqueSenderEmails.filter(email => 
        !senderPhotos[email] && !fetchedEmailsRef.current.has(email)
      );

      // If all photos are already in state or have been fetched, return early
      if (emailsToFetch.length === 0) return;

      // Mark these emails as fetched to prevent duplicate requests
      emailsToFetch.forEach(email => fetchedEmailsRef.current.add(email));

      const response = await axiosWithAuth.post('/batch-get-sender-photos', {
        senderEmails: emailsToFetch
      });

      if (response.data && response.data.photos) {
        // Update state with new photos
        setSenderPhotos(prevPhotos => ({
          ...prevPhotos,
          ...response.data.photos
        }));
      }
    } catch (error) {
      console.error('Error fetching sender photos:', error);
    }
  }, [axiosWithAuth]);

  // Add effect to fetch photos when emails change
  useEffect(() => {
    // Clear any pending timeout
    if (photoFetchTimeoutRef.current) {
      clearTimeout(photoFetchTimeoutRef.current);
    }

    // Debounce the photo fetch to avoid too many requests during rapid email updates
    photoFetchTimeoutRef.current = setTimeout(() => {
      const groupedEmails = groupEmailsByDate(currentEmails);
      if (groupedEmails) {
        const allEmails = [
          ...groupedEmails.today,
          ...groupedEmails.yesterday,
          ...groupedEmails.thisWeek,
          ...Object.values(groupedEmails.earlier).flat()
        ];
        
        // Only fetch if we have emails and they're not already being processed
        if (allEmails.length > 0) {
          fetchSenderPhotos(allEmails);
        }
      }
    }, 300);

    return () => {
      if (photoFetchTimeoutRef.current) {
        clearTimeout(photoFetchTimeoutRef.current);
      }
    };
  }, [currentEmails, fetchSenderPhotos]);

  // Reset fetchedEmailsRef when folder changes
  useEffect(() => {
    // Reset the set of fetched emails when folder changes
    fetchedEmailsRef.current = new Set();
  }, [currentFolder]);

  // Add effect to auto-expand thread when viewing a single email
  useEffect(() => {
    if (isViewingSingleEmail && selectedEmail?.id) {
      console.log('Auto-expanding thread for single email view:', selectedEmail.id);
      onThreadExpand(selectedEmail.id);
    }
  }, [isViewingSingleEmail, selectedEmail?.id, selectedEmail?.hasThread, onThreadExpand]);

  // Add effect to show a temporary message when email list is focused
  const [showShortcutsMessage, setShowShortcutsMessage] = useState(false);
  
  useEffect(() => {
    if (isEmailListFocused) {
      setShowShortcutsMessage(true);
      const timer = setTimeout(() => {
        setShowShortcutsMessage(false);
      }, 2000); // Hide after 2 seconds
      return () => clearTimeout(timer);
    }
  }, [isEmailListFocused]);

  const renderProps = {
    theme,
    selectedEmailIds,
    hoveredEmailId,
    expandedThreads,
    currentFolder,
    listItemRefs,
    senderColors,
    setSenderColors,
    searchQuery,
    DraggableEmailItem,
    handleEmailMove,
    handleSelectEmail,
    setHoveredEmailId,
    handleDoubleClick,
    onThreadExpand,
    handleDeleteThread,
    senderPhotos
  };

  useEffect(() => {
    const handleScrollToItem = (id) => {
      if (id && listItemRefs.current[id] && listRef.current) {
        const savedScrollPosition = listRef.current.scrollTop;
        
        // Use requestAnimationFrame to ensure scroll position is restored
        requestAnimationFrame(() => {
          listRef.current.scrollTop = savedScrollPosition;
        });
      }
    };

    const originalOnThreadExpand = onThreadExpand;
    // Wrap the original onThreadExpand to preserve scroll position
    const wrappedOnThreadExpand = (threadId) => {
      const savedScrollPosition = listRef.current?.scrollTop || 0;
      
      // Call the original function
      originalOnThreadExpand(threadId);
      
      // Restore scroll position in the next animation frame
      requestAnimationFrame(() => {
        if (listRef.current) {
          listRef.current.scrollTop = savedScrollPosition;
        }
      });
    };

    // Replace the original function with our wrapped version
    onThreadExpand = wrappedOnThreadExpand;
    
    // Listen for expanded threads changes to preserve scroll
    expandedThreads.forEach(id => {
      handleScrollToItem(id);
    });
  }, [onThreadExpand, expandedThreads]);

  return (
    <Box
      ref={containerRef}
      onClick={handleContainerClick}
      sx={{ 
        height: '100%', 
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginLeft: isMobile ? '5px' : '14px',
        marginRight: isMobile ? '5px' : '0px',
        '& @keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      }}
    >
      
      {/* Add pull-to-refresh indicator */}
      {isMobile && (isRefreshing) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: Math.max(pullProgress * pullThreshold, isRefreshing ? pullThreshold : 0),
            transition: isRefreshing ? 'none' : 'height 0.2s ease',
            overflow: 'hidden',
            zIndex: 1,
          }}
        >
        </Box>
      )}

      {/* Show EmailHeader when not on mobile */}
      {!isMobile && (
        <EmailHeader
          currentFolder={currentFolder}
          onRefresh={onRefresh}
          folderEmails={folderEmails}
          isMobile={isMobile}
          onFilterChange={handleFilterChange}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          currentEmails={currentEmails}
        />
      )}

      {/* Show folder name and refresh only on mobile */}
      {isMobile && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          px: 2,
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton 
              onClick={handleRefresh}
              size="small"
              sx={{
                '& .MuiSvgIcon-root': {
                  transition: 'transform 0.5s ease-out',
                  transform: isRefreshing ? 'rotate(360deg)' : 'rotate(0deg)',
                  animation: isRefreshing ? 'continuous-spin 1s linear infinite' : 'none',
                },
                '@keyframes continuous-spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' },
                },
              }}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: '1rem',
                fontWeight: 500,
                color: theme.palette.text.primary,
                textTransform: 'capitalize'
              }}
            >
              {currentFolder.split('_')[0]}
            </Typography>
          </Box>
                      
          {currentFolder.startsWith('inbox_') && !searchQuery && (
            <ToggleButtonGroup
              value={currentFolder.split('_')[1]}
              exclusive
              onChange={handleInferenceFilterChange}
              aria-label="inference filter"
              size="small"
              sx={{
                '& .MuiToggleButton-root': {
                  px: 2,
                  py: 0.5,
                  fontSize: '0.7rem',
                  textTransform: 'none', 
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.accent.primary}15`,
                    color: theme.palette.accent.primary,
                    '&:hover': {
                      backgroundColor: `${theme.palette.accent.primary}25`,
                    }
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  }
                }
              }}
            >
              <ToggleButton value="focused">
                Focused
              </ToggleButton>
              <ToggleButton value="other">
                Other
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      )}

      {/* Show toggle buttons for inbox folders on desktop only */}
      {!isMobile && currentFolder.startsWith('inbox_') && !searchQuery && (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          py: 1,
        }}>
          <ToggleButtonGroup
            value={currentFolder.split('_')[1]}
            exclusive
            onChange={handleInferenceFilterChange}
            aria-label="inference filter"
            size="small"
            sx={{
              paddingTop: 1,
              paddingBottom: 0,
              '& .MuiToggleButton-root': {
                px: 3,
                py: 0.5,
                textTransform: 'none', 
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.accent.primary}15`,
                  color: theme.palette.accent.primary,
                  '&:hover': {
                    backgroundColor: `${theme.palette.accent.primary}25`,
                  }
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              }
            }}
          >
            <ToggleButton value="focused">
              Focused
            </ToggleButton>
            <ToggleButton value="other">
              Other
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      <Box 
        ref={listRef}
        key={`email-list-${currentFolder}-${forceUpdate}`}
        sx={{ 
          height: '100%',
          overflowY: 'auto', 
          scrollbarWidth: 'thin', 
          scrollbarColor: 'rgba(0,0,0,.1) transparent',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '5px',
          },
          position: 'relative',
          zIndex: 0,
          outline: 'none',
        }}
        tabIndex="-1"
      > 
        {(isLoading || isFilterLoading || isFetchingSingleEmail || (isFetchingEmails && !isLoadingMore)) ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100px' 
          }}>
            <CircularProgress size={24} sx={{ color: theme.palette.accent.primary }} />
          </Box>
        ) : currentEmails.length === 0 ? (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center',
            height: '85%',
            color: theme.palette.text.secondary,
            gap: 2
          }}>
            <Typography variant="body1" sx={{ fontWeight: 400, textAlign: 'center' }}>
              {searchQuery ? 'No results found' : 
                Object.keys(activeFilters).length > 0 ? 'No emails match your filters' : 
                'Didn\'t find any emails 📥'}
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {/* When searching, render all results in a single group */}
              {searchQuery ? (
                <Box>
                  {currentEmails.map((email) => renderEmailGroup({
                    emails: [email],
                    ...renderProps
                  }))}
                </Box>
              ) : (
                <>
                  {renderEmailGroup({
                    title: 'Today',
                    emails: groupEmailsByDate(currentEmails).today,
                    ...renderProps
                  })}
                  {renderEmailGroup({
                    title: 'Yesterday',
                    emails: groupEmailsByDate(currentEmails).yesterday,
                    ...renderProps
                  })}
                  {renderEmailGroup({
                    title: 'This Week',
                    emails: groupEmailsByDate(currentEmails).thisWeek,
                    ...renderProps
                  })}
                  {/* Replace the earlier group with month-year groups */}
                  {groupEmailsByDate(currentEmails).earlierMonths.map(monthYear => (
                    renderEmailGroup({
                      title: monthYear,
                      emails: groupEmailsByDate(currentEmails).earlier[monthYear],
                      ...renderProps
                    })
                  ))}
                  
                  {/* Display sender emails if provided */}
                  {isViewingSingleEmail && (
                    <>
                      <Divider 
                        sx={{ 
                          my: 2, 
                          borderColor: theme.palette.divider,
                          '&::before, &::after': {
                            borderColor: theme.palette.divider,
                          }
                        }} 
                      />
                    </>
                  )}
                </>
              )}
            </List>
            {/* Always show the button, but with different states */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-start',
              padding: 2,
              paddingBottom: 3,
              paddingLeft: 3
            }}>
              <Button
                onClick={handleLoadMore}
                disabled={isLoadingMore || !hasMore}
                variant="text"
                sx={{
                  color: hasMore ? theme.palette.accent.primary : theme.palette.text.disabled,
                  '&:hover': {
                    backgroundColor: hasMore ? `${theme.palette.accent.primary}15` : 'transparent',
                  },
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                {isLoadingMore ? (
                  <CircularProgress size={20} sx={{ color: theme.palette.accent.primary, mr: 1 }} />
                ) : null}
                {!isViewingSingleEmail && (isLoadingMore ? 'Loading...' : hasMore ? 'Load more emails' : 'No more emails to fetch')}
              </Button>
            </Box>
          </>
        )}        
      </Box>
      <Dialog
        open={isPreviewDialogOpen}
        onClose={() => {
          setSelectedEmailForPreview(prev => ({
            ...prev,
            requestClose: true
          }));
        }}
        maxWidth="lg"
        fullWidth
        TransitionComponent={isMobile ? Slide : undefined}
        TransitionProps={isMobile ? { direction: 'up' } : undefined}
        sx={{
          '& .MuiDialog-paper': {
            height: isMobile ? '90vh' : '90vh',
            maxHeight: isMobile ? '90vh' : '90vh',
            minWidth: isMobile ? '100vw' : { sm: '800px', md: '1000px' },
            width: isMobile ? '100vw' : '90vw',
            m: isMobile ? 0 : 2,
            borderRadius: isMobile ? '16px 16px 0 0' : '8px',
            overflow: 'hidden',
            position: isMobile ? 'fixed' : 'relative',
            bottom: isMobile ? 0 : 'auto',
            left: isMobile ? 0 : 'auto',
            right: isMobile ? 0 : 'auto',
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiBackdrop-root': {
            backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogContent sx={{ padding: 0, display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
          {isMobile && (
            <Box
              sx={{
                height: '4px',
                width: '36px',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                borderRadius: '2px',
                position: 'absolute',
                top: '8px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
              }}
            />
          )}
          <EmailPreviewComponent 
            selectedEmail={selectedEmailForPreview} 
            onSwap={() => {
              if (selectedEmail?.id) {
                const currentEmails = folderEmails[currentFolder] || [];
                const currentIndex = currentEmails.findIndex(email => email?.id === selectedEmail?.id);
                const updatedEmails = currentEmails.filter(email => email?.id !== selectedEmail?.id);
                
                // Determine the next email to select
                let nextEmailToSelect = null;
                if (updatedEmails.length > 0) {
                  if (currentIndex < updatedEmails.length) {
                    nextEmailToSelect = updatedEmails[currentIndex];
                  } else {
                    nextEmailToSelect = updatedEmails[0];
                  }
                }

                // Update emails state and cache
                updateEmailsStateAndCache(currentFolder, updatedEmails)
                  .then(() => {
                    // Schedule UI updates
                    setTimeout(() => {
                      setIsPreviewDialogOpen(false);
                      if (nextEmailToSelect) {
                        setSelectedEmailIds([nextEmailToSelect.id]);
                        setSelectedEmail(nextEmailToSelect);
                        setLastSelectedEmailId(nextEmailToSelect.id);
                        setFocusedEmailId(nextEmailToSelect.id);
                      } else {
                        setSelectedEmailIds([]);
                        setSelectedEmail(null);
                      }
                    }, 300);
                  });
              }
            }}
            onClose={() => {
              setTimeout(() => {
                setIsPreviewDialogOpen(false);
                setSelectedEmailForPreview(null);
              }, 0);
            }}
            onSenderClick={(updatedEmail) => {
              // Update the preview email without the requestClose flag
              setSelectedEmailForPreview(updatedEmail);
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog 
        open={isHelpDialogOpen} 
        onClose={() => setIsHelpDialogOpen(false)}
        aria-labelledby="keyboard-shortcuts-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="keyboard-shortcuts-dialog-title" sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.divider}`
        }}>
          <Typography variant="h6">Keyboard Shortcuts</Typography>
          <IconButton 
            edge="end" 
            color="inherit" 
            onClick={() => setIsHelpDialogOpen(false)} 
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List dense>
            {keyboardShortcuts.map((shortcut, index) => (
              <ListItem key={index} disableGutters sx={{ 
                py: 1,
                ...(shortcut.isHeader && {
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                  borderRadius: 1,
                  mb: 1,
                }),
                ...(shortcut.isHelper && {
                  backgroundColor: alpha(theme.palette.info.light, 0.2),
                  borderRadius: 1,
                  mt: 1,
                })
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  width: '100%',
                  ...(shortcut.isHeader && { px: 1.5 }),
                  ...(shortcut.isHelper && { px: 1.5 })
                }}>
                  {!shortcut.isHeader && !shortcut.isHelper ? (
                    <Typography 
                      sx={{ 
                        fontWeight: 'bold', 
                        backgroundColor: 'rgba(0,0,0,0.05)', 
                        px: 1.5, 
                        py: 0.5, 
                        borderRadius: 1,
                        minWidth: '50px',
                        textAlign: 'center'
                      }}
                    >
                      {shortcut.key}
                    </Typography>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {shortcut.key}
                    </Typography>
                  )}
                  <Typography sx={{ 
                    flex: 1, 
                    pl: shortcut.isHeader || shortcut.isHelper ? 2 : 2,
                    fontStyle: shortcut.isHelper ? 'italic' : 'normal',
                    fontWeight: shortcut.isHeader ? 'bold' : 'normal'
                  }}>
                    {shortcut.description}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
            Press ? anytime to toggle this dialog
          </Typography>
        </DialogContent>
      </Dialog>
      <CustomDragLayer />
    </Box>
  );
};

export default EmailList;