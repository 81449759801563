import React, { useState, useEffect, useCallback, useRef, redirect } from 'react';
import { Paper, Box, Menu, MenuItem, Button, alpha, IconButton, TextField, Chip, Collapse, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Backdrop
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.primary.light,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
  minHeight: '100%',
}));

export const ToolbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .ql-toolbar': {
    border: 'none',
    padding: 0,
  },
}));

export const GradientHeader = styled(Box)(({ theme }) => ({
  background: 'transparent',
  padding: '18px 24px',
  color: theme.palette.accent.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  boxSizing: 'border-box',
  margin: 0,
  opacity: 0.9,
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px',
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100% - 40px)', // Subtract header height
  overflow: 'hidden', // Prevent wrapper from scrolling
  [theme.breakpoints.down('sm')]: {
    padding: '12px 12px 0 12px', // Remove bottom padding on mobile
  },
}));

export const FieldBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '10px',
  position: 'relative',
  paddingRight: '45px', // Add right padding to make space for the CC toggle button
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '1px',
    backgroundColor: '#e0e0e0',
    transition: 'background-color 0.3s ease',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '6px',
    paddingRight: '40px', // Slightly less on mobile
  },
}));

export const FieldIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: '14px',
  marginRight: '8px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '10px',
    marginRight: '4px',
  },
}));

export const SecondaryColoredIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '2px',
  '& > *': {
    fontSize: '24px',
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('sm')]: {
    '& > *': {
      fontSize: '20px',
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  '& .MuiInputBase-input': {
    fontSize: '16px', // Updated from 0.875rem
    paddingBottom: '8px',
    color: theme.palette.accent.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: theme.palette.secondary.main,
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

export const CustomButtons = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,           // Prevents the container from shrinking
  overflow: 'hidden',      // Hides overflowing buttons
});


export const CustomToolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 12px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  minHeight: '48px',
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  boxSizing: 'border-box',
  overflowX: 'auto',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  
  // Hide on mobile
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  
  // Make the toolbar use flexbox layout more effectively
  '& .ql-formats': {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
    marginRight: '8px',
    flexShrink: 0,
    
    '&:not(:last-child):not(.toolbar-right)': {
      borderRight: `1px solid ${theme.palette.divider}`,
      paddingRight: '8px',
    },
  },

  // Right-side buttons with improved layout
  '& .toolbar-right': {
    marginLeft: 'auto',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
    flexShrink: 0,
    height: '32px',
  },

  // Style all buttons consistently
  '& button': {
    height: '32px',
    padding: '4px 8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    flexShrink: 0,
    minWidth: 'fit-content',
    transition: 'background-color 0.2s',
    borderRadius: '4px',
    
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    
    '&.ql-active': {
      backgroundColor: theme.palette.action.selected,
    },

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
}));

// Update ToolbarButton to prevent shrinking
export const ToolbarButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  height: '28px', // Reduced from 32px
  padding: '4px 6px', // Reduced horizontal padding
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '4px',
  transition: 'background-color 0.2s',

  // Update inner content layout
  '& .button-content': {
    display: 'flex',
    alignItems: 'center',
    gap: '2px', // Reduced gap
  },

  '& .MuiSvgIcon-root': {
    fontSize: '18px', // Reduced from 20px
  },

  '& .MuiTypography-caption': {
    fontSize: '12px', // Reduced from 14px
    lineHeight: '18px', // Reduced from 20px
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 'bold', // Added bold text
  },

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },

  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const QuillContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
  position: 'relative',
  '& .ql-toolbar': {
    border: 'none',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.primary.light,
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the default Quill toolbar on mobile
    },
  },
  '& .ql-formats': {
    border: 'none',
  },
  '& button': {
    border: 'none',
    '&:hover': {
      color: `${theme.palette.ui.textLight} !important`,
    },
    '&.ql-active': {
      color: `${theme.palette.ui.textLight} !important`,
    },
  },
  '& .ql-picker-options': {
    '& .ql-picker-item:hover': {
      color: `${theme.palette.accent.primary} !important`,
    },
  },
  '& .ql-picker-label:hover': {
    color: `${theme.palette.accent.primary} !important`,
  },
  '& .ql-picker.ql-expanded .ql-picker-label': {
    color: `${theme.palette.accent.primary} !important`,
  },
}));

export const CustomAIbutton = styled(Button)(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // Changed from 'flex-start'
  flexWrap: 'nowrap', // Added to prevent wrapping
  minWidth: '75px',
  backgroundColor: theme.palette.common.black,
  color: disabled ? alpha(theme.palette.primary.main, 0.5) : theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    borderColor: theme.palette.common.black,
  },  
  cursor: disabled ? 'default' : 'pointer',
  '& .MuiTypography-root': {
    lineHeight: 1,
    display: 'inline-block',
    verticalAlign: 'middle',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const CustomButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'sticky',
  left: '270px',
  right: '8px',
  bottom: '600px',
  backgroundColor: 'white',
  zIndex: 1,
  padding: '8px 0',
  width: 'calc(100% - 278px)',
});

export const CustomIcon = styled(Button)(({ theme, disabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  color: disabled ? 'rgba(0, 0, 0, 0.38)' : theme.palette.accent.primary,  
  padding: '4px',
  zIndex: 1,  
  paddingRight: '12px',
  minWidth: 'unset',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  cursor: disabled ? 'default' : 'pointer',
}));

export const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: 'white',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
    marginTop: '4px',
    marginRight: '16px',    
  },
});

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.accent.primary,
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    color: theme.palette.accent.primary,
  },
}));

export const StyledReactQuill = styled(ReactQuill)(({$isReadOnly, $isDisabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minHeight: 0,
  marginBottom: 0,
  '& .ql-container': {
    border: 'none',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  },
  '& .ql-editor': {
    flex: 1,
    minHeight: 0,
    overflowY: 'auto',
    padding: '14px 8px',
    marginBottom: 0,
    // Add minimal scrollbar styles
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#E0E0E0',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#BDBDBD',
    },
    '& p': {
      margin: '0',
      padding: '0',
      marginBottom: '0em', // Reduced from 0.3em
    },
    '& p:last-child': {
      marginBottom: 0,
    },
    '& *': {
      whiteSpace: 'pre-wrap',
      fontSize: '14px !important',
      lineHeight: '1.5 !important', // Reduced from 1.5
      [`@media (max-width: 600px)`]: {
        fontSize: '14px !important',
      },
    },
  },
}));

export const MobileToolbar = styled('div')(({ theme }) => ({
  display: 'none', // Hidden by default
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.accent.secondary,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: '8px 8px calc(8px + env(safe-area-inset-bottom))',
    zIndex: theme.zIndex.modal + 1, // Ensure it's above other content
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    boxShadow: '0px -2px 8px rgba(0,0,0,0.1)',
    
    '& .left-buttons': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      overflowX: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      flex: 1,
      paddingRight: '8px',
    },
    
    '& .right-buttons': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexShrink: 0,
    },
    
    '& .MuiIconButton-root': {
      padding: '6px',
      borderRadius: '4px',
      color: theme.palette.text.primary,
      
      '&.ql-active': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.primary.main,
      },
      
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      
      '& .MuiTypography-button': {
        fontSize: '13px',
        minWidth: '20px',
        textAlign: 'center',
      },
    },
    
    '& .MuiButton-root': {
      minWidth: 'unset',
      padding: '6px 12px',
      
      '& .MuiSvgIcon-root': {
        fontSize: '20px',
      },
    },
    
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    }
  }
}));

export const BodyBox = styled(Box)(({ theme, $hasBottomNav }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '& > div': {
    lineHeight: '1.5 !important',
    fontFamily: 'Arial, sans-serif !important',
    fontSize: '14px !important',
  },
  '& p': {
    margin: '0',
    padding: '0',
    marginBottom: '0.15em',
    lineHeight: '1.5 !important',
    fontFamily: 'Arial, sans-serif !important',
    fontSize: '14px !important',
  },
  '& ol, & ul': {
    margin: '0',
    padding: '0 0 0 1.5em',
    marginBottom: '0.15em',
    lineHeight: '1.5 !important',
  },
  '& li:last-child': {
    marginBottom: '0em',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
    paddingBottom: 'calc(60px + env(safe-area-inset-bottom))', // Add padding to account for mobile toolbar height plus safe area
    position: 'relative', // Add position relative for pseudo-element positioning
    '&::after': {
      content: '""',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
      height: 'env(safe-area-inset-bottom)',
      backgroundColor: theme.palette.accent.secondary,
      zIndex: theme.zIndex.modal - 1, // Place it below the mobile toolbar but above other content
    }
  },
}));

export const ReadOnlyContent = styled('div')({
  padding: '14px 8px',
  fontFamily: 'Arial, sans-serif !important',
  fontSize: '14px !important',
  lineHeight: '1.5 !important',
  maxHeight: '50vh',
  overflowY: 'auto',
  '& p': {
    margin: '0',
    padding: '0',
    marginBottom: '0.15em',
  },
  '& *': {
    fontFamily: 'Arial, sans-serif !important',
    fontSize: '14px !important',
    lineHeight: '1.5 !important',
    [`@media (max-width: 600px)`]: {
      fontSize: '14px !important',
    },
  },
  '& br': {
    display: 'block',
    content: '""',
    marginTop: '0.15em',
  },
});

export const GradientButton = styled(Button, {
  shouldForwardProp: (prop) => !['$isKeyPressed'].includes(prop)
})(({ theme, $isKeyPressed }) => ({
  background: $isKeyPressed ? alpha(theme.palette.accent.darkGreen, 0.8) : theme.palette.accent.darkGreen,
  color: $isKeyPressed ? theme.palette.accent.secondary : theme.palette.accent.secondary,
  transition: 'background-color 0.3s, color 0.3s',
  '&:hover': {
    background: alpha(theme.palette.accent.darkGreen, 0.8),
    color: theme.palette.accent.secondary,
  },
  padding: '6px 16px',
  borderRadius: '4px',
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  flexGrow: 1,
  width: '100% !important',
  maxWidth: 'calc(100% - 5px)', // Ensure it doesn't overflow its container
  '& .MuiInputBase-input': {
    fontSize: '16px',
  },
  '& .MuiInputBase-root': {
    padding: '2px 9px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiAutocomplete-endAdornment': {
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& .MuiButtonBase-root': {
      padding: '4px',
      marginLeft: '0',
    },
  },
  '& .MuiAutocomplete-popper': {
    width: 'auto !important',
    minWidth: '100%',
  },
  '& .MuiAutocomplete-paper': {
    width: '100%',
  },
  '& .MuiChip-root': {
    margin: '1px',
  },
  '& .MuiAutocomplete-option': {
    fontSize: '0.8rem',
    padding: '4px 8px',
  },
  '& .MuiAutocomplete-option[aria-selected="true"]': {
    backgroundColor: alpha(theme.palette.accent.primary, 0.2),
  },
  '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
    backgroundColor: alpha(theme.palette.accent.primary, 0.4),
  },
  // Mobile-specific styles
  [theme.breakpoints.down('sm')]: {
    '& .MuiAutocomplete-popper': {
      position: 'fixed !important',
      left: '0 !important',
      right: '0 !important',
      top: 'auto !important',
      bottom: '0 !important',
      maxHeight: '50vh !important',
      width: '100% !important',
      transform: 'none !important',
    },
    '& .MuiAutocomplete-paper': {
      margin: '0',
      borderRadius: '12px 12px 0 0',
      maxHeight: '50vh !important',
      boxShadow: '0px -2px 8px rgba(0,0,0,0.1)',
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: 'calc(50vh - 40px) !important',
      padding: '8px 0',
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#E0E0E0',
        borderRadius: '4px',
      },
    },
    '& .MuiAutocomplete-option': {
      minHeight: '48px',
      fontSize: '16px',
      padding: '12px 16px',
    },
  },
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: '24px',
  padding: '4px',
  backgroundColor: theme.palette.accent.secondary,
  boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
  color: theme.palette.accent.primary,
  fontSize: '14px', // Updated from 0.75rem
  [`@media (max-width: 600px)`]: {
    fontSize: '14px',
    height: '18px',
  },
  '& .MuiChip-label': {
    padding: '0 2px 0 8px',
  },
  '& .MuiChip-deleteIcon': {
    fontSize: '13px',
    marginRight: '4px',
    marginLeft: '2px',
    color: theme.palette.accent.primary,
  },
}));

export const CcButton = styled(Button)(({ theme }) => ({
  marginLeft: '6px',
  minWidth: '40px',
  maxWidth: 'auto',
  padding: '4px 8px',
  fontSize: '0.7rem',
  color: theme.palette.accent.primary,
  alignSelf: 'flex-start',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
}));

export const AttachmentScroll = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  padding: '8px 0',
  minHeight: '80px',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FileWrapper = styled(Box)({
  margin: '0 8px',
  position: 'relative',
  flexShrink: 0, // Prevent file components from shrinking
});

export const RemoveButton = styled(IconButton)({
  position: 'absolute',
  top: '-6px',
  right: '-5px',
  backgroundColor: 'white',
  padding: '2px',
  boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
  '& .MuiSvgIcon-root': {
    fontSize: '14px',
  },
  '&:hover': {
    backgroundColor: 'white',
  }
});

export const DragOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
  borderRadius: '5px',
}));

export const TransparentBackdrop = styled(Backdrop)({
  backgroundColor: 'transparent',
  zIndex: -1,
});

export const StyledBackdrop = styled(Backdrop)({
  position: 'absolute',
  zIndex: 1000,
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
});

export const DeleteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.accent.primary,
  // padding: '6px',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  '& .MuiSvgIcon-root': {
    fontSize: '1.1rem',
    marginRight: '4px',
    marginBottom: '1px',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
}));

export const DraftStatus = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  marginRight: '8px',
  color: theme.palette.accent.primary,
  fontSize: '14px',
  [`@media (max-width: 600px)`]: {
    fontSize: '12px',
  },
}));

export const ButtonContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minWidth: '160px', // Combined width of Delete and Close buttons
  justifyContent: 'flex-end',
});

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.accent.primary,
  // marginLeft: '8px',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginRight: '3px',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
}));

export const OriginalEmailContainer = styled('div')({
  margin: '16px 0',
  padding: '8px 16px', // Reduced top/bottom padding
  backgroundColor: '#f9f9f9',
  position: 'relative',
  flex: '0 0 auto',
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
});

export const OriginalEmailHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#666',
  padding: '8px 0',
  userSelect: 'none',
  '&:hover': {
    color: '#000',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
    transition: 'transform 0.3s ease',
  },
});

export const OriginalEmailContent = styled(Collapse)({
  marginTop: '8px',
  '& .MuiCollapse-wrapper': {
    transition: 'height 0.3s ease',
  },
  '& .MuiCollapse-wrapperInner': {
    padding: '0 8px',
  },
  '& .original-email-content': {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
      margin: '8px 0'
    },
    '& p': {
      margin: '8px 0'
    }
  }
});

export const AIButtonsGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginLeft: 'auto', // Push to the right
  paddingRight: '8px',
}));

export const AIButton = styled(Button)(({ theme }) => ({
  minWidth: '80px',
  height: '32px', // Fixed height
  padding: '4px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  borderRadius: '4px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
    marginRight: '2px', // Add consistent spacing between icon and text
  },
  '& .MuiTypography-root': {
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
  }
}));

export const ThinkingLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 8px',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  '&:hover': {
    backgroundColor: alpha(theme.palette.accent.primary, 0.1),
  },
}));

export const ReasoningDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '600px',
    maxWidth: '90vw',
    maxHeight: '80vh',
    margin: theme.spacing(2),
    borderRadius: '8px',
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      margin: 0,
      borderRadius: '16px 16px 0 0',
      position: 'fixed',
      bottom: 0,
    },
  },
}));

export const ReasoningDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  '& p': {
    margin: '8px 0',
    fontSize: '14px',
    lineHeight: '1.5',
    color: theme.palette.text.primary,
  },
  '& p:first-of-type': {
    marginTop: 0,
  },
  '& p:last-of-type': {
    marginBottom: 0,
  },
}));

export const ReasoningDialogHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// CC toggle button container that positions the arrow consistently
export const CcToggleContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '8px',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  '& .MuiButtonBase-root': {
    padding: '4px',
    minWidth: '32px',
    height: '32px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.accent.primary,
      fontSize: '1.25rem',
    }
  }
}));