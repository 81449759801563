import React from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const emailGroups = {
  urgent: {
    title: 'Urgent',
    icon: <WarningAmberIcon sx={{ color: '#ff6b6b' }} />,
    emails: [
      { id: 1, subject: 'Critical System Update Required', from: 'IT Department', date: new Date().toISOString(), category: 'Urgent' },
      { id: 2, subject: 'Immediate Action: Client Meeting Rescheduled', from: 'Project Manager', date: new Date().toISOString(), category: 'Urgent' },
      { id: 11, subject: 'Server Downtime Alert', from: 'System Admin', date: new Date().toISOString(), category: 'Urgent' },
      { id: 12, subject: 'Security Breach Detection', from: 'Security Team', date: new Date().toISOString(), category: 'Urgent' },
      { id: 21, subject: 'Production Deployment Issue', from: 'DevOps Team', date: new Date().toISOString(), category: 'Urgent' },
      { id: 22, subject: 'Emergency Budget Review', from: 'Finance Director', date: new Date().toISOString(), category: 'Urgent' },
      { id: 23, subject: 'Critical Client Escalation', from: 'Account Executive', date: new Date().toISOString(), category: 'Urgent' },
      { id: 24, subject: 'Immediate Data Backup Required', from: 'Infrastructure Team', date: new Date().toISOString(), category: 'Urgent' }
    ],
    color: '#ff4444',
  },
  action: {
    title: 'Require Action',
    icon: <TaskAltIcon sx={{ color: '#2979ff' }} />,
    emails: [
      { id: 3, subject: 'Please Review: Q4 Report Draft', from: 'Finance Team', date: new Date().toISOString() },
      { id: 4, subject: 'Pending Approval: Travel Request', from: 'HR Department', date: new Date().toISOString() },
      { id: 13, subject: 'Document Sign-off Required', from: 'Legal Team', date: new Date().toISOString() },
      { id: 14, subject: 'Project Timeline Review', from: 'PMO', date: new Date().toISOString() },
      { id: 25, subject: 'Team Performance Reviews Due', from: 'HR Manager', date: new Date().toISOString() },
      { id: 26, subject: 'Budget Allocation Review', from: 'Finance Controller', date: new Date().toISOString() },
      { id: 27, subject: 'Client Proposal Feedback Needed', from: 'Sales Director', date: new Date().toISOString() },
      { id: 28, subject: 'Software License Renewal', from: 'Procurement Team', date: new Date().toISOString() }
    ],
    color: '#2979ff',
  },
  positive: {
    title: 'Positive',
    icon: <SentimentSatisfiedAltIcon sx={{ color: '#00c853' }} />,
    emails: [
      { id: 5, subject: 'Great job on the presentation!', from: 'CEO Office', date: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString() },
      { id: 6, subject: 'Project milestone achieved', from: 'Team Lead', date: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString() },
      { id: 15, subject: 'Client Feedback: Excellent Work', from: 'Account Manager', date: new Date().toISOString() },
      { id: 16, subject: 'Performance Recognition', from: 'HR Director', date: new Date().toISOString() },
      { id: 29, subject: 'Team Innovation Award Winner', from: 'Innovation Committee', date: new Date().toISOString() },
      { id: 30, subject: 'Customer Success Story', from: 'Marketing Team', date: new Date().toISOString() },
      { id: 31, subject: 'Record Sales Achievement', from: 'Sales Director', date: new Date().toISOString() },
      { id: 32, subject: 'Employee of the Month Nomination', from: 'Recognition Committee', date: new Date().toISOString() }
    ],
    color: '#00c853',
  },
  negative: {
    title: 'Negative',
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: '#ff9100' }} />,
    emails: [
      { id: 7, subject: 'Bug Report: Homepage Issues', from: 'QA Team', date: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString() },
      { id: 8, subject: 'Missed Deadline: Content Update', from: 'Content Team', date: new Date(Date.now() - 48 * 60 * 60 * 1000).toISOString() },
      { id: 17, subject: 'Customer Complaint', from: 'Support Team', date: new Date().toISOString() },
      { id: 18, subject: 'Resource Allocation Issue', from: 'Resource Manager', date: new Date().toISOString() },
      { id: 33, subject: 'Project Delay Notification', from: 'Project Director', date: new Date().toISOString() },
      { id: 34, subject: 'Quality Control Failure', from: 'Production Team', date: new Date().toISOString() },
      { id: 35, subject: 'Budget Overrun Alert', from: 'Finance Controller', date: new Date().toISOString() },
      { id: 36, subject: 'Team Conflict Report', from: 'HR Manager', date: new Date().toISOString() }
    ],
    color: '#ff9100',
  },
};

const EmptyStateContent = ({ botName, botImageUrl, username = 'there', userInfo }) => {
  const theme = useTheme();
  const urgentEmails = emailGroups.urgent?.emails || [];
  
  const getTimeBasedGreeting = () => {
    const now = new Date();
    const hour = now.getHours();
    
    // Extract the first name from givenName
    const firstName = userInfo?.givenName ? userInfo.givenName.split(' ')[0] : username.split(' ')[0];
  
    let header1, header2;
  
    if (hour >= 4 && hour < 6) {
      header1 = `You're up early, ${firstName}! 🌅`;
      header2 = "Let's seize the quiet hours and get a head start on the day!";
    } else if (hour >= 6 && hour < 10) {
      header1 = `Good morning, ${firstName}! ☀️`;
      header2 = "Time to tackle your inbox and make today count!";
    } else if (hour >= 10 && hour < 12) {
      header1 = `Almost noon, ${firstName}! ⏰`;
      header2 = "Let's breeze through those emails and finish the morning strong!";
    } else if (hour === 12) {
      header1 = `It's midday, ${firstName}! 🍽️`;
      header2 = "Perfect time to power through your inbox and set up the rest of your day for success!";
    } else if (hour > 12 && hour < 15) {
      header1 = `Good afternoon, ${firstName}! 🌤️`;
      header2 = "Stay focused and let's clear out those emails to keep the momentum going!";
    } else if (hour >= 15 && hour < 17) {
      header1 = `The day's winding down, ${firstName}! 🕒`;
      header2 = "But there's still time to finish strong—let's get through these emails!";
    } else if (hour >= 17 && hour < 21) {
      header1 = `Evening's here, ${firstName}! 🌇`;
      header2 = "Let's wrap up your inbox and finish the day on a high note!";
    } else if (hour >= 21 && hour < 24) {
      header1 = `Nighttime hustle, ${firstName}! 🌙`;
      header2 = "Let's make the most of these quiet hours and clear out your inbox!";
    } else {
      header1 = `Burning the midnight oil, ${firstName}? 🕛`;
      header2 = "Let's tackle those last emails and wind down for the day!";
    }
  
    return { header1, header2 };
  };

  const { header1, header2 } = getTimeBasedGreeting();
  
  return (
    <Box sx={{ 
      p: { xs: 1, md: 2 },
      pb: { xs: 2, md: 5 },
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      maxWidth: '1200px',
      margin: '0 auto'
    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          pt: { xs: 4, md: 6 },
          mb: 'auto'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.ui.textLight,
            fontWeight: 'light',
            textAlign: 'center',
            opacity: 0.75,
            fontSize: { xs: '2rem', md: '3rem' }
          }}
        >
          {header1}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.secondary,
            textAlign: 'center',
            mt: { xs: 2, md: 4 },
            opacity: 0.55,
            fontSize: { xs: '0.85rem', md: '1.25rem' }
          }}
        >
          {header2}
        </Typography>
        {/* <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.secondary,
            textAlign: 'center',
            mt: { xs: 1, md: 1 },
            opacity: 0.6,
            fontSize: { xs: '0.7rem', md: '0.75rem' }
          }}
        >
          Lexi can not send emails directly.
        </Typography> */}
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            width: '100%',
            overflowX: 'auto',
            pb: 2,
            '&::-webkit-scrollbar': {
              height: '6px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: alpha(theme.palette.divider, 0.1),
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: alpha(theme.palette.divider, 0.2),
              },
            },
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            gap: 2,
            minWidth: 'min-content',
            margin: '0 auto'
          }}>
            {Object.values(emailGroups).flatMap(group => 
              group.emails.slice(0, 3).map((email) => (
                <Paper
                  key={email.id}
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: alpha(theme.palette.background.paper, 0.04),
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.divider, 0.05)}`,
                    width: { xs: '300px', md: '320px' },
                    opacity: 0.85,
                    transition: 'all 0.2s ease',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.background.paper, 0.08),
                      transform: 'translateY(-2px)',
                      opacity: 0.95
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: alpha(theme.palette.text.primary, 0.7),
                          fontWeight: 600,
                          mb: 0.5
                        }}
                      >
                        {email.from}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: alpha(theme.palette.text.secondary, 0.5),
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5
                        }}
                      >
                        <EmailOutlinedIcon sx={{ fontSize: '0.875rem' }} />
                        {`${email.from.toLowerCase().replace(/ /g, '.')}@company.com`}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: alpha(theme.palette.text.primary, 0.6),
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: 1.4
                      }}
                    >
                      {email.subject}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: alpha(theme.palette.text.primary, 0.6),
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        lineHeight: 1.4
                      }}
                    >
                      {email.category}
                    </Typography>
                  </Box>
                </Paper>
              ))
            )}
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
};

export default EmptyStateContent; 