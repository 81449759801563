import React, { useState } from 'react';
import { Box, Typography, Button, Paper, IconButton, Chip, TextField } from '@mui/material';
import { Event, LocationOn, People, VideoCall } from '@mui/icons-material';
import { format } from 'date-fns';
import { axiosWithAuth } from './authService';
import { isToday, isThisWeek } from 'date-fns';

const formatEventDate = (dateString) => {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    if (isToday(date)) {
      return `Today ${format(date, 'HH:mm')}`;
    } else if (isThisWeek(date)) {
      return `${format(date, 'EEEE')} ${format(date, 'HH:mm')}`;
    } else {
      return format(date, 'dd.MM.yyyy HH:mm');
    }
  } catch (e) {
    console.error('Error formatting date:', e);
    return dateString;
  }
};

const CalendarEventPreview = ({ eventData }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [editedEvent, setEditedEvent] = useState(eventData);

  const handleCreateEvent = async () => {
    setIsCreating(true);
    setError(null);
    try {
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/create-calendar-event', editedEvent);
      if (response.status === 200) {
        setIsCreated(true);
      } else {
        setError('Failed to create event');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to create event');
    } finally {
      setIsCreating(false);
    }
  };

  const handleFieldChange = (field, value) => {
    setEditedEvent(prev => ({
      ...prev,
      [field]: value
    }));
  };

  if (isCreated) {
    return (
      <Paper elevation={1} sx={{ p: 2, mb: 2, backgroundColor: '#f5f5f5', width: '100%' }}>
        <Typography variant="body2" color="success.main">
          Calendar event created successfully!
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 2, width: '100%' }}>
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Subject"
          value={editedEvent.subject}
          onChange={(e) => handleFieldChange('subject', e.target.value)}
          sx={{ mb: 2 }}
        />
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
          <Event sx={{ fontSize: '1.2rem', color: 'text.secondary' }} />
          <TextField
            label="Start"
            type="datetime-local"
            value={format(new Date(editedEvent.start.dateTime), "yyyy-MM-dd'T'HH:mm")}
            onChange={(e) => handleFieldChange('start', { dateTime: e.target.value })}
            sx={{ flex: 1 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End"
            type="datetime-local"
            value={format(new Date(editedEvent.end.dateTime), "yyyy-MM-dd'T'HH:mm")}
            onChange={(e) => handleFieldChange('end', { dateTime: e.target.value })}
            sx={{ flex: 1 }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        {editedEvent.location && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <LocationOn sx={{ mr: 1, fontSize: '1.2rem', color: 'text.secondary' }} />
            <TextField
              fullWidth
              variant="outlined"
              label="Location"
              value={editedEvent.location.displayName}
              onChange={(e) => handleFieldChange('location', { displayName: e.target.value })}
            />
          </Box>
        )}

        {editedEvent.isOnlineMeeting && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <VideoCall sx={{ mr: 1, fontSize: '1.2rem', color: 'text.secondary' }} />
            <Typography variant="body2">Teams meeting</Typography>
          </Box>
        )}

        {editedEvent.attendees && editedEvent.attendees.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <People sx={{ mr: 1, fontSize: '1.2rem', color: 'text.secondary', mt: 0.5 }} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {editedEvent.attendees.map((attendee, index) => (
                <Chip
                  key={index}
                  label={attendee.emailAddress.address}
                  size="small"
                  onDelete={() => {
                    const newAttendees = [...editedEvent.attendees];
                    newAttendees.splice(index, 1);
                    handleFieldChange('attendees', newAttendees);
                  }}
                  sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)' }}
                />
              ))}
            </Box>
          </Box>
        )}

        {editedEvent.body && (
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Description"
            value={editedEvent.body.content}
            onChange={(e) => handleFieldChange('body', { content: e.target.value })}
            sx={{ mt: 2 }}
          />
        )}
      </Box>

      {error && (
        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsCreated(true)}
          disabled={isCreating}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateEvent}
          disabled={isCreating}
        >
          {isCreating ? 'Creating...' : 'Create Event'}
        </Button>
      </Box>
    </Paper>
  );
};

export default CalendarEventPreview; 