import React from 'react';
import { Box, useTheme } from '@mui/material';
import { keyframes } from '@mui/system';
import EmailIcon from '@mui/icons-material/Email';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingAnimation = () => {
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '160px',
          height: '160px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Spinning circle */}
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            border: `3px solid ${theme.palette.accent.darkGreen}`,
            borderTop: `3px solid transparent`,
            borderRadius: '50%',
            animation: `${spinAnimation} 1s linear infinite`,
          }}
        />
        
        {/* Email icon */}
        <EmailIcon
          sx={{
            fontSize: '5rem',
            color: theme.palette.accent.darkGreen,
            filter: 'drop-shadow(0 4px 8px rgba(0,0,0,0.1))',
          }}
        />
      </Box>
    </Box>
  );
};

export default LoadingAnimation; 