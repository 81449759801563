import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

// Request deduplication cache
const pendingRequests = new Map();

// Generate a unique key for request deduplication
const getRequestKey = (config) => {
    const { method, url } = config;
    const key = `${method?.toLowerCase()}_${url}`;
    console.debug(`[Request Key] ${url}: ${key}`);
    return key;
};

// Base axios config with necessary CORS settings
const axiosConfig = {
    withCredentials: true,
    // Ensure cookies are sent in cross-origin requests
    xhrFields: {
        withCredentials: true
    }
};

// Function to refresh the access token
const refreshAccessToken = async () => {
    try {
        // Get current account info
        const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
        const currentEmail = currentAccount.email;

        if (!currentEmail) {
            throw new Error('No current account email found');
        }

        console.log(`Refreshing access token for account: ${currentEmail}`);

        // Make sure to include credentials for cookies
        const response = await axios.post(
            `${BASE_URL}/refresh-token`,
            {},
            {
                ...axiosConfig,
                withCredentials: true,  // Ensure cookies are sent
                headers: {
                    ...axiosConfig.headers,
                    'Cache-Control': 'no-cache',
                    'userEmail': currentEmail
                }
            }
        );

        const { accessToken, userEmail } = response.data;
        
        // Verify the token is for the correct account
        if (userEmail !== currentEmail) {
            console.error(`Received token for wrong account: expected ${currentEmail}, got ${userEmail}`);
            throw new Error('Token mismatch');
        }
        
        console.log(`New access token received for ${currentEmail}`);
        
        // Update token only for this specific account
        if (currentEmail) {
            // Update token in session storage
            sessionStorage.setItem(`accessToken_${currentEmail}`, accessToken);
            
            // Update the token in the accounts list
            const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
            const updatedAccounts = accounts.map(account => {
                if (account.email === currentEmail) {
                    return { ...account, accessToken };
                }
                return account;
            });
            localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
            
            // Update current account
            const updatedCurrentAccount = { ...currentAccount, accessToken };
            localStorage.setItem('currentAccount', JSON.stringify(updatedCurrentAccount));
        }
        
        return accessToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
        
        // Check for specific errors that might require special handling
        if (error.response) {
            console.error(`Refresh token error status: ${error.response.status}`);
            console.error('Error response:', error.response.data);
            
            // If token is invalid or expired, trigger reauth
            if (error.response.status === 401 || 
                (error.response.data && error.response.data.error && 
                 (error.response.data.error.includes('invalid_grant') || 
                  error.response.data.error.includes('expired')))) {
                console.log('Token expired or invalid, redirecting to auth');
                
                // Get current account info for potential cleanup
                const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
                const currentEmail = currentAccount.email;
                
                if (currentEmail) {
                    // Remove session storage token
                    sessionStorage.removeItem(`accessToken_${currentEmail}`);
                    
                    // Remove only the failed account from accounts list
                    const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
                    const updatedAccounts = accounts.filter(account => account.email !== currentEmail);
                    localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
                    
                    // If there are other accounts, switch to the first available one
                    if (updatedAccounts.length > 0) {
                        const nextAccount = updatedAccounts[0];
                        localStorage.setItem('currentAccount', JSON.stringify(nextAccount));
                        window.location.reload(); // Reload to reinitialize with new account
                        return null;
                    }
                }
                
                // If no accounts left, clear everything and redirect
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/auth';
                return null;
            }
        }
        
        // For other types of errors, just log and clear the current account
        const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
        const currentEmail = currentAccount.email;
        
        if (currentEmail) {
            // Remove only the failed account from accounts list
            const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
            const updatedAccounts = accounts.filter(account => account.email !== currentEmail);
            localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
            
            // If there are other accounts, switch to the first available one
            if (updatedAccounts.length > 0) {
                const nextAccount = updatedAccounts[0];
                localStorage.setItem('currentAccount', JSON.stringify(nextAccount));
                localStorage.setItem('userInfo', JSON.stringify(nextAccount.userInfo));
                window.location.reload(); // Reload to reinitialize with new account
                return null;
            }
        }
        
        // If no accounts left, clear everything
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/auth';
        return null;
    }
};

// Create an Axios instance with interceptors
const axiosWithAuth = axios.create({
    ...axiosConfig,
    baseURL: BASE_URL
});

// Helper function to get the stored access token
const getStoredAccessToken = () => {
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    const currentEmail = currentAccount.email;
    
    if (currentEmail) {
        // Try to get account-specific token first
        const accountToken = sessionStorage.getItem(`accessToken_${currentEmail}`);
        if (accountToken) return accountToken;
    }
    
    // Only return the token from the current account, not from root storage
    return currentAccount.accessToken;
};

// Request interceptor
axiosWithAuth.interceptors.request.use(
    async (config) => {
        // Skip deduplication for cancelled requests or those with abort signals
        if (config.signal?.aborted) {
            console.debug(`[Request Cancelled] Request was already cancelled before sending:`, config.url);
            return Promise.reject(createCancellationError());
        }

        // Check for duplicate requests
        const requestKey = getRequestKey(config);
        const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}'); 
        
        // Skip deduplication for token refresh requests and requests with abort signals
        if (!config.url?.includes('/refresh-token') && !config.signal) {
            const existingRequest = pendingRequests.get(requestKey);
            if (existingRequest && !existingRequest.completed) {
                console.debug(`[Duplicate Request] ${config.url} - Using existing request`);
                return Promise.reject({
                    __DUPLICATE_REQUEST__: true,
                    promise: existingRequest.promise
                });
            }
            
            // Create a new promise for this request
            let promiseResolve, promiseReject;
            const promise = new Promise((resolve, reject) => {
                promiseResolve = resolve;
                promiseReject = reject;
            });
            
            console.debug(`[New Request] ${config.url} - Creating new request`);
            pendingRequests.set(requestKey, {
                promise,
                completed: false,
                resolve: promiseResolve,
                reject: promiseReject
            });
            
            // Store the request key in the config for later cleanup
            config.__REQUEST_KEY__ = requestKey;
        }

        // Set default content type if not specified
        if (!config.headers['Content-Type'] && !(config.data instanceof FormData)) {
            config.headers['Content-Type'] = 'application/json';
        }

        // Check for mailbox parameter in URL
        let userEmail;
        try {
            const urlParams = new URLSearchParams(window.location.search);
            userEmail = urlParams.get('mailbox');
            
            // // TEMPORARY SOLUTION: Check for id parameter when mailbox is not provided
            // // This will be replaced once mailbox is provided from the providers
            // if (!userEmail && urlParams.get('id')) {
            //     console.log('ID parameter found but no mailbox - using default mailbox temporarily');
            //     userEmail = "kundeservice@modstroem.dk";
            // }
        } catch (error) {
            console.error('Error parsing URL params:', error);
        }

        // If mailbox parameter is not present, fall back to the currentEmail from localStorage
        if (!userEmail) {
            userEmail = currentAccount.email;
        }

        // Add user email to headers for all requests
        if (userEmail) {
            config.headers['userEmail'] = userEmail;
        }

        const accessToken = getStoredAccessToken();
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        
        // Handle different content types
        if (!(config.data instanceof FormData)) {
            // JSON handling
            if (config.headers['Content-Type'] === 'application/json') {
                if (typeof config.data === 'string') {
                    try {
                        // Only parse if it's not already parsed
                        config.data = JSON.parse(config.data);
                    } catch (e) {
                        // If parsing fails, it might already be an object
                    }
                }
                // Add user info to JSON requests
                if (config.method !== 'get' && currentAccount.userInfo) {
                    config.data = {
                        ...config.data,
                        userInfo: currentAccount.userInfo
                    };
                }
                // Stringify at the end if needed
                if (typeof config.data !== 'string') {
                    config.data = JSON.stringify(config.data);
                }
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Create a proper cancellation error
const createCancellationError = () => {
    const cancelError = new Error('Request cancelled');
    cancelError.isAxiosError = true;
    cancelError.silent = true;
    cancelError.isCancelled = true;
    return cancelError;
};

// Response interceptor
axiosWithAuth.interceptors.response.use(
    (response) => {
        // Clean up the pending request
        const requestKey = response.config.__REQUEST_KEY__;
        if (requestKey) {
            const pendingRequest = pendingRequests.get(requestKey);
            if (pendingRequest) {
                pendingRequest.completed = true;
                pendingRequest.resolve(response);
                // Remove the request immediately for requests with abort signals
                if (response.config.signal) {
                    pendingRequests.delete(requestKey);
                } else {
                    // For other requests, keep the short delay
                    setTimeout(() => {
                        pendingRequests.delete(requestKey);
                    }, 100);
                }
            }
        }
        
        return response;
    },
    async (error) => {
        // Handle cancellation with successful response
        if ((error.name === 'CanceledError' || error.message === 'canceled') && error.response?.status === 200) {
            console.debug(`[Request Cancelled] Request was cancelled but had successful response`);
            const requestKey = error.config?.__REQUEST_KEY__;
            if (requestKey) {
                pendingRequests.delete(requestKey);
            }
            return error.response;
        }

        // Handle cancellation errors
        if (error.name === 'CanceledError' || error.message === 'canceled' || error.message === 'Request cancelled') {
            console.debug(`[Request Cancelled] Request was cancelled:`, error.config?.url);
            const requestKey = error.config?.__REQUEST_KEY__;
            if (requestKey) {
                pendingRequests.delete(requestKey);
            }
            return Promise.reject(createCancellationError());
        }

        // Handle duplicate request rejection
        if (error.__DUPLICATE_REQUEST__) {
            console.debug(`[Duplicate Request] Waiting for original request to complete`);
            try {
                // Wait for the original request to complete
                const response = await error.promise;
                console.debug(`[Duplicate Request] Original request completed successfully`);
                return response;
            } catch (originalError) {
                // If the original request was cancelled, handle it silently
                if (originalError.silent) {
                    return Promise.reject(createCancellationError());
                }
                console.debug(`[Duplicate Request] Original request failed:`, originalError);
                throw originalError;
            }
        }
        
        // Clean up the pending request on error
        const requestKey = error.config?.__REQUEST_KEY__;
        if (requestKey) {
            const pendingRequest = pendingRequests.get(requestKey);
            if (pendingRequest) {
                pendingRequest.completed = true;
                pendingRequest.reject(error);
                // Remove the request after a short delay
                setTimeout(() => {
                    pendingRequests.delete(requestKey);
                }, 100);
            }
        }
        
        const originalRequest = error.config;
        
        // Handle 401 errors silently
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            
            try {
                const newAccessToken = await refreshAccessToken();
                
                if (newAccessToken) {
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    axiosWithAuth.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    
                    // Retry the original request with new token
                    return axiosWithAuth(originalRequest);
                }
                
                // If refreshAccessToken returns null, it means it has already handled the redirect
                // Return a rejected promise without error details to prevent UI error display
                return Promise.reject({ silent: true });
            } catch (refreshError) {
                // Clear tokens and redirect without showing error
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = '/auth';
                return Promise.reject({ silent: true });
            }
        }
        
        // For non-401 errors or failed retries, reject with the original error
        return Promise.reject(error);
    }
);

// Add these functions for multiple account management
const saveAccountData = (accountData) => {
  try {
    console.log(`Saving account data for ${accountData.email}, token: ${accountData.accessToken?.substring(0, 10)}...`);
    // Get existing accounts
    const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
    
    // Check if account already exists
    const existingIndex = accounts.findIndex(acc => acc.email === accountData.email);
    
    if (existingIndex >= 0) {
      // Update existing account
      accounts[existingIndex] = {
        ...accounts[existingIndex],
        ...accountData
      };
    } else {
      // Add new account
      accounts.push(accountData);
    }
    
    // Save updated accounts list
    localStorage.setItem('accounts', JSON.stringify(accounts));
    
    // Set as current account
    localStorage.setItem('currentAccount', JSON.stringify(accountData));
  } catch (error) {
    console.error('Error saving account data:', error);
  }
};

const preserveAccountEmails = (email) => {
  try {
    // Get all session storage keys
    const keys = Object.keys(sessionStorage);
    
    // Find and preserve all folder email data for the account
    keys.forEach(key => {
      if (key.startsWith('emails_')) {
        const folderEmails = sessionStorage.getItem(key);
        if (folderEmails) {
          // Store with account-specific prefix
          sessionStorage.setItem(`account_${email}_${key}`, folderEmails);
        }
      }
    });

    // Also preserve hasMore state
    const hasMore = sessionStorage.getItem('folders_hasMore');
    if (hasMore) {
      sessionStorage.setItem(`account_${email}_folders_hasMore`, hasMore);
    }
  } catch (error) {
    console.error('Error preserving account emails:', error);
  }
};

const restoreAccountEmails = (email) => {
  try {
    // Get all session storage keys
    const keys = Object.keys(sessionStorage);
    
    // Find and restore all folder email data for the account
    keys.forEach(key => {
      if (key.startsWith(`account_${email}_emails_`)) {
        const folderKey = key.replace(`account_${email}_`, '');
        const folderEmails = sessionStorage.getItem(key);
        if (folderEmails) {
          sessionStorage.setItem(folderKey, folderEmails);
        }
      }
    });

    // Restore hasMore state
    const hasMore = sessionStorage.getItem(`account_${email}_folders_hasMore`);
    if (hasMore) {
      sessionStorage.setItem('folders_hasMore', hasMore);
    }
  } catch (error) {
    console.error('Error restoring account emails:', error);
  }
};

const switchAccount = async (account) => {
  try {
    console.log(`Switching to account ${account.email}, token: ${account.accessToken?.substring(0, 10)}...`);
    // Store current account data before switching
    const currentUserInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
    const currentAccountData = {
      accessToken: JSON.parse(localStorage.getItem('currentAccount') || '{}').accessToken,
      userInfo: currentUserInfo,
      email: currentUserInfo.userPrincipalName,
      name: `${currentUserInfo.givenName} ${currentUserInfo.surname}`,
      userPhoto: localStorage.getItem('userPhoto'),
      userSignature: localStorage.getItem('userSignature'),
      isSubscribed: localStorage.getItem('isSubscribed') === 'true',
    };
    
    // Only save current account data if we have valid user info
    if (currentUserInfo.userPrincipalName) {
      // Preserve current account's folder emails
      preserveAccountEmails(currentUserInfo.userPrincipalName);
      // Save current account data
      saveAccountData(currentAccountData);
    }
    
    // Clear current session data except account-specific cached emails
    const accountEmailKeys = Object.keys(sessionStorage).filter(key => 
      key.startsWith('account_')
    );
    const preservedData = {};
    accountEmailKeys.forEach(key => {
      preservedData[key] = sessionStorage.getItem(key);
    });
    
    sessionStorage.clear();
    
    // Restore preserved data
    Object.entries(preservedData).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    
    // Update localStorage with new account data - don't store accessToken at root level
    localStorage.setItem('userInfo', JSON.stringify(account.userInfo));
    localStorage.setItem('isSubscribed', account.isSubscribed);
    localStorage.setItem('userPhoto', account.userPhoto);
    localStorage.setItem('userSignature', account.userSignature);
    localStorage.setItem('currentAccount', JSON.stringify(account));
    
    // Dispatch event to notify components of account change
    window.dispatchEvent(new CustomEvent('accountsUpdated', {
      detail: {
        accounts: JSON.parse(localStorage.getItem('accounts') || '[]'),
        currentAccount: account
      }
    }));
    
    return true;
  } catch (error) {
    console.error('Error switching account:', error);
    throw error;
  }
};

// Add useAuth hook
const useAuth = () => {
  const getAccessToken = async () => {
    // Try to get the token from current account first
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    const currentEmail = currentAccount.email;
    
    if (currentEmail) {
      // Try to get account-specific token from session storage first
      const accountToken = sessionStorage.getItem(`accessToken_${currentEmail}`);
      if (accountToken) {
        console.log(`Using cached token for ${currentEmail} from session storage`);
        return accountToken;
      }
      
      // If no token in session storage, try the current account token
      if (currentAccount.accessToken) {
        console.log(`Using token from current account for ${currentEmail}`);
        return currentAccount.accessToken;
      }
    }
    
    // If no token found, try to refresh
    console.log('No token found, attempting to refresh token');
    try {
      const newToken = await refreshAccessToken();
      if (!newToken) {
        console.error('Token refresh failed to return a valid token');
        return null;
      }
      
      console.log('Successfully refreshed token');
      return newToken;
    } catch (error) {
      console.error('Error getting access token:', error);
      
      // If there's a network error, we might want to retry once
      if (error.message && (error.message.includes('network') || error.message.includes('timeout'))) {
        console.log('Network error during token refresh, retrying once...');
        try {
          // Wait a bit before retrying
          await new Promise(resolve => setTimeout(resolve, 1000));
          const retryToken = await refreshAccessToken();
          if (retryToken) {
            console.log('Successfully refreshed token on retry');
            return retryToken;
          }
        } catch (retryError) {
          console.error('Retry also failed:', retryError);
        }
      }
      
      // If we get here, all attempts failed
      window.location.href = '/auth';
      return null;
    }
  };

  const getUserEmail = () => {
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    return currentAccount.email || null;
  };

  return {
    getAccessToken,
    userEmail: getUserEmail()
  };
};

export { axiosWithAuth, refreshAccessToken, saveAccountData, switchAccount, useAuth };