const CACHE_PREFIX = 'lexi_';
const EMAIL_PREVIEW_CACHE = `${CACHE_PREFIX}email_preview_`;
const CHAT_MESSAGES_CACHE = `${CACHE_PREFIX}chat_messages_`;

export const cacheService = {
  // Email Preview Cache
  getEmailPreviewCache: (emailId) => {
    try {
      const cached = sessionStorage.getItem(`${EMAIL_PREVIEW_CACHE}${emailId}`);
      if (!cached) return null;
      
      return JSON.parse(cached);
    } catch (error) {
      console.error('Cache retrieval error:', error);
      return null;
    }
  },

  setEmailPreviewCache: (emailId, data) => {
    try {
      if (!data || !data.content) {
        console.warn('Attempted to cache invalid email preview data');
        return;
      }

      sessionStorage.setItem(`${EMAIL_PREVIEW_CACHE}${emailId}`, JSON.stringify({
        content: data.content,
        cidMap: data.cidMap || {},
        // Store minimal attachment metadata to reduce cache size
        attachments: (data.attachments || []).map(att => ({
          id: att.id,
          contentId: att.contentId,
          name: att.name,
          type: att.type
        }))
      }));
    } catch (error) {
      console.error('Cache setting error:', error);
    }
  },

  clearCache: (type, id) => {
    try {
      if (type === 'email') {
        sessionStorage.removeItem(`${EMAIL_PREVIEW_CACHE}${id}`);
      } else if (type === 'chat') {
        sessionStorage.removeItem(`${CHAT_MESSAGES_CACHE}${id}`);
      }
    } catch (error) {
      console.error('Cache clearing error:', error);
    }
  }
};