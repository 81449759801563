import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Popover, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon, 
  Checkbox, 
  Typography, 
  Divider, 
  TextField, 
  IconButton,
  Chip,
  Button,
  useTheme,
  Fade,
  Tooltip,
  Badge,
  Paper,
  CircularProgress,
  styled
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import FlagIcon from '@mui/icons-material/Flag';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { axiosWithAuth } from './authService';

// Add styled components for custom scrollbars
const ScrollableBox = styled(Box)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
}));

const ScrollablePaper = styled(Paper)(({ theme }) => ({
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
}));

const EmailFilter = ({ 
  onFilterChange, 
  activeFilters, 
  setActiveFilters,
  isMobile 
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [senderFilter, setSenderFilter] = useState('');
  const [uniqueSenders, setUniqueSenders] = useState([]);
  const [filteredSenders, setFilteredSenders] = useState([]);
  const [tempFilters, setTempFilters] = useState({});
  const [loading, setLoading] = useState(false);
  
  // Initialize tempFilters with activeFilters when component mounts or activeFilters changes
  useEffect(() => {
    setTempFilters({...activeFilters});
  }, [activeFilters]);
  
  // Initialize tempFilters with activeFilters when popover opens
  useEffect(() => {
    if (anchorEl) {
      setTempFilters({...activeFilters});
      // Fetch initial senders list when popover opens
      fetchSenders('');
    }
  }, [anchorEl, activeFilters]);
  
  // Fetch senders from the API
  const fetchSenders = async (query) => {
    setLoading(true);
    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + '/recipients', {
        params: { query }
      });
      const senders = response.data.map(recipient => recipient.displayName || recipient.email);
      setUniqueSenders(senders);
      setFilteredSenders(senders);
    } catch (error) {
      console.error('Error fetching senders:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Filter senders based on search input
  useEffect(() => {
    if (senderFilter) {
      fetchSenders(senderFilter);
    } else {
      fetchSenders('');
    }
  }, [senderFilter]);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // Initialize tempFilters immediately when opening the popover
    setTempFilters({...activeFilters});
    // Stop propagation to prevent parent click handlers
    event.stopPropagation();
  };
  
  const handleClose = () => {
    setAnchorEl(null);
    setSenderFilter('');
  };
  
  const handleFilterChange = (filterType, value) => {
    const newFilters = { ...tempFilters };
    
    if (filterType === 'sender') {
      // Handle sender filter (can have multiple)
      if (!newFilters.senders) {
        newFilters.senders = [];
      }
      
      if (newFilters.senders.includes(value)) {
        // Remove sender if already selected
        newFilters.senders = newFilters.senders.filter(sender => sender !== value);
        if (newFilters.senders.length === 0) {
          delete newFilters.senders;
        }
      } else {
        // Add sender
        newFilters.senders.push(value);
      }
    } else {
      // Handle boolean filters (flagged, unread, hasAttachments)
      if (newFilters[filterType]) {
        delete newFilters[filterType];
      } else {
        newFilters[filterType] = true;
      }
    }
    
    setTempFilters(newFilters);
  };
  
  const handleClearFilters = () => {
    setTempFilters({});
  };
  
  const handleApplyFilters = () => {
    // Make a copy of tempFilters to avoid reference issues
    const filtersToApply = {...tempFilters};
    console.log('Applying filters:', filtersToApply);
    setActiveFilters(filtersToApply);
    onFilterChange(filtersToApply);
    handleClose();
  };
  
  const handleRemoveFilter = (filterType, value) => {
    const newFilters = { ...tempFilters };
    
    if (filterType === 'senders') {
      newFilters.senders = newFilters.senders.filter(sender => sender !== value);
      if (newFilters.senders.length === 0) {
        delete newFilters.senders;
      }
    } else {
      delete newFilters[filterType];
    }
    
    setTempFilters(newFilters);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'email-filter-popover' : undefined;
  
  // Count active filters
  const activeFilterCount = Object.keys(activeFilters || {}).reduce((count, key) => {
    if (key === 'senders' && Array.isArray(activeFilters[key])) {
      return count + activeFilters[key].length;
    }
    return count + 1;
  }, 0);
  
  // Count temp filters for the UI
  const tempFilterCount = Object.keys(tempFilters || {}).reduce((count, key) => {
    if (key === 'senders' && Array.isArray(tempFilters[key])) {
      return count + tempFilters[key].length;
    }
    return count + 1;
  }, 0);
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Filter emails" arrow>
          <Badge
            badgeContent={activeFilterCount > 0 ? activeFilterCount : 0}
            color="primary"
            overlap="circular"
            invisible={activeFilterCount === 0}
          >
            <IconButton 
              onClick={handleClick}
              aria-describedby={id}
              size="small"
              color={activeFilterCount > 0 ? "primary" : "default"}
              sx={{ 
                color: activeFilterCount > 0 ? theme.palette.accent.primary : theme.palette.ui.textLight,
                transition: 'all 0.2s ease-in-out',
                padding: 0,
                width: '24px',
                height: '24px',
                '&:hover': {
                  transform: 'scale(1.05)',
                  backgroundColor: 'transparent',
                }
              }}
            >
              <FilterListIcon fontSize="small" />
            </IconButton>
          </Badge>
        </Tooltip>    
      </Box>
      
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            width: isMobile ? '90vw' : '320px',
            maxHeight: '75vh',
            overflow: 'visible',
            mt: 1.5,
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 250 }}
      >
        <Paper elevation={0} sx={{ overflow: 'hidden' }}>
          <Box sx={{ 
            p: 2, 
            pb: 1.5,
            backgroundColor: theme.palette.accent.secondary,
            borderBottom: `1px solid ${theme.palette.divider}`
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
              <Typography variant="h6" fontWeight="600" sx={{ fontSize: '1rem' }}>
                Filter Emails
              </Typography>
              <IconButton 
                size="small" 
                onClick={handleClose}
                sx={{
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'transparent',                    
                    color: theme.palette.accent.primary,
                  }
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            
            <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
              {tempFilterCount > 0 
                ? `${tempFilterCount} active filter${tempFilterCount > 1 ? 's' : ''}` 
                : 'No active filters'}
            </Typography>
          </Box>
          
          <ScrollableBox sx={{ height: '100%', maxHeight: 'calc(75vh - 180px)', overflow: 'auto', backgroundColor: theme.palette.accent.secondary }}>
            <List disablePadding sx={{ pt: 0.5, backgroundColor: theme.palette.accent.secondary }}>
              {/* Status filters */}
              <ListItem 
                disablePadding 
                sx={{ 
                  px: 2, 
                  py: 0.6,
                  backgroundColor: theme.palette.accent.secondary,
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <FlagIcon 
                    color={tempFilters.flagged ? "primary" : "action"} 
                    sx={{ 
                      transition: 'all 0.2s ease',
                      transform: tempFilters.flagged ? 'scale(1.1)' : 'scale(1)',
                      color: tempFilters.flagged ? theme.palette.accent.primary : undefined
                    }}
                  />
                </ListItemIcon>
                <ListItemText 
                  primary="Flagged" 
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    fontWeight: tempFilters.flagged ? 600 : 400,
                    color: tempFilters.flagged ? theme.palette.accent.primary : 'inherit'
                  }}
                />
                <Checkbox 
                  edge="end"
                  checked={!!tempFilters.flagged}
                  onChange={() => handleFilterChange('flagged')}
                  sx={{
                    color: theme.palette.accent.primary,
                    '&.Mui-checked': {
                      color: theme.palette.accent.primary,
                    },
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    }
                  }}
                />
              </ListItem>
              
              <ListItem 
                disablePadding 
                sx={{ 
                  px: 2, 
                  py: 0.6,
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <MarkEmailUnreadIcon 
                    color={tempFilters.unread ? "primary" : "action"} 
                    sx={{ 
                      transition: 'all 0.2s ease',
                      transform: tempFilters.unread ? 'scale(1.1)' : 'scale(1)',
                      color: tempFilters.unread ? theme.palette.accent.primary : undefined
                    }}
                  />
                </ListItemIcon>
                <ListItemText 
                  primary="Unread" 
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    fontWeight: tempFilters.unread ? 600 : 400,
                    color: tempFilters.unread ? theme.palette.accent.primary : 'inherit'
                  }}
                />
                <Checkbox 
                  edge="end"
                  checked={!!tempFilters.unread}
                  onChange={() => handleFilterChange('unread')}
                  sx={{
                    color: theme.palette.accent.primary,
                    '&.Mui-checked': {
                      color: theme.palette.accent.primary,
                    },
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    }
                  }}
                />
              </ListItem>
              
              <ListItem 
                disablePadding 
                sx={{ 
                  px: 2, 
                  py: 0.6,
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <AttachFileIcon 
                    color={tempFilters.hasAttachments ? "primary" : "action"} 
                    sx={{ 
                      transition: 'all 0.2s ease',
                      transform: tempFilters.hasAttachments ? 'scale(1.1)' : 'scale(1)',
                      color: tempFilters.hasAttachments ? theme.palette.accent.primary : undefined
                    }}
                  />
                </ListItemIcon>
                <ListItemText 
                  primary="Has Attachments" 
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    fontWeight: tempFilters.hasAttachments ? 600 : 400,
                    color: tempFilters.hasAttachments ? theme.palette.accent.primary : 'inherit'
                  }}
                />
                <Checkbox 
                  edge="end"
                  checked={!!tempFilters.hasAttachments}
                  onChange={() => handleFilterChange('hasAttachments')}
                  sx={{
                    color: theme.palette.accent.primary,
                    '&.Mui-checked': {
                      color: theme.palette.accent.primary,
                    },
                    transition: 'transform 0.2s ease',
                    '&:hover': {
                      transform: 'scale(1.1)',
                    }
                  }}
                />
              </ListItem>
              
              <Divider sx={{ my: 1, mx: 2 }} />
              
              {/* Sender filter */}
              <ListItem disablePadding sx={{ flexDirection: 'column', alignItems: 'flex-start', px: 2, py: 0.8 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
                  <ListItemIcon sx={{ minWidth: '36px' }}>
                    <PersonIcon color="action" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Filter by Sender" 
                    primaryTypographyProps={{ 
                      variant: 'body2',
                      fontWeight: 500
                    }}
                  />
                </Box>
                
                <TextField
                  placeholder="Search senders..."
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={senderFilter}
                  onChange={(e) => setSenderFilter(e.target.value)}
                  InputProps={{
                    startAdornment: <SearchIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />,
                    endAdornment: senderFilter ? (
                      <IconButton 
                        size="small" 
                        onClick={() => setSenderFilter('')}
                        edge="end"
                        sx={{
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            color: theme.palette.error.main,
                          }
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ) : null,
                    sx: {
                      borderRadius: '6px',
                      '&.Mui-focused': {
                        boxShadow: `0 0 0 2px ${theme.palette.accent.primary}30`,
                      }
                    }
                  }}
                  sx={{ 
                    mb: 1,
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.15)',
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.accent.primary,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: theme.palette.accent.primary,
                      },
                    }
                  }}
                />
                
                <ScrollablePaper 
                  elevation={0} 
                  variant="outlined"
                  sx={{ 
                    maxHeight: '180px', 
                    backgroundColor: theme.palette.accent.secondary,
                    overflow: 'auto', 
                    width: '100%',
                    borderRadius: '6px',
                    borderColor: 'rgba(0, 0, 0, 0.12)'
                  }}
                >
                  <List dense disablePadding>
                    {loading ? (
                      <ListItem sx={{ py: 2, justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                      </ListItem>
                    ) : filteredSenders.length > 0 ? (
                      filteredSenders.map((sender) => (
                        <ListItem 
                          key={sender} 
                          disablePadding 
                          dense
                          onClick={() => handleFilterChange('sender', sender)}
                          sx={{ 
                            cursor: 'pointer',
                            transition: 'background-color 0.2s ease',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            pl: 1,
                            borderLeft: tempFilters.senders?.includes(sender) 
                              ? `3px solid ${theme.palette.accent.primary}` 
                              : '3px solid transparent',
                          }}
                        >
                          <Checkbox
                            edge="start"
                            checked={tempFilters.senders?.includes(sender) || false}
                            tabIndex={-1}
                            disableRipple
                            size="small"
                            sx={{
                              color: theme.palette.accent.primary,
                              '&.Mui-checked': {
                                color: theme.palette.accent.primary,
                              },
                            }}
                          />
                          <ListItemText 
                            primary={sender} 
                            primaryTypographyProps={{ 
                              variant: 'body2',
                              noWrap: true,
                              fontWeight: tempFilters.senders?.includes(sender) ? 600 : 400,
                              color: tempFilters.senders?.includes(sender) ? theme.palette.accent.primary : 'inherit'
                            }}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem sx={{ py: 2 }}>
                        <ListItemText 
                          primary="No senders found" 
                          primaryTypographyProps={{ 
                            variant: 'body2',
                            color: 'text.secondary',
                            align: 'center'
                          }}
                        />
                      </ListItem>
                    )}
                  </List>
                </ScrollablePaper>
              </ListItem>
            </List>
          </ScrollableBox>
          
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            p: 2,
            pt: 1.5,            
            gap: 1,
            borderTop: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.accent.secondary,
            position: 'sticky',
            bottom: 0,
            zIndex: 1
          }}>
            <Button 
              variant="outlined" 
              size="small" 
              onClick={handleClearFilters}
              disabled={tempFilterCount === 0}
              sx={{ 
                textTransform: 'none',
                borderColor: theme.palette.accent.primary,
                color: theme.palette.accent.primary,
                borderRadius: '8px',
                fontWeight: 500,
                px: 2,
                '&:hover': {
                  borderColor: theme.palette.accent.primary,
                  backgroundColor: `${theme.palette.accent.primary}10`,
                },
                '&.Mui-disabled': {
                  borderColor: 'rgba(0, 0, 0, 0.12)',
                }
              }}
            >
              Clear All
            </Button>
            <Button 
              variant="contained" 
              size="small" 
              onClick={handleApplyFilters}
              sx={{ 
                textTransform: 'none',
                backgroundColor: theme.palette.accent.darkGreen,
                color: theme.palette.accent.secondary,
                borderRadius: '8px',
                fontWeight: 500,
                px: 2,
                boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
                '&:hover': {
                  backgroundColor: theme.palette.accent.primary,
                  opacity: 0.9,
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                }
              }}
            >
              Apply
            </Button>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default EmailFilter; 