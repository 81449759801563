import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import { axiosWithAuth } from './authService';
import EmailIcon from '@mui/icons-material/Email';
import { Mail } from 'lucide-react';

const formatDateTime = (dateString) => {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ''; // Return empty string if date is invalid

  const now = new Date();
  
  // Reset hours, minutes, seconds, and milliseconds to compare just the dates
  const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const compareDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  
  const diffTime = nowDate - compareDate;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const timeString = date.toLocaleTimeString('da-DK', { hour: '2-digit', minute: '2-digit', hour12: false });

  if (diffDays === 0) {
    return `i dag ${timeString}`;
  } else if (diffDays === 1) {
    return `i går ${timeString}`;
  } else if (diffDays > 1 && diffDays <= 7) {
    return `${date.toLocaleDateString('da-DK', { weekday: 'long' })} ${timeString}`;
  } else if (diffDays > 7 && diffDays <= 365) {
    return `${date.toLocaleDateString('da-DK', { day: 'numeric', month: 'long' })} ${timeString}`;
  } else {
    return `${date.toLocaleDateString('da-DK', { year: 'numeric', day: 'numeric', month: 'long' })} ${timeString}`;
  }
};

const EmailPreviewCard = ({ emailId, onOpenPreview }) => {
  const theme = useTheme();
  const [emailData, setEmailData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmailData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-single-email/${emailId}`);
        console.log('Email id:', emailId);
        console.log('Email preview data:', response.data);
        setEmailData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching email preview data:', error);
        setError('Failed to load email preview');
        setIsLoading(false);
      }
    };

    fetchEmailData();
  }, [emailId]);

  if (error) {
    return null;
  }

  return (
    <Box
      onClick={() => !isLoading && onOpenPreview(emailId)}
      sx={{
        minWidth: {
          xs: '280px', // smaller width on mobile
          sm: '350px'  // original width on larger screens
        },
        maxWidth: {
          xs: '280px', // smaller width on mobile
          sm: '350px'  // original width on larger screens
        },
        background: theme.palette.accent.secondary, 
        borderRadius: '5px',
        padding: {
          xs: '12px',  // smaller padding on mobile
          sm: '16px'   // original padding on larger screens
        },
        border: '1px solid #e0e0e0',
        cursor: isLoading ? 'default' : 'pointer',
        transition: 'all 0.2s ease',
        WebkitTapHighlightColor: 'transparent', // Remove tap highlight on mobile
        touchAction: 'manipulation', // Optimize for touch interactions
        '&:active': {
          transform: 'scale(0.98)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '48px' }}>
          <Typography 
            color="ui.textLight"
            sx={{ 
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            Loading email...
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: { xs: 1, sm: 2 } }}>
            <Typography 
              variant="subtitle1" 
              component="div" 
              sx={{ 
                fontWeight: 'bold', 
                color: 'ui.textDark',
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }}
            >
              {emailData.fromName || emailData.fromAddress}
            </Typography>
            <Mail size={20} color={theme.palette.ui.textDark} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              component="div"
              sx={{
                color: 'ui.textDark',
                fontWeight: 'normal',
                fontSize: { xs: '0.813rem', sm: '0.95rem' },
                flexGrow: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 'calc(100% - 80px)', // Leave space for the timestamp
                display: 'block', // Ensure the text container is block-level
              }}
            >
              {emailData.subject || 'No subject'} 
            </Typography>
            <Typography 
              variant="body2" 
              component="div" 
              sx={{ 
                color: 'ui.textLight', 
                flexShrink: 0, 
                ml: 2,
                fontSize: { xs: '0.75rem', sm: '0.875rem' }
              }}
            >
              {formatDateTime(emailData.date)}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EmailPreviewCard;