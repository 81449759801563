const EMAIL_PROCESSING_KEY = 'emailProcessingStatus';

export const isEmailProcessing = () => {
    const status = localStorage.getItem(EMAIL_PROCESSING_KEY);
    if (!status) return false;
    
    const { processing, timestamp, processed_count, lastCountUpdate, status: currentStatus, statusTimestamp } = JSON.parse(status);
    
    const now = Date.now();
    // Check if processed_count hasn't changed for 3 minutes
    if (processed_count !== undefined && lastCountUpdate && now - lastCountUpdate > 3 * 60 * 1000) {
        clearEmailProcessingStatus();
        return false;
    }
    
    // Check if status has been "pending" for more than 3 minutes
    if (currentStatus === 'pending' && now - statusTimestamp > 3 * 60 * 1000) {
        clearEmailProcessingStatus();
        return false;
    }

    return processing;
};

export const setEmailProcessingStatus = (jobId = null, processed_count = 0, status = 'pending') => {
    const now = Date.now();
    const currentData = localStorage.getItem(EMAIL_PROCESSING_KEY);
    const currentState = currentData ? JSON.parse(currentData) : {};
    
    const newState = {
        processing: true,
        timestamp: now,
        jobId,
        status,
        statusTimestamp: currentState.status !== status ? now : currentState.statusTimestamp,
        processed_count,
        lastCountUpdate: currentState.processed_count !== processed_count ? now : currentState.lastCountUpdate
    };

    localStorage.setItem(EMAIL_PROCESSING_KEY, JSON.stringify(newState));
};

export const clearEmailProcessingStatus = () => {
    localStorage.removeItem(EMAIL_PROCESSING_KEY);
};

export const getProcessingJobId = () => {
    const status = localStorage.getItem(EMAIL_PROCESSING_KEY);
    if (!status) return null;
    const { jobId } = JSON.parse(status);
    return jobId;
};
