import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Paper, Typography, Box, Menu, MenuItem, Button, Fade, CircularProgress, IconButton, TextField, Tooltip, Slide, Popover } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import ImageResize from 'quill-image-resize-module-react';
import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
// MUI Icons
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import SubjectIcon from '@mui/icons-material/Subject';
import GroupIcon from '@mui/icons-material/Group';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SignatureIcon from '@mui/icons-material/Draw';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';

// Local imports
import { axiosWithAuth } from './authService';
import FileComponent from './FileComponent';
import ShimmerEffect from './ShimmerEffectEmailPreview';
import EmailPreviewComponent from './EmailPreviewComponent';
import {
  parseEmailString,
  createEmailObject,
  applyInlineStyles,
  debounce,
  removeSignature,
  appendSignatureIfMissing,
} from './emailUtils';
import {
  StyledPaper, ToolbarContainer, GradientHeader, ContentWrapper, 
  FieldBox, FieldIcon, SecondaryColoredIcon, StyledTextField, 
  CustomButtons, CustomToolbar, QuillContainer, CustomAIbutton, CustomButtonsContainer,
  CustomIcon, StyledReactQuill, StyledMenu, StyledMenuItem, ToolbarButton,
  MobileToolbar, StyledAutocomplete, StyledChip, CcButton,
  AttachmentScroll, FileWrapper, RemoveButton, DragOverlay,
  TransparentBackdrop, StyledBackdrop, ButtonContainer, DeleteButton,
  DraftStatus, CloseButton, OriginalEmailContainer, OriginalEmailHeader,
  OriginalEmailContent, AIButtonsGroup, AIButton, ThinkingLabel,
  ReasoningDialog, ReasoningDialogContent, ReasoningDialogHeader,
  BodyBox, ReadOnlyContent, GradientButton, CcToggleContainer
} from './EmailComponentStyles';

Quill.register('modules/imageResize', ImageResize);

// Add quillFormats constant before the EmailComponent
const quillFormats = [
  'bold', 'italic', 'underline',
  'link', 'image',
  'color', 'background',
  'width', 'height',  // Important for image resizing
  'align', 'float'    // For image positioning
];

// Add Transition component before EmailComponent
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmailComponent = ({ emailData = {}, sendStatus: initialSendStatus = 'idle', onClose, toFieldRef, mode='send', isDraft=false, isOpenedFromDraftPreview=false, initilizeBody=false, reasoningContent=null, previewId, messages, thread_id, selectedEmailFromEmailList, email_content, onModeChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sendStatus, setSendStatus] = useState(initialSendStatus);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [isBodyLoading, setIsBodyLoading] = useState(false);
  const bodyProcessedRef = useRef(false);
  const quillRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCc, setShowCc] = useState(false);
  const fileInputRef = useRef(null);
  const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [isDraftSaved, setIsDraftSaved] = useState(false);
  const [lastSavedDraft, setLastSavedDraft] = useState(null);
  const [draftId, setDraftId] = useState(null);   
  const [toInputValue, setToInputValue] = useState('');
  const [currentMode, setCurrentMode] = useState(mode); // Add state to track current mode
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isKeyPressed, setIsKeyPressed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const componentRef = useRef(null);
  const [isReadOnly, setIsReadOnly] = useState(false);   
  const [hasReceivedFirstChunk, setHasReceivedFirstChunk] = useState(false); 
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userInfo')));
  const bodyRef = useRef(null);
  const draftIdRef = useRef(null);
  const instanceId = useRef(emailData.id || uuid()).current;
  const [hasSavedDraft, setHasSavedDraft] = useState(false);
  const isComponentMounted = useRef(true);
  const [toFieldFocused, setToFieldFocused] = useState(false);
  const [ccFieldFocused, setCcFieldFocused] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isStreamingReasoning, setIsStreamingReasoning] = useState(false);
  const streamingStartedRef = useRef(false);
  const [isReasoningDialogOpen, setIsReasoningDialogOpen] = useState(false);
  const [reasoningText, setReasoningText] = useState('');
  const [isThinking, setIsThinking] = useState(false);
  const [isGeneratingSubject, setIsGeneratingSubject] = useState(false);
  const [isGeneratingBody, setIsGeneratingBody] = useState(false);
  const [reasoningAnchorEl, setReasoningAnchorEl] = useState(null);
  const reasoningButtonRef = useRef(null);
  const reasoningContentRef = useRef(null);
  const [selectedSender, setSelectedSender] = useState(null);
  const [senderMenuAnchorEl, setSenderMenuAnchorEl] = useState(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState('');
  const draftSaveTimeoutRef = useRef(null);
  // Add new state for aliases
  const [userAliases, setUserAliases] = useState([]);
  const [isLoadingAliases, setIsLoadingAliases] = useState(false);

  console.log('Email data in EmailComponent:', emailData);

  const [editedEmail, setEditedEmail] = useState(() => ({
    From: [],
    Recipients: [],
    ccRecipients: [],
    Subject: '',
    attachments: [],
    id: instanceId,
    thread_id: null,
    Body: ''
  }));

  const quillModules = useMemo(() => ({
    toolbar: {
      container: isMobile ? undefined : '#custom-toolbar',
      handlers: {
        // Add any custom handlers here if needed
      }
    },
    clipboard: {
      matchVisual: false,
      onCapture: () => {
        return false;
      },
      onPaste: (e) => {
        e.preventDefault();
        
        const clipboardData = e.dataTransfer || window.clipboardData;
        if (!clipboardData) return;

        // Handle images for mobile
        if (isMobile && clipboardData.items) {
          const items = Array.from(clipboardData.items);
          items.forEach(item => {
            if (item.type.indexOf('image') === 0) {
              const blob = item.getAsFile();
              if (blob) {
                const reader = new FileReader();
                reader.onload = (event) => {
                  if (event.target?.result && quillRef.current) {
                    const editor = quillRef.current.getEditor();
                    const range = editor.getSelection();
                    if (range) {
                      editor.insertEmbed(range.index, 'image', event.target.result);
                    }
                  }
                };
                reader.readAsDataURL(blob);
              }
            }
          });
        }

        // Handle text content
        const text = clipboardData.getData('text/plain');
        if (text && quillRef.current) {
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          if (range) {
            editor.insertText(range.index, text);
          }
        }

        return false;
      }
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
      handleStyles: {
        backgroundColor: '#666',
        border: '1px solid white',
        color: 'white',
        width: '6px',
        height: '6px',
        cursor: 'pointer'
      },
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
        padding: '3px 6px',
        fontSize: '11px'
      },
      minWidth: 20,
      minHeight: 20,
    }
  }), [isMobile]);

  useEffect(() => {
    if (isInitialized && !emailData?.id) return; // Skip if already initialized and no new emailData

    const initializeEmail = async () => {
      console.log('Initializing email with draft status:', isDraft);
      console.log('Initial email data:', emailData);
      
      const storedData = sessionStorage.getItem(`emailComponent_${instanceId}`);
      console.log('Stored session data:', storedData);
      
      let initialData;
      
      if (storedData) {
        initialData = JSON.parse(storedData);
        console.log('Using stored data:', initialData);
      } else if (emailData) {
        console.log('Initializing with email data', emailData);
        
        // Format the body text
        let formattedBody = '';
        if (isDraft) {
          // For drafts, always use the body content as-is
          formattedBody = emailData.body || '';
        } else if (initilizeBody && emailData.body) {
          // For non-drafts, only format if initilizeBody is true
          formattedBody = emailData.body
            .replace(/\\n/g, '\n')
            .split('\n')
            .map(line => line.trim())
            .join('<br>');

          // Append signature only for non-drafts when initilizeBody is true
          const signature = localStorage.getItem('userSignature');
          if (signature) {
            const cleanSignature = DOMPurify.sanitize(signature);
            formattedBody = `${formattedBody}<p><br></p><p><br></p>${cleanSignature}`;
          }
        }

        // Create complete initial data object with proper data mapping
        initialData = {
          From: emailData.fromEmail ? [{ 
            email: emailData.fromEmail, 
            displayName: emailData.fromName 
          }] : [],
          Recipients: isDraft ? 
            (emailData.recipients ? parseEmailString(emailData.recipients) : []) :
            (currentMode === 'reply' || currentMode === 'replyAll' ? 
              [{ email: emailData.fromEmail, displayName: emailData.fromName }] : 
              (emailData.to ? emailData.to.map(email => ({ email, displayName: email })) : [])),
          ccRecipients: isDraft ? 
            (emailData.ccRecipients ? parseEmailString(emailData.ccRecipients) : []) :
            (emailData.cc ? emailData.cc.map(email => ({ email, displayName: email })) : []),
          Subject: isDraft ? 
            emailData.subject : 
            (emailData.subject || ''),
          attachments: emailData.attachments || [],
          id: instanceId,
          thread_id: emailData.thread_id || null,
          Body: formattedBody || editedEmail.Body,
          lastModified: new Date().toISOString()
        };
      } else {
        // Default empty state
        initialData = {
          From: [],
          Recipients: [],
          ccRecipients: [],
          Subject: '',
          attachments: [],
          id: instanceId,
          thread_id: null,
          Body: '',
          lastModified: new Date().toISOString()
        };
      }
    
      // Only update if the content has actually changed
      if (JSON.stringify(initialData) !== JSON.stringify(editedEmail)) {
        setEditedEmail(initialData);
        saveToSessionStorage(initialData);
      }
      setIsInitialized(true);
    };
  
    initializeEmail();    
  }, [instanceId, isDraft, currentMode, initilizeBody, emailData]);

  // update email on modes
  useEffect(() => {
    // Don't update if it's a draft
    if (isDraft) {
      console.log('Skipping recipient updates for draft');
      return;
    }
      
    const filterOutUserEmail = (recipients) => {
      if (!Array.isArray(recipients)) return [];
      return recipients.filter(recipient => {
        const email = typeof recipient === 'string' ? recipient : recipient.email;
        return email !== userInfo.userPrincipalName;
      });
    };
  
    // Remove the useMemo hook and calculate the subject directly
    let formattedSubject = emailData.subject || '';
    
    if (currentMode === 'reply' || currentMode === 'replyAll') {
      if (formattedSubject && !formattedSubject.startsWith('Re:')) {
        formattedSubject = `Re: ${formattedSubject}`;
      }
    } else if (currentMode === 'forward') {
      if (formattedSubject && !formattedSubject.startsWith('Fwd:')) {
        formattedSubject = `Fwd: ${formattedSubject}`;
      }
    }
  
    setEditedEmail(prev => {
      // Keep existing data for drafts
      if (isDraft) return prev;

      let newRecipients = prev.Recipients;
      let newCcRecipients = prev.ccRecipients;
      
      switch (currentMode) {
        case 'reply':
          newRecipients = [{ 
            email: emailData.fromEmail,
            displayName: emailData.fromName
          }];
          newCcRecipients = [];
          if (!formattedSubject.toLowerCase().startsWith('re:')) {
            formattedSubject = `RE: ${formattedSubject}`;
          }
          break;
  
        case 'replyAll':
          newRecipients = [{ 
            email: emailData.fromEmail,
            displayName: emailData.fromName
          }];
          
          if (emailData.recipients) {
            const originalRecipients = emailData.recipients.map(email => ({
              email,
              displayName: email
            }));
            newRecipients = [...newRecipients, ...originalRecipients];
          }
          
          newCcRecipients = (emailData.ccRecipients || []).map(email => ({
            email,
            displayName: email
          }));
          
          newRecipients = filterOutUserEmail(newRecipients);
          newCcRecipients = filterOutUserEmail(newCcRecipients);
          if (!formattedSubject.toLowerCase().startsWith('re:')) {
            formattedSubject = `RE: ${formattedSubject}`;
          }
          break;
  
        case 'forward':
          newRecipients = [];
          newCcRecipients = [];
          if (!formattedSubject.toLowerCase().startsWith('fw:')) {
            formattedSubject = `FW: ${formattedSubject}`;
          }
          break;
      }
  
      return {
        ...prev,
        Recipients: newRecipients,
        ccRecipients: newCcRecipients,
        Subject: formattedSubject
      };
    });
  }, [currentMode, emailData, userInfo, isDraft]);

  useEffect(() => {
      if (isDraft && emailData?.id) {
          console.log('Updating draft id to', emailData?.id);
          setDraftId(emailData?.id);
          draftIdRef.current = emailData?.id;
      }
  }, [isDraft, emailData]);

// 
useEffect(() => {
  if (editedEmail.ccRecipients && editedEmail.ccRecipients.length > 0) {
    setShowCc(true);
  }
  else {
    setShowCc(false);
  }
}, [editedEmail, emailData]);
  
const saveToSessionStorage = (data) => {
  const storageKey = `emailComponent_${instanceId}`;
  
  // Create a copy of the data to modify
  const storageData = {...data};
  
  // Convert File objects to storable format
  if (storageData.attachments) {
    storageData.attachments = storageData.attachments.map(attachment => {
      if (attachment instanceof File) {
        return {
          id: attachment.id, // Keep ID if it exists
          name: attachment.name,
          size: attachment.size,
          type: attachment.type,
          lastModified: attachment.lastModified,
          isTemporaryFile: true
        };
      }
      return attachment;
    });
  }
  
  sessionStorage.setItem(storageKey, JSON.stringify(storageData));
};

const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

const handleMenuClose = () => {
    setAnchorEl(null);
  };


useEffect(() => {
  if (isDraft && emailData?.id) {
      console.log('Updating draft id to', emailData?.id);
      setDraftId(emailData?.id);
      draftIdRef.current = emailData?.id;
  }
}, [isDraft, emailData]);

const handleDrag = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
}, []);

const handleDragIn = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
  dragCounter.current++;
  if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
    setIsDragging(true);
  }
}, []);

const handleDragOut = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
  dragCounter.current--;
  if (dragCounter.current === 0) {
    setIsDragging(false);
  }
}, []);

const handleDrop = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
  setIsDragging(false);
  dragCounter.current = 0;
  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    const files = Array.from(e.dataTransfer.files);
    
    // Create file objects with preview URLs
    const filesWithUrls = files.map(file => ({
      file: file,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      previewUrl: URL.createObjectURL(file)
    }));

    // Single state update with optimistic UI
    setEditedEmail(prev => {
      const updatedEmail = {
        ...prev,
        attachments: [...(prev.attachments || []), ...filesWithUrls]
      };
      
      // Save to session storage immediately to prevent flicker
      saveToSessionStorage(updatedEmail);
      return updatedEmail;
    });

    e.dataTransfer.clearData();
  }
}, [setEditedEmail, saveToSessionStorage]);

useEffect(() => {
  const div = document.getElementById('email-component-drop-zone');
  if (div) {
    div.addEventListener('dragenter', handleDragIn);
    div.addEventListener('dragleave', handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', handleDrop);

    return () => {
      div.removeEventListener('dragenter', handleDragIn);
      div.removeEventListener('dragleave', handleDragOut);
      div.removeEventListener('dragover', handleDrag);
      div.removeEventListener('drop', handleDrop);
    };
  }
}, [handleDragIn, handleDragOut, handleDrag, handleDrop]);

// Update handleBodyKeyDown to only handle Tab navigation
const handleBodyKeyDown = (event) => {
  if (event.target.placeholder === "Subject" && event.key === 'Tab' && !event.shiftKey) {
    event.preventDefault();
    const quillEditor = quillRef.current.getEditor();
    quillEditor.focus();
  }
};

// Replace the existing useEffect for focus management
useEffect(() => {
  // Skip if component is not fully mounted
  if (!toFieldRef?.current || !quillRef?.current) {
    return;
  }

  // Set initial focus based on mode
  if (currentMode === 'send') {
    // For new emails, focus on recipients field
    const toField = toFieldRef?.current;
    if (toField) {
      toField.focus();
    }
  } else {
    // For reply/forward, focus at the beginning of the body
    const editor = quillRef.current.getEditor();
    editor.focus();
    editor.setSelection(0, 0);
  }
}, [currentMode, toFieldRef, quillRef]);

const renderAttachments = () => {
  if (!editedEmail.attachments || editedEmail.attachments.length === 0) return null;

  return (
    <AttachmentScroll>
      {editedEmail.attachments.map((attachment, index) => {
        // Get the appropriate URL for preview
        let fileUrl = null;
        if (attachment.file instanceof File) {
          fileUrl = attachment.previewUrl;
        } else if (attachment.url) {
          fileUrl = attachment.url;
        }

        // Create a file object with all necessary properties
        const fileWithUrl = {
          ...attachment,
          url: fileUrl,
          name: attachment.name || attachment.fileName,
          type: attachment.type || attachment.mimeType,
          size: attachment.size
        };

        return (
          <FileWrapper key={index}>
            <FileComponent 
              file={fileWithUrl}
              onDownload={() => handleDownload(attachment)}            
              clickable={true}
            />
            <RemoveButton
              size="medium"
              onClick={() => handleRemoveAttachment(index)}
              disabled={!isEditing}
            >
              <CloseIcon fontSize="small" />
            </RemoveButton>
          </FileWrapper>
        );
      })}
    </AttachmentScroll>
  );
};

  const handleDownload = useCallback(async (file) => {
    console.log('Downloading attachment:', file);
    try {      
      if (file.file instanceof File) {
        // For newly added files
        const url = URL.createObjectURL(file.file);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (file.isTemporaryFile) {
        // For files loaded from session storage
        console.log('Downloading temporary file from session storage');
        const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + 
          `/download-attachment/${draftId}/${file.id}`,
          {
            responseType: 'blob'
          }
        );
        
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (file.id && editedEmail?.id) {
        // Handle existing attachments from drafts/emails
        const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + 
          `/download-attachment/${editedEmail?.id}/${file.id}`,
          {
            responseType: 'blob'
          }
        );
        
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error('Invalid file object:', file);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }, [editedEmail?.id, draftId]);

// callback to avoid unnecessary re-renders    
const getOptionLabel = (option) => {
  if (typeof option === 'string') {
    return option;
  }
  return option.displayName || option.emailAddress?.address || option.email || '';
};

const renderTags = (value, getTagProps, fieldType = 'to') => {
  const isFocused = fieldType === 'to' ? toFieldFocused : ccFieldFocused;
  
  if (!isFocused && value.length > 1) {
    const firstOption = value[0];
    const firstLabel = typeof firstOption === 'string' 
      ? firstOption 
      : (firstOption.displayName || firstOption.email || firstOption.emailAddress?.address || '');
    const firstTooltip = typeof firstOption === 'string'
      ? firstOption
      : [
          firstOption.displayName,
          firstOption.email || firstOption.emailAddress?.address
        ].filter(Boolean).join(' - ');

    const { key, ...tagProps } = getTagProps({ index: 0 });
    return [
      <Tooltip key={key} title={firstTooltip} arrow>
        <StyledChip
          style={{marginRight: '6px'}}
          label={firstLabel}
          {...tagProps}
        />
      </Tooltip>,
      <StyledChip
        key="more"
        style={{marginRight: '6px'}}
        label={`+${value.length - 1} more`}
      />
    ];
  }

  return value.map((option, index) => {
    let label = '';
    let tooltip = '';
    if (option) {
      if (typeof option === 'string') {
        label = option;
        tooltip = option;
      } else {
        label = option.displayName || option.email || option.emailAddress?.address || '';
        tooltip = [
          option.displayName,
          option.email || option.emailAddress?.address
        ].filter(Boolean).join(' - ');
      }
    }
    const { key, ...tagProps } = getTagProps({ index });
    return (
      <Tooltip key={key} title={tooltip} arrow>
        <StyledChip
          style={{marginRight: '6px'}}
          label={label}
          {...tagProps}
        />
      </Tooltip>
    );
  });
};

const renderOption = (props, option) => (
    <li {...props}>
        <Typography variant="body2">
        {option.displayName || option.email} 
        {option.displayName && option.email && (
            <Typography component="span" variant="caption" color="textSecondary">
            {" "}({option.email})
            </Typography>
        )}
        </Typography>
    </li>
    );

// Add debounced save draft function
const debouncedSaveDraft = useCallback(
  debounce(async (emailData) => {
    console.log('Debounced save draft triggered', emailData);
    if (!isDraft) {
      console.log('Skipping draft save - not a draft or component unmounted');
      return;
    }
    console.log('Saving draft in debouncedSaveDraft');
    try {
      setIsDraftSaving(true);
      
      const formData = new FormData();
      
      // Get the latest body content directly from the editor
      const bodyContent = quillRef.current?.getEditor()?.root.innerHTML || emailData.Body || '';
      
      // Create email data for server
      const emailDataForServer = {
        ...emailData,
        Body: bodyContent,
        attachments: emailData.attachments.map(attachment => {
          if (attachment.file instanceof File) {
            return {
              name: attachment.name,
              size: attachment.size,
              type: attachment.type,
              isNew: true,
              lastModified: attachment.lastModified,
              previewUrl: attachment.previewUrl
            };
          }
          return {
            ...attachment,
            isTemporaryFile: true
          };
        })
      };

      formData.append('emailData', JSON.stringify(emailDataForServer));

      // Append actual File objects for new attachments
      emailData.attachments.forEach((attachment, index) => {
        if (attachment.file instanceof File) {
          formData.append(`attachment${index}`, attachment.file);
        }
      });

      // Keep track of existing attachments
      const attachmentsToKeep = emailData.attachments
        .filter(attachment => !(attachment.file instanceof File))
        .map(attachment => attachment.id)
        .filter(Boolean);
      
      formData.append('attachmentsToKeep', JSON.stringify(attachmentsToKeep));
      formData.append('isDraft', 'true'); // Ensure this is a string

      if (draftIdRef.current) {
        formData.append('draftId', draftIdRef.current);
      }

      console.log('Saving draft with ID:', draftIdRef.current);
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/save-draft', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Draft save response:', response.data);
      if (response.status === 200 && response.data.id) {
        // Update draft ID if it's a new draft
        if (!draftIdRef.current) {
          console.log('Setting new draft ID:', response.data.id);
          setDraftId(response.data.id);
          draftIdRef.current = response.data.id;
        }
        
        setLastSavedDraft(new Date());
        setIsDraftSaved(true);
        
        // Hide the "Draft saved" indicator after 3 seconds
        setTimeout(() => {
          setIsDraftSaved(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error saving draft:', error);
    } finally {
      setIsDraftSaving(false);
    }
  }, 2000),
  [isDraft]
);

// Add function to fetch email body for drafts
const fetchEmailBody = useCallback(async (emailId) => {
  if (!emailId) return;
  
  try {
    setIsBodyLoading(true);
    const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-email-body/${emailId}`);
    
    if (response.data && response.data.bodyContent) {
      // Directly update the editor content if quillRef is available
      if (quillRef.current && quillRef.current.getEditor) {
        const editor = quillRef.current.getEditor();
        if (editor) {
          editor.root.innerHTML = DOMPurify.sanitize(response.data.bodyContent);
        }
      }
      
      // Update state
      setEditedEmail(prev => {
        const updated = {
          ...prev,
          Body: response.data.bodyContent
        };
        saveToSessionStorage(updated);
        bodyRef.current = response.data.bodyContent;
        return updated;
      });
      
      setHasReceivedFirstChunk(true);
      bodyProcessedRef.current = true;
    }
  } catch (error) {
    console.error('Error fetching email body:', error);
  } finally {
    setIsBodyLoading(false);
  }
}, []);

// Update useEffect to fetch email body for drafts
useEffect(() => {
  if (isDraft && emailData?.id && !bodyProcessedRef.current) {
    console.log('Fetching email body for draft:', emailData.id);
    fetchEmailBody(emailData.id);
  }
}, [isDraft, emailData?.id, fetchEmailBody]);


const fetchRecipients = useCallback(async (query) => {
    if (!query) {
      setRecipients([]);
      return;
    }
    setLoading(true);
    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + '/recipients', {
        params: { query }
      });
      setRecipients(response.data);
    } catch (error) {
      console.error('Error fetching recipients:', error);
    } finally {
      setLoading(false);
    }
  }, []);

const debouncedFetchRecipients = useCallback(
  debounce((query) => fetchRecipients(query), 100),
  [fetchRecipients]
);

const handleToInputChange = (event, newInputValue, reason) => {
    setToInputValue(newInputValue);
    if (reason === 'input') {
        debouncedFetchRecipients(newInputValue);
    }
};

const removeDuplicates = (array) => {
  const uniqueEmails = new Set();
  return array.filter(item => {
      const email = item.email || item.emailAddress?.address || item;
      if (!uniqueEmails.has(email)) {
          uniqueEmails.add(email);
          return true;
      }
      return false;
  });
};

const handleToChange = useCallback((event, newValue) => {
  const formattedNewValue = newValue.map(item => {
      if (typeof item === 'string') {
          return { email: item.trim(), displayName: item.trim() };
      }
      return { 
          email: item.email || item.emailAddress?.address || item.displayName,
          displayName: item.displayName || item.email || item.emailAddress?.address
      };
  });

  const uniqueRecipients = removeDuplicates(formattedNewValue);
  console.log('Recipients changed to:', uniqueRecipients);
  
  setEditedEmail(prev => {
    const updated = {
      ...prev,
      Recipients: uniqueRecipients,
      lastModified: new Date().toISOString()
    };
    saveToSessionStorage(updated);
    
    // Ensure we're calling the debounced function correctly
    if (isDraft) {
      console.log('Triggering debounced save from recipients change');
      debouncedSaveDraft(updated);
    }
    
    return updated;
  });
}, [debouncedSaveDraft, isDraft]);

const handleToKeyDown = (event) => {
  if (event.key === 'Tab' && toInputValue.trim()) {
      event.preventDefault();
      const newEmail = createEmailObject(toInputValue);
      const updatedTo = [...editedEmail.Recipients, newEmail];
      handleToChange(null, updatedTo);
      setToInputValue('');
  }
};

const handleCcInputChange = (event, newInputValue, reason) => {
if (reason === 'input') {
  debouncedFetchRecipients(newInputValue);
}
};

const handleCcChange = useCallback((event, newValue) => {
  const formattedNewValue = newValue.map(item => {
      if (typeof item === 'string') {
          return { email: item.trim(), displayName: item.trim() };
      }
      return { 
          email: item.email || item.emailAddress?.address || item.displayName,
          displayName: item.displayName || item.email || item.emailAddress?.address
      };
  });

  const uniqueCcRecipients = removeDuplicates(formattedNewValue);
  console.log('CC Recipients changed to:', uniqueCcRecipients);

  setEditedEmail(prev => {
    const updated = {
      ...prev,
      ccRecipients: uniqueCcRecipients,
      lastModified: new Date().toISOString()
    };
    saveToSessionStorage(updated);
    
    // Ensure we're calling the debounced function correctly
    if (isDraft) {
      console.log('Triggering debounced save from CC recipients change');
      debouncedSaveDraft(updated);
    }
    
    return updated;
  });

  if (uniqueCcRecipients.length === 0 && !isReadOnly) {
      setShowCc(false);
  }
}, [isReadOnly, debouncedSaveDraft, isDraft]);

const toggleCcField = () => {
setShowCc(prev => !prev);
};

useEffect(() => {
if (sendStatus === 'success') {
    setIsEditing(false);
}
}, [sendStatus]);

const handleBodyChange = useCallback((content) => {
  console.log('Body content changed');
  setEditedEmail(prev => {
    const updated = {
      ...prev,
      Body: content,
      lastModified: new Date().toISOString()
    };
    saveToSessionStorage(updated);
    bodyRef.current = content;
    bodyProcessedRef.current = true;
    
    // Ensure we're calling the debounced function correctly
    if (isDraft) {
      console.log('Triggering debounced save from body change');
      debouncedSaveDraft(updated);
    }
    
    return updated;
  });
}, [debouncedSaveDraft, isDraft]);

const handleChange = useCallback((field) => (event) => {
  const value = event.target.value;
  console.log(`Field ${field} changed to:`, value);
  
  setEditedEmail(prev => {
    const updated = {
      ...prev,
      [field]: value,
      lastModified: new Date().toISOString()
    };
    saveToSessionStorage(updated);
    
    // Ensure we're calling the debounced function correctly
    if (isDraft) {
      console.log(`Triggering debounced save from ${field} change`);
      debouncedSaveDraft(updated);
    }
    
    return updated;
  });
}, [debouncedSaveDraft, isDraft]);

const handleFileAttachment = (event) => {
  const files = Array.from(event.target.files);
  console.log('Files attached:', files.map(f => f.name));
  
  // Create file objects with preview URLs
  const filesWithUrls = files.map(file => ({
    file: file,
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified,
    previewUrl: URL.createObjectURL(file)
  }));

  const updatedEmail = {
    ...editedEmail,
    attachments: [...(editedEmail.attachments || []), ...filesWithUrls]
  };
  
  setEditedEmail(updatedEmail);
  saveToSessionStorage(updatedEmail);
  
  // Ensure we're calling the debounced function correctly
  if (isDraft) {
    console.log('Triggering debounced save from file attachment');
    debouncedSaveDraft(updatedEmail);
  }
  
  if (fileInputRef.current) {
    fileInputRef.current.value = '';
  }
};

const handleAttachClick = () => {
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};

const handleAppendSignature = () => {
  const editor = quillRef.current.getEditor();
  const cursorPosition = editor.getSelection()?.index || editor.getLength();
  const cleanSignature = DOMPurify.sanitize(localStorage.getItem('userSignature'));
  const styledSignature = `
    <p><br></p>
    <p><br></p>
    ${cleanSignature}
  `;
  editor.clipboard.dangerouslyPasteHTML(cursorPosition, styledSignature);
};

const handleRemoveAttachment = (index) => {
  const updatedAttachments = [...editedEmail.attachments];
  const removedFile = updatedAttachments[index];
  console.log('Removing attachment:', removedFile?.name || 'unknown');
  
  // Revoke object URL if it exists
  if (removedFile?.previewUrl) {
    URL.revokeObjectURL(removedFile.previewUrl);
  }
  
  updatedAttachments.splice(index, 1);
  
  const updatedEmail = {
    ...editedEmail,
    attachments: updatedAttachments
  };
  
  setEditedEmail(updatedEmail);
  saveToSessionStorage(updatedEmail);
  
  // Ensure we're calling the debounced function correctly
  if (isDraft) {
    console.log('Triggering debounced save from attachment removal');
    debouncedSaveDraft(updatedEmail);
  }
};

const deleteDraft = useCallback(async () => {
if (draftId) {
  try {
    await axiosWithAuth.delete(process.env.REACT_APP_API_URL + `/delete-draft/${draftId}`);
    console.log('Draft deleted successfully');
  } catch (error) {
    console.error('Error deleting draft:', error);
  }
}
}, [draftId]);

const handleClose = useCallback(async () => {
  try {
    // Save draft before closing if there's content
    if (editedEmail.Recipients.length > 0 || editedEmail.Subject || editedEmail.Body) {
      setIsDraftSaving(true);
      const formData = new FormData();
      
      // Create email data for server
      const emailDataForServer = {
        ...editedEmail,
        Body: quillRef.current?.getEditor().root.innerHTML || '',
        attachments: editedEmail.attachments.map(attachment => {
          if (attachment.file instanceof File) {
            return {
              name: attachment.name,
              size: attachment.size,
              type: attachment.type,
              isNew: true,
              lastModified: attachment.lastModified,
              previewUrl: attachment.previewUrl
            };
          }
          return {
            ...attachment,
            isTemporaryFile: true
          };
        })
      };

      formData.append('emailData', JSON.stringify(emailDataForServer));

      // Append actual File objects for new attachments
      editedEmail.attachments.forEach((attachment, index) => {
        if (attachment.file instanceof File) {
          formData.append(`attachment${index}`, attachment.file);
        }
      });

      // Keep track of existing attachments
      const attachmentsToKeep = editedEmail.attachments
        .filter(attachment => !(attachment.file instanceof File))
        .map(attachment => attachment.id)
        .filter(Boolean);
      
      formData.append('attachmentsToKeep', JSON.stringify(attachmentsToKeep));
      formData.append('isDraft', isDraft);

      if (draftIdRef.current) {
        formData.append('draftId', draftIdRef.current);
      }

      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/save-draft', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setHasSavedDraft(true);
      }
    }
  } catch (error) {
    console.error('Error saving draft:', error);
  } finally {
    setIsDraftSaving(false);
    isComponentMounted.current = false;
    const componentKey = `emailComponent_${instanceId}`;
    sessionStorage.removeItem(componentKey);
    onClose();
  }
}, [editedEmail, instanceId, onClose, sendStatus, currentMode, emailData.id]);

const handleDeleteDraft = useCallback(() => {
  isComponentMounted.current = false;
  setIsReadOnly(true);
  if (typeof onClose === 'function') {
    onClose();
  }
  const draftKey = `emailComponent_${instanceId}`;
  sessionStorage.removeItem(draftKey);
}, [onClose, instanceId]);

const extractInlineImages = (htmlContent) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const images = doc.querySelectorAll('img[src^="data:image/"]');
  const attachments = [];
  
  images.forEach((img, index) => {
    const dataUrl = img.src;
    const mimeMatch = dataUrl.match(/^data:(image\/\w+);base64,(.*)$/);
    if (mimeMatch) {
      const [, mimeType, base64Data] = mimeMatch;
      const fileName = `inline_image_${index}.png`;
      const cid = `inline_${index}`;

      attachments.push({ 
        name: fileName,
        mimeType,
        content: base64Data,
        cid: cid
      });
      
      // Replace src with cid reference
      img.src = `cid:${cid}`;
    }
  });
  
  return {
    convertedHtml: doc.body.innerHTML,
    inlineAttachments: attachments
  };
};

console.log('editedEmail in EmailComponent:', editedEmail);

const handleSend = useCallback(async () => {
  if (sendStatus === 'success') return;

  if (!editedEmail.Recipients || editedEmail.Recipients.length === 0) {
    setError('Please add at least one recipient');
    return;
  }

  if (!selectedSender) {
    setError('Please select a sender email address');
    return;
  }

  setIsSending(true);
  setError(null);
  setSendStatus('sending');

  try {
    const bodyContent = quillRef.current?.getEditor()?.root.innerHTML || '';
    const styledBodyContent = applyInlineStyles(bodyContent);
    const { convertedHtml, inlineAttachments } = extractInlineImages(styledBodyContent);

    // Prepare attachments data for the server
    const attachmentsData = editedEmail.attachments.map(attachment => {
      if (attachment.file instanceof File) {
        return {
          name: attachment.name,
          size: attachment.size,
          type: attachment.type,
          isNew: true
        };
      }
      // For existing attachments, include all necessary properties
      return {
        id: attachment.id,
        name: attachment.name || attachment.fileName,
        size: attachment.size,
        type: attachment.type || attachment.mimeType,
        isTemporaryFile: attachment.isTemporaryFile,
        url: attachment.url
      };
    });

    // If in reply mode, include original email attachments if they exist
    if ((currentMode === 'reply' || currentMode === 'replyAll') && emailData.attachments) {
      const originalAttachments = emailData.attachments.map(attachment => ({
        id: attachment.id,
        name: attachment.name || attachment.fileName,
        size: attachment.size,
        type: attachment.type || attachment.mimeType,
        url: attachment.url,
        fromOriginalEmail: true
      }));
      attachmentsData.push(...originalAttachments);
    }

    const formData = new FormData();
    
    const emailDataForServer = {
      ...editedEmail,
      id: emailData.id, // Make sure to include the original email ID for reply modes
      To: editedEmail.Recipients,
      Body: convertedHtml,
      inlineAttachments: inlineAttachments,
      attachments: attachmentsData,
      originalEmailId: emailData.id, // Include original email ID for reference
      fromEmail: selectedSender.emailAddress,
      fromName: selectedSender.displayName || selectedSender.emailAddress
    };

    formData.append('emailData', JSON.stringify(emailDataForServer));
    formData.append('mode', currentMode);

    // Append actual File objects for new attachments
    editedEmail.attachments.forEach((attachment, index) => {
      if (attachment.file instanceof File) {
        formData.append(`attachment${index}`, attachment.file);
      }
    });

    // Keep track of existing attachments to preserve
    const existingAttachmentIds = [
      ...editedEmail.attachments
        .filter(attachment => !attachment.file)
        .map(attachment => attachment.id),
      ...(currentMode === 'reply' || currentMode === 'replyAll' ? 
        emailData.attachments?.map(attachment => attachment.id) || [] : [])
    ].filter(Boolean);
    
    formData.append('attachmentsToKeep', JSON.stringify(existingAttachmentIds));

    const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/send-email', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status !== 200) {
      throw new Error('Failed to send email');
    }

    await deleteDraft();
    
    sessionStorage.removeItem(`emailComponent_${instanceId}`);

    setSendStatus('success');
    setIsEditing(false);
    setDraftId(null);
    setLastSavedDraft(null);

    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 1000); 
    }

  } catch (error) {
    console.error('Error sending email:', error);
    
    // Check for the specific 403 error in either error or message field
    const errorMessage = error.response?.data?.error || error.response?.data?.message || '';
    if (errorMessage.includes('ErrorSendAsDenied')) {
      setErrorDialogMessage("You can't send emails from this email address. Contact your IT admin.");
      setOpenErrorDialog(true);
    } else {
      setError('Failed to send email');
    }
    
    setSendStatus('error');
  } finally {
    setIsSending(false);
  }
}, [editedEmail, deleteDraft, instanceId, onClose, sendStatus, currentMode, emailData.id, selectedSender]);

const getSendButtonIcon = () => {
  if (isSending) return <CircularProgress size={20} color="inherit" />;
  if (sendStatus === 'success') return <CheckCircleIcon />;
  if (sendStatus === 'error') return <ReplayIcon />;
  return <SendIcon />;
};

useEffect(() => {
const handleKeyDown = (event) => {
  if (isFocused) {
    if (event.metaKey || event.ctrlKey) {
      setIsKeyPressed(true);
    }
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  }
};

const handleKeyUp = (event) => {
  if (!event.metaKey && !event.ctrlKey) {
    setIsKeyPressed(false);
  }
};

const handleFocus = () => setIsFocused(true);
const handleBlur = () => setIsFocused(false);

if (componentRef.current) {
  componentRef.current.addEventListener('focus', handleFocus, true);
  componentRef.current.addEventListener('blur', handleBlur, true);
}

document.addEventListener('keydown', handleKeyDown);
document.addEventListener('keyup', handleKeyUp);

return () => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keyup', handleKeyUp);
  if (componentRef.current) {
    componentRef.current.removeEventListener('focus', handleFocus, true);
    componentRef.current.removeEventListener('blur', handleBlur, true);
  }
};
}, [handleSend, isFocused]);

const getSendButtonText = () => {
if (isSending) return 'Sending';
if (sendStatus === 'success') return 'Sent';
if (sendStatus === 'error') return 'Try again';
return 'Send';
};


// Replace the existing signature useEffect with this updated version
const initializeSignature = useCallback(() => {
  if (!quillRef.current || !quillRef.current.getEditor) return;
  
  const editor = quillRef.current.getEditor();
  if (!editor) return;

  // Get the current content and signature
  const currentContent = editor.root.innerHTML || '';
  const signature = localStorage.getItem('userSignature');
  
  if (!signature) return;

  // More robust empty content check
  const isEmptyContent = !currentContent || 
                        currentContent.trim() === '' ||
                        currentContent === '<p><br></p>' ||
                        currentContent === '<p></p>' ||
                        currentContent.trim() === '<p> </p>';

  if (isEmptyContent && !isDraft) {
    const cleanSignature = DOMPurify.sanitize(signature);
    const styledSignature = `
        <p><br></p>
        <p><br></p>
        ${cleanSignature}
    `;

    editor.clipboard.dangerouslyPasteHTML(styledSignature);
    // editor.history.clear();
    // editor.setSelection(0, 0);
    
    // Force a re-render of the editor
    // editor.update();
  }
}, [isDraft]);

// Replace the existing useEffect for signature with this one
useEffect(() => {
  let attempts = 0;
  const maxAttempts = 5;
  const attemptInterval = 100; // 100ms between attempts

  const tryInitializeSignature = () => {
    if (attempts >= maxAttempts) return;
    
    if (quillRef.current?.getEditor()) {
      initializeSignature();
    } else {
      attempts++;
      setTimeout(tryInitializeSignature, attemptInterval);
    }
  };

  // Initial attempt
  tryInitializeSignature();

  // Set up mutation observer as backup
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.addedNodes.length) {
        initializeSignature();
        break;
      }
    }
  });

  const editorContainer = document.querySelector('.ql-container');
  if (editorContainer) {
    observer.observe(editorContainer, {
      childList: true,
      subtree: true
    });
  }

  return () => {
    observer.disconnect();
  };
}, [initializeSignature]);

const renderOriginalEmail = () => {
  if (currentMode === 'send' || !emailData || isMobile) return null;

  return (
    <OriginalEmailContainer>
      <Box 
        sx={{ 
          width: '99%',
          overflow: 'visible',
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiPaper-root': {
            boxShadow: 'none',
            border: 'none',
            height: 'fit-content',
            '& > div': {
              height: 'fit-content'
            }
          }
        }}
      >
        <EmailPreviewComponent
          selectedEmail={emailData}
          isEmbedded={true} 
          onSwap={null}
          mode={currentMode}
          onSenderClick={null}
          isReplyMode={currentMode === 'reply' || currentMode === 'replyAll'}
        />
      </Box>
    </OriginalEmailContainer>
  );
};

const handleFormatClick = useCallback((format, value) => {
  if (!quillRef.current) return;
  const editor = quillRef.current.getEditor();
  if (format === 'header') {
    editor.format('header', value);
  } else {
    editor.format(format, !editor.getFormat()[format]);
  }
}, []);

// Update cleanup effect
useEffect(() => {
  return () => {
    // Cleanup object URLs when component unmounts
    editedEmail.attachments?.forEach(file => {
      if (file?.previewUrl) {
        URL.revokeObjectURL(file.previewUrl);
      }
    });
    
    // Cancel any pending draft saves
    if (draftSaveTimeoutRef.current) {
      clearTimeout(draftSaveTimeoutRef.current);
    }
    
    isComponentMounted.current = false;
  };
}, [editedEmail.attachments]);

// Add the renderDraftStatus function
const renderDraftStatus = () => {
  if (isDraftSaving) {
    return (
      <Box display="flex" alignItems="center" sx={{ marginRight: '8px'}}>
        <CircularProgress size={16} sx={{ marginRight: 1, color: theme.palette.accent.primary }} />
        <Typography variant="caption" sx={{ fontSize: '12px' }}>Saving draft...</Typography>
      </Box>
    );
  } else if (isDraftSaved) {
    return (
      <Box display="flex" alignItems="center" sx={{ marginRight: '8px'}}>
        <CheckIcon size={16} sx={{ marginRight: 1, color: theme.palette.accent.darkGreen }} />
        <Typography variant="caption" sx={{ fontSize: '12px' }}>Draft saved</Typography>
      </Box>
    );
  } else if (lastSavedDraft) {
    return (
      <Box display="flex" alignItems="center" sx={{ marginRight: '8px'}}>
        <Typography variant="caption" sx={{ fontSize: '12px' }}>
          Last saved {new Date(lastSavedDraft).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </Typography>
      </Box>
    );
  }
  
  return null;
};

useEffect(() => {
  let isMounted = true;
  let animationTimeout = null;

  const animateEmailContent = async () => {
    // Check if we have a previewId and if this preview has already streamed
    if (!previewId || !isOpenedFromDraftPreview) {
      return;
    }

    const streamKey = `draft_preview_streamed_${previewId}`;
    const contentKey = `draft_preview_content_${previewId}`;
    const hasStreamed = sessionStorage.getItem(streamKey);
    
    // If already streamed, get content from session storage
    if (hasStreamed) {
      const storedContent = sessionStorage.getItem(contentKey);
      if (storedContent && quillRef.current?.getEditor()) {
        const editor = quillRef.current.getEditor();
        editor.root.innerHTML = '';
        editor.root.innerHTML = DOMPurify.sanitize(storedContent);
        
        setEditedEmail(prev => ({
          ...prev,
          Body: storedContent,
          lastModified: new Date().toISOString()
        }));
        return;
      }
    }

    if (isStreamingReasoning || streamingStartedRef.current) {
      return;
    }

    try {
      streamingStartedRef.current = true;
      setIsStreamingReasoning(true);
      let accumulatedResponse = '';
      
      // Get editor reference once
      const editor = quillRef.current?.getEditor();
      if (!editor) throw new Error('Editor not initialized');

      // Clear existing content before animation
      editor.root.innerHTML = '';

      // Split content into words and trim whitespace from start and end
      const trimmedContent = email_content.trim();
      const words = trimmedContent.split(/(\s+)/);
      
      // Animate word by word
      for (let i = 0; i < words.length; i++) {
        if (!isMounted) break;
        
        await new Promise(resolve => {
          animationTimeout = setTimeout(() => {
            accumulatedResponse += words[i];
            const formattedContent = accumulatedResponse
              .replace(/\n/g, '<br><br>')
              .replace(/\r/g, '<br>')
              .replace(/<br\s*\/?>\s*<br\s*\/?>/g, '</p><p>')
              .replace(/(<br\s*\/?>)+/g, '</p><p>')
              .trim() // Trim any whitespace
              .replace(/^(<p>\s*<\/p>)+|(<p>\s*<\/p>)+$/g, ''); // Remove empty paragraphs from start and end

            const wrappedContent = formattedContent.startsWith('<p>') ? 
              formattedContent : 
              `<p>${formattedContent}</p>`;
              
            if (isMounted) {
              editor.root.innerHTML = DOMPurify.sanitize(wrappedContent);
            }
            resolve();
          }, 10); // Adjust timing as needed
        });
      }

      // After animation is complete, append signature if needed
      if (isMounted) {
        const signature = localStorage.getItem('userSignature');
        if (signature && editor) {
          const cleanSignature = DOMPurify.sanitize(signature);
          
          // Get the current content and ensure proper spacing
          let currentContent = editor.root.innerHTML;
          
          // Remove any excessive blank lines at the end
          currentContent = currentContent
            .replace(/(<p>\s*<\/p>|<p><br><\/p>|<br>)*$/g, '') // Remove trailing empty paragraphs and breaks
            .replace(/^(<p>\s*<\/p>|<p><br><\/p>|<br>)*/g, ''); // Remove leading empty paragraphs and breaks
          
          // Ensure content ends with closing paragraph tag
          if (!currentContent.endsWith('</p>')) {
            currentContent = `${currentContent}</p>`;
          }
          
          // Add exactly two line breaks before signature
          const contentWithSignature = `${currentContent}<p><br></p><p><br></p>${cleanSignature}`;
          
          editor.root.innerHTML = contentWithSignature;
          accumulatedResponse = contentWithSignature;
          
          // Store the final content in session storage
          sessionStorage.setItem(contentKey, accumulatedResponse);
          sessionStorage.setItem(streamKey, 'true');
          
          // Update state with final content and persist to session storage using previous state
          setEditedEmail(prev => {
              const updated = {
                  ...prev,
                  Body: accumulatedResponse,
                  lastModified: new Date().toISOString()
              };
              saveToSessionStorage(updated);
              return updated;
          });
        }
      }
    } catch (error) {
      console.error('Error animating content:', error);
    } finally {
      if (isMounted) {
        setIsStreamingReasoning(false);
        streamingStartedRef.current = false;
      }
    }
  };

  // Only start animation if we're not already streaming
  if (!streamingStartedRef.current) {
    animateEmailContent();
  }

  // Cleanup function
  return () => {
    isMounted = false;
    if (animationTimeout) {
      clearTimeout(animationTimeout);
    }
    streamingStartedRef.current = false;
  };
}, [previewId, isOpenedFromDraftPreview, email_content]);

// Update handleReasoningClick function
const handleReasoningClick = useCallback((event) => {
  setReasoningAnchorEl(event.currentTarget);
}, []);

// Add handleReasoningClose function
const handleReasoningClose = () => {
  setReasoningAnchorEl(null);
};

// Add function to handle subject generation
const handleGenerateSubject = useCallback(async () => {
  try {
    // Set thinking state
    setIsGeneratingSubject(true);
    
    // Store the current subject
    const currentSubject = editedEmail.Subject;
    
    // Make API call to get-body-text endpoint with subject option
    const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/get-body-text', {
      option: 'subject',  
      selected_email: JSON.stringify(emailData),
      current_body: editedEmail.Body,
      recipients: {
        To: editedEmail.Recipients,
        Cc: editedEmail.ccRecipients
      },
      subject: currentSubject,
      userInfo: localStorage.getItem('userInfo'),
    });
    
    if (response.data && response.data.body_text) {
      const generatedSubject = response.data.body_text.trim();
      
      // Start with the current subject to avoid flashing
      let accumulatedSubject = '';
      const characters = generatedSubject.split('');
      
      // Create a placeholder with non-breaking spaces to maintain height
      // This prevents the field from showing placeholder text during animation
      if (!currentSubject) {
        setEditedEmail(prev => ({
          ...prev,
          Subject: '\u00A0', // Use non-breaking space as placeholder
        }));
        
        // Small delay to ensure the non-breaking space is rendered
        await new Promise(resolve => setTimeout(resolve, 10));
      }
      
      // Animate character by character for smoother effect
      for (let i = 0; i < characters.length; i++) {
        await new Promise(resolve => {
          setTimeout(() => {
            accumulatedSubject += characters[i];
            
            setEditedEmail(prev => ({
              ...prev,
              Subject: accumulatedSubject || '\u00A0', // Keep at least a non-breaking space
              lastModified: new Date().toISOString()
            }));
            
            resolve();
          }, 15); // Faster animation for smoother effect
        });
      }
      
      // Final update with the complete subject
      setEditedEmail(prev => {
        const updated = {
          ...prev,
          Subject: generatedSubject,
          lastModified: new Date().toISOString()
        };
        saveToSessionStorage(updated);
        return updated;
      });
    }
  } catch (error) {
    console.error('Error generating subject:', error);
    // Restore original subject in case of error
    setEditedEmail(prev => ({
      ...prev,
      Subject: prev.Subject || '',
    }));
  } finally {
    // Re-enable editing
    setIsGeneratingSubject(false);
  }
}, [emailData, editedEmail, saveToSessionStorage]);

// Add handleOptionSelect function
const handleOptionSelect = useCallback(async (option) => {
  try {
    // Set thinking state and disable editing
    setIsGeneratingBody(true);
    setIsStreaming(true);
    setAnchorEl(null); // Close the menu
    
    // Get current body content
    const editor = quillRef.current?.getEditor();
    if (!editor) return;
    
    const currentBody = editor.root.innerHTML;
    
    // Remove signature from body content
    const bodyWithoutSignature = removeSignature(currentBody.replace(/<[\/]?p>/g, '').replace(/<br\s*[\/]?>/g, ''));
    
    // Make API call to get-body-text endpoint
    const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/get-body-text', {
      option: option,  
      selected_email: JSON.stringify(emailData),
      current_body: bodyWithoutSignature,
      recipients: {
        To: editedEmail.Recipients,
        Cc: editedEmail.ccRecipients
      },
      subject: editedEmail.Subject,
      userInfo: localStorage.getItem('userInfo'),
    });
    
    if (response.data && response.data.body_text) {
      // Start animation
      setIsStreamingReasoning(true);
      streamingStartedRef.current = true;
      
      // Clear existing content
      editor.root.innerHTML = '';
      
      let accumulatedResponse = '';
      const responseText = response.data.body_text;
      const words = responseText.split(/(\s+)/);
      
      // Animate word by word
      for (let i = 0; i < words.length; i++) {
        await new Promise(resolve => {
          setTimeout(() => {
            accumulatedResponse += words[i];
            const formattedContent = accumulatedResponse
              .replace(/\n/g, '<br><br>')
              .replace(/\r/g, '<br>')
              .replace(/<br\s*\/?>\s*<br\s*\/?>/g, '</p><p>')
              .replace(/(<br\s*\/?>)+/g, '</p><p>')
              .trim()
              .replace(/^(<p>\s*<\/p>)+|(<p>\s*<\/p>)+$/g, '');

            const wrappedContent = formattedContent.startsWith('<p>') ? 
              formattedContent : 
              `<p>${formattedContent}</p>`;
              
            editor.root.innerHTML = DOMPurify.sanitize(wrappedContent);
            resolve();
          }, 10);
        });
      }
      
      // After animation is complete, append signature if needed
      const signature = localStorage.getItem('userSignature');
      if (signature) {
        const cleanSignature = DOMPurify.sanitize(signature);
        
        // Get the current content and ensure proper spacing
        let currentContent = editor.root.innerHTML;
        
        // Remove any excessive blank lines at the end
        currentContent = currentContent
          .replace(/(<p>\s*<\/p>|<p><br><\/p>|<br>)*$/g, '')
          .replace(/^(<p>\s*<\/p>|<p><br><\/p>|<br>)*/g, '');
        
        // Ensure content ends with closing paragraph tag
        if (!currentContent.endsWith('</p>')) {
          currentContent = `${currentContent}</p>`;
        }
        
        // Add exactly two line breaks before signature
        const contentWithSignature = `${currentContent}<p><br></p><p><br></p>${cleanSignature}`;
        
        editor.root.innerHTML = contentWithSignature;
        
        // Update state with final content
        setEditedEmail(prev => {
          const updated = {
            ...prev,
            Body: contentWithSignature,
            lastModified: new Date().toISOString()
          };
          saveToSessionStorage(updated);
          return updated;
        });
      }
    }
  } catch (error) {
    console.error('Error applying option:', error);
  } finally {
    // Re-enable editing
    setIsGeneratingBody(false);
    setIsStreamingReasoning(false);
    setIsStreaming(false);
    streamingStartedRef.current = false;
  }
}, [emailData, editedEmail, saveToSessionStorage]);

// Add auto-scroll effect for streaming content
useEffect(() => {
  const scrollToBottom = () => {
    if (reasoningContentRef.current) {
      const scrollElement = reasoningContentRef.current;
      scrollElement.scrollTo({
        top: scrollElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  if (isStreamingReasoning) {
    // Use requestAnimationFrame to ensure DOM has updated
    requestAnimationFrame(scrollToBottom);
  }
}, [reasoningText, isStreamingReasoning]);

// Add this after the handleDropdownClick and handleMenuClose functions
const handleSenderClick = (event) => {
  setSenderMenuAnchorEl(event.currentTarget);
};

const handleSenderMenuClose = () => {
  setSenderMenuAnchorEl(null);
};

// Update the handleSenderSelect function to handle email addresses directly
const handleSenderSelect = (sender) => {
  // Check if sender is a simple string (email address) or an object
  const isEmailString = typeof sender === 'string';
  const senderEmail = isEmailString ? sender : sender.emailAddress || sender.userPrincipalName || sender.email;
  const senderName = isEmailString ? sender : (sender.displayName || sender.userPrincipalName || sender.emailAddress || sender.email);
  
  setSelectedSender({
    emailAddress: senderEmail,
    displayName: senderName
  });
  
  setEditedEmail(prev => ({
    ...prev,
    From: [{ email: senderEmail, displayName: senderName }],
    lastModified: new Date().toISOString()
  }));
  
  handleSenderMenuClose();
};

console.log('Selected sender:', selectedSender);

// Update the userInfo useEffect to handle flat email addresses
useEffect(() => {
  const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  setUserInfo(storedUserInfo);
  
  // Skip if we already have a selected sender or no user info
  if (selectedSender || !storedUserInfo) return;

  // Get all available email addresses (primary + shared mailboxes)
  const availableEmails = [storedUserInfo.userPrincipalName];
  
  // Add shared mailboxes from departments
  if (storedUserInfo.departments && storedUserInfo.departments.length > 0) {
    storedUserInfo.departments.forEach(department => {
      if (department.shared_mail_boxes) {
        // Handle both string and array cases
        const mailboxes = Array.isArray(department.shared_mail_boxes) 
          ? department.shared_mail_boxes 
          : [department.shared_mail_boxes];
        
        mailboxes.forEach(mailbox => {
          if (mailbox) availableEmails.push(mailbox);
        });
      }
    });
  }
  
  // Check if this is a reply to an email
  if (currentMode === 'reply' || currentMode === 'replyAll' || currentMode === 'forward') {
    // Try to find which of our mailboxes received this email
    const recipientEmails = [];
    
    // Check direct recipients
    if (emailData.recipients && Array.isArray(emailData.recipients)) {
      recipientEmails.push(...emailData.recipients);
    } else if (emailData.to && Array.isArray(emailData.to)) {
      recipientEmails.push(...emailData.to);
    }
    
    // Check CC recipients
    if (emailData.ccRecipients && Array.isArray(emailData.ccRecipients)) {
      recipientEmails.push(...emailData.ccRecipients);
    } else if (emailData.cc && Array.isArray(emailData.cc)) {
      recipientEmails.push(...emailData.cc);
    }
    
    // Find the first matching email address
    let matchedEmail = null;
    let matchedDepartment = null;
    
    for (const recipient of recipientEmails) {
      const recipientEmail = typeof recipient === 'string' ? recipient : recipient.email || recipient.emailAddress?.address;
      
      if (recipientEmail && availableEmails.includes(recipientEmail)) {
        matchedEmail = recipientEmail;
        
        // Find the department name if it's a shared mailbox
        if (recipientEmail !== storedUserInfo.userPrincipalName) {
          for (const dept of storedUserInfo.departments || []) {
            const mailboxes = Array.isArray(dept.shared_mail_boxes) 
              ? dept.shared_mail_boxes 
              : [dept.shared_mail_boxes];
            
            if (mailboxes.includes(recipientEmail)) {
              matchedDepartment = dept.name;
              break;
            }
          }
        }
        break;
      }
    }
    
    // If we found a matching email, use it as the sender
    if (matchedEmail) {
      const senderEmail = {
        emailAddress: matchedEmail,
        displayName: matchedDepartment || storedUserInfo.displayName || matchedEmail
      };
      
      setSelectedSender(senderEmail);
      setEditedEmail(prev => ({
        ...prev,
        From: [{ email: senderEmail.emailAddress, displayName: senderEmail.displayName }]
      }));
      return;
    }
  }
  
  // Default to primary email if no match found
  const primaryEmail = {
    emailAddress: storedUserInfo.userPrincipalName,
    displayName: storedUserInfo.displayName || storedUserInfo.userPrincipalName
  };
  
  setSelectedSender(primaryEmail);
  setEditedEmail(prev => ({
    ...prev,
    From: [{ email: primaryEmail.emailAddress, displayName: primaryEmail.displayName }]
  }));
}, [selectedSender, emailData, currentMode]);

// Add a new function to handle mode switching
const handleModeSwitch = () => {
  if (sendStatus === "success") return; // Don't allow switching if email is already sent
  if (!emailData?.id) return; // Don't allow switching if emailData doesn't have an id
  
  // Define the mode cycle: send -> reply -> replyAll -> forward -> send
  const modes = ['send', 'reply', 'replyAll', 'forward'];
  const currentIndex = modes.indexOf(currentMode);
  const nextIndex = (currentIndex + 1) % modes.length;
  const newMode = modes[nextIndex];
  setCurrentMode(newMode);
  
  // Notify parent components of mode change if needed
  if (typeof onModeChange === 'function') {
    onModeChange(newMode);
  }
};

// Add a function to get the display text based on current mode
const getModeDisplayText = () => {
  if (sendStatus === "success") return "Email Sent Successfully";
  
  switch (currentMode) {
    case 'reply':
      return "Reply";
    case 'replyAll':
      return "Reply All";
    case 'forward':
      return "Forward";
    case 'send':
    default:
      return "New Email";
  }
};

// Add an effect to update currentMode when the mode prop changes
useEffect(() => {
  setCurrentMode(mode);
}, [mode]);

// Add useEffect to fetch user's aliases
useEffect(() => {
  const fetchUserAliases = async () => {
    try {
      setIsLoadingAliases(true);
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + '/get-user-aliases');
      console.log('Fetched user aliases:', response.data);
      setUserAliases(response.data);
      
      // If no sender is selected yet, select the primary email (first in the list)
      if (!selectedSender && response.data.length > 0) {
        // Find the primary email (isPrimary=true) or default to the first one
        const primaryEmail = response.data.find(alias => alias.isPrimary) || response.data[0];
        handleSenderSelect({
          emailAddress: primaryEmail.email,
          displayName: primaryEmail.displayName
        });
      }
    } catch (error) {
      console.error('Error fetching user aliases:', error);
    } finally {
      setIsLoadingAliases(false);
    }
  };

  fetchUserAliases();
}, []);

    return (
      <>
        <Dialog
          open={openErrorDialog}
          onClose={() => setOpenErrorDialog(false)}
          aria-labelledby="error-dialog-title"
          aria-describedby="error-dialog-description"
          sx={{
            zIndex: 9999,
            '& .MuiDialog-paper': {
              minWidth: '300px',
              maxWidth: '400px',
              borderRadius: '8px',
              p: 1
            }
          }}
        >
          <DialogTitle id="error-dialog-title" sx={{ pb: 1 }}>
            {"Could not send email"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description">
              {errorDialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setOpenErrorDialog(false)} 
              // color={theme.palette.accent.darkGreen}
              variant="contained"
              sx={{ textTransform: 'none', color: 'white', backgroundColor: theme.palette.accent.darkGreen, '&:hover': { backgroundColor: alpha(theme.palette.accent.darkGreen, 0.8) } }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Fade in={true} timeout={500}>
          <StyledPaper 
            elevation={3} 
            id="email-component-drop-zone"
            ref={componentRef}
            tabIndex={-1} 
          >
            {isDragging && (
              <DragOverlay>
                <Typography variant="h6">Drop files here to attach</Typography>
              </DragOverlay>
            )}

            <GradientHeader>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                  <EmailIcon fontSize="small" style={{ marginRight: '8px' }} />
                  <Box display="flex" alignItems="center">
                    {/* Hide "New Email" label on mobile */}
                    {!isMobile && (
                      <Tooltip title={sendStatus === "success" || !emailData?.id ? "" : "Click to change email mode"}>
                        <Typography 
                          fontSize='14px' 
                          variant="h7" 
                          fontWeight={'bold'} 
                          style={{ 
                            marginRight: '8px',
                            cursor: sendStatus === "success" || !emailData?.id ? 'default' : 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          onClick={handleModeSwitch}
                        >
                          {getModeDisplayText()}
                          {sendStatus !== "success" && emailData?.id && (
                            <SwitchRightIcon fontSize="small" style={{ marginLeft: '2px' }} />
                          )}
                        </Typography>
                      </Tooltip>
                    )}
                      <Box 
                        onClick={handleSenderClick}
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          cursor: 'pointer',
                          border: '1px solid #e0e0e0',
                          borderRadius: '4px',
                          padding: '2px 8px',
                          fontSize: '12px',
                          maxWidth: isMobile ? '180px' : 'auto',
                          '&:hover': {
                            backgroundColor: '#f5f5f5'
                          }
                        }}
                      >
                        <Typography fontSize='12px' color="text.secondary" sx={{ mr: 0.5, flexShrink: 0 }}>
                          From:
                        </Typography>
                        <Typography 
                          fontSize='12px' 
                          fontWeight="medium"
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: isMobile ? '120px' : 'auto'
                          }}
                        >
                          {selectedSender?.displayName || selectedSender?.emailAddress || userInfo?.userPrincipalName || 'Loading...'}
                        </Typography>
                        <ArrowDropDownIcon fontSize="small" sx={{ flexShrink: 0 }} />
                      </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" flex={1} justifyContent="flex-end">
                  {!isMobile && (
                    <DraftStatus>
                      {renderDraftStatus()}
                    </DraftStatus>
                  )}
                  {!isDraft && (
                    <ButtonContainer>
                      <DeleteButton onClick={handleDeleteDraft} aria-label="delete">
                        <DeleteIcon />
                        <Typography fontSize='12px'>
                          Delete
                        </Typography>
                      </DeleteButton>
                      <CloseButton onClick={handleClose} aria-label="close">
                        <CloseIcon />
                        <Typography fontSize='12px'>
                          Save draft
                        </Typography>
                      </CloseButton>
                    </ButtonContainer>
                  )}
                </Box>
              </Box>
            </GradientHeader>

            <ContentWrapper>
              <FieldBox sx={{ pb: 1, mt: -1 }}>
                <FieldIcon>
                  <SecondaryColoredIcon>
                    <PersonIcon />
                  </SecondaryColoredIcon>
                </FieldIcon>
                <StyledAutocomplete
                  multiple
                  id="to-field"
                  options={recipients}
                  loading={loading}
                  onInputChange={handleToInputChange}
                  inputValue={toInputValue}
                  getOptionLabel={getOptionLabel}
                  renderOption={renderOption}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      placeholder="Add recipients"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        tabIndex: 1,
                        onKeyDown: handleToKeyDown,
                        onFocus: () => setToFieldFocused(true),
                        onBlur: () => setToFieldFocused(false),
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="primary" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                      inputRef={toFieldRef}
                    />
                  )}
                  value={editedEmail.Recipients}
                  onChange={handleToChange}
                  disabled={!isEditing || isReadOnly}
                  freeSolo
                  renderTags={(value, getTagProps) => renderTags(value, getTagProps, 'to')}
                  disableClearable
                />
                {!isReadOnly && (
                  <CcToggleContainer>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleCcField();
                      }}
                      disabled={!isEditing}
                      size="small"
                      sx={{ 
                        color: 'text.secondary',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        }
                      }}
                    >
                      {showCc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>
                  </CcToggleContainer>
                )}
              </FieldBox>

              {((showCc && !isReadOnly) || (isReadOnly && editedEmail.ccRecipients && editedEmail.ccRecipients.length > 0)) && (
                <FieldBox sx={{pb: 1}}>
                  <FieldIcon>
                    <SecondaryColoredIcon>
                      <GroupIcon />
                    </SecondaryColoredIcon>                  
                  </FieldIcon>
                  <StyledAutocomplete
                    multiple
                    options={recipients}
                    loading={loading}
                    onInputChange={handleCcInputChange}
                    renderOption={renderOption}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder="Add cc recipients"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          tabIndex: 2,
                          onFocus: () => setCcFieldFocused(true),
                          onBlur: () => setCcFieldFocused(false),
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="primary" size={20} /> : null}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    value={editedEmail.ccRecipients}
                    onChange={handleCcChange}
                    disabled={!isEditing || isReadOnly}
                    freeSolo
                    renderTags={(value, getTagProps) => renderTags(value, getTagProps, 'cc')}
                    disableClearable
                    ListboxProps={{
                      style: {
                        maxWidth: 'none',
                        width: 'max-content',
                        minWidth: '100%',
                      }
                    }}
                  />
                </FieldBox>
              )}

              <FieldBox sx={{pb: 0}}>
                <FieldIcon>
                  <SecondaryColoredIcon>
                    <SubjectIcon />
                  </SecondaryColoredIcon>
                </FieldIcon>
                <Box sx={{ display: 'flex', width: '100%', position: 'relative' }}>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={editedEmail.Subject}
                    onChange={handleChange('Subject')}
                    disabled={!isEditing || isReadOnly || isGeneratingSubject}
                    tabIndex={3}
                    onKeyDown={handleBodyKeyDown}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Generate subject">
                          <IconButton
                            onClick={handleGenerateSubject}
                            disabled={!isEditing || isReadOnly || isGeneratingSubject}
                            edge="end"
                            size="small"
                            sx={{
                              mr: -6, // Move it all the way to the right edge
                              color: theme => isGeneratingSubject ? theme.palette.accent.darkGreen : theme.palette.accent.primary,
                              '&:hover': {
                                color: theme => theme.palette.accent.darkGreen,
                                backgroundColor: 'transparent',
                              },
                              animation: isGeneratingSubject ? 'sparkle 1.5s ease-in-out infinite' : 'none',
                              '@keyframes sparkle': {
                                '0%': {
                                  transform: 'scale(1) rotate(0deg)',
                                  opacity: 0.7
                                },
                                '50%': {
                                  transform: 'scale(1.2) rotate(180deg)',
                                  opacity: 1
                                },
                                '100%': {
                                  transform: 'scale(1) rotate(360deg)',
                                  opacity: 0.7
                                }
                              }
                            }}
                          >
                            <AutoAwesomeIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Box>
              </FieldBox>

              {renderAttachments()}

              <BodyBox $hasBottomNav={currentMode === 'reply' || currentMode === 'replyAll'}>
                {isBodyLoading && !hasReceivedFirstChunk ? (
                  <ShimmerEffect />
                ) : !isReadOnly ? (
                  <>
                    <QuillContainer>
                      <CustomToolbar id="custom-toolbar" sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <span className="ql-formats">
                          <button className="ql-bold"></button>
                          <button className="ql-italic"></button>
                          <button className="ql-underline"></button>
                          <button className="ql-image"></button>
                        </span>
                        
                        <span className="ql-formats">
                          <ToolbarButton
                            onClick={handleAttachClick}
                            disabled={sendStatus === 'success'}
                            aria-label="Attach File"
                          >
                            <AttachFileIcon />
                          </ToolbarButton>
                          <ToolbarButton
                            onClick={handleAppendSignature}
                            disabled={sendStatus === 'success'}
                            aria-label="Append Signature"
                          >
                            <SignatureIcon />
                          </ToolbarButton>
                        </span>

                        <div className="toolbar-right">
                          <ThinkingLabel 
                            onClick={handleReasoningClick}
                            ref={reasoningButtonRef}
                          >
                            {isGeneratingBody && (
                              <CircularProgress size={16} sx={{ color: theme => theme.palette.accent.primary }} />                              
                            )}
                            {reasoningContent && reasoningContent.length > 10 && (
                              <Typography variant="body2">{isGeneratingBody ? "Generating..." : "View Reasoning"}</Typography>
                            )}
                          </ThinkingLabel>
                          <ToolbarButton
                            onClick={handleDropdownClick}
                            disabled={sendStatus === 'success'}
                          >
                            <div className="button-content">
                              <AutoFixHighIcon />
                              <Typography variant="caption">Adjust</Typography>
                              <ArrowDropDownIcon sx={{ ml: -0.5 }} />
                            </div>
                          </ToolbarButton>
                        </div>
                      </CustomToolbar>

                      {isGeneratingBody ? (
                        <div style={{ height: '300px', width: '100%' }}>
                          <ShimmerEffect baseColor={alpha(theme.palette.accent.darkGreen, 0.3)} highlightColor={theme.palette.primary.light} />
                        </div>
                      ) : (
                        <StyledReactQuill
                          ref={quillRef}
                          value={editedEmail.Body}
                          onChange={handleBodyChange}
                          modules={quillModules}
                          formats={quillFormats}
                          readOnly={isSending || isStreaming || isStreamingReasoning}
                          $isDisabled={sendStatus === 'success' || isStreamingReasoning}
                          tabIndex={4}
                        />
                      )}
                    </QuillContainer>    
                    {(currentMode === 'reply' || currentMode === 'replyAll') && renderOriginalEmail()}
                  </>
                ) : (
                  <>
                    <ReadOnlyContent
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(editedEmail.Body),
                      }}
                    />
                    {(currentMode === 'reply' || currentMode === 'replyAll') && renderOriginalEmail()}
                  </>
                )}
              </BodyBox>

              <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <StyledMenuItem onClick={() => handleOptionSelect('Elaborate')}>
                  <AutoFixHighIcon style={{ fontSize: 15 }} />
                  Elaborate
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleOptionSelect('Professionalize')}>
                  <AutoFixHighIcon style={{ fontSize: 15 }} />
                  Professionalize
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleOptionSelect('Proofread')}>
                  <AutoFixHighIcon style={{ fontSize: 15 }} />
                  Proofread
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleOptionSelect('Shorten')}>
                  <AutoFixHighIcon style={{ fontSize: 15 }} />
                  Shorten
                </StyledMenuItem>
              </StyledMenu>

              {!isReadOnly && (
                <>
                  {isMobile ? (
                    <MobileToolbar>
                      <div className="left-buttons">
                        <IconButton 
                          onClick={() => handleFormatClick('bold')}
                          disabled={sendStatus === 'success'}
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <FormatBoldIcon sx={{ fontSize: 20, fontWeight: 100, opacity: 0.8 }} />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleFormatClick('italic')}
                          disabled={sendStatus === 'success'}
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <FormatItalicIcon sx={{ fontSize: 20, fontWeight: 100, opacity: 0.8 }} />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleFormatClick('underline')}
                          disabled={sendStatus === 'success'}
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <FormatUnderlinedIcon sx={{ fontSize: 20, fontWeight: 100, opacity: 0.8 }} />
                        </IconButton>
                        <IconButton 
                          disabled={sendStatus === 'success'}
                          component="label"
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <AttachFileIcon sx={{ fontSize: 20 }} />
                          <input
                            accept="*/*"
                            style={{ display: 'none' }}
                            id={`attachment-button-file-${editedEmail.component_id}`}
                            type="file"
                            multiple
                            onChange={handleFileAttachment}
                          />
                        </IconButton>
                        <IconButton 
                          onClick={handleAppendSignature} 
                          disabled={sendStatus === 'success'} 
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <SignatureIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <IconButton 
                          onClick={handleDropdownClick} 
                          disabled={sendStatus === 'success'} 
                          size="small"
                        >
                          <AutoFixHighIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </div>
                      <div className="right-buttons">
                        <GradientButton
                          variant="contained"
                          startIcon={getSendButtonIcon()}
                          onClick={handleSend}
                          disabled={isSending || sendStatus === 'success' || isBodyLoading}
                          $isKeyPressed={isKeyPressed}
                          size="small"
                          sx={{ 
                            minWidth: 'unset',
                            padding: '4px 8px',
                            '& .MuiButton-startIcon': {
                              marginRight: '4px'
                            }
                          }}
                        >
                          <Typography variant="caption" component="span" sx={{ fontSize: '11px' }}>
                            {getSendButtonText()}
                          </Typography>
                        </GradientButton>
                      </div>
                    </MobileToolbar>
                  ) : (
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                      <Box display="flex" alignItems="center">
                        {/* {sendStatus !== "success" && renderVersionControl()} */}
                      </Box>
                      <Box>
                        <input
                          accept="*/*"
                          ref={fileInputRef}
                          style={{ display: 'none' }}
                          id={`attachment-button-file-${editedEmail.component_id}`}
                          type="file"
                          multiple
                          onChange={handleFileAttachment}
                        />
                        <label htmlFor={`attachment-button-file-${editedEmail.component_id}`}>
                        </label>
                        <GradientButton
                          variant="contained"
                          startIcon={getSendButtonIcon()}
                          onClick={handleSend}
                          size="small"
                          disabled={isSending || sendStatus === 'success' || isBodyLoading}
                          $isKeyPressed={isKeyPressed}
                        >
                          <Typography variant="button" component="span">
                            {getSendButtonText()}
                          </Typography>
                        </GradientButton>
                      </Box>
                    </Box>
                  )}
                </>
              )}

              {error && (
                <Typography color="error" variant="body2" mt={2}>
                  {error}
                </Typography>
              )}
            </ContentWrapper>
          </StyledPaper>
        </Fade>

        {reasoningContent && (
          <Popover
          open={Boolean(reasoningAnchorEl)}
          anchorEl={reasoningAnchorEl}
          onClose={handleReasoningClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            ref: reasoningContentRef,
            sx: {
              width: '400px',
              maxHeight: '400px',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              backgroundColor: theme.palette.accent.secondary,
              overflowY: 'auto',
              scrollBehavior: 'smooth',
              // scrollbar
              '&::-webkit-scrollbar': {
                width: '8px',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.accent.secondary,
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                // backgroundColor: alpha(theme.palette.accent.primary, 0.2),
                borderRadius: '4px',
                // '&:hover': {
                //   backgroundColor: alpha(theme.palette.accent.primary, 0.3),
                // }
              }
            }
          }}
        >
          <ReasoningDialogContent>
            {reasoningContent
              .split('\n')
              .filter(paragraph => paragraph.trim()) // Remove empty paragraphs
              .map((paragraph, index) => (
                <Typography 
                  key={index} 
                  variant="body1" 
                  component="p"
                >
                  {paragraph.trim()}
                </Typography>
              ))}
          </ReasoningDialogContent>
          </Popover>
        )}

        {/* Sender selection menu */}
        <Menu
          anchorEl={senderMenuAnchorEl}
          open={Boolean(senderMenuAnchorEl)}
          onClose={handleSenderMenuClose}
          PaperProps={{
            sx: {
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              backgroundColor: theme.palette.accent.secondary,
              mt: 1
            }
          }}
        >
          {/* Loading indicator for aliases */}
          {isLoadingAliases && (
            <MenuItem disabled sx={{ fontSize: '14px', py: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={16} />
                <Typography fontSize="14px">Loading email addresses...</Typography>
              </Box>
            </MenuItem>
          )}

          {/* User email aliases */}
          {userAliases.map((alias, index) => (
            <MenuItem 
              key={`alias-${index}`}
              onClick={() => handleSenderSelect({
                emailAddress: alias.email,
                displayName: alias.displayName
              })}
              selected={selectedSender?.emailAddress === alias.email}
              sx={{ 
                fontSize: '14px', 
                py: 1,
                '&.Mui-selected': {
                  backgroundColor: theme => alpha(theme.palette.accent.primary, 0.05),
                  '&:hover': {
                    backgroundColor: theme => alpha(theme.palette.accent.primary, 0.25),
                  }
                }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography fontWeight="medium" fontSize="14px">
                  {alias.displayName || alias.email}
                  {alias.isPrimary && ' (Primary)'}
                </Typography>
                <Typography fontSize="12px" color="text.secondary">
                  {alias.email}
                </Typography>
              </Box>
            </MenuItem>
          ))}
          
          {/* Separator between aliases and department mailboxes if both exist */}
          {userAliases.length > 0 && userInfo?.departments && userInfo.departments.length > 0 && (
            <Divider sx={{ my: 1 }} />
          )}
          
          {/* Department shared mailboxes */}
          {userInfo?.departments && userInfo.departments.length > 0 && (
            userInfo.departments.map((department, deptIndex) => {
              // Check if shared_mail_boxes exists
              if (!department.shared_mail_boxes) return null;
              
              // Handle both string and array cases
              const mailboxes = Array.isArray(department.shared_mail_boxes) 
                ? department.shared_mail_boxes 
                : [department.shared_mail_boxes];
              
              return mailboxes.map((emailAddress, mailboxIndex) => (
                <MenuItem 
                  key={`${deptIndex}-${mailboxIndex}`}
                  onClick={() => handleSenderSelect({
                    emailAddress: emailAddress,
                    displayName: department.name
                  })}
                  selected={selectedSender?.emailAddress === emailAddress}
                  sx={{ 
                    fontSize: '14px', 
                    py: 1,
                    '&.Mui-selected': {
                      backgroundColor: theme => alpha(theme.palette.accent.primary, 0.05),
                      fontWeight: 'medium',
                      '&:hover': {
                        backgroundColor: theme => alpha(theme.palette.accent.primary, 0.25),
                      }
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography fontWeight="medium" fontSize="14px">
                      {department.name}
                    </Typography>
                    <Typography fontSize="12px" color="text.secondary">
                      {emailAddress}
                    </Typography>
                  </Box>
                </MenuItem>
              ));
            })
          )}
        </Menu>
      </>
    );
};


export default React.memo(EmailComponent);