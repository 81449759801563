import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Dialog, DialogContent, CircularProgress, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import EmailComponent from './EmailComponent';
import { v4 as uuid } from 'uuid';
import { alpha } from '@mui/material/styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const DraftEmailPreview = ({ draftData, reasoningContent, email_content, isLoading, isMobile, messageIndex, messages, thread_id, selectedEmailFromEmailList }) => {
  const theme = useTheme();
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [previewId, setPreviewId] = useState(`${thread_id}_${messages[messages.length - 2].text}`);

  console.log('email_content', email_content);

  useEffect(() => {
    if (!isLoading && !sessionStorage.getItem(`draft_preview_opened_${messages[messages.length - 2].text}`)) {
      const timer = setTimeout(() => {
        setIsEmailOpen(true);
        sessionStorage.setItem(`draft_preview_opened_${messages[messages.length - 2].text}`, 'true');
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [isLoading, previewId]);

  const parsedDraftData = typeof draftData === 'string' ? JSON.parse(draftData) : draftData;

  if (isLoading) {
    return (
      <Paper elevation={0} sx={{ p: 1.5, border: `1px solid ${theme.palette.divider}`, borderRadius: '8px', display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <EmailIcon sx={{ color: 'text.secondary', fontSize: 18 }} />
        <Typography variant="subtitle2" color="text.secondary">Creating draft email...</Typography>
        <CircularProgress size={16} />
      </Paper>
    );
  }

  return (
    <>
      <Paper
        elevation={0}
        onClick={() => setIsEmailOpen(true)}
        sx={{
          p: 1.5,
          width: '300px',
          maxWidth: '500px',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          backgroundColor: theme.palette.accent.secondary,
          cursor: 'pointer',
          // '&:hover': { boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)' },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              fontWeight: 500, 
              maxWidth: '100%',
              fontSize: '0.9rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%'
            }}
          >
            {parsedDraftData.subject || 'No subject'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="subtitle2" color="text.secondary">Draft</Typography>
            <EmailIcon sx={{ color: 'text.secondary', fontSize: 18 }} />
          </Box>
        </Box>
      </Paper>

      <Dialog
        open={isEmailOpen}
        onClose={() => setIsEmailOpen(false)}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            height: isMobile ? '80vh' : '90vh',
            width: isMobile ? '100vw' : '90vw',
            m: isMobile ? 0 : 2,
            borderRadius: isMobile ? '16px 16px 0 0' : '8px',
            bgcolor: 'primary.light',
            position: isMobile ? 'fixed' : 'relative',
            bottom: isMobile ? 0 : 'auto',
          }
        }}
      >
        <DialogContent sx={{ 
          p: 0, 
          height: '100%',
          overflow: 'hidden',
        }}>
          <EmailComponent
            emailData={parsedDraftData}
            email_content={email_content}
            onClose={() => setIsEmailOpen(false)}
            initilizeBody={true}
            isOpenedFromDraftPreview={true}
            reasoningContent={reasoningContent}
            mode={parsedDraftData.mode}
            previewId={previewId}
            thread_id={thread_id}
            messages={messages}
            selectedEmailFromEmailList={selectedEmailFromEmailList}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DraftEmailPreview; 