// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme.ts';
import EmailChatApp from './components/EmailChatApp';
import './index.css';
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <EmailChatApp />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
