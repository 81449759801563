import React, { useEffect, useRef, useCallback } from 'react';
import { useAuth } from './authService';

// Derive WebSocket URL from API URL
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';
const WEBSOCKET_URL = API_URL.replace(/^http/, 'ws') + '/ws';
const RECONNECT_DELAY = 5000;
const PING_INTERVAL = 30000;

const WebSocketManager = ({ onEmailNotification }) => {
  const ws = useRef(null);
  const reconnectTimeout = useRef(null);
  const pingInterval = useRef(null);
  const { getAccessToken, userEmail } = useAuth();

  const sendMessage = useCallback((message) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket not ready, message not sent:', message);
    }
  }, []);

  const startPingInterval = useCallback(() => {
    if (pingInterval.current) {
      clearInterval(pingInterval.current);
    }
    pingInterval.current = setInterval(() => {
      sendMessage({ type: 'ping' });
    }, PING_INTERVAL);
  }, [sendMessage]);

  useEffect(() => {
    let isActive = true; // For cleanup/prevent memory leaks

    const connect = async () => {
      try {
        // Clear any existing connection
        if (ws.current) {
          ws.current.close();
          ws.current = null;
        }

        // Get fresh access token
        const accessToken = await getAccessToken();
        if (!accessToken || !isActive) {
          console.error('No access token available for WebSocket connection');
          return;
        }

        // Create new WebSocket connection with access token
        const wsUrl = `${WEBSOCKET_URL}?access_token=${encodeURIComponent(accessToken)}`;
        const socket = new WebSocket(wsUrl);

        socket.onopen = () => {
          if (!isActive) {
            socket.close();
            return;
          }
          
          console.log('WebSocket connected');
          ws.current = socket;

          // Send identify message after ensuring connection is open
          setTimeout(() => {
            sendMessage({
              type: 'identify',
              userEmail: userEmail
            });
          }, 100);

          // Start ping interval after connection is established
          startPingInterval();
        };

        socket.onmessage = (event) => {
          if (!isActive) return;
          
          try {
            const data = JSON.parse(event.data);
            console.log('WebSocket message received:', data);

            switch (data.type) {
              case 'connection_confirmed':
                console.log('Connection confirmed:', data);
                break;
              case 'email_notification':
                if (data.email) {
                  onEmailNotification(data.email);
                }
                break;
              case 'pong':
                // Handle pong response if needed
                break;
              case 'error':
                console.error('WebSocket error:', data.message);
                break;
              default:
                console.log('Unknown message type:', data.type);
            }
          } catch (error) {
            console.error('Error processing WebSocket message:', error);
          }
        };

        socket.onclose = (event) => {
          console.log('WebSocket closed:', event);
          ws.current = null;

          // Clear ping interval
          if (pingInterval.current) {
            clearInterval(pingInterval.current);
          }

          // Attempt to reconnect only if not a clean close and component is still mounted
          if (!event.wasClean && isActive) {
            console.log('Scheduling reconnection attempt...');
            reconnectTimeout.current = setTimeout(connect, RECONNECT_DELAY);
          }
        };

        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
          // The onclose handler will handle reconnection
        };

      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
        // Schedule reconnection attempt only if component is still mounted
        if (isActive) {
          reconnectTimeout.current = setTimeout(connect, RECONNECT_DELAY);
        }
      }
    };

    if (userEmail) {
      connect();
    }

    return () => {
      isActive = false; // Prevent any callbacks from executing after unmount
      
      // Cleanup on unmount
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      if (pingInterval.current) {
        clearInterval(pingInterval.current);
      }
    };
  }, [userEmail, getAccessToken, onEmailNotification, sendMessage, startPingInterval]); // Only depend on what's needed

  return null; // This is a non-visual component
};

export default WebSocketManager; 