import React, { useState, useRef, useEffect, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { InputBase, IconButton, Box, Button, Paper, Typography, Menu, MenuItem, Fade } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SearchIcon from '@mui/icons-material/Search';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StopIcon from '@mui/icons-material/Stop';
import { alpha, useTheme } from '@mui/material/styles';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const MessageInput = forwardRef(({ 
  onSend, 
  onStop, 
  isMobile, 
  disabled, 
  attachedFiles, 
  onFileAttach, 
  onRemoveFile, 
  autoFocus, 
  emailProcessingStatus, 
  onMessageInputChange, 
  hasSelectedEmail, 
  setMessage: externalSetMessage,
  message: externalMessage,
  selectedEmail
}, ref) => {
  const theme = useTheme();
  const [internalMessage, setInternalMessage] = useState(externalMessage || '');
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const inputContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedModel, setSelectedModel] = useState('Grok 3');
  const [selectedOption, setSelectedOption] = useState(null); //

  // Expose the focus method to parent components
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    }
  }));

  // Handle model selection menu
  const handleModelMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModelMenuClose = () => {
    setAnchorEl(null);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
    handleModelMenuClose();
  };

  // Update internal message when external message changes
  useEffect(() => {
    if (externalMessage !== undefined) {
      setInternalMessage(externalMessage);
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }
  }, [externalMessage, autoFocus]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, []);

  const handleSend = useCallback(() => {
    if (internalMessage.trim() && !disabled) {
      onSend(internalMessage, selectedOption);
      setInternalMessage('');
      setSelectedOption(null); // Reset the selected option after sending
      if (externalSetMessage) {
        externalSetMessage('');
      }
    }
  }, [internalMessage, disabled, onSend, externalSetMessage, selectedOption]);

  const handleStop = useCallback(() => {
    if (onStop) {
      onStop();
    }
  }, [onStop]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      // If Shift+Enter, allow line break
      if (e.shiftKey) {
        return; // Let the default behavior (line break) happen
      }
      // If just Enter and not disabled, send message
      else if (!disabled) {
        e.preventDefault();
        handleSend();
      }
    }
  }, [handleSend, disabled]);

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    setInternalMessage(newValue);
    
    if (externalSetMessage) {
      externalSetMessage(newValue);
    }
    
    if (onMessageInputChange) {
      onMessageInputChange(newValue);
    }
  }, [onMessageInputChange, externalSetMessage]);

  const handleAttachClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleDeepSearch = () => {
    // Toggle search option
    setSelectedOption(selectedOption === 'search' ? null : 'search');
    // Focus the input field
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleAutoDraft = () => {
    // Send a static message instantly
    if (!disabled) {
      onSend("Write the best possible draft to this email.", "draft");
    }
  };

  return (
    <Box sx={{ 
      width: '100%',
      marginBottom: isMobile ? 0 : '4px',
    }}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: '24px',
          border: '1px solid #e0e0e0',
          padding: isMobile ? '10px' : '12px',
          backgroundColor: theme.palette.accent.secondary,
        }}
      >
        {/* Input Field */}
        <Box
          ref={inputContainerRef}
          sx={{
            width: '100%',
            display: 'flex',
            marginBottom: isMobile ? '10px' : '12px',
          }}
        >
          <InputBase
            multiline
            maxRows={isMobile ? 4 : 5}
            placeholder="What do you want to know?"
            value={internalMessage}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            fullWidth
            sx={{
              fontSize: isMobile ? '15px' : '16px',
              color: '#333',
              '& .MuiInputBase-input': {
                padding: '4px 0',
                lineHeight: 1.5,
              }
            }}
          />
        </Box>

        {/* Action Buttons Row */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center', 
          width: '100%',
        }}>
          {/* Left Action Buttons */}
          <Box sx={{ display: 'flex', gap: isMobile ? '4px' : '8px' }}>
            {/* Auto draft button moved to right side */}
          </Box>
          
          {/* Right Side */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobile ? '8px' : '10px' }}>
            {/* Auto Draft Button with same styling as Send button */}
            {selectedEmail?.fromName !== "Lexi" && (
              isMobile ? (
                <IconButton
                  onClick={handleAutoDraft}
                  aria-label="auto draft"
                  sx={{
                    width: isMobile ? '36px' : '36px',
                    height: isMobile ? '36px' : '36px',
                    padding: 0,
                    color: 'white',
                    backgroundColor: alpha(theme.palette.accent.darkGreen, 0.9),
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: theme.palette.accent.darkGreen,
                    }
                  }}
                >
                  <AutoAwesomeIcon fontSize="small" />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  startIcon={<AutoAwesomeIcon />}
                  onClick={handleAutoDraft}
                  sx={{
                    borderRadius: '20px',
                    padding: '5px 16px',
                    textTransform: 'none',
                    color: 'white',
                    border: 'none',
                    backgroundColor: alpha(theme.palette.accent.darkGreen, 0.9),
                    height: '36px',
                    fontWeight: 500,
                    transition: 'all 0.2s ease',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: theme.palette.accent.darkGreen,
                      boxShadow: 'none',
                    }
                  }}
                >
                  Auto draft
                </Button>
              )
            )}
            
            {/* Send/Stop Button */}
            <IconButton
              sx={{
                width: isMobile ? '36px' : '36px',
                height: isMobile ? '36px' : '36px',
                padding: 0,
                color: 'white',
                backgroundColor: alpha(theme.palette.accent.darkGreen, 0.9),
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: theme.palette.accent.darkGreen,
                }
              }}
              onClick={disabled ? handleStop : handleSend}
              aria-label={disabled ? "stop" : "send"}
            >
              {disabled ? (
                <StopIcon fontSize="small" />
              ) : (
                <KeyboardArrowUpIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Box>
      </Paper>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => onFileAttach(e.target.files)}
        multiple
      />
    </Box>
  );
});

// Wrap with React.memo for performance optimization
export default React.memo(MessageInput);