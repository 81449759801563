// EmailPreviewComponent.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, IconButton, Fade, Paper, Typography, TextField, Button, useTheme, Menu, MenuItem, Dialog, DialogTitle, DialogContent, ListItemIcon, List, ListItem, ListItemText } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ForwardIcon from '@mui/icons-material/Forward';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { axiosWithAuth } from './authService';
import ChatHeader from './ChatHeader';
import EmailComponent from './EmailComponent';
import ShimmerEffect from './ShimmerEffectEmailPreview';
import { cacheService } from '../utils/cacheService';
import { format, isToday, isThisWeek } from 'date-fns';

const EmailPreviewComponent = ({ 
  selectedEmail, 
  onSwap, 
  onSenderClick, 
  isEmbedded = false, 
  mode, 
  isMobile, 
  onComposeModeChange, 
  onClose,
  updateEmailsStateAndCache,
}) => {
  const theme = useTheme();
  const [previewContent, setPreviewContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contentReady, setContentReady] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [composeMode, setComposeMode] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const abortControllerRef = useRef(null);
  const iframeRef = useRef(null);
  const embeddedImagesRef = useRef({});
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [failedAttachments, setFailedAttachments] = useState(new Set());
  const [respondingToInvite, setRespondingToInvite] = useState(false);
  const [hasResponded, setHasResponded] = useState(false);
  const [contentHeight, setContentHeight] = useState('100%');
  const contentRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(!isEmbedded);
  const resizeObserverRef = useRef(null);
  const rafIdRef = useRef(null);
  const [returningFromConfirmation, setReturningFromConfirmation] = useState(false);
  const [replyOptionsOpen, setReplyOptionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isFetchingRef = useRef(false);
  const currentEmailIdRef = useRef(null);  
  const [isViewingSingleEmail, setIsViewingSingleEmail] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);      
  const emailId = searchParams.get('mailthreadid');

  // Add effect to handle email ID in URL
  useEffect(() => {
    if (emailId) {
      setIsViewingSingleEmail(true);
    }
  }, [emailId]);

  // Fetch email body content
  const fetchEmailContent = async (emailId, signal) => {
    console.log('fetchEmailContent called for selectedEmail:', selectedEmail);
    // First check if body content is available in selectedEmail
    if (selectedEmail?.body) {
      console.log('Using selectedEmail body content:', selectedEmail.body);
      return selectedEmail.body;
    }

    console.log('Using fetched email content:', emailId);

    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-email-body/${emailId}`, {
        params: {},
        signal,
      });
      console.log('body Response:', response);
      console.log('body Response content:', response.data.bodyContent);
      return response.data.bodyContent;      
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Failed to fetch email content:', error);
      }
      return null;
    }
  };

  // Fetch attachments metadata
  const fetchAttachmentsMetadata = async (emailId, signal) => {
    try {
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-email-attachments/${emailId}`, {
        params: {},
        signal,
      });
      return response.data.attachments || [];
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch attachments metadata aborted');
      } else {
        console.error('Failed to fetch attachments metadata:', error);
      }
      return [];
    }
  };

  // Fetch a single attachment
  const fetchAttachment = async (emailId, attachmentId) => {
    try {
      console.log(`Fetching attachment ${attachmentId} for email ${emailId}`);
      const response = await axiosWithAuth.get(process.env.REACT_APP_API_URL + `/fetch-email-attachment/${emailId}/${attachmentId}`);
      console.log(`Successfully fetched attachment ${attachmentId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch attachment ${attachmentId}:`, error);
      if (error.response) {
        console.error('Error response:', error.response.status, error.response.data);
      }
      return null;
    }
  };

  // Inject images into iframe
  const injectImagesIntoIframe = useCallback(async () => {
    if (!iframeRef.current) return;
    
    const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
    const images = iframeDoc.querySelectorAll('img[data-attachment-id]');
    console.log(`Found ${images.length} images to load in email ${selectedEmail?.id}`);

    setImagesLoaded(false);

    // Clean up any existing blob URLs
    Object.values(embeddedImagesRef.current).forEach(url => URL.revokeObjectURL(url));
    embeddedImagesRef.current = {};

    for (const img of images) {
      const attachmentId = img.getAttribute('data-attachment-id');
      if (!attachmentId) continue;

      console.log(`Processing image with attachment ID: ${attachmentId}`);
      const attachment = await fetchAttachment(selectedEmail?.id, attachmentId);
      
      if (attachment && attachment.contentBytes) {
        try {
          const byteCharacters = atob(attachment.contentBytes);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: attachment.contentType || 'application/octet-stream' });
          const blobUrl = URL.createObjectURL(blob);
          
          embeddedImagesRef.current[attachmentId] = blobUrl;
          img.setAttribute('src', blobUrl);
          img.removeAttribute('data-attachment-id');
          console.log(`Successfully loaded image ${attachmentId}`);
        } catch (error) {
          console.error(`Error processing attachment ${attachmentId}:`, error);
          img.setAttribute('alt', 'Error loading image');
        }
      } else {
        console.log(`No content bytes for attachment ${attachmentId}`);
        img.setAttribute('alt', 'Image not available');
      }
    }

    setImagesLoaded(true);
  }, [selectedEmail?.id]);

  // Write content to iframe
  const writeContentToIframe = useCallback(
    (content, cidMap) => {
      if (!iframeRef.current) return;
      
      console.log('Writing content to iframe:', content);
      console.log('Type of content:', typeof content);
      
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      
      iframeDoc.open();

      // Add custom scrollbar styles
      const customStyles = `
        <style>
          ::-webkit-scrollbar {
            width: 4px;
            // height: 4px;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
          }
          ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 4px;
          }
          ::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.2);
          }
          * {
            scrollbar-width: thin;
            scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
          }
        </style>
      `;

      // Parse the HTML content
      const parser = new DOMParser();
      console.log('Parsing content with DOMParser');
      const doc = parser.parseFromString(content, 'text/html');
      console.log('Parsed document:', doc.documentElement.outerHTML);
      
      // Process all links
      const links = doc.querySelectorAll('a[href]');
      links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
      
      // Process images
      const images = doc.querySelectorAll('img');
      images.forEach((img) => {
        const src = img.getAttribute('src');
        if (src && src.startsWith('cid:')) {
          const cid = src.substring(4);
          const attachmentId = cidMap[cid];
          if (attachmentId) {
            img.setAttribute('data-attachment-id', attachmentId);
            img.removeAttribute('src');
            img.setAttribute('alt', 'Loading image...');
            console.log(`Mapped cid:${cid} to attachmentId:${attachmentId}`);
          } else {
            console.warn(`No attachment found for cid:${cid}`);
            img.setAttribute('alt', 'Image not available');
          }
        }
      });

      // Insert the custom styles and content
      console.log('Final content being written to iframe:', customStyles + doc.documentElement.outerHTML);
      iframeDoc.write(customStyles + doc.documentElement.outerHTML);
      iframeDoc.close();

      // Set content as ready and show it immediately
      // This will hide the shimmer effect as soon as the body content is loaded
      setContentReady(true);
      setShowContent(true);
      
      // Start loading images
      requestAnimationFrame(() => {
        injectImagesIntoIframe();
      });
    },
    [injectImagesIntoIframe]
  );

  // Load content with caching
  const loadContent = useCallback(async () => {
    if (!selectedEmail?.id) return;

    // Prevent duplicate fetches for the same email
    if (isFetchingRef.current && currentEmailIdRef.current === selectedEmail?.id) {
      console.log('Already fetching content for this email, skipping duplicate request');
      return;
    }
    
    // If we're switching to a new email while fetching, abort the previous request
    if (isFetchingRef.current && currentEmailIdRef.current !== selectedEmail?.id) {
      if (abortControllerRef.current) {
        console.log('Aborting previous fetch for email:', currentEmailIdRef.current);
        abortControllerRef.current.abort();
      }
    }
    
    // Set current email and fetching state
    currentEmailIdRef.current = selectedEmail?.id;
    isFetchingRef.current = true;

    // Reset states
    setIsLoading(true);
    setContentReady(false);
    setShowContent(false);
    setPreviewContent('');
    setImagesLoaded(false);
    setFailedAttachments(new Set());
    
    // Clean up existing blob URLs
    Object.values(embeddedImagesRef.current).forEach(url => URL.revokeObjectURL(url));
    embeddedImagesRef.current = {};

    // Check cache first
    const cachedData = cacheService.getEmailPreviewCache(selectedEmail?.id);
    if (cachedData && cachedData.content) {
      console.log('Loading email content from cache:', selectedEmail?.id);
      console.log('Cached content:', cachedData.content);
      setPreviewContent(cachedData.content);
      writeContentToIframe(cachedData.content, cachedData.cidMap || {});
      // Immediately update loading states for cached content
      setIsLoading(false);
      isFetchingRef.current = false;
      return;
    }

    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    try {
      // First fetch attachments metadata
      console.log('Fetching attachments metadata for email:', selectedEmail?.id);
      const attachments = await fetchAttachmentsMetadata(selectedEmail?.id, signal);
      console.log('Fetched attachments:', attachments);

      // Create mapping from contentId to attachmentId
      const cidMap = {};
      attachments.forEach((att) => {
        if (att.contentId) {
          cidMap[att.contentId] = att.id;
        }
      });

      // Then fetch content
      console.log('Fetching content for email:', selectedEmail?.id);
      const content = await fetchEmailContent(selectedEmail?.id, signal);
      console.log('Fetched content:', content);
      
      if (content) {
        setPreviewContent(content);
        
        // Cache the content with metadata
        cacheService.setEmailPreviewCache(selectedEmail?.id, {
          content,
          cidMap,
          attachments,
          timestamp: Date.now()
        });
        
        writeContentToIframe(content, cidMap);
      } else {
        console.error('No content loaded for email:', selectedEmail?.id);
        setContentReady(true);
        setShowContent(true);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error loading email content:', error);
      }
      setContentReady(true);
      setShowContent(true);
    } finally {
      setIsLoading(false);
      isFetchingRef.current = false;
    }
  }, [selectedEmail?.id, writeContentToIframe]);

  // Remove the effect that handles content visibility since we're now showing content immediately
  // after the body is loaded
  useEffect(() => {
    let timer;
    if (contentReady && !showContent) {
      timer = setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [contentReady, showContent]);

  // Load content when email changes
  useEffect(() => {
    console.log('Loading content effect triggered. selectedEmail:', selectedEmail?.id);
    if (selectedEmail?.id && selectedEmail?.id !== 'thread_newLexiChat' && selectedEmail?.fromName.toLowerCase() !== 'lexi') {
      loadContent();
    }
    
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Clean up blob URLs
      Object.values(embeddedImagesRef.current).forEach(url => URL.revokeObjectURL(url));
      embeddedImagesRef.current = {};
      // Reset fetching state on cleanup
      isFetchingRef.current = false;
    };
  }, [selectedEmail?.id, loadContent]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Clean up blob URLs
      Object.values(embeddedImagesRef.current).forEach(url => URL.revokeObjectURL(url));
      embeddedImagesRef.current = {};
    };
  }, []);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  const handleReply = () => {
    setComposeMode('reply');
    onComposeModeChange?.('reply');
    setReplyOptionsOpen(false);
  };
  
  const handleReplyAll = () => {
    setComposeMode('replyAll');
    onComposeModeChange?.('replyAll');
    setReplyOptionsOpen(false);
  };
  
  const handleForward = () => {
    setComposeMode('forward');
    onComposeModeChange?.('forward');
    setReplyOptionsOpen(false);
  };
  
  const handleCloseCompose = () => {
    setComposeMode(null);
    onComposeModeChange?.(null);
  };

  const handleOpenReplyOptions = (event) => {
    setAnchorEl(event.currentTarget);
    setReplyOptionsOpen(true);
  };

  const handleCloseReplyOptions = () => {
    setReplyOptionsOpen(false);
  };

  const handleInviteResponse = async (status) => {
    if (respondingToInvite || hasResponded) return;
    setRespondingToInvite(true);

    try {
      // Update invitation status
      await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/update-invite-status', {
        email_id: selectedEmail?.id,
        status
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Get the email to be moved
      const emailToMove = selectedEmail;

      // Move email to sent items using updateEmailsStateAndCache
      if (emailToMove && updateEmailsStateAndCache) {
        await updateEmailsStateAndCache('move', [emailToMove], {
          targetFolder: 'sentitems',
          movedEmails: [emailToMove],
          skipCache: false
        });
      }

      // Call onSwap to trigger the dialog close and email refresh
      if (onSwap) {
        onSwap();
      }

      setHasResponded(true);

    } catch (error) {
      console.error('Failed to process calendar invite response:', error);
    } finally {
      setRespondingToInvite(false);
    }
  };

  // Update the button disabled and opacity conditions
  const buttonDisabledState = respondingToInvite || hasResponded;
  const buttonOpacityStyle = buttonDisabledState ? 0.5 : 1;

  console.log('selectedEmail from EmailPreviewComponent:', selectedEmail); 

  // Update the helper function
  const formatEventDate = (startDateString, endDateString) => {
    if (!startDateString) return '';
    try {
      // Parse ISO date strings while preserving timezone
      // The date strings from Microsoft Graph API are in ISO format with timezone
      const startDate = startDateString.endsWith('Z') ? 
        new Date(startDateString) : 
        new Date(startDateString + 'Z');
      
      const endDate = endDateString ? (
        endDateString.endsWith('Z') ? 
          new Date(endDateString) : 
          new Date(endDateString + 'Z')
      ) : null;
      
      const formatSingleDate = (date) => {
        if (isToday(date)) {
          return `Today ${format(date, 'HH:mm')}`;
        } else if (isThisWeek(date)) {
          return `${format(date, 'EEEE')} ${format(date, 'HH:mm')}`;
        } else {
          return format(date, 'dd.MM.yyyy HH:mm');
        }
      };

      if (!endDate) {
        return formatSingleDate(startDate);
      }

      return `${formatSingleDate(startDate)} - ${formatSingleDate(endDate)}`;
    } catch (e) {
      console.error('Error formatting date:', e);
      return startDateString;
    }
  };

  // Add effect to handle requestClose prop
  useEffect(() => {
    if (selectedEmail?.requestClose) {
      handleCloseRequest();
    }
  }, [selectedEmail?.requestClose]);

  // Update handleCloseRequest to reset requestClose
  const handleCloseRequest = () => {
    /* Temporarily disable confirmation dialog
    if (!isViewingSingleEmail) {
      setShowConfirmation(true);
    }
    else {
      onClose?.();
    }
    */
    // Bypass confirmation dialog temporarily and directly close
    onClose?.();
  };

  // Add function to handle back to email
  const handleBackToEmail = () => {
    setShowConfirmation(false);
    setReturningFromConfirmation(true);
    
    // Reset the requestClose flag in parent component
    if (selectedEmail?.requestClose) {
      // Clone the email object without the requestClose property
      const { requestClose, ...emailWithoutRequest } = selectedEmail;
      // Update the selectedEmailForPreview in the parent
      onSenderClick?.(emailWithoutRequest);
    }
  };

  // Add effect to handle returning from confirmation
  useEffect(() => {
    if (returningFromConfirmation && selectedEmail?.id) {
      console.log('Reloading content after returning from confirmation');
      // Reset iframe content
      if (iframeRef.current) {
        const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write('');
        iframeDoc.close();
      }
      
      // Reset states
      setContentReady(false);
      setShowContent(false);
      setPreviewContent('');
      
      // Reload content with a small delay to ensure clean slate
      setTimeout(() => {
        loadContent();
        setReturningFromConfirmation(false);
      }, 50);
    }
  }, [returningFromConfirmation, selectedEmail?.id, loadContent]);

  // Update handleFinalClose to handle both keep and archive actions
  const handleFinalClose = async (action = 'keep') => {
    setShowConfirmation(false);
    
    try {
      if (action === 'archive' && selectedEmail) {
        // Move the email to archive folder using updateEmailsStateAndCache
        if (updateEmailsStateAndCache) {
          await updateEmailsStateAndCache('move', [selectedEmail], {
            targetFolder: 'archive',
            movedEmails: [selectedEmail],
            skipCache: false
          });
        }

        // Call onSwap if provided to refresh the email list
        if (onSwap) {
          onSwap();
        }
      }
      
      // Close the preview
      setTimeout(() => {
        onClose?.();
      }, 0);
    } catch (error) {
      console.error('Error handling email action:', error);
      // Close the preview even if there's an error
      onClose?.();
    }
  };

  // Add cleanup effect
  useEffect(() => {
    return () => {
      setShowConfirmation(false);
    };
  }, []);

  // Add effect to reset composeMode on selectedEmail change
  useEffect(() => {
    setComposeMode(null);
    onComposeModeChange?.(null);
  }, [selectedEmail?.id, onComposeModeChange]);

  if (!selectedEmail) return null;

  return (
    <Fade in={true} timeout={200}>
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative',
          boxShadow: '0px 5px 15px rgba(0,0,0,0.2)',
          borderRadius: '5px',
          bgcolor: theme.palette.primary.light,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <ChatHeader
            messageId={selectedEmail?.id}
            fromName={selectedEmail?.fromName}
            fromEmail={selectedEmail?.fromEmail}
            receiveDate={selectedEmail?.date}
            recipients={selectedEmail?.recipients}
            cc={selectedEmail?.ccRecipients}
            bcc={selectedEmail?.bccRecipients}
            subject={selectedEmail?.subject}
            attachments={selectedEmail?.attachments}
            onSwap={onSwap}
            onSenderClick={onSenderClick}
            isEmbedded={isEmbedded}
            isReplyMode={mode === 'reply' || mode === 'replyAll'}
            isExpanded={isExpanded}
            onToggleExpand={() => setIsExpanded(prev => !prev)}
            onClose={handleCloseRequest}
          />
          
          {/* Wrap content in a collapsible container */}
          <Box
            sx={{
              display: isExpanded ? 'flex' : 'none',
              flexDirection: 'column',
              flexGrow: 1,
              minHeight: 0,
              transition: 'all 0.3s ease',       
              borderRadius: '5px',
              ...(isEmbedded && {
                height: isExpanded ? 'auto' : 0,
                maxHeight: isExpanded ? 'none' : 0,
                overflow: 'visible'
              })
            }}
          >
            {/* Only show action buttons when not embedded */}
            {!isEmbedded && (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                pt: 1, 
                pr: 1, 
                pb: 0,
                flexShrink: 0,
                borderRadius: '5px'  
              }}>
                {/* Subject and Reply Buttons Row */}
                <Box sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  pt: 1,
                  pb: 0,
                }}>
                  {/* Subject Container */}
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    flexShrink: 1,
                    minWidth: 0,
                    mr: 2,
                    order: 1,
                    justifyContent: 'center', // Center content vertically
                  }}>
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        pl: 2,
                        pr: 2,
                        wordBreak: 'break-word',
                        fontSize: {
                          xs: '1rem',
                          sm: '1.25rem'
                        },
                        lineHeight: 1.2,
                        py: 0.5, // Add vertical padding to match buttons
                      }}
                    >
                      {selectedEmail?.subject}
                    </Typography>
                  </Box>

                  {/* Action Buttons Box - Now with conditional display based on screen size */}
                  <Box sx={{ 
                    display: 'flex',
                    flexShrink: 0,
                    gap: { xs: 0.5, sm: 1 }, // Smaller gap on mobile
                    alignItems: 'flex-start',
                    order: 2,
                    minWidth: 'fit-content',
                  }}>
                    {/* Mobile view: Single button */}
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                      <IconButton 
                        sx={{ 
                          '&:hover': { color: 'black' },
                          padding: '4px',
                        }} 
                        disableRipple 
                        onClick={handleOpenReplyOptions}
                      >
                        <MoreHorizIcon sx={{ fontSize: '1.2rem' }} />
                      </IconButton>
                      
                      <Menu
                        anchorEl={anchorEl}
                        open={replyOptionsOpen}
                        onClose={handleCloseReplyOptions}
                        PaperProps={{
                          elevation: 3,
                          sx: {
                            minWidth: 180,
                            borderRadius: '8px',
                            mt: 1.5,
                            bgcolor: theme.palette.accent.secondary,
                            '& .MuiMenuItem-root': {
                              fontSize: '0.95rem',
                              py: 1.25,
                            }
                          }
                        }}
                      >
                        <MenuItem onClick={handleReply}>
                          <ListItemIcon>
                            <ReplyIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />
                          </ListItemIcon>
                          <ListItemText primary="Reply" />
                        </MenuItem>
                        <MenuItem onClick={handleReplyAll}>
                          <ListItemIcon>
                            <ReplyAllIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />
                          </ListItemIcon>
                          <ListItemText primary="Reply All" />
                        </MenuItem>
                        <MenuItem onClick={handleForward}>
                          <ListItemIcon>
                            <ForwardIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />
                          </ListItemIcon>
                          <ListItemText primary="Forward" />
                        </MenuItem>
                      </Menu>
                    </Box>
                    
                    {/* Desktop view: Regular buttons */}
                    <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1 }}>
                      <IconButton 
                        sx={{ 
                          '&:hover': { color: 'black' },
                          padding: '8px', 
                        }} 
                        disableRipple 
                        onClick={handleReply}
                      >
                        <ReplyIcon sx={{ fontSize: '1.5rem' }} />
                        <Typography 
                          sx={{ ml: 0.5 }} 
                          variant="caption"
                        >
                          Reply
                        </Typography>
                      </IconButton>
                      <IconButton 
                        sx={{ 
                          '&:hover': { color: 'black' },
                          padding: '8px',
                        }} 
                        disableRipple 
                        onClick={handleReplyAll}
                      >
                        <ReplyAllIcon sx={{ fontSize: '1.5rem' }} />
                        <Typography 
                          sx={{ ml: 0.5 }}
                          variant="caption"
                        >
                          Reply All
                        </Typography>
                      </IconButton>
                      <IconButton 
                        sx={{ 
                          '&:hover': { color: 'black' },
                          padding: '8px',
                        }} 
                        disableRipple 
                        onClick={handleForward}
                      >
                        <ForwardIcon sx={{ fontSize: '1.5rem' }} />
                        <Typography 
                          sx={{ ml: 0.5 }}
                          variant="caption"
                        >
                          Forward
                        </Typography>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>

                {/* Calendar Section - Full width on new row */}
                {selectedEmail?.isCalendarInvite && (
                  <Box sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: 1, // Reduced from 2 to 1
                    pl: 2,
                    mt: 0, // Removed margin top to bring it closer to subject
                    paddingBottom: 2, // Added padding bottom
                  }}>
                    {selectedEmail.eventDetails && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          color: 'text.primary',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          fontSize: {
                            xs: '0.875rem',
                            sm: 'inherit'
                          }
                        }}
                      >
                        {`${formatEventDate(selectedEmail.eventDetails.start, selectedEmail.eventDetails.end)}${selectedEmail.eventDetails.location ? ` • ${selectedEmail.eventDetails.location}` : ''}`}
                      </Typography>
                    )}
                    
                    {/* Calendar Buttons - More compact sizing */}
                    <Box sx={{ 
                      display: 'flex',
                      gap: { xs: 0.25, sm: 0.5 },
                      flexWrap: 'wrap',
                    }}>
                      <Button
                        startIcon={<CheckIcon sx={{ fontSize: '0.7rem' }} />}
                        disabled={buttonDisabledState}
                        onClick={() => handleInviteResponse('accept')}
                        variant="text"
                        size="small"
                        sx={{
                          opacity: buttonOpacityStyle,
                          color: 'success.main',
                          fontSize: '0.7rem',
                          py: 0.25,
                          px: 0.5,
                          minWidth: 'auto',
                          minHeight: '24px',
                          '& .MuiButton-startIcon': {
                            marginRight: 0.3,
                          },
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        startIcon={<QuestionMarkIcon sx={{ fontSize: '0.7rem' }} />}
                        disabled={buttonDisabledState}
                        onClick={() => handleInviteResponse('maybe')}
                        variant="text"
                        size="small"
                        sx={{
                          opacity: buttonOpacityStyle,
                          color: 'warning.main',
                          fontSize: '0.7rem',
                          py: 0.25,
                          px: 0.5,
                          minWidth: 'auto',
                          minHeight: '24px',
                          '& .MuiButton-startIcon': {
                            marginRight: 0.3,
                          },
                        }}
                      >
                        Maybe
                      </Button>
                      <Button
                        startIcon={<CloseIcon sx={{ fontSize: '0.7rem' }} />}
                        disabled={buttonDisabledState}
                        onClick={() => handleInviteResponse('decline')}
                        variant="text"
                        size="small"
                        sx={{
                          opacity: buttonOpacityStyle,
                          color: 'error.main',
                          fontSize: '0.7rem',
                          py: 0.25,
                          px: 0.5,
                          minWidth: 'auto',
                          minHeight: '24px',
                          '& .MuiButton-startIcon': {
                            marginRight: 0.3,
                          },
                        }}
                      >
                        Decline
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            <Box 
              ref={contentRef}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pl: 2,
                pr: 2,
                pb: 2,
                pt: isEmbedded ? 2 : selectedEmail?.isCalendarInvite ? 0 : 1,
                overflow: isEmbedded ? 'visible' : 'hidden', // Allow content to flow when embedded
                flexGrow: 1,
                minHeight: 0,
                ...(isEmbedded && {
                  height: 'auto', // Let content determine height when embedded
                })
              }}>
              <Box sx={{ 
                position: 'relative',
                flexGrow: 1,
                minHeight: 0,
                ...(isEmbedded && {
                  position: 'static', // Change position to static when embedded
                  height: 'auto', // Let content determine height
                })
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: (!showContent || !contentReady) ? 1 : 0,
                  transition: 'opacity 0.2s ease-in-out',
                  pointerEvents: (!showContent || !contentReady) ? 'auto' : 'none',
                  display: (!showContent || !contentReady) ? 'block' : 'none', // Add display property
                }}>
                  <ShimmerEffect />
                </Box>
                
                <Fade in={showContent && contentReady} timeout={200}>
                  <Box sx={{ 
                    height: '100%',
                    opacity: (showContent && contentReady) ? 1 : 0,
                    ...(isEmbedded && {
                      height: 'auto', // Let content determine height when embedded
                    })
                  }}>
                    <iframe
                      ref={iframeRef}
                      title="Email Content"
                      style={{
                        width: '100%',
                        height: isEmbedded ? 'auto' : '100%',
                        border: 'none',
                        overflow: 'visible',
                        display: (showContent && contentReady) ? 'block' : 'none',
                      }}
                      sandbox="allow-same-origin allow-popups allow-forms allow-scripts"
                      onLoad={isEmbedded ? () => {
                        if (iframeRef.current) {
                          const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
                          let lastHeight = 0;
                          
                          const resizeIframe = () => {
                            if (!iframeRef.current) return;
                            
                            const height = iframeDoc.documentElement.scrollHeight;
                            // Only update if height has actually changed
                            if (height !== lastHeight) {
                              lastHeight = height;
                              iframeRef.current.style.height = `${height}px`;
                            }
                          };

                          // Debounced resize function
                          const debouncedResize = () => {
                            if (rafIdRef.current) {
                              cancelAnimationFrame(rafIdRef.current);
                            }
                            rafIdRef.current = requestAnimationFrame(resizeIframe);
                          };
                          
                          // Initial resize
                          debouncedResize();
                          
                          // Cleanup any existing observer
                          if (resizeObserverRef.current) {
                            resizeObserverRef.current.disconnect();
                          }
                          
                          // Create new observer
                          resizeObserverRef.current = new ResizeObserver(() => {
                            debouncedResize();
                          });
                          
                          // Observe both documentElement and body for better coverage
                          resizeObserverRef.current.observe(iframeDoc.documentElement);
                          resizeObserverRef.current.observe(iframeDoc.body);
                        }
                      } : undefined}
                    />
                  </Box>
                </Fade>
              </Box>
            </Box>

            {composeMode && (
              <Box 
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 10, // Ensure it's above other content
                  bgcolor: 'background.paper', // Match your app's background color
                }}
                onClick={(e) => e.stopPropagation()} // Prevent click events from bubbling up
              >
                <EmailComponent
                  mode={composeMode}
                  emailData={selectedEmail}
                  onClose={handleCloseCompose}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* Confirmation overlay */}
        {showConfirmation && (
          <Fade in={showConfirmation} timeout={200}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                zIndex: 10,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  // backdropFilter: 'blur(5px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 0,
                },
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  zIndex: 0,
                }
              }}
            >
              {/* Back button */}
              <Box
                onClick={handleBackToEmail}
                sx={{
                  position: 'absolute',
                  top: 16,
                  left: 16,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                  color: 'text.secondary',
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: 'text.primary',
                  },
                  transition: 'all 0.2s ease',
                  zIndex: 2,
                }}
              >
                <Typography variant="body2" sx={{ 
                  fontWeight: 500,
                  color: theme.palette.accent.primary,
                }}>
                  Back to email
                </Typography>
              </Box>

              {/* Confirmation content */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  p: 3,
                  textAlign: 'center',
                  position: 'relative',
                  zIndex: 1,
                  borderRadius: 2,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: theme.palette.accent.secondary,
                    p: 4,
                    borderRadius: 2,
                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.07)',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 3, color: 'text.primary' }}>
                    Can you consider this email done?
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="outlined"
                      onClick={() => handleFinalClose('keep')}
                      sx={{
                        px: 4,
                        borderColor: theme.palette.accent.primary,
                        color: theme.palette.accent.primary,
                        '&:hover': {
                          borderColor: theme.palette.accent.primary,
                          backgroundColor: `${theme.palette.accent.primary}10`,
                        }
                      }}
                    >
                      Keep in inbox
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleFinalClose('archive')}
                      sx={{
                        px: 4,
                        color: 'white',
                        backgroundColor: theme.palette.accent.darkGreen,
                        '&:hover': {
                          backgroundColor: theme.palette.accent.darkGreen,
                          opacity: 0.8,
                        }
                      }}
                    >
                      Archive
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>
        )}
      </Paper>
    </Fade>
  );
};

export default EmailPreviewComponent;