import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// Assume these are imported from somewhere in your project
import { ReactComponent as WordIcon } from '../icons/word-icon.svg';
import { ReactComponent as ExcelIcon } from '../icons/excel-icon.svg';
import { ReactComponent as PowerPointIcon } from '../icons/powerpoint-icon.svg';

const FileContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '303px',
  height: '56px',
  background: '#FFFFFF',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
  overflow: 'hidden',
  
  '@media (max-width: 600px)': {
    height: '48px',
    maxWidth: '100%',
  }
}));

const DownloadButtonWrapper = styled('div')({
  position: 'absolute',
  right: '8px',
  top: '50%',
  transform: 'translateY(-50%)'
});

const GradientBar = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  width: '5px',
  height: '100%',
  background: theme.palette.accent.darkGreen,
  borderRadius: '0px 5px 5px 0px'
}));

const ContentWrapper = styled('div')(({ hasDownloadButton }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  paddingRight: hasDownloadButton ? '48px' : '12px',
  height: '100%',

  '@media (max-width: 600px)': {
    padding: '8px',
    paddingRight: hasDownloadButton ? '40px' : '8px'
  }
}));

const IconWrapper = styled('div')({
  marginRight: '12px',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  position: 'relative',

  '@media (max-width: 600px)': {
    marginRight: '8px',
    width: '28px',
    height: '28px'
  },

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
    cursor: 'pointer',
  }
});

const TextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  flex: 1
});

const FileName = styled('h3')({
  fontFamily: "'Inter', sans-serif",
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '15px',
  color: '#000000',
  margin: '0 0 2px 0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '@media (max-width: 600px)': {
    fontSize: '11px',
    lineHeight: '14px'
  }
});

const FileType = styled('p')({
  fontFamily: "'Inter', sans-serif",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '12px',
  color: '#666666',
  margin: 0,

  '@media (max-width: 600px)': {
    fontSize: '9px',
    lineHeight: '11px'
  }
});

const ImagePreviewDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    maxWidth: '90vw',
    maxHeight: '90vh'
  },

  '& .MuiDialogContent-root': {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '& img': {
    maxWidth: '100%',
    maxHeight: '90vh',
    objectFit: 'contain'
  }
});

const ImagePreview = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '4px',
  cursor: 'pointer',
});

const FileComponent = ({ file, onDownload, clickable }) => {
  const theme = useTheme();
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);

  const handleClick = (e) => {
    if (file && file.url) {
      onDownload(file);
    }
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    onDownload(file);
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
    setImagePreviewOpen(true);
  };
    
  const getFileIcon = (fileName) => {
    if (!fileName) {
      return <ErrorIcon style={{ width: '24px', height: '24px', color: "#F44336" }} />;
    }

    const fileExtension = fileName.split('.').pop().toLowerCase();
    const iconProps = { 
      style: { 
        width: window.innerWidth <= 600 ? '20px' : '24px', 
        height: window.innerWidth <= 600 ? '20px' : '24px' 
      } 
    };

    // Check if it's an image file and we have a URL
    if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension) && file?.url) {
      return (
        <ImagePreview
          src={file.url}
          alt={fileName}
          onClick={handleImageClick}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = <ImageIcon {...iconProps} style={{ ...iconProps.style, color: "#4CAF50" }} />;
          }}
        />
      );
    }

    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon {...iconProps} style={{ ...iconProps.style, color: "#FF0000" }} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageIcon {...iconProps} style={{ ...iconProps.style, color: "#4CAF50" }} />;
      case 'doc':
      case 'docx':
        return <WordIcon {...iconProps} style={{ ...iconProps.style, fill: "#2B579A" }} />;
      case 'xls':
      case 'xlsx':
        return <ExcelIcon {...iconProps} style={{ ...iconProps.style, fill: "#217346" }} />;
      case 'ppt':
      case 'pptx':
        return <PowerPointIcon {...iconProps} style={{ ...iconProps.style, fill: "#D24726" }} />;
      case 'txt':
        return <DescriptionIcon {...iconProps} style={{ ...iconProps.style, color: "#607D8B" }} />;
      default:
        return <InsertDriveFileIcon {...iconProps} />;
    }
  };
  
  const getFileType = (fileName) => {
    if (!fileName) return 'Unknown';

    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'PDF';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'Image';
      case 'doc':
      case 'docx':
        return 'Word';
      case 'xls':
      case 'xlsx':
        return 'Excel';
      case 'ppt':
      case 'pptx':
        return 'PowerPoint';
      case 'txt':
        return 'Text';
      default:
        return 'Unknown';
    }
  };

  if (!file || typeof file !== 'object' || !file.name) {
    return (
      <FileContainer>
        <ContentWrapper>
          <IconWrapper>
            <ErrorIcon style={{ width: '24px', height: '24px', color: "#F44336" }} />
          </IconWrapper>
          <TextWrapper>
            <FileName>Error: Invalid File</FileName>
            <FileType>Unknown</FileType>
          </TextWrapper>
        </ContentWrapper>
      </FileContainer>
    );
  }

  return (
    <>
      <FileContainer onClick={handleClick}>
        <GradientBar />
        <ContentWrapper hasDownloadButton={clickable}>
          <IconWrapper>
            {getFileIcon(file.name)}
          </IconWrapper>
          <TextWrapper>
            <FileName>{file.name}</FileName>
            <FileType>{getFileType(file.name)}</FileType>
          </TextWrapper>
        </ContentWrapper>
        {clickable && (
          <DownloadButtonWrapper>
            <IconButton 
              onClick={handleDownload} 
              size="small"
              sx={{ 
                padding: { xs: '4px', sm: '8px' },
                '& .MuiSvgIcon-root': {
                  fontSize: { xs: '18px', sm: '20px' }
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
          </DownloadButtonWrapper>
        )}
      </FileContainer>

      <ImagePreviewDialog
        open={imagePreviewOpen}
        onClose={() => setImagePreviewOpen(false)}
        onClick={() => setImagePreviewOpen(false)}
      >
        <DialogContent>
          <img src={file.url} alt={file.name} />
        </DialogContent>
      </ImagePreviewDialog>
    </>
  );
};

export default FileComponent;