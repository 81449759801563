import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EmailComponent from './EmailComponent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { axiosWithAuth } from './authService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import debounce from 'lodash/debounce';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';
import EmailFilter from './EmailFilter';

const isElectron = () => {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
    return true;
  }
  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
    return true;
  }
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
    return true;
  }
  return false;
};

// Replace the Search styled component
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.accent.primary, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.accent.primary, 0.15),
  },
  width: '100%',
  maxWidth: 700,
  marginRight: 10,
  display: 'flex',
  alignItems: 'center',
  '& .MuiInputBase-root': {
    color: theme.palette.accent.primary,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    marginRight: 0,
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
  },
}));

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: 'none',
  width: '100%',  // Takes full width of its container
  position: 'relative',  // Change to relative positioning
  WebkitAppRegion: 'drag', // Make the header draggable
  '& button, & input, & .MuiInputBase-root, & .no-drag': {
    WebkitAppRegion: 'no-drag' // Make interactive elements non-draggable
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.accent.primary,
  borderRadius: 5,
  gap: 1, // Add consistent spacing between icon and text  
  minHeight: '32px', // Add fixed height
  textTransform: 'none',
  transition: 'background-color 0.3s',
  fontSize: '0.875rem', // Slightly smaller font
  '&:hover': {
    backgroundColor: alpha(theme.palette.accent.primary, 0.1),
  },
  '&.Mui-disabled': {
    color: alpha(theme.palette.accent.primary, 0.5),
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px', // Smaller icons
  },
  minWidth: '90px', // Add fixed minimum width
  justifyContent: 'flex-start', // Align content to the left
  '& .MuiButton-startIcon': {
    marginRight: '8px', // Consistent icon spacing
  },
}));

const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.accent.darkGreen,
  color: theme.palette.common.white,
  borderRadius: 5,
  display: 'flex',
  padding: 0,
  minHeight: 36,
  textTransform: 'none',
  transition: 'all 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.accent.darkGreen,
    opacity: 0.9,
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.accent.darkGreen,
    opacity: 0.8,
  },
  '&:focus': {
    boxShadow: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
  '& .shortcut': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1.5),
    color: alpha(theme.palette.common.white, 0.7),
    fontSize: '0.8em',
  },
  '& .button-content': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 1.5),
    borderRadius: 5,
    cursor: 'pointer',
    flexGrow: 1,
    minWidth: 'fit-content',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  '& .divider': {
    height: 24,
    margin: '0 2px',
    borderRight: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
  },
  '& .arrow-wrapper': {
    padding: theme.spacing(0, 0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}));

const FloatingWhiteButton = styled(WhiteButton)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1200,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  minWidth: 'auto',
  borderRadius: '28px',
  padding: 0,
  '& .button-content': {
    padding: theme.spacing(1.5),
    borderRadius: '28px',
    minWidth: '140px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      flex: 'none',
      marginLeft: '8px',
    },
  },
}));

const SearchActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  position: 'absolute',
  right: 8,
  top: '50%',
  transform: 'translateY(-50%)',
  color: alpha(theme.palette.accent.primary, 0.7),
  '&:hover': {
    color: theme.palette.accent.primary,
  },
}));

const WarningMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.accent.primary,
  backgroundColor: alpha(theme.palette.accent.primary, 0.1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  '& .MuiCircularProgress-root': {
    color: theme.palette.accent.primary,
    marginRight: theme.spacing(1),
    width: '20px !important',
    height: '20px !important',
  },
  '& span': {
    fontSize: '0.875rem',
  },
}));

const BackIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.accent.primary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.accent.primary, 0.1),
  },
}));

const SearchBar = React.memo(({ 
  localSearchQuery, 
  handleSearchChange, 
  handleSearchSubmit, 
  handleClearSearch, 
  submittedQuery,
  isSearching,
  setIsSidebarOpen,
  isMobile,
  disabled,
  emails,
  activeFilters,
  setActiveFilters,
  onFilterChange
}) => {
  const inputRef = useRef(null);
  const theme = useTheme();
  
  // Create a handler for filter changes
  const handleFilterChangeFromSearchBar = useCallback((newFilters) => {
    console.log('Filter changed from search bar:', newFilters);
    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  }, [onFilterChange]);
  
  return (
    <Search>
      {isMobile && (
        <IconButton
          onClick={() => setIsSidebarOpen(true)}
          sx={{ 
            color: theme => theme.palette.ui.textLight,
            padding: '8px',
            marginLeft: '4px'
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <SearchIconWrapper sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        inputRef={inputRef}
        placeholder={disabled ? "Search unavailable in conversations" : "Search…"}
        inputProps={{ 'aria-label': 'search' }}
        value={localSearchQuery}
        onChange={handleSearchChange}
        fullWidth
        disabled={disabled}
        sx={{
          '& .MuiInputBase-input': {
            paddingLeft: theme => theme.spacing(isMobile ? 1 : 6),
            paddingRight: isMobile ? '40px' : theme.spacing(4),
            cursor: disabled ? 'not-allowed' : 'text'
          }
        }}
      />
      {submittedQuery ? (
        <SearchActionButton 
          onClick={() => {
            handleClearSearch();
            inputRef.current?.focus();
          }} 
          aria-label="clear search"
          disabled={isSearching || disabled}
        >
          {isSearching ? (
            <CircularProgress size={20} thickness={4} sx={{ color: theme => theme.palette.accent.darkGreen }}/>
          ) : (
            <ClearIcon />
          )}
        </SearchActionButton>
      ) : (
        isMobile ? (
          <Box 
            sx={{ 
              position: 'absolute', 
              right: 8, 
              top: '50%', 
              transform: 'translateY(-50%)',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <EmailFilter 
              emails={emails}
              onFilterChange={handleFilterChangeFromSearchBar}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              isMobile={isMobile}
            />
          </Box>
        ) : (
          <SearchActionButton 
            onClick={(e) => {
              handleSearchSubmit(e);
              inputRef.current?.focus();
            }}
            aria-label="send search"
            disabled={isSearching || disabled}
          >
            {isSearching ? (
              <CircularProgress size={20} thickness={4} sx={{ color: theme => theme.palette.accent.darkGreen }} />
            ) : (
              <SubdirectoryArrowLeftIcon sx={{fontSize: '20px'}}/>
            )}
          </SearchActionButton>
        )
      )}
    </Search>
  );
}, (prevProps, nextProps) => {
  // Update the memoization check to include filter-related props
  return prevProps.localSearchQuery === nextProps.localSearchQuery &&
         prevProps.submittedQuery === nextProps.submittedQuery &&
         prevProps.isSearching === nextProps.isSearching &&
         prevProps.disabled === nextProps.disabled &&
         JSON.stringify(prevProps.activeFilters) === JSON.stringify(nextProps.activeFilters);
});

const Transition = React.forwardRef(function Transition(props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Slide direction={isMobile ? "up" : "down"} ref={ref} {...props} />;
});

const AppHeader = ({ 
  selectedEmail,
  setSearchQuery, 
  clearSearchQuery, 
  searchQuery, 
  setSelectedEmail, 
  indexingStatus,
  onActionComplete, 
  selectedEmailIds, 
  areSelectedEmailsFlagged,
  showEmailList,
  setShowEmailList,
  isSearching,
  currentFolder,
  setIsSidebarOpen,
  updateEmailsStateAndCache,
  emails,
  activeFilters,
  setActiveFilters,
  onFilterChange
}) => {
  const timeoutRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [sendStatus, setSendStatus] = useState('idle'); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [shortcutKey, setShortcutKey] = useState('⌘K');
  const emailToFieldRef = useRef(null);
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery || '');
  const [submittedQuery, setSubmittedQuery] = useState('');
  const dialogRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showMobileConfirmation, setShowMobileConfirmation] = useState(false);

  console.log('selectedEmail app header', selectedEmail);

  const debouncedSetSearchQuery = useMemo(() => 
    debounce((query) => {
      setSearchQuery(query);
      setSubmittedQuery(query);
    }, 300),
    [setSearchQuery]
  );

  useEffect(() => {
    return () => {
      debouncedSetSearchQuery.cancel();
    };
  }, [debouncedSetSearchQuery]);

  useEffect(() => {
    setLocalSearchQuery(searchQuery || '');
    setSubmittedQuery(searchQuery || '');
  }, [searchQuery]);

  const handleClearSearch = useCallback(() => {
    setLocalSearchQuery('');
    setSubmittedQuery('');
    clearSearchQuery();
  }, [clearSearchQuery]);

  const handleSearchSubmit = useCallback((e) => {
    e?.preventDefault();
    const trimmedQuery = localSearchQuery.trim();
    if (trimmedQuery === '') {
      handleClearSearch();
    } else {
      debouncedSetSearchQuery(trimmedQuery);
    }
  }, [localSearchQuery, handleClearSearch, debouncedSetSearchQuery]);

  const handleSearchChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalSearchQuery(newValue);
    if (newValue.trim() === '') {
      handleClearSearch();
    } else {
      debouncedSetSearchQuery(newValue);
    }
  }, [debouncedSetSearchQuery, handleClearSearch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        const currentPath = window.location.pathname;
        if (currentPath !== '/home') {
          event.preventDefault();
          handleNewEmail();
        }
      } else if (event.key === 'Escape' && isDialogOpen) {
        handleCloseDialog();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    setShortcutKey(isMac ? '⌘K' : 'Ctrl+K');

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDialogOpen]);

  const handleNewEmail = () => {
    resetEmailState();
    setIsDialogOpen(true);
    setSelectedEmail(null)
    setTimeout(() => {
      if (emailToFieldRef.current) {
        emailToFieldRef.current.focus();
      }
    }, 300);
  };

  const resetEmailState = () => {
    setEmailData({
      To: [],
      Cc: [],
      Subject: '',
      Body: '',
      component_id: '',
      thread_id: '',
      attachments: [],
    });
    setSendStatus('idle');
  };

  const handleCloseDialog = async () => {
    // Animate out
    if (dialogRef.current) {
      dialogRef.current.style.opacity = 0;
      dialogRef.current.style.transform = 'translateY(20px)';
    }
    
    // Delay actual closing
    setTimeout(() => {
      setIsDialogOpen(false);
      resetEmailState();
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleAction = (action) => {
    if (selectedEmailIds.length === 0 || currentFolder === 'conversations' || selectedEmail?.id === 'thread_newLexiChat') return;
  
    // Get the emails to be moved for archiving
    const emailsToMove = action === 'archive' ? 
      (currentFolder === 'inbox_focused' || currentFolder === 'inbox_other' ? 
        selectedEmailIds.map(id => {
          const email = document.querySelector(`[data-email-id="${id}"]`);
          if (email) {
            return JSON.parse(email.dataset.emailData || '{}');
          }
          return null;
        }).filter(Boolean) : 
        []) : 
      [];

    // Skip onActionComplete for permanent deletion
    onActionComplete(action, selectedEmailIds);
  
    // Make the API call
    axiosWithAuth.post(`${process.env.REACT_APP_API_URL}/${action}-emails`, { 
      emailIds: selectedEmailIds,
    })
    .then(() => {
      // Handle archiving with updateEmailsStateAndCache
      if (action === 'archive' && emailsToMove.length > 0) {
        updateEmailsStateAndCache('archive', emailsToMove, {
          targetFolder: 'archive',
          movedEmails: emailsToMove,
          skipCache: false
        });
      }

    })
    .catch(error => {
      console.error(`Error ${action}ing emails:`, error);
    });
  };

  // Modify the StyledButton component to handle mobile icon-only view
  const MobileStyledButton = styled(StyledButton)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: '40px',
      padding: theme.spacing(1),
      '& .button-text': {
        display: 'none',
      },
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  }));

  // Modify the ActionButtons component to check currentFolder
  const ActionButtons = () => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 1,
    }}>
      <MobileStyledButton 
        startIcon={<DeleteIcon />}
        onClick={() => handleAction('delete')}
        disabled={selectedEmailIds.length === 0 || currentFolder === "conversations" || selectedEmail?.fromName === "Lexi"}
      >
        <span className="button-text">Delete</span>
      </MobileStyledButton>
      <MobileStyledButton 
        startIcon={<ArchiveIcon />}
        onClick={() => handleAction('archive')}
        disabled={selectedEmailIds.length === 0 || currentFolder === "conversations" || selectedEmail?.fromName === "Lexi"}
      >
        <span className="button-text">Archive</span>
      </MobileStyledButton>
      <MobileStyledButton 
        startIcon={<MarkEmailUnreadIcon />}
        onClick={() => handleAction('mark-unread')}
        disabled={selectedEmailIds.length === 0 || currentFolder === "conversations" || selectedEmail?.fromName === "Lexi"}
      >
        <span className="button-text">Unread</span>
      </MobileStyledButton>
      <MobileStyledButton 
        startIcon={areSelectedEmailsFlagged ? <FlagIcon /> : <OutlinedFlagIcon />}
        onClick={() => handleAction(areSelectedEmailsFlagged ? 'unflag' : 'flag')}
        disabled={selectedEmailIds.length === 0 || currentFolder === "conversations" || selectedEmail?.fromName === "Lexi"}
      >
        <span className="button-text">{areSelectedEmailsFlagged ? 'Unflag' : 'Flag'}</span>
      </MobileStyledButton>
    </Box>
  );

  const searchBarElement = useMemo(() => (
    <SearchBar
      localSearchQuery={localSearchQuery}
      handleSearchChange={handleSearchChange}
      handleSearchSubmit={handleSearchSubmit}
      handleClearSearch={handleClearSearch}
      submittedQuery={submittedQuery}
      isSearching={isSearching}
      setIsSidebarOpen={setIsSidebarOpen}
      isMobile={isMobile}
      disabled={currentFolder === "conversations"}
      emails={emails}
      activeFilters={activeFilters || {}}
      setActiveFilters={setActiveFilters || (() => {})}
      onFilterChange={onFilterChange || (() => {})}
    />
  ), [
    localSearchQuery, 
    handleSearchChange, 
    handleSearchSubmit, 
    handleClearSearch, 
    submittedQuery, 
    isSearching,
    setIsSidebarOpen,
    isMobile,
    currentFolder,
    emails,
    activeFilters,
    setActiveFilters,
    onFilterChange
  ]);

  const handleBack = useCallback((currentFolder, selectedEmail) => {
    console.log('currentFolder app header', currentFolder);
    console.log('selectedEmail handleback', selectedEmail);
    /* Temporarily disable mobile confirmation dialog
    if (isMobile && (currentFolder === 'inbox_focused' || currentFolder === 'inbox_other') && selectedEmail?.id !== 'thread_newLexiChat') {
      setShowMobileConfirmation(true);
    } else {
      setShowEmailList(true);
      setSelectedEmail(null);
    }
    */
    // Bypass mobile confirmation dialog temporarily
    setShowEmailList(true);
    setSelectedEmail(null);
  }, [setShowEmailList, setSelectedEmail, isMobile]);

  // Add handlers for mobile confirmation actions
  const handleKeepInInbox = () => {
    setShowMobileConfirmation(false);
    setShowEmailList(true);
    setSelectedEmail(null);
  };

  const handleArchive = () => {
    // Archive the email
    if (selectedEmailIds.length > 0) {
      handleAction('archive');
    }
    setShowMobileConfirmation(false);
    setShowEmailList(true);
    setSelectedEmail(null);
  };

  const handleCancelConfirmation = () => {
    setShowMobileConfirmation(false);
  };

  const handleNewChat = () => {
    // Create new chat email object
    const newChatEmail = {
      id: "thread_newLexiChat",
      fromName: "Lexi", 
      date: new Date().toISOString(),
      subject: "",
      snippet: "Start a new conversation here.",
      messages: []
    };
    setSelectedEmail(newChatEmail);
    setShowEmailList(false);
  };
  

  return (
    <Box sx={{ 
      flexGrow: 0, 
      position: 'relative',
      width: '100%',
      marginBottom: 0 
    }}>
      <CustomAppBar position="static">
        <Toolbar sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
        }}>
          {isMobile ? (
            showEmailList ? (
              <Box sx={{ width: '100%' }}>
                {searchBarElement}
              </Box>
            ) : (
              <Box sx={{ 
                width: '100%', 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}>
                {showMobileConfirmation ? (
                  <Fade in={showMobileConfirmation} timeout={200}>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 2,
                        backgroundColor: theme.palette.primary.main,
                        zIndex: 10,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                        }}
                      >
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            handleKeepInInbox();
                          }}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            pl: 0,
                            ml: -1,
                            cursor: 'pointer',
                          }}
                        >
                          <ArrowBackIcon sx={{ fontSize: '1.2rem' }} />
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              fontWeight: 500,
                              cursor: 'pointer',
                              '&:hover': {
                                opacity: 0.8
                              }
                            }}
                          >
                            Keep
                          </Typography>
                        </Box>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleArchive();
                          }}
                          sx={{
                            color: 'white',
                            backgroundColor: theme.palette.accent.darkGreen,
                            minWidth: '80px',
                            padding: '4px 12px',
                            fontSize: '0.8rem',
                            '&:hover': {
                              backgroundColor: theme.palette.accent.darkGreen,
                              opacity: 0.8,
                            }
                          }}
                        >
                          Archive
                        </Button>
                      </Box>
                      <Button
                        size="small"
                        onClick={handleCancelConfirmation}
                        sx={{
                          color: theme.palette.text.secondary,
                          fontSize: '0.8rem',
                          mr: -1,
                          minWidth: 'auto',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.text.primary,
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Fade>
                ) : (
                  <>
                    <BackIconButton
                      onClick={() => handleBack(currentFolder, selectedEmail)}
                      aria-label="back to list"
                    >
                      <ArrowBackIcon />
                    </BackIconButton>
                    <ActionButtons />
                  </>
                )}
              </Box>
            )
          ) : (
            (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ 
                    display: { xs: 'block', sm: 'none' },
                    marginRight: 1
                  }}>
                    <IconButton
                      onClick={() => setIsSidebarOpen(true)}
                      sx={{ color: theme.palette.ui.textLight }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                  <ActionButtons />
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  flex: 1,
                  margin: '0 auto',
                }}>
                  {searchBarElement}
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  minWidth: 'fit-content',
                }}>
                  {indexingStatus && 
                   indexingStatus.status === 'processing_emails' && 
                   indexingStatus.total_count > 0 &&
                   indexingStatus.processed_count > 0 && (
                    <WarningMessage>
                      <CircularProgress size={20} thickness={4} sx={{ color: theme => theme.palette.accent.darkGreen }}/>
                      <span>
                        Indexing new emails {
                          indexingStatus.processed_count && indexingStatus.total_count 
                            ? `${Math.round((indexingStatus.processed_count / indexingStatus.total_count) * 100)}%`
                            : '0%'
                        }
                      </span>
                    </WarningMessage>
                  )}                  
                  {!isMobile && (
                    <WhiteButton 
                      onClick={handleNewEmail}
                      sx={{
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        padding: 0,
                      }}
                    >
                      <div className="button-content" style={{ width: '100%' }}>
                        <AddIcon />
                        <span style={{ marginLeft: '8px', whiteSpace: 'nowrap' }}>New email</span>
                        <span className="shortcut" style={{ whiteSpace: 'nowrap' }}>{shortcutKey}</span>
                      </div>
                    </WhiteButton>
                  )}
                </Box>
              </>
            )
          )}
        </Toolbar>
      </CustomAppBar>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        fullScreen={isMobile}
        PaperProps={{
          sx: { 
            height: isMobile ? '80vh' : '90vh',
            maxHeight: isMobile ? '80vh' : '90vh',
            minWidth: isMobile ? '100vw' : { sm: '800px', md: '1000px' },
            width: isMobile ? '100vw' : '90vw',
            m: isMobile ? 0 : 2,
            borderRadius: isMobile ? '16px 16px 0 0' : '8px',
            overflow: 'hidden',
            bgcolor: theme.palette.primary.light,
            position: isMobile ? 'fixed' : 'relative',
            bottom: isMobile ? 0 : 'auto',
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.7)',
          },
        }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleCloseDialog();
          }
        }}
      >
        <DialogContent 
          sx={{ 
            p: 0,
            height: '100%',
            overflow: 'hidden',
            backgroundColor: 'transparent',
            position: 'relative',
          }}
        >
          <EmailComponent           
            emailData={emailData} 
            sendStatus={sendStatus}
            toFieldRef={emailToFieldRef}
            onClose={handleCloseDialog}
            isDraft={false}
            isMobile={isMobile}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isMobile && showEmailList && (
        <FloatingWhiteButton
          onClick={currentFolder === 'conversations' ? handleNewChat : handleNewEmail}
          sx={{
            position: 'fixed',
            bottom: currentFolder === 'conversations' ? 'calc(25px + env(safe-area-inset-bottom))' : 'calc(60px + env(safe-area-inset-bottom))',
            right: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <div className="button-content">
            <AddIcon />
            <span>{currentFolder === 'conversations' ? 'New chat' : 'New email'}</span>
          </div>
        </FloatingWhiteButton>
      )}
    </Box>
  );
};

export default React.memo(AppHeader);