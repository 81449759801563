import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Dialog, DialogContent, Box, List, ListItem, ListItemIcon, Divider, Typography, Button, Avatar, IconButton, Tooltip, CircularProgress, Slide, ListItemAvatar, ListItemText, ListItemSecondaryAction, TextField, Select, MenuItem, FormControl } from '@mui/material';
import { Person as PersonIcon, Settings as SettingsIcon, Close as CloseIcon, Add as AddIcon, Delete as DeleteIcon, CloseFullscreen as CloseFullscreenIcon, GroupAdd, Build as BuildIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import ChatIcon from '@mui/icons-material/Chat';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './QuillPreview.css';
import { height, styled } from '@mui/system';
import ImageResize from 'quill-image-resize-module-react';
import { Quill } from 'react-quill';
import { axiosWithAuth, refreshAccessToken, saveAccountData, switchAccount, stopTokenRefreshInterval } from './authService';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LogoutIcon from '@mui/icons-material/Logout';
import { debounce } from 'lodash';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DrawIcon from '@mui/icons-material/Draw';
import Zoom from '@mui/material/Zoom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArticleIcon from '@mui/icons-material/Article';
import DescriptionIcon from '@mui/icons-material/Description';
import CodeIcon from '@mui/icons-material/Code';
import DashboardIcon from '@mui/icons-material/Dashboard';
// Import the newly created DashboardTab component
import DashboardTab from './DashboardTab';
// Keep the existing Chart.js imports for other components that might use them
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { useSnackbar } from 'notistack';
import { ReactComponent as WordIcon } from '../icons/word-icon.svg';
import { ReactComponent as ExcelIcon } from '../icons/excel-icon.svg';
import { ReactComponent as PowerPointIcon } from '../icons/powerpoint-icon.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { alpha } from '@mui/material/styles';
import NoCodeAutomationUI from './NoCodeAutomationUI';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Legend,
  TimeScale
);

// Register Quill modules
Quill.register('modules/imageResize', ImageResize);

// Set up custom font sizes
const Size = Quill.import('attributors/style/size');
// Allow any font size value
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px'];
Quill.register(Size, true);

// Define custom font size options for dropdown
const fontSizeOptions = Size.whitelist;

const getGradientColor = (name) => {
  // Handle undefined or empty name
  if (!name) {
    return `linear-gradient(135deg, 
      hsl(220, 70%, 50%) 0%, 
      hsl(200, 65%, 45%) 100%
    )`;
  }

  // Generate a unique hue based on the name
  const nameHash = name.split('').reduce((acc, char, i) => acc + char.charCodeAt(0) * (i + 1), 0);
  const hue = nameHash % 360; // Get a value between 0-359 for the hue
  
  // Create complementary hues for the gradient
  const hue2 = (hue + 20) % 360;
  
  // Use vibrant colors with higher saturation and medium lightness
  const saturation = 70 + (name.length % 15); // 70-85%
  const lightness = 35 + (name.length % 15); // 35-50% - darker to ensure visibility

  return `linear-gradient(135deg, 
    hsl(${hue}, ${saturation}%, ${lightness}%) 0%, 
    hsl(${hue2}, ${saturation - 5}%, ${lightness - 5}%) 100%
  )`;
};

const StyledReactQuill = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  height: '100%',
  '& .quill': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: 0,
  },
  '& .ql-container': {
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
    flex: 1,
    overflowY: 'auto',
    padding: 0,
    minHeight: 0,
    '& p': {
      whiteSpace: 'pre-wrap',
      lineHeight: '1.5',
      [`@media (max-width: 600px)`]: {
        margin: '2px 0',
      },
    },
    '& .ql-size-small': {
      fontSize: '10px',
    },
    '& .ql-size-normal': {
      fontSize: '14px',
    },
    '& .ql-size-large': {
      fontSize: '18px',
    },
    '& .ql-size-huge': {
      fontSize: '24px',
    },
    '& [style*="font-size: 10px"]': { fontSize: '10px !important' },
    '& [style*="font-size: 12px"]': { fontSize: '12px !important' },
    '& [style*="font-size: 14px"]': { fontSize: '14px !important' },
    '& [style*="font-size: 16px"]': { fontSize: '16px !important' },
    '& [style*="font-size: 18px"]': { fontSize: '18px !important' },
    '& [style*="font-size: 20px"]': { fontSize: '20px !important' },
    '& [style*="font-size: 24px"]': { fontSize: '24px !important' },
    '& [style*="font-size: 30px"]': { fontSize: '30px !important' },
    '& [style*="font-size: 36px"]': { fontSize: '36px !important' },
    '& img': {
      maxWidth: '100%',
      minWidth: '20px',
      minHeight: '20px',
      maxHeight: '300px',
      height: 'auto !important',
      display: 'block',
      margin: '10px 0',
      cursor: 'default',
      [`@media (max-width: 600px)`]: {
        height: 'auto !important',
        minWidth: '20px',
        minHeight: '20px',
        objectFit: 'contain',
        margin: '5px 0',
        display: 'block',
        padding: 0
      },
      '&.focused': {
        outline: '2px solid #666',
      },
    },
  },
  '& .ql-toolbar': {
    border: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '8px 0',
    position: 'sticky',
    top: 0,
    backgroundColor: 'transparent',
    zIndex: 2,
    '& button': {
      border: 'none',
      '&:hover': {
        color: theme.palette.text.secondary,
      },
      '&.ql-active': {
        color: theme.palette.text.secondary,
      },
    },
    '& .ql-picker-options': {
      '& .ql-picker-item:hover': {
        color: theme.palette.text.primary,
      },
      '& .ql-picker-item': {
        '&[data-value="10px"]::before': { content: '"10px"', fontSize: '10px' },
        '&[data-value="12px"]::before': { content: '"12px"', fontSize: '12px' },
        '&[data-value="14px"]::before': { content: '"14px"', fontSize: '14px' },
        '&[data-value="16px"]::before': { content: '"16px"', fontSize: '16px' },
        '&[data-value="18px"]::before': { content: '"18px"', fontSize: '18px' },
        '&[data-value="20px"]::before': { content: '"20px"', fontSize: '20px' },
        '&[data-value="24px"]::before': { content: '"24px"', fontSize: '24px' },
        '&[data-value="30px"]::before': { content: '"30px"', fontSize: '30px' },
        '&[data-value="36px"]::before': { content: '"36px"', fontSize: '36px' },
      }
    },
    '& .ql-picker-label': {
      '&:hover': {
        color: theme.palette.text.primary,
      },
      '&[data-value="10px"]::before': { content: '"10px"' },
      '&[data-value="12px"]::before': { content: '"12px"' },
      '&[data-value="14px"]::before': { content: '"14px"' },
      '&[data-value="16px"]::before': { content: '"16px"' },
      '&[data-value="18px"]::before': { content: '"18px"' },
      '&[data-value="20px"]::before': { content: '"20px"' },
      '&[data-value="24px"]::before': { content: '"24px"' },
      '&[data-value="30px"]::before': { content: '"30px"' },
      '&[data-value="36px"]::before': { content: '"36px"' },
    },
    '& .ql-picker.ql-expanded .ql-picker-label': {
      color: theme.palette.text.primary,
    },
  },
  '& .ql-editor': {
    minHeight: '200px',
    maxHeight: '50vh',
    overflowY: 'auto',
    padding: '8px',
    [`@media (max-width: 600px)`]: {
      padding: '4px',
      '& p': {
        margin: 0,
        padding: 0
      }
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.divider,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.action.hover,
    },
    '& p': {
      margin: '0',
      padding: '0',
      marginBottom: '0em',
    },
    '& p:last-child': {
      marginBottom: 1,
    },
  },
  '& .image-resizer': {
    position: 'relative',
    display: 'inline-block',
    minWidth: '20px',
    minHeight: '20px',
    padding: 0,
    margin: 0,
    [`@media (max-width: 600px)`]: {
      display: 'block',
      margin: '5px 0',
      padding: 0,
      left: 0
    },
    '& .resize-handle': {
      position: 'absolute',
      height: '6px',
      width: '6px',
      backgroundColor: '#666',
      border: '1px solid white',
      cursor: 'pointer',
      zIndex: 100,
      padding: 0,
      [`@media (max-width: 600px)`]: {
        width: '10px',
        height: '10px',
        border: '2px solid white'
      },
      '&.se': {
        right: '-3px',
        bottom: '-3px',
        cursor: 'se-resize',
      },
      '&.sw': {
        left: '-3px',
        bottom: '-3px',
        cursor: 'sw-resize',
      },
      '&.ne': {
        right: '-3px',
        top: '-3px',
        cursor: 'ne-resize',
      },
      '&.nw': {
        left: '-3px',
        top: '-3px',
        cursor: 'nw-resize',
      }
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Slide direction={isMobile ? "up" : "down"} ref={ref} {...props} />;
});

// Add a new transition for expanding dialogs
const ExpandTransition = React.forwardRef(function ExpandTransition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const ProfileDialog = ({ open=true, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fileInputRef = useRef(null);
  // Initialize with 'navigation' on mobile, 'profile' on desktop
  const [selectedTab, setSelectedTab] = useState(isMobile ? 'navigation' : 'profile');
  const [signature, setSignature] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [quickReplies, setQuickReplies] = useState([]);
  const [isLoadingQuickReplies, setIsLoadingQuickReplies] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [processingStatus, setProcessingStatus] = useState({
    counts: {
      completed: 0,
      pending: 0,
      processing: 0,
      failed: 0,
      total: 0
    }
  });
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const navigate = useNavigate();

  // Add preview dialog state
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedFileContent, setSelectedFileContent] = useState(null);
  const [isLoadingContent, setIsLoadingContent] = useState(false);

  // Add accounts state
  const [accounts, setAccounts] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [isAccountSwitching, setIsAccountSwitching] = useState(false);

  const [editableUserInfo, setEditableUserInfo] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  // Add state variables for the assistant tab
  const [generalAssistantPrompt, setGeneralAssistantPrompt] = useState('');
  const [emailGenerationPrompt, setEmailGenerationPrompt] = useState('');
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [documentToDeleteIndex, setDocumentToDeleteIndex] = useState(null);
  // Add state for manual content dialog
  const [isAddContentDialogOpen, setIsAddContentDialogOpen] = useState(false);
  const [newContentName, setNewContentName] = useState('');
  const [newContentText, setNewContentText] = useState('');

  // Add new state variables for dashboard
  const [dashboardMetrics, setDashboardMetrics] = useState(null);
  const [analyzedUserEmail, setAnalyzedUserEmail] = useState(null);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [namespaceOptions, setNamespaceOptions] = useState([]);
  const [documentSearchQuery, setDocumentSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const { enqueueSnackbar } = useSnackbar();

  console.log('accounts', accounts);
  console.log('currentAccount', currentAccount);
  console.log('editableUserInfo', editableUserInfo);
  console.log('userInfo', userInfo);

  useEffect(() => {
    if (open) {
      const initializeData = async () => {
        try {
          // Load stored counts first
          const storedCounts = localStorage.getItem('emailCounts');
          let shouldFetchCounts = true;

          if (storedCounts) {
            const { lastUpdated, ...counts } = JSON.parse(storedCounts);
            setProcessingStatus(prev => ({
              ...prev,
              counts: counts
            }));
            
            // Only skip fetch if we have valid counts and they're recent
            if (counts.total > 0 && Date.now() - lastUpdated < 5000) {
              shouldFetchCounts = false;
            }
          }

          // Other initialization code...
          // Load user info
          const storedUserInfo = localStorage.getItem('userInfo');
          if (storedUserInfo) {
            const parsedUserInfo = JSON.parse(storedUserInfo);
            setUserInfo(parsedUserInfo);
            console.log('Loaded user info:', parsedUserInfo);
          }

          // Load user photo
          const storedPhoto = localStorage.getItem('userPhoto');
          if (storedPhoto) {
            setUserPhoto(storedPhoto);
            console.log('Loaded user photo');
          }

          // Load signature
          const storedSignature = localStorage.getItem('userSignature');
          if (storedSignature) {
            setSignature(storedSignature);
            console.log('Loaded signature');
          }

          // Fetch counts if needed
          if (shouldFetchCounts) {
            await fetchEmailCounts();
          }

          // Check if there's an active job and update status
          const storedJobId = localStorage.getItem('emailProcessingJobId');
          if (storedJobId) {
            setProcessingStatus(prev => ({ ...prev, isActive: true }));
            await fetchEmailCounts(); // Get fresh counts
          }
          
        } catch (error) {
          console.error('Error initializing stored data:', error);
        }
      };

      initializeData();
    }
  }, [open]);

  // Update selectedTab when mobile status changes
  useEffect(() => {
    if (isMobile) {
      setSelectedTab('navigation');
    } else {
      setSelectedTab('profile');
    }
  }, [isMobile]);

  console.log('userInfo in profile dialog', userInfo);

  useEffect(() => {
    if (userInfo) {
      setEditableUserInfo(userInfo);
    }
  }, [userInfo]);

  // Add effect to initialize assistant prompts from userInfo
  useEffect(() => {
    if (userInfo) {
      // Initialize assistant prompts from userInfo if available
      if (userInfo.AssistantPrompt) {
        setGeneralAssistantPrompt(userInfo.AssistantPrompt);
      }
      if (userInfo.EmailPrompt) {
        setEmailGenerationPrompt(userInfo.EmailPrompt);
      }
    }
  }, [userInfo]);

  // Update the debouncedSaveProfile function
  const debouncedSaveProfile = useCallback(
    debounce(async (updatedInfo) => {
      try {
        const userEmail = updatedInfo?.mail || updatedInfo?.userPrincipalName;
        
        // Update backend
        const response = await axiosWithAuth.post(`${process.env.REACT_APP_API_URL}/update-airtable-user-object`, {
          user_email: userEmail,
          user_data: updatedInfo
        });

        if (response.status === 200) {
          // Only update localStorage and emit events, don't set state
          localStorage.setItem('userInfo', JSON.stringify(updatedInfo));
          
          // Update accounts list and current account in localStorage
          const allAccounts = JSON.parse(localStorage.getItem('accounts') || '[]');
          const updatedAccounts = allAccounts.map(account => {
            if (account.email === userEmail) {
              return {
                ...account,
                userInfo: updatedInfo,
                name: `${updatedInfo.givenName} ${updatedInfo.surname}`
              };
            }
            return account;
          });
          
          localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
          
          // Update current account if it's the same user
          const currentAccount = JSON.parse(localStorage.getItem('currentAccount'));
          if (currentAccount?.email === userEmail) {
            const updatedCurrentAccount = {
              ...currentAccount,
              userInfo: updatedInfo,
              name: `${updatedInfo.givenName} ${updatedInfo.surname}`
            };
            localStorage.setItem('currentAccount', JSON.stringify(updatedCurrentAccount));
          }
          
          // Emit custom event for account updates
          window.dispatchEvent(new CustomEvent('accountsUpdated', {
            detail: {
              accounts: updatedAccounts,
              currentAccount: currentAccount?.email === userEmail ? {
                ...currentAccount,
                userInfo: updatedInfo,
                name: `${updatedInfo.givenName} ${updatedInfo.surname}`
              } : currentAccount
            }
          }));
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        // Revert changes on error
        setEditableUserInfo(userInfo);
      }
    }, 2000),
    [userInfo]
  );

  // Add effect to save profile changes when editableUserInfo changes
  useEffect(() => {
    // Skip the first render when editableUserInfo is initialized from userInfo
    if (!editableUserInfo || JSON.stringify(editableUserInfo) === JSON.stringify(userInfo)) {
      return;
    }
    
    // Call the debouncedSaveProfile function with the updated user info
    debouncedSaveProfile(editableUserInfo);
  }, [editableUserInfo, userInfo, debouncedSaveProfile]);

  // const handleAddQuickReply = async (newReplyText) => {
  //   if (newReplyText.trim() && quickReplies.length < 6) {
  //     const userEmail = userInfo?.mail || userInfo?.userPrincipalName;
  //     const tempId = `temp-${Date.now()}-${Math.random()}`;
      
  //     setQuickReplies(prevReplies => [...prevReplies, { id: tempId, text: newReplyText }]);

  //     try {
  //       const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/add-quick-action', { 
  //         data: { item_text: newReplyText, user_email: userEmail }
  //       });
        
  //       setQuickReplies(prevReplies => 
  //         prevReplies.map(reply => 
  //           reply.id === tempId ? { id: response.data.id || tempId, text: newReplyText } : reply
  //         )
  //       );
        
  //       sessionStorage.setItem('quickReplies', JSON.stringify(quickReplies));
  //     } catch (error) {
  //       console.error('Error adding quick reply:', error);
  //       setQuickReplies(prevReplies => prevReplies.filter(reply => reply.id !== tempId));
  //     }
  //   }
  // };

  // const handleDeleteQuickReply = async (replyToDelete) => {
  //   const userEmail = userInfo?.mail || userInfo?.userPrincipalName;
  //   setQuickReplies(prevReplies => prevReplies.filter(reply => reply.id !== replyToDelete.id));

  //   try {
  //     await axiosWithAuth.delete(process.env.REACT_APP_API_URL + '/delete-quick-action', { 
  //       data: { item_text: replyToDelete.text, user_email: userEmail }
  //     });
  //     sessionStorage.setItem('quickReplies', JSON.stringify(quickReplies));
  //   } catch (error) {
  //     console.error('Error deleting quick reply:', error);
  //     setQuickReplies(prevReplies => [...prevReplies, replyToDelete]);
  //   }
  // };

  const handleLogout = async (accountToLogout) => {
    setIsLoggingOut(true);
    try {      
      const userEmail = accountToLogout?.email || userInfo?.mail || userInfo?.userPrincipalName;
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
      
      // Get all accounts and filter out the current one
      const allAccounts = JSON.parse(localStorage.getItem('accounts') || '[]');
      const remainingAccounts = allAccounts.filter(acc => acc.email !== userEmail);
      
      // If we have remaining accounts, switch to the first one
      if (remainingAccounts.length > 0) {
        try {
          // Call backend to logout single account
          // await axiosWithAuth.post(`${baseUrl}/logout`, {
          //   user_email: userEmail,
          //   remaining_accounts: remainingAccounts.length
          // });

          // Store the next account's data before cleanup
          const nextAccount = remainingAccounts[0];
          const nextAccountData = {
            userInfo: nextAccount.userInfo,
            userPhoto: nextAccount.userPhoto,
            userSignature: nextAccount.userSignature,
            isSubscribed: nextAccount.isSubscribed,
            accessToken: nextAccount.accessToken
          };

          // Clean up localStorage for the current account
          const localStorageKeys = Object.keys(localStorage);
          localStorageKeys.forEach(key => {
            // Remove all items except accounts and account-specific data for other accounts
            if (key !== 'accounts' && !key.startsWith('account_') && 
                !key.startsWith(`${nextAccount.email}_`)) {
              localStorage.removeItem(key);
            }
          });

          // Clean up sessionStorage for the current account
          const sessionStorageKeys = Object.keys(sessionStorage);
          sessionStorageKeys.forEach(key => {
            // Remove all items except account-specific data for other accounts
            if (!key.startsWith('account_') && !key.startsWith(`${nextAccount.email}_`)) {
              sessionStorage.removeItem(key);
            }
          });
          
          // Update accounts in localStorage
          localStorage.setItem('accounts', JSON.stringify(remainingAccounts));
          localStorage.setItem('currentAccount', JSON.stringify(nextAccount));

          // Set the next account's data
          Object.entries(nextAccountData).forEach(([key, value]) => {
            if (value) localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
          });

          // Emit custom event for account removal
          window.dispatchEvent(new CustomEvent('accountsUpdated', {
            detail: {
              accounts: remainingAccounts,
              currentAccount: nextAccount
            }
          }));
          
          // If logging out current account, switch to the first remaining account
          if (userEmail === currentAccount?.email) {
            await switchAccount(nextAccount);
            await onClose();
            window.location.reload();
          } else {
            // Just update the accounts list if not logging out current account
            setAccounts(remainingAccounts);
          }
        } catch (error) {
          console.error('Error during account switch:', error);
          setIsLoggingOut(false);
        }
      } else {
        
        // Stop token refresh interval
        // stopTokenRefreshInterval();
        
        localStorage.clear();
        sessionStorage.clear();
        await onClose();
        navigate('/auth', { replace: true });
      }
    } catch (error) {
      console.error('Logout error:', error);
      setIsLoggingOut(false);
      navigate('/auth?error=logout_failed');
    }
  };

  // Add debounced save function
  const debouncedSaveSignature = useCallback(
    debounce(async (newSignature, userEmail) => {
      try {
        const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/save-email-signature', {
          signature: newSignature,
          user_email: userEmail
        });

        if (response.status === 200) {
          localStorage.setItem('userSignature', newSignature);
          console.log('Signature saved automatically');
        }
      } catch (error) {
        console.error('Error saving signature:', error);
      }
    }, 2000), // 2 second delay
    []
  );

  // Add function to process content and preserve dimensions
  const processSignatureContent = (content) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    
    // Process images
    const images = tempDiv.getElementsByTagName('img');
    Array.from(images).forEach(img => {
      // Preserve existing width/height attributes
      if (img.getAttribute('width')) {
        img.style.width = img.getAttribute('width');
      }
      if (img.getAttribute('height')) {
        img.style.height = img.getAttribute('height');
      }
      // Convert style dimensions to attributes if they exist
      if (img.style.width || img.style.height) {
        img.setAttribute('width', img.style.width || img.width + 'px');
        img.setAttribute('height', img.style.height || img.height + 'px');
      }
    });
    
    // Ensure all font sizes are applied as inline styles
    const elements = tempDiv.querySelectorAll('*');
    Array.from(elements).forEach(el => {
      // Check for font size in style attribute
      const style = el.getAttribute('style');
      if (style && style.includes('font-size')) {
        // Font size already in style, make sure it's using px
        if (!style.includes('px')) {
          const size = style.match(/font-size:\s*([^;]+)/)?.[1];
          if (size) {
            // Convert to px if needed
            const newStyle = style.replace(/font-size:\s*[^;]+/, `font-size: ${size}px`);
            el.setAttribute('style', newStyle);
          }
        }
      } else if (el.className && el.className.includes('ql-size-')) {
        // Convert class-based size to inline style
        const sizeClass = el.className.match(/ql-size-([a-z]+)/)?.[1];
        if (sizeClass) {
          let fontSize = '14px'; // Default
          if (sizeClass === 'small') fontSize = '10px';
          if (sizeClass === 'large') fontSize = '18px';
          if (sizeClass === 'huge') fontSize = '24px';
          
          const currentStyle = el.getAttribute('style') || '';
          el.setAttribute('style', `${currentStyle}; font-size: ${fontSize};`);
        }
      }
    });
    
    return tempDiv.innerHTML;
  };

  // Update useEffect to process initial content and handle mutations
  useEffect(() => {
    // Process initial content
    if (signature) {
      const processedContent = processSignatureContent(signature);
      if (processedContent !== signature) {
        setSignature(processedContent);
      }
    }

    // Initialize Quill for proper font sizing
    if (selectedTab === 'signature') {
      setTimeout(() => {
        const quillEditor = document.querySelector('.ql-editor');
        if (quillEditor) {
          // Add a small delay to ensure Quill is fully initialized
          const toolbar = document.querySelector('.ql-toolbar');
          if (toolbar) {
            // Force size menu to update
            const sizeButton = toolbar.querySelector('.ql-size');
            if (sizeButton) {
              // Make sure the size picker shows properly
              sizeButton.click();
              document.body.click(); // Close the dropdown
            }
          }
        }
      }, 300);
    }

    const quill = document.querySelector('.ql-editor');
    if (quill) {
      // Process any existing images immediately
      const images = quill.getElementsByTagName('img');
      Array.from(images).forEach(img => {
        if (!img.getAttribute('data-sized')) {
          if (img.getAttribute('width')) {
            img.style.width = img.getAttribute('width');
          }
          if (img.getAttribute('height')) {
            img.style.height = img.getAttribute('height');
          }
          img.setAttribute('data-sized', 'true');
        }
      });

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'childList' || mutation.type === 'attributes') {
            const images = quill.getElementsByTagName('img');
            Array.from(images).forEach(img => {
              if (!img.getAttribute('data-sized')) {
                // Handle both style-based and attribute-based dimensions
                const width = img.style.width || img.getAttribute('width');
                const height = img.style.height || img.getAttribute('height');
                
                if (width || height) {
                  img.setAttribute('width', width);
                  img.setAttribute('height', height);
                  img.style.width = width;
                  img.style.height = height;
                  img.setAttribute('data-sized', 'true');
                }
              }
            });
          }
        });
      });

      observer.observe(quill, {
        attributes: true,
        childList: true,
        subtree: true,
        attributeFilter: ['style', 'width', 'height']
      });

      return () => observer.disconnect();
    }
  }, [signature, selectedTab]); // Add selectedTab as dependency

  // Update the signature change handler to use the new process function
  const handleSignatureChange = (content) => {
    const processedContent = processSignatureContent(content);
    setSignature(processedContent);
    const userEmail = userInfo?.mail || userInfo?.userPrincipalName;
    if (userEmail) {
      debouncedSaveSignature(processedContent, userEmail);
    }
  };

  const fetchEmailCounts = async () => {
    const userEmail = userInfo?.mail || userInfo?.userPrincipalName;
    if (!userEmail) return;
  
    setIsLoadingStatus(true);
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axiosWithAuth.get(`${baseUrl}/email-status-counts`, {
        params: { user_email: userEmail }
      });
      
      if (response.data.counts) {
        const apiCounts = response.data.counts;
        const mappedCounts = {
          completed: apiCounts.COMPLETED || 0,
          failed: apiCounts.FAILED || 0,
          pending: apiCounts.PENDING || 0,
          processing: apiCounts.PROCESSING || 0,
        };
        const total = Object.values(mappedCounts).reduce((a, b) => a + b, 0);
        
        const newCounts = { ...mappedCounts, total };
        localStorage.setItem('emailCounts', JSON.stringify({
          ...newCounts,
          lastUpdated: Date.now()
        }));

        setProcessingStatus(prev => ({
          ...prev,
          counts: newCounts
        }));
      }
    } catch (error) {
      console.error('Failed to fetch email counts:', error);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const quillModules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'size': fontSizeOptions }],
        ['bold', 'italic', 'underline'],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
      ],
      handlers: {
        // Custom handler to ensure size values are properly applied
        size: function(value) {
          this.quill.format('size', value);
        }
      }
    },
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize'],
      handleStyles: {
        backgroundColor: '#666',
        border: '1px solid white',
        color: 'white',
        width: '6px',
        height: '6px',
        cursor: 'pointer'
      },
      displayStyles: {
        backgroundColor: 'black',
        border: 'none',
        color: 'white',
        padding: '3px 6px',
        fontSize: '11px'
      },
      minWidth: 20,
      minHeight: 20,
    }
  }), []);

  const quillFormats = [
    'size',
    'bold', 'italic', 'underline',
    'link', 'image',
    'color', 'background',
    'width', 'height',
    'align', 'float'  // Add these formats for image positioning
  ];

  const sidebarItems = [
    { text: 'Profile', icon: <PersonIcon />, key: 'profile', group: 'main' },
    { text: 'Assistant', icon: <SmartToyIcon />, key: 'assistant', group: 'main' },
    { text: 'Signature', icon: <DrawIcon />, key: 'signature', group: 'main' },
    // { text: 'Automations', icon: <BuildIcon />, key: 'automations', group: 'main' },
    // { text: '30-day Dashboard', icon: <DashboardIcon />, key: 'dashboard', group: 'bottom' },
    { text: 'Accounts', icon: <GroupAdd />, key: 'accounts', group: 'bottom' },
  ];

  useEffect(() => {
    if (open) {
      // Initialize with accounts from localStorage
      const storedAccounts = JSON.parse(localStorage.getItem('accounts') || '[]');
      const currentAccountData = JSON.parse(localStorage.getItem('currentAccount') || 'null');
      
      if (storedAccounts.length > 0) {
        // Update stored accounts with current photo URLs
        const updatedAccounts = storedAccounts.map(account => {
          // If this is the current user's account, ensure we have the latest photo
          if (account.email === currentAccountData?.email) {
            const currentPhoto = localStorage.getItem('userPhoto');
            return {
              ...account,
              userPhoto: currentPhoto || account.userPhoto
            };
          }
          return account;
        });
        
        setAccounts(updatedAccounts);
        setCurrentAccount(currentAccountData || updatedAccounts[0]);
        
        // Update localStorage with the latest account data
        localStorage.setItem('accounts', JSON.stringify(updatedAccounts));
      } else {
        // If no accounts, initialize with current user's account only if not already stored
        const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
        if (userInfo && userInfo.userPrincipalName) {
          const userEmail = userInfo.userPrincipalName;
          const currentPhoto = localStorage.getItem('userPhoto');
          
          // Check if this account was previously stored
          const wasAccountStored = storedAccounts.some(acc => acc.email === userEmail);
          
          if (!wasAccountStored) {
            const mainAccount = {
              name: `${userInfo.givenName} ${userInfo.surname}`,
              email: userEmail,
              userPhoto: currentPhoto, // Use the raw base64 data
              isActive: true,
              accessToken: localStorage.getItem('accessToken'),
              userInfo: userInfo,
              isSubscribed: localStorage.getItem('isSubscribed') === 'true',
              userSignature: localStorage.getItem('userSignature'),
            };
            setAccounts([mainAccount]);
            setCurrentAccount(mainAccount);
            
            // Store the account data
            localStorage.setItem('accounts', JSON.stringify([mainAccount]));
            localStorage.setItem('currentAccount', JSON.stringify(mainAccount));
          }
        }
      }

      // Add event listener for account updates
      const handleAccountsUpdated = (event) => {
        const { accounts: updatedAccounts, currentAccount: updatedCurrentAccount } = event.detail;
        setAccounts(updatedAccounts);
        setCurrentAccount(updatedCurrentAccount);
      };

      window.addEventListener('accountsUpdated', handleAccountsUpdated);

      // Cleanup
      return () => {
        window.removeEventListener('accountsUpdated', handleAccountsUpdated);
      };
    }
  }, [open]);

  const handleAddAccount = () => {
    // Logic for adding new account will be implemented later
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/microsoft?add_account=true`;
  };

  const handleAccountSelect = async (account) => {
    if (account.email === currentAccount?.email || isAccountSwitching) {
      return;
    }

    setIsAccountSwitching(true);
    try {
      // Switch to the selected account
      await switchAccount(account);
      
      // Update local state
      setCurrentAccount(account);
      
      // Emit custom event for account switching
      window.dispatchEvent(new CustomEvent('accountsUpdated', {
        detail: {
          accounts,
          currentAccount: account
        }
      }));
      
      // Close the profile dialog
      onClose();
      
      // Reload the page to reinitialize with new account
      window.location.reload();
    } catch (error) {
      console.error('Error switching account:', error);
    } finally {
      setIsAccountSwitching(false);
    }
  };


  console.log('accounts', accounts);

  const handleFieldChange = (field, value) => {
    setEditableUserInfo(prev => ({ ...prev, [field]: value }));
  };

  const renderContent = () => {
    // Only show back button if not on navigation view
    if (isMobile && selectedTab !== 'navigation') {
      return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ 
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.primary.light,
            zIndex: 1,
            p: 3,
            pb: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => setSelectedTab('navigation')}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ ml: 1 }}>
                {sidebarItems.find(item => item.key === selectedTab)?.text || ''}
              </Typography>
            </Box>
            <IconButton 
              aria-label="close"
              onClick={onClose}
              sx={{
                color: theme.palette.grey[500],
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <CloseIcon sx={{ color: theme.palette.accent.primary, backgroundColor: 'transparent' }} />
            </IconButton>
          </Box>
          <Box sx={{ p: 3, flex: 1, overflowY: 'auto' }}>
            {renderTabContent()}
          </Box>
        </Box>
      );
    }
    return (
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {selectedTab !== 'navigation' && (
          <Box sx={{ 
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.primary.light,
            zIndex: 1,
            p: 3,
            pb: 2,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h4" sx={{ color: theme.palette.accent.primary }}>
              {selectedTab === 'profile' && 'Profile'}
              {selectedTab === 'signature' && 'Signature'}
              {selectedTab === 'accounts' && 'Accounts'}
              {selectedTab === 'processing' && 'Processing Status'}
              {selectedTab === 'assistant' && 'Assistant'}
              {selectedTab === 'dashboard' && '30-day Dashboard'}
              {selectedTab === 'automations' && 'API Automations'}
            </Typography>
            <IconButton 
              aria-label="close"
              onClick={onClose}
              sx={{
                color: theme.palette.grey[500],
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >              
              <CloseIcon sx={{ color: theme.palette.accent.primary, backgroundColor: 'transparent' }} />
            </IconButton>
          </Box>
        )}
        <Box sx={{ p: 3, flex: 1, overflowY: 'auto' }}>
          {renderTabContent()}
        </Box>
      </Box>
    );
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'navigation':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" sx={{ mb: 4, color: theme.palette.ui.textDark }}>
              Hi, {userInfo?.givenName?.split(' ')[0] || "there"}
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {sidebarItems.map((item) => (
                <Button
                  key={item.key}
                  onClick={() => setSelectedTab(item.key)}
                  startIcon={item.icon}
                  sx={{
                    justifyContent: 'flex-start',
                    color: theme.palette.ui.textDark,
                    backgroundColor: theme.palette.accent.secondary,
                    p: 2,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  {item.text}
                </Button>
              ))}
            </Box>
          </Box>
        );

      case 'profile':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: 1, minWidth: '200px' }}>
                  <Typography variant="subtitle2" sx={{ color: theme.palette.ui.textLight, mb: 1 }}>
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={editableUserInfo?.givenName || ''}
                    onChange={(e) => handleFieldChange('givenName', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: theme.palette.primary.light,
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.accent.primary,
                          borderWidth: 2
                        }
                      }
                    }}
                  />
                </Box>
                <Box sx={{ flex: 1, minWidth: '200px' }}>
                  <Typography variant="subtitle2" sx={{ color: theme.palette.ui.textLight, mb: 1 }}>
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={editableUserInfo?.surname || ''}
                    onChange={(e) => handleFieldChange('surname', e.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: theme.palette.primary.light,
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.accent.primary,
                          borderWidth: 2
                        }
                      }
                    }}
                  />
                </Box>
              </Box>

              {[
                { label: 'Job Title', field: 'jobTitle' },
                { 
                  label: 'Preferred Language', 
                  field: 'preferredLanguage',
                  type: 'select',
                  options: [
                    { value: 'English', label: 'English' },
                    { value: 'Danish', label: 'Danish' }
                  ]
                },
              ].map((item, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography variant="subtitle2" sx={{ color: theme.palette.ui.textLight, mb: 1 }}>
                    {item.label}
                  </Typography>
                  {item.description && (
                    <Typography variant="body2" sx={{ 
                      mb: 2, 
                      color: theme.palette.ui.textLight,
                      fontSize: isMobile ? '0.875rem' : '0.875rem'
                    }}>
                      {item.description}
                    </Typography>
                  )}
                  {item.readOnly ? (
                    <Typography variant="body1" sx={{ color: theme.palette.ui.textDark }}>
                      {editableUserInfo?.[item.field] || 'Not specified'}
                    </Typography>
                  ) : item.type === 'select' ? (
                    <Select
                      fullWidth
                      size="small"
                      value={editableUserInfo?.[item.field] || 'en'}
                      onChange={(e) => handleFieldChange(item.field, e.target.value)}
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.accent.primary,
                          borderWidth: 2
                        }
                      }}
                    >
                      {item.options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      fullWidth
                      size="small"
                      value={editableUserInfo?.[item.field] || ''}
                      onChange={(e) => handleFieldChange(item.field, e.target.value)}
                      multiline={item.multiline}
                      rows={item.rows}
                      placeholder={item.placeholder}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: theme.palette.primary.light,
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.accent.primary,
                            borderWidth: 2
                          }
                        }
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        );

      case 'signature':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 32px)'}}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
              <StyledReactQuill sx={{ flex: 1, display: 'flex', flexDirection: 'column', minHeight: 0 }}>
                <ReactQuill 
                  className="quill"
                  theme="snow"
                  value={signature}
                  onChange={handleSignatureChange}
                  modules={quillModules}
                  formats={quillFormats}
                />
              </StyledReactQuill>
            </Box>
          </Box>
        );

      case 'processing':
        return (
          <Box sx={{ height: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              alignItems: 'center', 
              mb: 4,
              pr: 5,
              pt: 1
            }}>
              <Button
                variant="contained"
                onClick={fetchEmailCounts}
                disabled={isLoadingStatus}
              >
                {isLoadingStatus ? (
                  <CircularProgress size={24} color={theme.palette.accent.primary} />
                ) : (
                  'Update Counts'
                )}
              </Button>
            </Box>

            {/* Status Cards */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              maxWidth: '100%'
            }}>
              {[
                { 
                  title: 'Completed', 
                  value: processingStatus.counts.completed,
                  color: 'success.main',
                  lightColor: 'success.lighter'
                },
                { 
                  title: 'Pending', 
                  value: processingStatus.counts.pending,
                  color: 'warning.main',
                  lightColor: 'warning.lighter'
                },
              ].map((card, index) => (
                <Box
                  key={index}
                  sx={{ 
                    p: 3,  // Increased padding
                    borderRadius: 2,
                    bgcolor: card.lightColor || 'background.paper',
                    boxShadow: 1,
                    transition: 'transform 0.2s',
                    minHeight: '120px',  // Added minimum height
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 3
                    }
                  }}
                >
                  <Typography variant="h6" color="text.secondary">
                    {card.title}
                  </Typography>
                  <Typography 
                    variant="h3"  // Increased text size
                    sx={{ 
                      mt: 2,
                      color: card.color
                    }}
                  >
                    {card.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        );

      case 'assistant':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {/* General Assistant Prompt */}
            <Box>
              <Typography variant="h6" sx={{ 
                mb: 2, 
                color: theme.palette.accent.primary,
                fontSize: isMobile ? '1.1rem' : '1.25rem'
              }}>
                Assistant Instructions
              </Typography>
              <Typography variant="body2" sx={{ 
                mb: 2, 
                color: theme.palette.ui.textLight,
                fontSize: isMobile ? '0.875rem' : '0.875rem'
              }}>
                These instructions guide how the assistant interacts with you across all features.
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={isMobile ? 8 : 6}
                placeholder="Example: You are a helpful assistant that helps me write professional emails..."
                value={generalAssistantPrompt}
                onChange={handleAssistantPromptChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.primary.light,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.accent.primary,
                      borderWidth: 2
                    }
                  }
                }}
              />
            </Box>

            {/* Email Generation Prompt */}
            <Box>
              <Typography variant="h6" sx={{ 
                mb: 2, 
                color: theme.palette.accent.primary,
                fontSize: isMobile ? '1.1rem' : '1.25rem'
              }}>
                Email Generation Instructions
              </Typography>
              <Typography variant="body2" sx={{ 
                mb: 2, 
                color: theme.palette.ui.textLight,
                fontSize: isMobile ? '0.875rem' : '0.875rem'
              }}>
                These instructions specifically guide how the assistant generates emails for you.
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={isMobile ? 8 : 6}
                placeholder="Example: When generating emails, use a professional tone and be concise..."
                value={emailGenerationPrompt}
                onChange={handleEmailGenerationPromptChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.primary.light,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.accent.primary,
                      borderWidth: 2
                    }
                  }
                }}
              />
            </Box>

            {/* Document Upload for Knowledge */}
            <Box>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between', 
                alignItems: isMobile ? 'flex-start' : 'center', 
                mb: 2 
              }}>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <Typography variant="h6" sx={{ 
                    color: theme.palette.ui.textDark,
                    fontSize: isMobile ? '1.1rem' : '1.25rem',
                    mb: isMobile ? 1 : 0
                  }}>
                    Knowledge Documents
                  </Typography>
                  
                  {/* Namespace Selector */}
                  <FormControl 
                    variant="outlined" 
                    size="small"
                    sx={{ 
                      minWidth: 150,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: alpha(theme.palette.accent.primary, 0.3),
                        },
                        '&:hover fieldset': {
                          borderColor: alpha(theme.palette.accent.primary, 0.3),
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: alpha(theme.palette.accent.primary, 0.1),
                        },
                      },
                    }}
                  >
                    <Select
                      value={selectedNamespace}
                      onChange={handleNamespaceChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Select namespace' }}
                      sx={{
                        fontSize: '0.875rem',
                        color: theme.palette.accent.primary,
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 300,
                            backgroundColor: theme.palette.accent.secondary,
                            '& .MuiMenuItem-root': {
                              fontSize: '0.875rem',
                              py: 1,
                            },
                          },
                        },
                      }}
                    >
                      {namespaceOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '0.775rem' }}>
                            {option.label} ({option.description})
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ 
                  display: 'flex', 
                  gap: 1,
                  width: isMobile ? '100%' : 'auto'
                }}>
                  {/* Upload Button */}
                  {/* <Button
                    variant="outlined"
                    component="label"
                    startIcon={<AddIcon fontSize="small" />}
                    disabled={isUploadingDocument}
                    size="small"
                    fullWidth={isMobile}
                    sx={{
                      color: theme.palette.accent.primary,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.accent.primary, 0.04),
                      }
                    }}
                  >
                    {isUploadingDocument ? 'Uploading...' : 'Upload'}
                    <input
                      type="file"
                      hidden
                      onChange={handleFileUpload}
                      accept=".txt,.pdf,.doc,.docx,.md"
                      ref={fileInputRef}
                    />
                  </Button> */}
                  
                  {/* Add Content Button */}
                  <Button
                    // variant="outlined"
                    startIcon={<AddIcon fontSize="small" />}
                    size="small"
                    fullWidth={isMobile}
                    onClick={() => setIsAddContentDialogOpen(true)}
                    sx={{
                      color: theme.palette.accent.primary,
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.accent.primary, 0.04),
                      }
                    }}
                  >
                    Add Manual Content
                  </Button>
                </Box>
              </Box>

              {/* Hidden File Input */}
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
                accept=".txt,.pdf,.md,.doc,.docx,.ppt,.pptx"
                ref={fileInputRef}
              />

              {/* Drag and Drop Area */}
              <Box
                sx={{
                  border: '2px dashed',
                  borderColor: isDragging ? theme.palette.accent.primary : theme.palette.ui.border,
                  borderRadius: 2,
                  p: 3,
                  mb: 2,
                  textAlign: 'center',
                  backgroundColor: isDragging ? alpha(theme.palette.accent.primary, 0.05) : 'transparent',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  position: 'relative', // Added to ensure proper event capturing
                  '&:hover': {
                    borderColor: theme.palette.accent.primary,
                    backgroundColor: alpha(theme.palette.accent.primary, 0.05),
                  },
                  '& *': { // This ensures all child elements are considered part of the drop zone
                    pointerEvents: 'none', // This prevents child elements from intercepting drag events
                  }
                }}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body1" sx={{ color: theme.palette.ui.textDark, mb: 1 }}>
                    {'Drag and drop a file here'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.ui.textLight }}>
                    or click to browse
                  </Typography>
                  <Typography variant="caption" sx={{ color: theme.palette.ui.textLight, mt: 1 }}>
                    Supported formats: .txt, .pdf, .doc, .docx, .md
                  </Typography>
                </Box>
              </Box>
              
              {/* Document List */}
              <Box sx={{ mb: 2 }}>
                {/* Search bar for documents */}
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    placeholder="Search documents..."
                    size="small"
                    value={documentSearchQuery}
                    onChange={handleDocumentSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: theme.palette.accent.primary }} />
                        </InputAdornment>
                      ),
                      endAdornment: documentSearchQuery ? (
                        <InputAdornment position="end">
                          <IconButton 
                            size="small" 
                            onClick={() => setDocumentSearchQuery('')}
                            sx={{ color: theme.palette.text.secondary }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                      sx: {
                        backgroundColor: alpha(theme.palette.primary.light, 0.7),
                        borderRadius: 1
                      }
                    }}
                    sx={{ 
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.1)',
                        },
                        '&:hover fieldset': {
                          borderColor: theme.palette.accent.primary,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.accent.primary,
                        },
                      }
                    }}
                  />
                </Box>
                
                {isUploadingDocument && (
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    p: 2, 
                    bgcolor: alpha(theme.palette.accent.primary, 0.05),
                    borderRadius: 1,
                    mb: 2
                  }}>
                    <CircularProgress size={24} sx={{ mr: 2, color: theme.palette.accent.primary }} />
                    <Typography variant="body2" color="text.secondary">
                      Processing document...
                    </Typography>
                  </Box>
                )}
                
                {isLoadingDocuments ? (
                  <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 4,
                    bgcolor: theme.palette.primary.light,
                    borderRadius: 1
                  }}>
                    <CircularProgress size={32} sx={{ color: theme.palette.accent.primary, mb: 2 }} />
                    <Typography variant="body2" color="text.secondary">
                      Loading documents...
                    </Typography>
                  </Box>
                ) : uploadedDocuments.length > 0 ? (
                  <>
                    <List sx={{ 
                      bgcolor: theme.palette.accent.secondary, 
                      borderRadius: 1, 
                      border: '1px solid rgba(0, 0, 0, 0.1)',
                      maxHeight: isMobile ? '300px' : 'none',
                      overflowY: isMobile ? 'auto' : 'visible'
                    }}>
                      {filteredDocuments
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((doc, index) => (
                        <ListItem 
                          sx={{
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.accent.primary, 0.04),
                            },
                            cursor: 'pointer'
                          }}
                          key={doc.id || index}
                          onClick={() => handleFilePreview(doc)}
                          secondaryAction={
                            <IconButton 
                              sx={{ 
                                color: alpha(theme.palette.accent.primary, 0.8), 
                                '&:hover': { 
                                  color: theme.palette.accent.primary, 
                                  backgroundColor: 'transparent' 
                                }, 
                                pr: 3,
                                '&:active': { backgroundColor: 'transparent' },
                                '&.MuiIconButton-root': {
                                  '&:hover': { backgroundColor: 'transparent' },
                                  '&:active': { backgroundColor: 'transparent' },
                                  '&:focus': { backgroundColor: 'transparent' }
                                }
                              }} 
                              edge="end" 
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteDocument(page * rowsPerPage + index);
                              }}
                              disableRipple
                            >
                              {documentToDeleteIndex === (page * rowsPerPage + index) ? (
                                <CircularProgress size={20} sx={{ color: theme.palette.accent.primary }} />
                              ) : (
                                <DeleteIcon />
                              )}
                            </IconButton>
                          }
                        >
                          <ListItemAvatar>
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              color: theme.palette.accent.primary,
                              width: 40,
                              height: 40
                            }}>
                              {getDocumentIcon(doc)}
                            </Box>
                          </ListItemAvatar>
                          <ListItemText 
                            primary={<Typography noWrap sx={{ maxWidth: isMobile ? '150px' : '300px' }}>{doc.filename || doc.name}</Typography>} 
                            secondary={
                              <>
                                {doc.upload_date ? new Date(doc.upload_date).toLocaleDateString() : new Date().toLocaleDateString()}
                              </>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                    {/* Add pagination controls */}
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                      gap: 2
                    }}>
                      <Typography variant="body2" color="text.secondary">
                        {documentSearchQuery.trim() !== '' && 
                         `Showing ${filteredDocuments.length} of ${uploadedDocuments.length} documents`}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                          {`${page * rowsPerPage + 1}-${Math.min((page + 1) * rowsPerPage, filteredDocuments.length)} of ${filteredDocuments.length}`}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton 
                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                            disabled={page === 0}
                            size="small"
                            sx={{
                              color: theme.palette.accent.primary,
                              '&.Mui-disabled': {
                                color: alpha(theme.palette.accent.primary, 0.3)
                              }
                            }}
                          >
                            <ArrowBackIcon fontSize="small" />
                          </IconButton>
                          <IconButton 
                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredDocuments.length / rowsPerPage) - 1, prev + 1))}
                            disabled={page >= Math.ceil(filteredDocuments.length / rowsPerPage) - 1}
                            size="small"
                            sx={{
                              color: theme.palette.accent.primary,
                              '&.Mui-disabled': {
                                color: alpha(theme.palette.accent.primary, 0.3)
                              }
                            }}
                          >
                            <ArrowForwardIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box 
                    sx={{ 
                      p: 3, 
                      textAlign: 'center', 
                      bgcolor: theme.palette.primary.light,
                      borderRadius: 1 
                    }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      {documentSearchQuery.trim() !== '' 
                        ? 'No documents match your search' 
                        : 'No documents uploaded yet'}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );

      case 'automations':
        return <NoCodeAutomationUI />;

      case 'accounts':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>

            <List sx={{ width: '100%' }}>
              {accounts.map((account) => (
                <ListItem
                  key={account.email}
                  sx={{
                    mb: 1.5,
                    backgroundColor: theme.palette.accent.secondary,
                    borderRadius: 2,
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    transition: 'all 0.2s ease-in-out',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    padding: isMobile ? 2 : undefined,
                    '&:hover': {
                      // backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    width: '100%',
                    mb: isMobile ? 2 : 0 
                  }}>
                    <ListItemAvatar>
                      <Avatar
                        src={account.userPhoto}
                        alt={account.name}
                        sx={{
                          width: isMobile ? 40 : 44,
                          height: isMobile ? 40 : 44,
                          border: account.email === currentAccount?.email 
                            ? `3px solid ${theme.palette.accent.primary}` 
                            : '1px solid rgba(255, 255, 255, 0.3)',
                          backgroundColor: 'transparent',
                          background: !account.userPhoto ? getGradientColor(account.name) : undefined,
                          color: !account.userPhoto ? '#fff' : undefined,
                          fontWeight: 600,
                          fontSize: '1.1rem',
                          boxShadow: account.email === currentAccount?.email
                            ? `0 0 0 2px ${theme.palette.background.paper}`
                            : 'none',
                        }}
                      >
                        {!account.userPhoto && account.name?.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            {account.name}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }}>
                          {account.email}
                        </Typography>
                      }
                    />
                  </Box>
                  
                  {/* Mobile-optimized action buttons */}
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 1, 
                    alignItems: 'center',
                    width: isMobile ? '100%' : 'auto',
                    justifyContent: isMobile ? 'flex-start' : 'flex-end',
                    mt: isMobile ? 1 : 0
                  }}>
                    {account.email !== currentAccount?.email ? (
                      <>
                        {/* <Button
                          fullWidth={isMobile}
                          size="small"
                          onClick={() => handleAccountSelect(account)}
                          startIcon={<PersonIcon sx={{ fontSize: '1.1rem' }} />}
                          sx={{
                            minWidth: isMobile ? '48%' : 'auto',
                            px: 1.5,
                            py: 0.75,
                            color: theme.palette.accent.primary,
                            borderColor: theme.palette.accent.primary,
                            '&:hover': {
                              backgroundColor: `${theme.palette.accent.primary}15`,
                              borderColor: theme.palette.accent.primary,
                            },
                          }}
                          // variant="outlined"
                        >
                          Switch
                        </Button> */}
                        <Button
                          fullWidth={isMobile}
                          size="small"
                          onClick={() => handleLogout(account)}
                          startIcon={<LogoutIcon sx={{ fontSize: '1.1rem' }} />}
                          sx={{
                            minWidth: isMobile ? '48%' : 'auto',
                            px: 1.5,
                            py: 0.75,
                            color: theme.palette.error.main,
                            borderColor: theme.palette.error.main,
                            '&:hover': {
                              backgroundColor: `${theme.palette.error.main}15`,
                              borderColor: theme.palette.error.main,
                            },
                          }}
                          // variant="outlined"
                        >
                          Remove
                        </Button>
                      </>
                    ) : (
                      <>

                        <Button
                          fullWidth={isMobile}
                          size="small"
                          onClick={() => handleLogout(account)}
                          startIcon={<LogoutIcon sx={{ fontSize: '1.1rem' }} />}
                          sx={{
                            minWidth: isMobile ? '48%' : 'auto',
                            px: 1.5,
                            py: 0.75,
                            color: theme.palette.error.main,
                            borderColor: theme.palette.error.main,
                            '&:hover': {
                              backgroundColor: `${theme.palette.error.main}15`,
                              borderColor: theme.palette.error.main,
                            },
                          }}
                          // variant="outlined"
                        >
                          Remove
                        </Button>
                      </>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        );

      case 'dashboard':
        return (
          <DashboardTab 
            dashboardMetrics={dashboardMetrics}
            analyzedUserEmail={analyzedUserEmail}
            lastUpdated={lastUpdated}
            isLoadingMetrics={isLoadingMetrics}
            fetchDashboardMetrics={fetchDashboardMetrics}
          />
        );

      default:
        return null;
    }
  };

  // Remove bottom navigation since we're using the options view
  const renderNavigation = () => {
    if (isMobile) {
      return null; // Don't render bottom navigation on mobile
    }

    const mainItems = sidebarItems.filter(item => item.group === 'main');
    const bottomItems = sidebarItems.filter(item => item.group === 'bottom');

    return (
      <Box sx={{ 
        width: '64px', 
        borderRight: 1, 
        borderColor: 'rgba(0, 0, 0, 0.12)',
        backgroundColor: theme.palette.accent.secondary,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
        <List sx={{ width: '100%', pt: 1, pl: '0px' }}>
          {mainItems.map((item) => (
            <Tooltip key={item.key} title={item.text} placement="right">
              <ListItem
                button
                onClick={() => setSelectedTab(item.key)}
                sx={{
                  height: '48px',
                  paddingLeft: '0px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <ListItemIcon 
                  sx={{ 
                    minWidth: '64px',
                    width: '64px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiSvgIcon-root': {
                      fontSize: '20px',
                    },
                    color: selectedTab === item.key ? theme.palette.ui?.textDark : theme.palette.ui?.textLight,
                  }}
                >
                  {React.cloneElement(item.icon, { 
                    style: { color: selectedTab === item.key ? theme.palette.accent.textDark : theme.palette.ui.textLight } 
                  })}
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          ))}
        </List>

        {/* Spacer */}
        <Box sx={{ flex: 1 }} />

        {/* Bottom Items */}
        <List sx={{ width: '100%', pb: 1, pl: '0px' }}>
          {bottomItems.map((item) => (
            <Tooltip key={item.key} title={item.text} placement="right">
              <ListItem
                button
                onClick={() => setSelectedTab(item.key)}
                sx={{
                  height: '48px',
                  paddingLeft: '0px',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'transparent',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <ListItemIcon 
                  sx={{ 
                    minWidth: '64px',
                    width: '64px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '& .MuiSvgIcon-root': {
                      fontSize: '20px',
                    },
                    color: selectedTab === item.key ? theme.palette.ui?.textDark : theme.palette.ui?.textLight,
                  }}
                >
                  {React.cloneElement(item.icon, { 
                    style: { color: selectedTab === item.key ? theme.palette.accent.textDark : theme.palette.ui.textLight } 
                  })}
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Box>
    );
  };

  // Clean up debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedSaveSignature.cancel();
      debouncedSaveProfile.cancel();
    };
  }, [debouncedSaveSignature, debouncedSaveProfile]);

  // Add this function to handle manual content addition
  const handleAddManualContent = async () => {
    if (newContentName.trim() && newContentText.trim()) {
      setIsUploadingDocument(true);
      
      try {
        // Create a text file from the manual content
        const fileName = `${newContentName.trim()}.txt`;
        const fileContent = newContentText.trim();
        const blob = new Blob([fileContent], { type: 'text/plain' });
        const file = new File([blob], fileName, { type: 'text/plain', lastModified: Date.now() });
        
        // Create form data
        const formData = new FormData();
        formData.append('file', file);
        formData.append('namespace', selectedNamespace);
        
        // Make API call to upload file
        const response = await axiosWithAuth.post(
          process.env.REACT_APP_API_URL + '/upload-file-to-pinecone',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          }
        );

        if (response.data.success) {
          // Add to uploaded documents with the returned file_id and current date
          setUploadedDocuments(prev => {
            const newDocs = [...prev, {
              id: response.data.file_id,
              filename: fileName,
              file_type: 'text/plain',
              upload_date: new Date().toISOString()
            }];
            // Set page to the last page based on the new document length
            setPage(Math.ceil(newDocs.length / rowsPerPage) - 1);
            return newDocs;
          });
          
          // Show success message
          enqueueSnackbar('Content added successfully', { variant: 'success' });
        }
      } catch (error) {
        console.error('Error adding manual content:', error);
        enqueueSnackbar(error.response?.data?.error || 'Error adding content', { variant: 'error' });
      } finally {
        setIsUploadingDocument(false);
      }
      
      // Reset form fields and close dialog
      setNewContentName('');
      setNewContentText('');
      setIsAddContentDialogOpen(false);
    }
  };

  // Add a function to determine document icon based on file type
  const getDocumentIcon = (doc) => {
    // Common icon style to ensure consistent sizing
    const iconStyle = { fontSize: 24 };
    
    // If it's manual content, use ArticleIcon
    if (doc.isManualContent) {
      return <ArticleIcon sx={iconStyle} />;
    }
    
    // Determine icon based on file type
    const fileType = (doc.type || doc.file_type || '').toLowerCase();
    const fileName = (doc.filename || doc.name || '').toLowerCase();
    
    if (fileType.includes('pdf')) {
      return <PictureAsPdfIcon sx={iconStyle} />;
    } else if (fileType.includes('image') || fileName.match(/\.(jpeg|jpg|png|gif|bmp|svg)$/)) {
      return <ImageIcon sx={iconStyle} />;
    } else if (fileType.includes('text/plain') || fileName.match(/\.(txt|md)$/)) {
      return <DescriptionIcon sx={iconStyle} />;
    } else if (fileName.match(/\.(doc|docx)$/)) {
      return <SvgIcon component={WordIcon} sx={iconStyle} viewBox="0 0 48 48" />;
    } else if (fileName.match(/\.(xls|xlsx)$/)) {
      return <SvgIcon component={ExcelIcon} sx={iconStyle} viewBox="0 0 48 48" />;
    } else if (fileName.match(/\.(ppt|pptx)$/)) {
      return <SvgIcon component={PowerPointIcon} sx={iconStyle} viewBox="0 0 48 48" />;
    } else if (fileName.match(/\.(js|ts|jsx|tsx|html|css|py|java|c|cpp|php|rb)$/)) {
      return <CodeIcon sx={iconStyle} />;
    } else {
      return <InsertDriveFileIcon sx={iconStyle} />; // Default document icon
    }
  };

  // Add function to fetch dashboard metrics
  const fetchDashboardMetrics = async (forceRefresh = false) => {
    const userEmail = userInfo?.mail || userInfo?.userPrincipalName;
    if (!userEmail) return;

    // Check session storage first if not forcing refresh
    if (!forceRefresh) {
      const storedMetrics = sessionStorage.getItem(`metrics_${userEmail}`);
      const storedEmailAnalytics = sessionStorage.getItem(`email_analytics_${userEmail}`);
      if (storedMetrics && storedEmailAnalytics) {
        const { data: metricsData, timestamp: metricsTimestamp } = JSON.parse(storedMetrics);
        const { data: analyticsData, timestamp: analyticsTimestamp } = JSON.parse(storedEmailAnalytics);
        setDashboardMetrics({
          ...metricsData,
          emailAnalytics: analyticsData
        });
        setLastUpdated(metricsTimestamp);
        return;
      }
    }

    setIsLoadingMetrics(true);
    try {
      // Fetch response time metrics
      const responseTimePromise = axiosWithAuth.get(`${process.env.REACT_APP_API_URL}/calculate-average-response-time`, {
        params: { user_email: userEmail }
      });
      
      // Fetch email analytics
      const emailAnalyticsPromise = axiosWithAuth.get(`${process.env.REACT_APP_API_URL}/email-analytics`, {
        params: { user_email: userEmail }
      });
      
      // Wait for both requests to complete
      const [responseTimeResponse, emailAnalyticsResponse] = await Promise.all([
        responseTimePromise,
        emailAnalyticsPromise
      ]);
      
      if (responseTimeResponse.data.status === 'success' && emailAnalyticsResponse.data) {
        const currentTime = new Date().toISOString();
        const combinedData = {
          ...responseTimeResponse.data,
          emailAnalytics: emailAnalyticsResponse.data
        };
        setDashboardMetrics(combinedData);
        setAnalyzedUserEmail(userEmail);
        setLastUpdated(currentTime);
        
        // Store in session storage
        sessionStorage.setItem(`metrics_${userEmail}`, JSON.stringify({
          data: responseTimeResponse.data,
          timestamp: currentTime
        }));
        sessionStorage.setItem(`email_analytics_${userEmail}`, JSON.stringify({
          data: emailAnalyticsResponse.data,
          timestamp: currentTime
        }));
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      enqueueSnackbar('Could not load metrics. Please try again later.', { variant: 'error' });
    } finally {
      setIsLoadingMetrics(false);
    }
  };

  // Fetch metrics when dashboard tab is selected
  useEffect(() => {
    if (selectedTab === 'dashboard' && open) {
      fetchDashboardMetrics();
    }
  }, [selectedTab, open]);

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      await processFileUpload(file);
    }
  };

  // Add new useEffect for initialization
  useEffect(() => {
    const fetchUploadedFiles = async (namespace = selectedNamespace) => {
      setIsLoadingDocuments(true);
      try {
        const response = await axiosWithAuth.get(
          process.env.REACT_APP_API_URL + '/get-pinecone-files',
          {
            params: { namespace }
          }
        );
        if (response.data.data) {
          setUploadedDocuments(response.data.data);
          // Reset search and pagination when fetching new documents
          setDocumentSearchQuery('');
          setPage(0);
        }
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
        enqueueSnackbar('Error loading uploaded files', { variant: 'error' });
      } finally {
        setIsLoadingDocuments(false);
      }
    };

    if (selectedTab === 'assistant' && selectedNamespace) {
      fetchUploadedFiles();
    }
  }, [selectedTab, selectedNamespace]);

  // Make fetchUploadedFiles available outside the useEffect
  const fetchUploadedFiles = async (namespace = selectedNamespace) => {
    setIsLoadingDocuments(true);
    try {
      const response = await axiosWithAuth.get(
        process.env.REACT_APP_API_URL + '/get-pinecone-files',
        {
          params: { namespace }
        }
      );
      if (response.data.data) {
        setUploadedDocuments(response.data.data);
        // Reset search and pagination when fetching new documents
        setDocumentSearchQuery('');
        setPage(0);
      }
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
      enqueueSnackbar('Error loading uploaded files', { variant: 'error' });
    } finally {
      setIsLoadingDocuments(false);
    }
  };

  // Update the delete handler
  const handleDeleteDocument = async (index) => {
    const documentToDelete = uploadedDocuments[index];
    setDocumentToDeleteIndex(index);
    
    try {
      const response = await axiosWithAuth.delete(
        process.env.REACT_APP_API_URL + '/delete-pinecone-file',
        {
          headers: {
            'fileId': documentToDelete.id
          },
          params: {
            namespace: selectedNamespace
          }
        }
      );

      if (response.data.success) {
        setUploadedDocuments(prev => {
          const newDocs = prev.filter((_, i) => i !== index);
          // If current page would be empty after deletion, go to previous page
          const totalPages = Math.ceil(newDocs.length / rowsPerPage);
          if (page >= totalPages && page > 0) {
            setPage(totalPages - 1);
          }
          return newDocs;
        });
        enqueueSnackbar('File deleted successfully', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      enqueueSnackbar(error.response?.data?.error || 'Error deleting file', { variant: 'error' });
    } finally {
      setDocumentToDeleteIndex(null);
    }
  };

  // Add debounced save function for assistant prompts
  const debouncedSaveAssistantPrompts = useCallback(
    debounce(async (assistantPrompt, emailPrompt) => {
      try {
        const userEmail = userInfo?.mail || userInfo?.userPrincipalName;
        if (!userEmail) return;
        
        // Update backend
        const response = await axiosWithAuth.post(`${process.env.REACT_APP_API_URL}/update-airtable-user-object`, {
          user_email: userEmail,
          user_data: {
            AssistantPrompt: assistantPrompt,
            EmailPrompt: emailPrompt
          }
        });

        if (response.status === 200) {
          console.log('Assistant prompts saved successfully');
          
          // Update userInfo in localStorage if it exists
          const storedUserInfo = localStorage.getItem('userInfo');
          if (storedUserInfo) {
            const parsedUserInfo = JSON.parse(storedUserInfo);
            const updatedUserInfo = {
              ...parsedUserInfo,
              AssistantPrompt: assistantPrompt,
              EmailPrompt: emailPrompt
            };
            localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
            
            // // Also update the userInfo state so it stays in sync
            // setUserInfo(prevInfo => ({
            //   ...prevInfo,
            //   AssistantPrompt: assistantPrompt,
            //   EmailPrompt: emailPrompt
            // }));
          }
        }
      } catch (error) {
        console.error('Error saving assistant prompts:', error);
      }
    }, 2000),
    [userInfo]
  );

  // Add handlers for assistant prompts
  const handleAssistantPromptChange = (e) => {
    const newValue = e.target.value;
    setGeneralAssistantPrompt(newValue);
    debouncedSaveAssistantPrompts(newValue, emailGenerationPrompt);
  };

  const handleEmailGenerationPromptChange = (e) => {
    const newValue = e.target.value;
    setEmailGenerationPrompt(newValue);
    debouncedSaveAssistantPrompts(generalAssistantPrompt, newValue);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Check if we're entering from outside or between child elements
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Only set isDragging to false if we're leaving the container itself
    // Check if the related target is not within the drop zone
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      await processFileUpload(file);
    }
  };

  const processFileUpload = async (file) => {
    setIsUploadingDocument(true);
    
    try {
      // Create form data
      const formData = new FormData();
      formData.append('file', file);
      formData.append('namespace', selectedNamespace);
      
      // Make API call to upload file
      const response = await axiosWithAuth.post(
        process.env.REACT_APP_API_URL + '/upload-file-to-pinecone',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );

      if (response.data.success) {
        // Add to uploaded documents with the returned file_id and current date
        setUploadedDocuments(prev => {
          const newDocs = [...prev, {
            id: response.data.file_id,
            filename: file.name,
            file_type: file.type,
            upload_date: new Date().toISOString()
          }];
          // Set page to the last page based on the new document length
          setPage(Math.ceil(newDocs.length / rowsPerPage) - 1);
          return newDocs;
        });
        enqueueSnackbar('File uploaded successfully', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      enqueueSnackbar('Error uploading file', { variant: 'error' });
    } finally {
      setIsUploadingDocument(false);
    }
  };

  // Drag and drop states
  const [isDragging, setIsDragging] = useState(false);
  
  // Initialize namespace options and selected namespace from localStorage or default to personal email
  useEffect(() => {
    if (userInfo) {
      const options = [];
      
      // Add personal namespace (user's email)
      const personalEmail = userInfo?.mail || userInfo?.userPrincipalName;
      if (personalEmail) {
        options.push({ 
          label: 'Personal', 
          value: personalEmail,
          description: personalEmail
        });
      }
      
      // Add department namespaces
      // if (userInfo.departments && userInfo.departments.length > 0) {
      //   userInfo.departments.forEach(department => {
      //     if (department.shared_mail_boxes) {
      //       // Handle both string and array cases
      //       const mailboxes = Array.isArray(department.shared_mail_boxes) 
      //         ? department.shared_mail_boxes 
      //         : [department.shared_mail_boxes];
            
      //       mailboxes.forEach(mailbox => {
      //         if (mailbox) {
      //           options.push({
      //             label: department.name || 'Department',
      //             value: mailbox,
      //             description: mailbox
      //           });
      //         }
      //       });
      //     }
      //   });
      // }
      
      // Add company namespace (domain from email)
      if (personalEmail && personalEmail.includes('@')) {
        const domain = personalEmail.split('@')[1];
        options.push({
          label: 'Company',
          value: domain,
          description: domain
        });
      }
      
      setNamespaceOptions(options);
      
      // Get selected namespace from localStorage or default to company namespace
      const savedNamespace = localStorage.getItem('selectedDocumentNamespace');
      if (savedNamespace && options.some(option => option.value === savedNamespace)) {
        setSelectedNamespace(savedNamespace);
      } else if (options.length > 0) {
        // Find company namespace option (domain from email)
        const companyOption = options.find(option => option.label === 'Company');
        if (companyOption) {
          // Default to company namespace
          setSelectedNamespace(companyOption.value);
          localStorage.setItem('selectedDocumentNamespace', companyOption.value);
        } else {
          // Fallback to personal if company not available
          setSelectedNamespace(options[0].value);
          localStorage.setItem('selectedDocumentNamespace', options[0].value);
        }
      }
    }
  }, [userInfo]);
  
  // Handle namespace change
  const handleNamespaceChange = (event) => {
    const newNamespace = event.target.value;
    setSelectedNamespace(newNamespace);
    localStorage.setItem('selectedDocumentNamespace', newNamespace);
    setPage(0); // Reset page when namespace changes
    
    // Refresh the document list with the new namespace
    fetchUploadedFiles(newNamespace);
  };
  
  // Add function to handle file preview
  const handleFilePreview = async (file) => {
    setIsPreviewDialogOpen(true);
    setIsLoadingContent(true);
    
    try {
      const response = await axiosWithAuth.get(
        `${process.env.REACT_APP_API_URL}/get-pinecone-content`,
        {
          params: { 
            fileId: file.id,
            namespace: selectedNamespace
          },
          headers: {
            userEmail: userInfo?.mail || userInfo?.userPrincipalName
          }
        }
      );

      if (response.data.success) {
        setSelectedFileContent({
          content: response.data.content,
          metadata: response.data.metadata
        });
      }
    } catch (error) {
      console.error('Error fetching file content:', error);
      enqueueSnackbar('Error loading file content', { variant: 'error' });
    } finally {
      setIsLoadingContent(false);
    }
  };

  // Add preview dialog component
  const renderPreviewDialog = () => (
    <Dialog
      open={isPreviewDialogOpen}
      onClose={() => setIsPreviewDialogOpen(false)}
      maxWidth="md"
      fullWidth
      TransitionComponent={ExpandTransition}
      PaperProps={{
        sx: {
          bgcolor: theme.palette.primary.light,
          p: 3,
          height: isMobile ? '80vh' : '800px',
          width: isMobile ? '100%' : '1100px',
          maxWidth: 'none', 
          maxHeight: isMobile ? '80vh' : 'none',
          overflowY: 'hidden',
          position: isMobile ? 'fixed' : 'static',
          bottom: 0,
          borderTopLeftRadius: isMobile ? '16px' : '4px',
          borderTopRightRadius: isMobile ? '16px' : '4px',
          borderBottomLeftRadius: isMobile ? '0px' : '4px',
          borderBottomRightRadius: isMobile ? '0px' : '4px',
          m: isMobile ? 0 : 2,
        }
      }}
    >
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 3
        }}>
          <Typography variant="h6" sx={{ color: theme.palette.accent.primary }}>
            {selectedFileContent?.metadata?.filename || 'File Preview'}
          </Typography>
          <IconButton
            onClick={() => setIsPreviewDialogOpen(false)}
            sx={{
              color: theme.palette.ui.textLight,
              '&:hover': {
                color: theme.palette.accent.primary,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        {isLoadingContent ? (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%' 
          }}>
            <CircularProgress sx={{ color: theme.palette.accent.primary }} />
          </Box>
        ) : (
          <Box sx={{ 
            flex: 1, 
            overflowY: 'auto',
            bgcolor: theme.palette.background.paper,
            borderRadius: 1,
            p: 3,
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
            fontSize: '0.875rem',
            lineHeight: 1.5
          }}>
            {selectedFileContent?.content || 'No content available'}
          </Box>
        )}
      </Box>
    </Dialog>
  );

  const handleDocumentSearchChange = (event) => {
    setDocumentSearchQuery(event.target.value);
    setPage(0); // Reset to first page when search changes
  };

  // Filter documents based on search query
  const filteredDocuments = useMemo(() => {
    if (!documentSearchQuery.trim()) return uploadedDocuments;
    
    return uploadedDocuments.filter(doc => 
      (doc.filename || doc.name || '')
        .toLowerCase()
        .includes(documentSearchQuery.toLowerCase())
    );
  }, [uploadedDocuments, documentSearchQuery]);

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="md" 
        fullWidth
        TransitionComponent={Transition}
        fullScreen={isMobile}
        PaperProps={{
          sx: { 
            width: isMobile ? '100%' : '1100px',
            height: isMobile ? '80vh' : '800px',
            maxWidth: 'none', 
            maxHeight: isMobile ? '80vh' : 'none',
            overflowY: 'hidden',
            bgcolor: theme.palette.primary.light,
            position: isMobile ? 'fixed' : 'static',
            bottom: 0,
            borderTopLeftRadius: isMobile ? '16px' : '4px',
            borderTopRightRadius: isMobile ? '16px' : '4px',
            borderBottomLeftRadius: isMobile ? '0px' : '4px',
            borderBottomRightRadius: isMobile ? '0px' : '4px',
            m: isMobile ? 0 : 2,
          }
        }}
      >
        <DialogContent sx={{ 
          p: 0, 
          display: 'flex', 
          height: '100%',
          flexDirection: isMobile ? 'column' : 'row',
        }}>
          {!isMobile && renderNavigation()}
          <Box sx={{ 
            flexGrow: 1, 
            position: 'relative',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            ...(isMobile && {
              boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: theme.palette.primary.light,
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
            })
          }}>
            {renderContent()}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Add Content Dialog */}
      <Dialog
        open={isAddContentDialogOpen}
        onClose={() => setIsAddContentDialogOpen(false)}
        maxWidth="md"
        fullWidth
        TransitionComponent={ExpandTransition}
        PaperProps={{
          sx: {
            bgcolor: theme.palette.primary.light,
            p: 3,
            height: isMobile ? '80vh' : '800px',
            width: isMobile ? '100%' : '1100px',
            maxWidth: 'none', 
            maxHeight: isMobile ? '80vh' : 'none',
            overflowY: 'hidden',
            position: isMobile ? 'fixed' : 'static',
            bottom: 0,
            borderTopLeftRadius: isMobile ? '16px' : '4px',
            borderTopRightRadius: isMobile ? '16px' : '4px',
            borderBottomLeftRadius: isMobile ? '0px' : '4px',
            borderBottomRightRadius: isMobile ? '0px' : '4px',
            m: isMobile ? 0 : 2,
          }
        }}
      >
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 3
          }}>
            <Typography variant="h6" sx={{ color: theme.palette.accent.primary }}>
              Add Manual Content
            </Typography>
            <IconButton
              onClick={() => setIsAddContentDialogOpen(false)}
              disabled={isUploadingDocument}
              sx={{
                color: theme.palette.ui.textLight,
                '&:hover': {
                  color: theme.palette.accent.primary,
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          
          {isUploadingDocument && (
            <Box sx={{ width: '100%', mb: 2 }}>
              <LinearProgress 
                sx={{ 
                  height: 4,
                  backgroundColor: alpha(theme.palette.accent.primary, 0.2),
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: theme.palette.accent.primary
                  }
                }} 
              />
              <Typography variant="caption" sx={{ color: theme.palette.accent.primary, display: 'block', textAlign: 'center', mt: 1 }}>
                Uploading content to knowledge base...
              </Typography>
            </Box>
          )}
          
          <Box sx={{ flex: 1, overflowY: 'auto', px: 2, display: 'flex', flexDirection: 'column', height: 'calc(100% - 60px)' }}>
            <TextField
              fullWidth
              label="Content Name"
              value={newContentName}
              onChange={(e) => setNewContentName(e.target.value)}
              margin="normal"
              disabled={isUploadingDocument}
              InputLabelProps={{
                sx: {
                  color: theme.palette.accent.primary,
                  '&.Mui-focused': {
                    color: theme.palette.accent.primary
                  }
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: theme.palette.primary.light,
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.accent.primary,
                    borderWidth: 2
                  }
                }
              }}
            />
            
            <TextField
              fullWidth
              label="Content Text"
              multiline
              value={newContentText}
              onChange={(e) => setNewContentText(e.target.value)}
              margin="normal"
              disabled={isUploadingDocument}
              rows={20}
              InputLabelProps={{
                sx: {
                  color: theme.palette.accent.primary,
                  '&.Mui-focused': {
                    color: theme.palette.accent.primary
                  }
                }
              }}
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  backgroundColor: theme.palette.primary.light,
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.accent.primary,
                    borderWidth: 2
                  }
                },
                '& .MuiInputBase-root': {
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-start'
                },
                '& .MuiInputBase-input': {
                  height: '100% !important',
                  overflowY: 'auto !important'
                }
              }}
            />
          </Box>
          
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={() => setIsAddContentDialogOpen(false)}
              disabled={isUploadingDocument}
              sx={{
                color: theme.palette.ui.textLight,
                borderColor: theme.palette.ui.textLight,
                '&:hover': {
                  borderColor: theme.palette.ui.textDark,
                  backgroundColor: alpha(theme.palette.ui.textLight, 0.04),
                }
              }}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              onClick={handleAddManualContent}
              disabled={!newContentName.trim() || !newContentText.trim() || isUploadingDocument}
              sx={{
                bgcolor: theme.palette.accent.darkGreen,
                color: theme.palette.accent.secondary,
                '&:hover': {
                  bgcolor: alpha(theme.palette.accent.darkGreen, 0.8),
                }
              }}
            >
              {isUploadingDocument ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} sx={{ color: theme.palette.accent.secondary, mr: 1 }} />
                  Uploading...
                </Box>
              ) : (
                'Add Content'
              )}
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Preview Dialog */}
      {renderPreviewDialog()}
    </>
  );
};

export default ProfileDialog;