import React, { useState, useEffect, useMemo } from 'react';
import { Box, IconButton, Typography, Tooltip, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import { axiosWithAuth } from './authService'; // Remove getUserInfo from import
import EmailFilter from './EmailFilter'; // Import the EmailFilter component

// Include onActionSuccess and userInfo in the props
const EmailHeader = ({ 
  currentFolder, 
  onRefresh, 
  folderEmails, 
  isMobile,
  onFilterChange,
  activeFilters,
  setActiveFilters,
  currentEmails
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const theme = useTheme();

  console.log('currentFolder from EmailHeader', currentFolder);

  // if currentFolder is inbox_focused or inbox_other, then set currentFolder to inbox
  const normalizedFolder = currentFolder.startsWith('inbox_focused') || currentFolder.startsWith('inbox_other') 
    ? 'inbox' 
    : currentFolder;

  console.log('normalizedFolder from EmailHeader', normalizedFolder);
  
  // Get folder display name
  const folderDisplayName = useMemo(() => {
    if (currentFolder === 'inbox_focused') return 'Focused';
    if (currentFolder === 'inbox_other') return 'Other';
    return currentFolder.charAt(0).toUpperCase() + currentFolder.slice(1);
  }, [currentFolder]);
  
  // Use currentEmails if provided, otherwise derive from folderEmails
  const emails = currentEmails || (folderEmails[currentFolder] || []);

  const iconButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.ui.textLight,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.ui.textDark
    },
    padding: '4px',
    minWidth: 'auto',
    marginRight: '12px',
  };

  const iconStyle = {
    fontSize: '1.2rem',
  };

  const handleRefresh = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    await onRefresh();
    setIsRefreshing(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Updated shadow to match ChatHeader
        padding: '6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '4px' }}>
        <IconButton 
          onClick={handleRefresh}
          disabled={isRefreshing}
          sx={{
            ...iconButtonStyle,
            '& .MuiSvgIcon-root': {
              transition: 'transform 0.5s ease-out',
              transform: isRefreshing ? 'rotate(360deg)' : 'rotate(0deg)',
              animation: isRefreshing ? 'continuous-spin 1s linear infinite' : 'none',
            },
            '@keyframes continuous-spin': {
              '0%': { transform: 'rotate(0deg)' },
              '100%': { transform: 'rotate(360deg)' },
            },
          }}
        >
          <RefreshIcon sx={iconStyle} />
        </IconButton>
        <Typography 
          variant="h6" 
          sx={{ 
            marginLeft: 0, 
            paddingBottom: '1px', 
            fontWeight: '500',
            color: theme.palette.ui.textDark
          }}
        >
          {folderDisplayName}
        </Typography>
      </Box>
      
      {/* Add EmailFilter component */}
      <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
        <EmailFilter 
          emails={emails}
          onFilterChange={onFilterChange}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          isMobile={isMobile}
        />
      </Box>
    </Box>
  );
};

export default EmailHeader;