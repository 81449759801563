import { createTheme } from '@mui/material/styles';

const colors = {
  primary: {
    main: '#EFEADD',
    dark: '#C5B696',
    light: '#F7F4ED',
    contrastText: '#2A2A2A',
  },
  secondary: {
    main: '#A87F4A',
    light: '#BFA07A',
    dark: '#8A6339',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#FFFFFF',
    paper: '#F7F4ED',
  },
  text: {
    primary: '#2C2824',
    secondary: '#766F66',
  },
  error: {
    main: '#A85B4A',
  },
  success: {
    main: '#6A8F57',
  },
  // Custom colors can be added under a custom namespace
  accent: {
    primary: '#2A2A2A',
    secondary: '#f8f8f7',
    tertiary: '#A87F4A',
    darkGreen: '#395144',
  },
  ui: {
    textDark: '#2C2824',
    textLight: '#766F66',
    border: '#D8D0C0',
    error: '#A85B4A',
    success: '#6A8F57',
    link: '#526B9E',
  },
};

const theme = createTheme({
  palette: {
    primary: colors.primary,
    secondary: {
      main: colors.accent.tertiary,
      light: colors.accent.secondary,
      dark: colors.accent.darkGreen,
      contrastText: '#FFFFFF',
    },
    error: {
      main: colors.ui.error,
    },
    success: {
      main: colors.ui.success,
    },
    text: {
      primary: colors.ui.textDark,
      secondary: colors.ui.textLight,
    },
    background: {
      default: '#FFFFFF',
      paper: colors.primary.light,
    },
    // Custom colors
    accent: colors.accent,
    ui: colors.ui,
  },
  typography: {
    fontFamily: [
      'DM Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
});

// Add type augmentation for custom palette colors
declare module '@mui/material/styles' {
  interface Palette {
    accent: {
      primary: string;
      secondary: string;
      tertiary: string;
      darkGreen: string;
    };
    ui: {
      textDark: string;
      textLight: string;
      border: string;
      error: string;
      success: string;
      link: string;
    };
  }
  interface PaletteOptions {
    accent?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
      darkGreen?: string;
    };
    ui?: {
      textDark?: string;
      textLight?: string;
      border?: string;
      error?: string;
      success?: string;
      link?: string;
    };
  }
}

export { colors };
export default theme;
