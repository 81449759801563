import { useEffect, useCallback, useState, useRef } from 'react';

/**
 * Custom hook to handle keyboard navigation in email list
 * 
 * @param {Object} props
 * @param {Array} props.emails - List of emails to navigate through
 * @param {String} props.focusedEmailId - Currently focused email ID
 * @param {Function} props.setFocusedEmailId - Function to set the focused email ID
 * @param {Array} props.selectedEmailIds - Array of selected email IDs
 * @param {Function} props.setSelectedEmailIds - Function to set selected email IDs
 * @param {Function} props.setSelectedEmail - Function to set the selected email object
 * @param {Object} props.listItemRefs - Ref object containing references to list items
 * @param {Set} props.expandedThreads - Set of expanded thread IDs
 * @param {Function} props.onThreadExpand - Function to toggle thread expansion
 * @param {Boolean} props.isViewingSingleEmail - Whether a single email is being viewed
 * @param {Function} props.updateReadStatus - Function to update read status of emails
 * @param {Function} props.handleDoubleClick - Function to handle double-clicking an email
 * @param {Function} props.handleDeleteThread - Function to delete a thread
 * @param {Boolean} props.isPreviewDialogOpen - Whether the preview dialog is open
 * @param {Function} props.setIsPreviewDialogOpen - Function to set whether the preview dialog is open
 * @param {HTMLElement} props.emailListRef - Reference to the email list container element
 * @param {Array} props.senderEmails - Array of sender emails
 * @returns {Object} - Keyboard navigation handlers and state
 */
const useEmailKeyboardNavigation = ({
  emails,
  focusedEmailId,
  setFocusedEmailId,
  selectedEmailIds,
  setSelectedEmailIds,
  setSelectedEmail,
  listItemRefs,
  expandedThreads,
  onThreadExpand,
  isViewingSingleEmail,
  updateReadStatus,
  currentFolder,
  handleDoubleClick,
  handleDeleteThread,
  isPreviewDialogOpen,
  setIsPreviewDialogOpen,
  setSelectedEmailForPreview,
  groupEmailsByDate,
  emailListRef,
  senderEmails = []
}) => {
  // Keep track of all visible emails (including thread emails when expanded)
  const [visibleEmails, setVisibleEmails] = useState([]);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  // Store the last selected email index for shift selection
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  // Track current navigation direction (1 for down, -1 for up, 0 for initial)
  const [navigationDirection, setNavigationDirection] = useState(0);
  // Track if the email list is currently focused
  const [isEmailListFocused, setIsEmailListFocused] = useState(false);

  // Update visible emails whenever the emails list or expanded threads change
  useEffect(() => {
    if (!emails || emails.length === 0) {
      console.log('No emails provided to keyboard navigation');
      console.log('Using empty email list for keyboard navigation');
      setVisibleEmails([]);
      return;
    }

    const groupedEmails = {
      today: [],
      yesterday: [],
      thisWeek: [],
      earlier: {},
      earlierMonths: []
    };

    try {
      // Try to use the same grouping function as the main UI - this ensures exact same ordering
      const grouped = groupEmailsByDate ? groupEmailsByDate(emails) : 
        {today: [], yesterday: [], thisWeek: [], earlier: {}, earlierMonths: []};
      
      Object.assign(groupedEmails, grouped);
      console.log('Email groups created for keyboard navigation:', 
        Object.keys(groupedEmails).map(k => {
          if (k === 'earlier') {
            return `earlier: ${Object.keys(groupedEmails[k]).length} month-year groups`;
          } else if (k === 'earlierMonths') {
            return null; // Skip this in log
          } else {
            return `${k}: ${groupedEmails[k]?.length || 0}`;
          }
        }).filter(Boolean).join(', '));
      
      // Create a flat list of emails that EXACTLY matches the UI rendering order
      const flattenedEmails = [];
      
      // Process each group in the order they appear in the UI
      ['today', 'yesterday', 'thisWeek'].forEach(group => {
        if (groupedEmails[group] && groupedEmails[group].length > 0) {
          // Add each parent email followed by its thread emails when expanded
          groupedEmails[group].forEach(email => {
            if (!email) return;
            
            // Add the parent email
            flattenedEmails.push(email);
            
            // If this email has a thread and it's expanded, add all emails with the same conversationId
            if (email.hasThread && 
                email.conversationId && 
                expandedThreads.has(email.id)) {
              
              // Find all emails with the same conversationId but different id
              const threadEmails = emails.filter(e => 
                e?.conversationId === email.conversationId && 
                e?.id !== email.id
              );
              
              // Sort thread emails by date, newest first or other appropriate order
              const sortedThreadEmails = [...threadEmails]
                .sort((a, b) => new Date(b.date) - new Date(a.date));
              
              sortedThreadEmails.forEach(threadEmail => {
                if (threadEmail && threadEmail.id) {
                  // Mark this as a subitem with reference to its parent
                  flattenedEmails.push({
                    ...threadEmail,
                    subitem: true,
                    parentId: email.id
                  });
                }
              });
            }
          });
        }
      });
      
      // Then add emails from earlier months
      if (groupedEmails.earlierMonths && groupedEmails.earlierMonths.length) {
        groupedEmails.earlierMonths.forEach(monthYear => {
          if (groupedEmails.earlier[monthYear]) {
            groupedEmails.earlier[monthYear].forEach(email => {
              if (!email) return;
              
              // Add the parent email
              flattenedEmails.push(email);
              
              // If this email has a thread and it's expanded, add its thread emails
              if (email.hasThread && 
                  email.conversationId && 
                  expandedThreads.has(email.id)) {
                
                // Find all emails with the same conversationId but different id
                const threadEmails = emails.filter(e => 
                  e?.conversationId === email.conversationId && 
                  e?.id !== email.id
                );
                
                const sortedThreadEmails = [...threadEmails]
                  .sort((a, b) => new Date(b.date) - new Date(a.date));
                
                sortedThreadEmails.forEach(threadEmail => {
                  if (threadEmail && threadEmail.id) {
                    flattenedEmails.push({
                      ...threadEmail,
                      subitem: true,
                      parentId: email.id
                    });
                  }
                });
              }
            });
          }
        });
      }

      // Add sender emails if available
      if (isViewingSingleEmail && senderEmails && senderEmails.length > 0) {
        senderEmails.forEach(email => {
          if (!email || !email.id) return;
          
          // Add sender email to the list of visible emails
          flattenedEmails.push(email);
          
          // If this email has a thread and it's expanded, add its thread emails
          if (email.hasThread && 
              email.conversationId && 
              expandedThreads.has(email.id)) {
            
            // Find all emails with the same conversationId but different id
            const threadEmails = emails.filter(e => 
              e?.conversationId === email.conversationId && 
              e?.id !== email.id
            );
            
            const sortedThreadEmails = [...threadEmails]
              .sort((a, b) => new Date(b.date) - new Date(a.date));
            
            sortedThreadEmails.forEach(threadEmail => {
              if (threadEmail && threadEmail.id) {
                flattenedEmails.push({
                  ...threadEmail,
                  subitem: true,
                  parentId: email.id
                });
              }
            });
          }
        });
      }
      
      // Now set the visible emails state
      console.log(`Keyboard nav visible emails: ${flattenedEmails.length} emails`);
      setVisibleEmails(flattenedEmails);
      
    } catch (error) {
      console.error('Error building visible emails list for keyboard nav:', error);
      setVisibleEmails([]);
    }
  }, [emails, expandedThreads, groupEmailsByDate, isViewingSingleEmail]);

  // List of keyboard shortcuts to display in help
  const keyboardShortcuts = [
    { key: 'Note', description: 'Shortcuts only work when the email list is focused', isHeader: true },
    { key: '↑/↓', description: 'Navigate between emails' },
    { key: 'Enter', description: 'Open selected email' },
    { key: 'Space', description: 'Expand/collapse thread' },
    { key: 'Delete', description: 'Delete email' },
    { key: 'r', description: 'Mark as read' },
    { key: 'u', description: 'Mark as unread' },
    { key: 'Shift+↑/↓', description: 'Select multiple emails (add to selection)' },
    { key: 'Shift+Click', description: 'Toggle individual email selection' },
    { key: 'Ctrl/Cmd+↑/↓', description: 'Navigate to top/bottom of list' },
    { key: 'Ctrl/Cmd+a', description: 'Select all emails' },
    { key: 'Esc', description: 'Close preview/dialog' },
    { key: '?', description: 'Show keyboard shortcuts' },
    { key: 'Home', description: 'Navigate to the first email' },
    { key: 'End', description: 'Navigate to the last email' },
    { key: 'Tip', description: 'Click on the email list area to enable shortcuts', isHelper: true }
  ];

  // Handle keyboard selection with Shift key (now for non-contiguous selection)
  const handleShiftSelection = (currentIndex) => {
    const emailId = visibleEmails[currentIndex].id;
    
    // Detect direction change
    const newDirection = currentIndex > lastSelectedIndex ? 1 : -1;
    const isChangingDirection = lastSelectedIndex !== -1 && 
                                navigationDirection !== 0 && 
                                newDirection !== navigationDirection;
    
    console.log(`Navigation: ${navigationDirection === 1 ? 'DOWN' : 'UP'} -> ${newDirection === 1 ? 'DOWN' : 'UP'}, Change: ${isChangingDirection}`);
    
    // Update direction
    setNavigationDirection(newDirection);
    
    // Track direction changes to add/remove emails from selection
    setSelectedEmailIds(prevSelected => {
      let updatedSelection = [...prevSelected];
      
      // If we're changing direction, remove the last selected email
      if (isChangingDirection && lastSelectedIndex !== -1) {
        const previousEmailId = visibleEmails[lastSelectedIndex]?.id;
        
        if (previousEmailId) {
          console.log(`Direction change: removing ${previousEmailId.slice(0, 8)} from selection`);
          updatedSelection = updatedSelection.filter(id => id !== previousEmailId);
        }
      }
      
      // Always ensure the current email is in the selection
      if (!updatedSelection.includes(emailId)) {
        console.log(`Adding email ${emailId.slice(0, 8)} to selection`);
        updatedSelection.push(emailId);
      }
      
      return updatedSelection;
    });
    
    // Update last selected index
    setLastSelectedIndex(currentIndex);
    
    // Debug log the selection state
    console.log(`Current position: ${currentIndex}, Last selected: ${lastSelectedIndex}`);
  };

  // Handle keyboard selection with Ctrl/Cmd+Arrow
  const handleCtrlArrowSelection = (isArrowDown) => {
    if (visibleEmails.length === 0) return;
    
    // Navigate to either top or bottom of list
    const targetIndex = isArrowDown ? visibleEmails.length - 1 : 0;
    const targetEmail = visibleEmails[targetIndex];
    
    // Update focus and selection
    setFocusedEmailId(targetEmail.id);
    setSelectedEmailIds([targetEmail.id]);
    setSelectedEmail(targetEmail);
    setLastSelectedIndex(targetIndex);
    
    // Scroll into view
    const ref = listItemRefs.current[targetEmail.id];
    if (ref && typeof ref.scrollIntoView === 'function') {
      ref.scrollIntoView({ behavior: 'smooth', block: isArrowDown ? 'end' : 'start' });
    }
    
    // Mark email as read if needed
    if (!targetEmail.read && ((currentFolder !== 'inbox_focused' && currentFolder !== 'flagged') || targetEmail.subitem)) {
      updateReadStatus(targetEmail.id, false);
    }
  };

  // Function to explicitly rebuild visible emails list
  const rebuildVisibleEmails = useCallback(() => {
    if (!emails || emails.length === 0) {
      setVisibleEmails([]);
      return [];
    }

    // Use the same grouping logic as in useEffect, but without the delay
    try {
      const groupedEmails = groupEmailsByDate ? groupEmailsByDate(emails) : 
        {today: [], yesterday: [], thisWeek: [], earlier: {}, earlierMonths: []};
      
      // Create a flat list that exactly matches UI rendering order
      const flattenedEmails = [];
      
      // Process each group in the order they appear in the UI
      ['today', 'yesterday', 'thisWeek'].forEach(group => {
        if (groupedEmails[group] && groupedEmails[group].length > 0) {
          groupedEmails[group].forEach(email => {
            if (!email) return;
            
            // Add the parent email
            flattenedEmails.push(email);
            
            // If this email has a thread and it's expanded, add all emails with the same conversationId
            if (email.hasThread && 
                email.conversationId && 
                expandedThreads.has(email.id)) {
              
              // Find all emails with the same conversationId but different id
              const threadEmails = emails.filter(e => 
                e?.conversationId === email.conversationId && 
                e?.id !== email.id
              );
              
              // Sort thread emails by date, newest first or other appropriate order
              const sortedThreadEmails = [...threadEmails]
                .sort((a, b) => new Date(b.date) - new Date(a.date));
              
              sortedThreadEmails.forEach(threadEmail => {
                if (threadEmail && threadEmail.id) {
                  flattenedEmails.push({
                    ...threadEmail,
                    subitem: true,
                    parentId: email.id
                  });
                }
              });
            }
          });
        }
      });
      
      // Then process each early month group
      if (groupedEmails.earlierMonths && groupedEmails.earlierMonths.length) {
        groupedEmails.earlierMonths.forEach(monthYear => {
          if (groupedEmails.earlier[monthYear]) {
            groupedEmails.earlier[monthYear].forEach(email => {
              if (!email) return;
              
              // Add the parent email
              flattenedEmails.push(email);
              
              // If this email has a thread and it's expanded, add its thread emails
              if (email.hasThread && 
                  email.conversationId && 
                  expandedThreads.has(email.id)) {
                
                // Find all emails with the same conversationId but different id
                const threadEmails = emails.filter(e => 
                  e?.conversationId === email.conversationId && 
                  e?.id !== email.id
                );
                
                const sortedThreadEmails = [...threadEmails]
                  .sort((a, b) => new Date(b.date) - new Date(a.date));
                
                sortedThreadEmails.forEach(threadEmail => {
                  if (threadEmail && threadEmail.id) {
                    flattenedEmails.push({
                      ...threadEmail,
                      subitem: true,
                      parentId: email.id
                    });
                  }
                });
              }
            });
          }
        });
      }

      // Add sender emails if available
      if (isViewingSingleEmail && senderEmails && senderEmails.length > 0) {
        senderEmails.forEach(email => {
          if (!email || !email.id) return;
          
          // Add sender email to the list of visible emails
          flattenedEmails.push(email);
          
          // If this email has a thread and it's expanded, add its thread emails
          if (email.hasThread && 
              email.conversationId && 
              expandedThreads.has(email.id)) {
            
            // Find all emails with the same conversationId but different id
            const threadEmails = emails.filter(e => 
              e?.conversationId === email.conversationId && 
              e?.id !== email.id
            );
            
            const sortedThreadEmails = [...threadEmails]
              .sort((a, b) => new Date(b.date) - new Date(a.date));
            
            sortedThreadEmails.forEach(threadEmail => {
              if (threadEmail && threadEmail.id) {
                flattenedEmails.push({
                  ...threadEmail,
                  subitem: true,
                  parentId: email.id
                });
              }
            });
          }
        });
      }
      
      // Update the state and return the array for immediate use
      setVisibleEmails(flattenedEmails);
      return flattenedEmails;
    } catch (error) {
      console.error('Error rebuilding visible emails list:', error);
      setVisibleEmails([]);
      return [];
    }
  }, [emails, expandedThreads, groupEmailsByDate, isViewingSingleEmail]);

  // Create a dedicated function to expand a thread and select the parent
  const expandThreadAndSelectParent = useCallback((parentEmail) => {
    if (!parentEmail || !parentEmail.hasThread || !parentEmail.conversationId) {
      return; // Nothing to do if no thread exists
    }
    
    console.log(`Expanding thread and selecting parent: ${parentEmail.id}`);
    
    // First expand the thread
    onThreadExpand(parentEmail.id);
    
    // Use a timeout to ensure thread expansion completes
    setTimeout(() => {
      // Just rebuild the list to include the expanded thread
      rebuildVisibleEmails();
      
      // Now select the parent email instead of jumping to the first thread item
      setFocusedEmailId(parentEmail.id);
      setSelectedEmailIds([parentEmail.id]);
      setSelectedEmail(parentEmail);
      
      // Find the parent's index in the updated visible emails list
      const updatedList = rebuildVisibleEmails();
      const parentIndex = updatedList.findIndex(email => email.id === parentEmail.id);
      if (parentIndex >= 0) {
        setLastSelectedIndex(parentIndex);
      }
      
      // Scroll parent into view
      const ref = listItemRefs.current[parentEmail.id];
      if (ref && typeof ref.scrollIntoView === 'function') {
        ref.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
      
      // Mark parent as read if needed
      if (!parentEmail.read && currentFolder !== 'inbox_focused') {
        updateReadStatus(parentEmail.id, false);
      }
      
      console.log(`Thread expanded, parent selected: ${parentEmail.id}. Use arrow down to navigate to thread items.`);
    }, 100);
  }, [emails, expandedThreads, groupEmailsByDate, onThreadExpand, setFocusedEmailId, 
      setSelectedEmailIds, setSelectedEmail, setLastSelectedIndex, listItemRefs, 
      currentFolder, updateReadStatus, rebuildVisibleEmails]);

  // Handle keyboard navigation
  const handleKeyDown = useCallback((event) => {
    // Skip if shortcuts help dialog is open (except for Escape)
    if (isHelpDialogOpen) {
      if (event.key === 'Escape') {
        setIsHelpDialogOpen(false);
        event.preventDefault();
      }
      return;
    }

    // The '?' shortcut is always enabled to show keyboard shortcuts
    // if (event.key === '?' && !event.ctrlKey && !event.metaKey) {
    //   setIsHelpDialogOpen(true);
    //   event.preventDefault();
    //   return;
    // }

    // Only enable Escape key for preview dialog globally
    if (event.key === 'Escape' && isPreviewDialogOpen) {
      setIsPreviewDialogOpen(false);
      event.preventDefault();
      return;
    }

    // Skip if the email list is not focused, we're in a text field, or viewing a single email
    if (
      !isEmailListFocused || 
      event.target.tagName === 'INPUT' || 
      event.target.tagName === 'TEXTAREA' ||
      isViewingSingleEmail || 
      isPreviewDialogOpen
    ) {
      return;
    }

    // If no emails, skip navigation
    if (!visibleEmails || visibleEmails.length === 0) {
      return;
    }

    // Find current focused email
    const currentIndex = focusedEmailId 
      ? visibleEmails.findIndex(email => email.id === focusedEmailId)
      : -1;
    
    const currentEmail = currentIndex >= 0 ? visibleEmails[currentIndex] : null;

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp': {
        event.preventDefault();
        
        // Handle Ctrl/Cmd+Arrow to jump to top/bottom
        if (event.ctrlKey || event.metaKey) {
          handleCtrlArrowSelection(event.key === 'ArrowDown');
          return;
        }
        
        // Calculate next index
        let nextIndex;
        if (event.key === 'ArrowDown') {
          // Move down the list, handling thread navigation
          nextIndex = currentIndex < visibleEmails.length - 1 ? currentIndex + 1 : 0;
        } else {
          // Move up the list, handling thread navigation
          nextIndex = currentIndex > 0 ? currentIndex - 1 : visibleEmails.length - 1;
        }
        
        const nextEmail = visibleEmails[nextIndex];
        if (!nextEmail) return;
        
        // Debug to track email navigation
        console.log(`Keyboard nav: ${currentIndex} -> ${nextIndex} | ${nextEmail.subitem ? 'Thread item' : 'Parent'} | ID: ${nextEmail.id}`);
        
        // Check if we need to expand a thread
        if (event.key === 'ArrowDown' && 
            nextEmail.hasThread && 
            nextEmail.conversationId && 
            !nextEmail.subitem &&
            !expandedThreads.has(nextEmail.id)) {
          
          // Expand the thread
          expandThreadAndSelectParent(nextEmail);
          
          return; // Skip the default navigation logic when expanding
        }
        
        // Regular navigation when not expanding a thread
        // Update the focused email
        setFocusedEmailId(nextEmail.id);
        
        // Handle different selection modes
        if (event.shiftKey) {
          // Shift key: now for non-contiguous selection (was range before)
          handleShiftSelection(nextIndex);
        } else {
          // Normal selection: select only this email
          setSelectedEmailIds([nextEmail.id]);
          setLastSelectedIndex(nextIndex);
          // Reset navigation direction when not using Shift
          setNavigationDirection(0);
        }
        
        // Update selected email for preview panel if only one is selected
        if (!event.shiftKey) {
          setSelectedEmail(nextEmail);
        }
        
        // Scroll selected item into view if reference exists
        const ref = listItemRefs.current[nextEmail.id];
        if (ref && typeof ref.scrollIntoView === 'function') {
          ref.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
        
        // Automatically update read status for non-focused or subitem emails
        if (!nextEmail.read && ((currentFolder !== 'inbox_focused' && currentFolder !== 'flagged') || nextEmail.subitem)) {
          updateReadStatus(nextEmail.id, false);
        }
        
        break;
      }
      
      case 'Enter': {
        // Open the selected email (simulate double click)
        if (currentEmail && listItemRefs.current[currentEmail.id]) {
          event.preventDefault();
          // Create a mock event object for handleDoubleClick
          const mockEvent = { stopPropagation: () => {}, preventDefault: () => {} };
          handleDoubleClick(currentEmail, mockEvent);
          
          // Always mark as read when using Enter key (simulating double click)
          // This follows our rule that double-clicking always marks an email as read
          if (!currentEmail.read) {
            updateReadStatus(currentEmail.id, false);
          }
        }
        break;
      }
      
      case ' ': { // Space key
        event.preventDefault();
        
        // If no email is focused, do nothing
        if (currentIndex < 0 || !currentEmail) return;
        
        // If the current email is a thread email (subitem), do nothing special
        if (currentEmail.subitem) return;
        
        // If the email has a thread, toggle its expansion
        if (currentEmail.hasThread && currentEmail.conversationId) {
          const isExpanded = expandedThreads.has(currentEmail.id);
          
          if (isExpanded) {
            // If already expanded, just collapse it
            onThreadExpand(currentEmail.id); // This will toggle/collapse
            setTimeout(() => rebuildVisibleEmails(), 50);
            console.log(`Thread collapsed: ${currentEmail.id}`);
          } else {
            // If collapsed, expand it but stay on the parent
            expandThreadAndSelectParent(currentEmail);
          }
        }
        break;
      }
      
      case 'Delete':
      case 'Backspace':
        // Delete the current email/thread
        if (currentEmail && currentEmail.id && currentFolder === 'conversations') {
          event.preventDefault();
          handleDeleteThread(currentEmail.id);
        }
        break;
      
      case 'r':
        // Mark as read - this is an explicit action so we always mark as read
        // regardless of folder or email type
        if (currentEmail && currentEmail.id && !currentEmail.read) {
          event.preventDefault();
          updateReadStatus(currentEmail.id, false);
        }
        break;
      
      case 'u':
        // Mark as unread
        if (currentEmail && currentEmail.id && currentEmail.read) {
          event.preventDefault();
          updateReadStatus(currentEmail.id, true);
        }
        break;
      
      case 'a':
        // Select all emails with Ctrl+A or Cmd+A
        if ((event.ctrlKey || event.metaKey) && !event.shiftKey) {
          event.preventDefault();
          const allEmailIds = visibleEmails.map(email => email.id);
          setSelectedEmailIds(allEmailIds);
        }
        break;
      
      case 'Home':
        event.preventDefault();
        if (visibleEmails.length > 0) {
          const firstEmail = visibleEmails[0];
          setFocusedEmailId(firstEmail.id);
          if (!event.shiftKey) {
            setSelectedEmailIds([firstEmail.id]);
            setSelectedEmail(firstEmail);
          } else {
            // Shift+Home selects from current to first
            if (currentIndex > 0) {
              const emailsToSelect = visibleEmails
                .slice(0, currentIndex + 1)
                .map(email => email.id);
              setSelectedEmailIds(emailsToSelect);
            }
          }
          // Scroll to top
          const ref = listItemRefs.current[firstEmail.id];
          if (ref && typeof ref.scrollIntoView === 'function') {
            ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
        break;
      
      case 'End':
        event.preventDefault();
        if (visibleEmails.length > 0) {
          const lastEmail = visibleEmails[visibleEmails.length - 1];
          setFocusedEmailId(lastEmail.id);
          if (!event.shiftKey) {
            setSelectedEmailIds([lastEmail.id]);
            setSelectedEmail(lastEmail);
          } else {
            // Shift+End selects from current to last
            if (currentIndex >= 0 && currentIndex < visibleEmails.length - 1) {
              const emailsToSelect = visibleEmails
                .slice(currentIndex)
                .map(email => email.id);
              setSelectedEmailIds(emailsToSelect);
            }
          }
          // Scroll to bottom
          const ref = listItemRefs.current[lastEmail.id];
          if (ref && typeof ref.scrollIntoView === 'function') {
            ref.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
        break;
      
      default:
        break;
    }
  }, [
    visibleEmails,
    focusedEmailId,
    setFocusedEmailId,
    setSelectedEmailIds,
    setSelectedEmail,
    listItemRefs,
    isViewingSingleEmail,
    updateReadStatus,
    currentFolder,
    handleDoubleClick,
    onThreadExpand,
    handleDeleteThread,
    isPreviewDialogOpen,
    setIsPreviewDialogOpen,
    isHelpDialogOpen,
    setIsHelpDialogOpen,
    lastSelectedIndex,
    handleShiftSelection,
    handleCtrlArrowSelection,
    expandThreadAndSelectParent,
    expandedThreads,
    setLastSelectedIndex,
    navigationDirection,
    setNavigationDirection,
    isEmailListFocused
  ]);

  // Handle focus events for the email list
  const handleEmailListFocus = useCallback(() => {
    console.log('Email list focused - keyboard shortcuts enabled');
    setIsEmailListFocused(true);
  }, []);

  // Handle blur events for the email list
  const handleEmailListBlur = useCallback((event) => {
    // Check if the new focus target is still within the email list container
    // This prevents losing focus when clicking on elements inside the list
    if (emailListRef && emailListRef.current && emailListRef.current.contains(event.relatedTarget)) {
      return;
    }
    
    console.log('Email list blurred - keyboard shortcuts disabled');
    setIsEmailListFocused(false);
  }, [emailListRef]);

  // Add event listeners for focus and blur on the email list container
  useEffect(() => {
    const listElement = emailListRef?.current;
    
    if (listElement) {
      // Add focus and blur event listeners
      listElement.addEventListener('focus', handleEmailListFocus, true);
      listElement.addEventListener('blur', handleEmailListBlur, true);
      
      // Make the list focusable if it isn't already
      if (!listElement.getAttribute('tabindex')) {
        listElement.setAttribute('tabindex', '-1');
      }
      
      return () => {
        // Clean up event listeners
        listElement.removeEventListener('focus', handleEmailListFocus, true);
        listElement.removeEventListener('blur', handleEmailListBlur, true);
      };
    }
  }, [emailListRef, handleEmailListFocus, handleEmailListBlur]);

  // Add event listener for keyboard navigation
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Add additional useEffect to detect changes in expanded threads
  useEffect(() => {
    // This ensures we rebuild our list whenever threads are expanded/collapsed externally
    const updatedList = rebuildVisibleEmails();
    
    // If we have a focused email, ensure it's still visible/available in the updated list
    if (focusedEmailId && updatedList?.length) {
      const focusedEmailIndex = updatedList.findIndex(email => email.id === focusedEmailId);
      if (focusedEmailIndex === -1) {
        // The focused email is no longer visible, reset focus to the first email if available
        console.log('Focused email no longer visible after thread changes, resetting focus');
        if (updatedList.length > 0) {
          setFocusedEmailId(updatedList[0].id);
          setSelectedEmailIds([updatedList[0].id]);
          setSelectedEmail(updatedList[0]);
        }
      }
    }
    
    // Log the expanded threads for debugging
    console.log('Expanded threads changed, threads now expanded:', 
      Array.from(expandedThreads).join(', '));
  }, [expandedThreads, rebuildVisibleEmails, focusedEmailId, setFocusedEmailId, setSelectedEmailIds, setSelectedEmail]);

  return {
    visibleEmails,
    keyboardShortcuts,
    isHelpDialogOpen,
    setIsHelpDialogOpen,
    // Expose focus methods for parent components to use
    focusEmailList: () => emailListRef?.current?.focus(),
    isEmailListFocused
  };
};

export default useEmailKeyboardNavigation; 