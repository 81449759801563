import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Grid, Container, Paper, Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Sidebar from './Sidebar';
import AuthPage from './AuthPage';  
import AppHeader from './AppHeader';
import { axiosWithAuth } from './authService';
import MailView from './MailView';
import ProfileDialog from './ProfileDialog';
import { isEmailProcessing, setEmailProcessingStatus, clearEmailProcessingStatus, getProcessingJobId } from '../utils/emailProcessingUtil';
import CircularProgress from '@mui/material/CircularProgress';

// Add these cache utility functions at the top level
const getCacheKey = (folder, accountEmail, searchParams) => {
  // Check for mailbox parameter in URL
  // Use provided searchParams or create new URLSearchParams from window.location.search
  const urlParams = searchParams || new URLSearchParams(window.location.search);
  const mailboxParam = urlParams.get('mailbox');
  
  // If mailbox param exists, include it in the cache key
  if (mailboxParam) {
    const cacheKey = accountEmail 
      ? `account_${accountEmail}_mailbox_${mailboxParam}_emails_${folder}` 
      : `mailbox_${mailboxParam}_emails_${folder}`;
    
    console.log(`Using mailbox-specific cache key: ${cacheKey}`);
    return cacheKey;
  }
  
  // Otherwise use the original format
  const cacheKey = accountEmail ? `account_${accountEmail}_emails_${folder}` : `emails_${folder}`;
  console.log(`Using standard cache key: ${cacheKey}`);
  return cacheKey;
};

const getCachedEmails = (folder, searchParams) => {
  try {
    const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
    const accountEmail = currentAccount.email;
    
    // Support for folder names with filter parameters
    const cacheKey = getCacheKey(folder, accountEmail, searchParams);
    
    const cached = sessionStorage.getItem(cacheKey);
    if (!cached) return null;
    
    const parsedCache = JSON.parse(cached);
    if (!Array.isArray(parsedCache)) {
      console.error('Invalid cache format');
      return null;
    }
    
    return parsedCache.map(email => ({
      ...email,
      date: new Date(email.date)
    }));
  } catch (error) {
    console.error('Error reading cache:', error);
    return null;
  }
};

// Add these utility functions at the top of the file
const setViewHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};



const ViewWrapper = ({ children, showEmailList, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Add state for filters that will be shared between EmailList and AppHeader
  const [sharedActiveFilters, setSharedActiveFilters] = useState({});
  
  // Add handler for filter changes
  const handleSharedFilterChange = (newFilters) => {
    console.log('Shared filter change:', newFilters);
    setSharedActiveFilters(newFilters);
    // If there's an onFilterChange prop, call it
    if (props.onFilterChange) {
      props.onFilterChange(newFilters);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      height: '100%', 
      overflow: 'hidden',
    }}>
      {/* On desktop, show the sidebar as a fixed component */}
      {!isMobile && (
        <Sidebar {...props} showEmailList={showEmailList} folderEmails={props.folderEmails} />
      )}
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        minWidth: 0,
      }}>
        <AppHeader 
          selectedEmail={props.selectedEmail} 
          {...props} 
          showEmailList={showEmailList} 
          // Pass filter-related props
          activeFilters={sharedActiveFilters}
          setActiveFilters={setSharedActiveFilters}
          onFilterChange={handleSharedFilterChange}
          emails={props.folderEmails[props.currentFolder] || []}
        />
        <Box sx={{ 
          display: 'flex',
          flexGrow: 1,
          minWidth: 0,
          overflow: 'hidden',
        }}>
          {/* Clone children and pass filter props */}
          {React.Children.map(children, child => 
            React.cloneElement(child, { 
              activeFilters: sharedActiveFilters,
              setActiveFilters: setSharedActiveFilters,
              onFilterChange: handleSharedFilterChange
            })
          )}
        </Box>
      </Box>
      {/* On mobile, the sidebar is rendered as a drawer that can be opened/closed */}
      {isMobile && (
        <Sidebar {...props} showEmailList={showEmailList} folderEmails={props.folderEmails} />
      )}
    </Box>
  );
};

const AuthWrapper = ({ children, isAuthenticated, isSubscribed }) => {
  const location = useLocation();
  
  // Check if there are any existing accounts
  const hasExistingAccounts = () => {
    const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
    return accounts.length > 0;
  };
  
  // Store URL parameters before any redirects if they haven't been stored yet
  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    if (currentParams.toString() && !localStorage.getItem('auth_redirect_params')) {
      localStorage.setItem('auth_redirect_params', currentParams.toString());
    }
  }, [location.search]);
  
  // If there are existing accounts, don't redirect to auth unless explicitly requested
  if (!isAuthenticated && location.pathname !== '/auth') {
    if (!hasExistingAccounts()) {
      // Store current URL parameters before redirecting
      const currentParams = new URLSearchParams(location.search);
      if (currentParams.toString()) {
        localStorage.setItem('auth_redirect_params', currentParams.toString());
      }
      
      // Preserve the current URL parameters when redirecting to auth
      const authPath = currentParams.toString() ? `/auth?${currentParams.toString()}` : '/auth';
      return <Navigate to={authPath} state={{ from: location }} replace />;
    }
    // If there are existing accounts, redirect to inbox_focused instead
    return <Navigate to="/inbox?folder=inbox_focused" replace />;
  }
  
  // If authenticated but not subscribed, stay on auth page with preserved parameters
  if (isAuthenticated && !isSubscribed && location.pathname !== '/auth') {
    // Store current URL parameters before redirecting
    const currentParams = new URLSearchParams(location.search);
    if (currentParams.toString()) {
      localStorage.setItem('auth_redirect_params', currentParams.toString());
    }
    
    const authPath = currentParams.toString() ? `/auth?${currentParams.toString()}` : '/auth';
    return <Navigate to={authPath} state={{ from: location }} replace />;
  }
  
  // If on auth page but already authenticated and subscribed, redirect to last location or inbox_focused
  if (isAuthenticated && isSubscribed && location.pathname === '/auth') {
    const from = location.state?.from?.pathname || '/inbox?folder=inbox_focused';
    
    // Get stored parameters
    const storedParams = localStorage.getItem('auth_redirect_params');
    
    // Clear stored parameters as they're no longer needed
    localStorage.removeItem('auth_redirect_params');
    
    // Parse and merge parameters
    const currentParams = new URLSearchParams(location.search);
    const targetParams = new URLSearchParams(from.includes('?') ? from.split('?')[1] : '');
    const storedParamsObj = storedParams ? new URLSearchParams(storedParams) : new URLSearchParams();
    
    // Merge parameters in order of priority: stored > current > target
    // Skip auth-specific parameters
    const authParams = ['access_token', 'is_subscribed', 'user_info', 'error', 'code', 'state', 'forward_params', 'original_params'];
    
    // First add stored parameters
    storedParamsObj.forEach((value, key) => {
      if (!authParams.includes(key) && !targetParams.has(key)) {
        targetParams.append(key, value);
      }
    });
    
    // Then add current parameters
    currentParams.forEach((value, key) => {
      if (!authParams.includes(key) && !targetParams.has(key)) {
        targetParams.append(key, value);
      }
    });
    
    const targetPath = from.split('?')[0];
    const finalPath = targetParams.toString() ? `${targetPath}?${targetParams.toString()}` : targetPath;
    return <Navigate to={finalPath} replace />;
  }

  return children;
};

const EmailChatApp = () => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmailIds, setSelectedEmailIds] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentFolder, setCurrentFolder] = useState('inbox_focused');
  const [clearThreadFunction, setClearThreadFunction] = useState(null);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [lastSelectedEmailId, setLastSelectedEmailId] = useState(null);
  const [folderEmails, setFolderEmails] = useState({});
  const [focusedEmailId, setFocusedEmailId] = useState(null);
  const [unreadEmails, setUnreadEmails] = useState({});
  const [showEmailList, setShowEmailList] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  
  // Add new state for email processing
  const [jobId, setJobId] = useState(null);
  const [indexingStatus, setIndexingStatus] = useState(null);
  const [statusError, setStatusError] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const isCheckingRef = useRef(false);
  const refreshTargetFolderRef = useRef(null);

  // effect to setselectedemail to lexi when no email is selected
  useEffect(() => {
    if (!selectedEmail) {
      setSelectedEmail(
        { 
          id: "thread_newLexiChat",
          fromName: "Lexi",
          subject: "AI Assistant",
          date: new Date().toISOString(),
          recipients: [],
          fromEmail: "ai@lexi.com"
        }
      );
    }
  }, [selectedEmail]);

  // Add updateEmailsStateAndCache as a common function for all email state/cache updates
  const updateEmailsStateAndCache = useCallback(async (folder, emails, options = {}, searchParams) => {
    // If searchParams is not provided, use the one from useSearchParams
    const urlParams = searchParams || new URLSearchParams(window.location.search);
    
    const {
      isAppend = false,
      skipCache = false,
      targetFolder = null,
      movedEmails = [],
      isPartialUpdate = false,
      cacheKey = null, // Add cacheKey parameter
      preserveOnEmpty = false, // Add preserveOnEmpty parameter
      appendOperation = false, // New flag to force append behavior
      preserveExistingData = false, // New flag to preserve existing data
      filterOperationWithNoResults = false, // Special flag for filter operations with no results
      isFilterChange = false // Flag to indicate this is a filter change operation
    } = options;

    try {
      // Get current account email
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const accountEmail = currentAccount.email;

      // Format emails to ensure consistent date objects
      const formattedEmails = emails.map(email => ({
        ...email,
        date: email.date instanceof Date ? email.date : new Date(email.date)
      }));

      // Validate email format
      const isValidFormat = formattedEmails.every(email => 
        email && 
        typeof email === 'object' && 
        email.id && 
        email.date
      );

      if (!isValidFormat) {
        console.error('Invalid email format detected');
        return false;
      }


      // If preserveOnEmpty is true and emails array is empty, don't update state
      if ((preserveOnEmpty || filterOperationWithNoResults) && (!formattedEmails.length)) {
        console.log(`Preserving existing emails for ${folder} because preserveOnEmpty=true and email array is empty`);
        return true;
      }

      // Create a stable state update that won't be affected by race conditions
      const stateUpdate = (prevState) => {
        let updatedState = { ...prevState };
        
        // Handle main folder update
        if (isAppend || appendOperation) {
          // Force logging the current state before append
          console.log(`APPEND operation for ${folder}: Current count=${(prevState[folder] || []).length}, New count=${formattedEmails.length}`);
          
          const existingEmails = prevState[folder] || [];
          
          // Create a Set of existing IDs for faster lookup
          const existingIds = new Set(existingEmails.map(e => e?.id));
          
          // Only append emails that don't already exist
          const uniqueNewEmails = formattedEmails.filter(email => 
            !existingIds.has(email?.id)
          );
          
          
          if (uniqueNewEmails.length === 0 && !filterOperationWithNoResults) {
            return prevState; // No changes needed
          }

          // Create a new array with existing and new emails
          updatedState[folder] = [...existingEmails, ...uniqueNewEmails]
            .filter(Boolean) // Remove any null/undefined entries
            .sort((a, b) => new Date(b?.date) - new Date(a?.date));
            
          console.log(`Final count after append: ${updatedState[folder].length}`);
        } else if (isPartialUpdate) {
          // For partial updates, only update the specific emails
          const existingEmails = prevState[folder] || [];
          const emailIdsToUpdate = new Set(formattedEmails.map(email => email.id));
          
          // Create a map of email IDs to their updated versions
          const emailUpdatesById = {};
          formattedEmails.forEach(email => {
            emailUpdatesById[email.id] = email;
          });
          
          // Update only the specific emails, keeping others unchanged
          updatedState[folder] = existingEmails.map(email => {
            if (email && emailIdsToUpdate.has(email.id)) {
              return emailUpdatesById[email.id];
            }
            return email;
          })
          .filter(Boolean) // Remove any null/undefined entries
          .sort((a, b) => new Date(b?.date) - new Date(a?.date));
          
          console.log(`Partial update for ${folder}: Updated ${formattedEmails.length} emails`);
        } else if (preserveExistingData || filterOperationWithNoResults) {
          // Special case: Preserve existing data (used for filter operations with no results)
          console.log(`Preserving existing data for ${folder}`);
          return prevState;
        } else {
          // For complete folder updates, ensure we're not accidentally mixing states
          // But first log what we're replacing
          const existingCount = (prevState[folder] || []).length;
          console.log(`REPLACE operation for ${folder}: Replacing ${existingCount} emails with ${formattedEmails.length} emails`);
          
          updatedState[folder] = formattedEmails
            .filter(Boolean)
            .sort((a, b) => new Date(b?.date) - new Date(a?.date));
        }

        // Handle target folder update if specified
        if (targetFolder && movedEmails.length > 0) {
          // Only update the target folder if it already has emails in it
          const existingTargetEmails = prevState[targetFolder] || [];
          if (existingTargetEmails.length > 0) {
            const movedEmailIds = new Set(movedEmails.map(email => email.id));
            
            // Remove any emails that are being moved to prevent duplicates
            const filteredTargetEmails = existingTargetEmails.filter(email => 
              !movedEmailIds.has(email?.id)
            );

            // Add moved emails to target folder
            updatedState[targetFolder] = [...movedEmails, ...filteredTargetEmails]
              .filter(Boolean)
              .sort((a, b) => new Date(b?.date) - new Date(a?.date));
          }
        }

        return updatedState;
      };

      // Update state atomically
      setFolderEmails(stateUpdate);

      // Skip cache updates if specified
      if (skipCache) {
        return true;
      }

      // Update cache immediately without debouncing
      const updateCache = async () => {
        try {
          // Get cache key for the folder, using the provided cacheKey if available
          const folderCacheKey = cacheKey || folder;
          const actualCacheKey = getCacheKey(folderCacheKey, accountEmail, urlParams);
          
          console.log(`Updating cache for ${folderCacheKey}`, {
            isAppend,
            appendOperation,
            isPartialUpdate,
            preserveExistingData,
            filterOperationWithNoResults
          });
          
          if (isPartialUpdate) {
            // For partial updates, update only the specific emails in the cache
            const existingCache = sessionStorage.getItem(actualCacheKey);
            if (existingCache) {
              const existingEmails = JSON.parse(existingCache);
              const emailIdsToUpdate = new Set(formattedEmails.map(email => email.id));
              
              // Create a map of email IDs to their updated versions
              const emailUpdatesById = {};
              formattedEmails.forEach(email => {
                emailUpdatesById[email.id] = email;
              });
              
              // Update only the specific emails, keeping others unchanged
              const updatedCache = existingEmails.map(email => {
                if (email && emailIdsToUpdate.has(email.id)) {
                  return emailUpdatesById[email.id];
                }
                return email;
              })
              .filter(Boolean)
              .sort((a, b) => new Date(b?.date) - new Date(a?.date));
              
              // Update the cache with the merged emails
              sessionStorage.setItem(actualCacheKey, JSON.stringify(updatedCache));
            } else {
              // If no cache exists yet, just store the formatted emails
              sessionStorage.setItem(actualCacheKey, JSON.stringify(formattedEmails));
            }
          } else if (isAppend || appendOperation) {
            // For append operations, add to existing cache
            const existingCache = sessionStorage.getItem(actualCacheKey);
            if (existingCache) {
              const existingEmails = JSON.parse(existingCache);
              
              // Create a Set of existing IDs for faster lookup
              const existingIds = new Set(existingEmails.map(e => e?.id));
              
              // Only append emails that don't already exist
              const uniqueNewEmails = formattedEmails.filter(email => 
                !existingIds.has(email?.id)
              );
              
              if (uniqueNewEmails.length > 0) {
                // Create a new array with existing and new emails
                const combinedEmails = [...existingEmails, ...uniqueNewEmails]
                  .filter(Boolean) // Remove any null/undefined entries
                  .sort((a, b) => new Date(b?.date) - new Date(a?.date));
                
                // Update the cache with the combined emails
                sessionStorage.setItem(actualCacheKey, JSON.stringify(combinedEmails));
                console.log(`Cache updated for ${folderCacheKey} - appended ${uniqueNewEmails.length} emails, total: ${combinedEmails.length}`);
              } else {
                console.log(`No new unique emails to add to cache for ${folderCacheKey}`);
              }
            } else {
              // If no cache exists yet, just store the formatted emails
              sessionStorage.setItem(actualCacheKey, JSON.stringify(formattedEmails));
              console.log(`Created new cache for ${folderCacheKey} with ${formattedEmails.length} emails`);
            }
          } else if (preserveExistingData || filterOperationWithNoResults) {
            // Skip cache update for these special cases
            console.log(`Preserving existing cache for ${folderCacheKey} due to preserveExistingData or filterOperationWithNoResults flag`);
          } else {
            // For complete updates, replace the entire cache
            sessionStorage.setItem(actualCacheKey, JSON.stringify(formattedEmails));
            console.log(`Replaced cache for ${folderCacheKey} with ${formattedEmails.length} emails`);
          }

          // Update target folder cache if needed
          if (targetFolder && movedEmails.length > 0) {
            const targetCacheKey = getCacheKey(targetFolder, accountEmail, urlParams);
            // Only update the target folder cache if it already has emails in it
            const existingTargetCache = sessionStorage.getItem(targetCacheKey);
            if (existingTargetCache) {
              const existingTargetEmails = JSON.parse(existingTargetCache || '[]');
              // Only update if there are actually emails in the target folder
              if (existingTargetEmails.length > 0) {
                const targetState = movedEmails.concat(existingTargetEmails).filter(Boolean);
                sessionStorage.setItem(targetCacheKey, JSON.stringify(targetState));
              }
            }
          }
        } catch (error) {
          if (error.name === 'QuotaExceededError') {
            // Instead of removing entire folder caches, trim emails within folders
            try {
              const MAX_EMAILS_PER_FOLDER = 100; // Adjust based on your needs
              
              // Get all folder cache keys
              const folderKeys = Object.keys(sessionStorage)
                .filter(key => key.includes('emails_') || key.includes('account_'));
              
              // For each folder, keep only the most recent MAX_EMAILS_PER_FOLDER emails
              for (const key of folderKeys) {
                try {
                  const folderEmails = JSON.parse(sessionStorage.getItem(key) || '[]');
                  if (folderEmails.length > MAX_EMAILS_PER_FOLDER) {
                    // Sort by date and keep only most recent emails
                    const trimmedEmails = folderEmails
                      .sort((a, b) => new Date(b?.date) - new Date(a?.date))
                      .slice(0, MAX_EMAILS_PER_FOLDER);
                    sessionStorage.setItem(key, JSON.stringify(trimmedEmails));
                  }
                } catch (parseError) {
                  console.error('Error parsing folder cache:', parseError);
                  continue;
                }
              }
              
              // Try updating the cache again after trimming
              const folderCacheKey = cacheKey || folder;
              const actualCacheKey = getCacheKey(folderCacheKey, accountEmail, urlParams);
              sessionStorage.setItem(actualCacheKey, JSON.stringify(formattedEmails));
            } catch (trimError) {
              console.error('Failed to trim and update cache:', trimError);
            }
          } else {
            console.error('Error updating cache:', error);
          }
        }
      };

      // Execute cache update
      await updateCache();

      return true;
    } catch (error) {
      console.error('Error in updateEmailsStateAndCache:', error);
      return false;
    }
  }, [searchParams]); // Add searchParams to dependencies

  // Add handleEmailMove function
  const handleEmailMove = useCallback(async (emailIds, targetFolder) => {
    // Store original state at the top level of the function
    let originalState;
    
    try {
      console.log(`Moving emails to ${targetFolder}...`);
      
      // Get target folder ID from userFolders in session storage   
      // Normalize inbox_focused and inbox_other to inbox
      const normalizedTargetFolder = targetFolder === 'inbox_focused' || targetFolder === 'inbox_other' ? 'inbox' : targetFolder;
      
      // Get userFolders from session storage
      const userFolders = JSON.parse(sessionStorage.getItem('userFolders') || '[]');
      
      // Recursive function to find folder ID by display name
      const findFolderIdByDisplayName = (displayName, folders) => {
        if (!folders || !Array.isArray(folders)) return null;
        
        for (const folder of folders) {
          if (folder.displayName === displayName) {
            return folder.id;
          }
          
          if (folder.childFolders && Array.isArray(folder.childFolders) && folder.childFolders.length > 0) {
            const childResult = findFolderIdByDisplayName(displayName, folder.childFolders);
            if (childResult) return childResult;
          }
        }
        return null;
      };
      
      // Find the target folder ID
      const targetFolderId = findFolderIdByDisplayName(normalizedTargetFolder, userFolders);

      // Get current emails for both folders
      const currentEmails = folderEmails[currentFolder] || [];
      
      // Check if target folder exists and has emails
      const targetFolderExists = targetFolder in folderEmails && Array.isArray(folderEmails[targetFolder]);
      const targetHasEmails = targetFolderExists && folderEmails[targetFolder].length > 0;
      
      // Only get target emails if the folder exists and has emails
      const targetEmails = targetHasEmails ? folderEmails[targetFolder] : [];

      // Store original state in case we need to revert
      originalState = {
        sourceFolder: [...currentEmails],
        targetFolder: targetHasEmails ? [...targetEmails] : []
      };

      // Store affected emails before removing them
      const movedEmails = currentEmails
        .filter(email => {
          // Just check if this email's ID is in the list of emails to move
          return emailIds.includes(email?.id);
        })
        .map(email => {
          // Create a copy of the email with updated folderName
          return {
            ...email,
            folderName: targetFolder
          };
        });

      // Also include any emails that belong to the same conversation as moved emails
      const movedConversationIds = movedEmails
        .filter(email => email.conversationId)
        .map(email => email.conversationId);
      
      // Find thread emails that belong to moved conversations but weren't explicitly included in emailIds
      if (movedConversationIds.length > 0) {
        const relatedThreadEmails = currentEmails.filter(email => 
          email && 
          email.conversationId && 
          movedConversationIds.includes(email.conversationId) && 
          !emailIds.includes(email.id)
        ).map(email => ({
          ...email,
          folderName: targetFolder
        }));
        
        // Add these related thread emails to the movedEmails array
        if (relatedThreadEmails.length > 0) {
          movedEmails.push(...relatedThreadEmails);
        }
      }

      // Remove moved emails from source folder (just remove the IDs that were explicitly moved)
      const updatedSourceEmails = currentEmails
        .filter(email => !emailIds.includes(email?.id));

      // Update source folder with removed emails
      await updateEmailsStateAndCache(currentFolder, updatedSourceEmails, {}, searchParams);

      // Update target folder with moved emails ONLY if the target folder already has emails
      if (movedEmails.length > 0 && targetHasEmails) {
        const movedEmailIds = movedEmails.map(email => email.id);
        const filteredTargetEmails = targetEmails.filter(email => !movedEmailIds.includes(email?.id));
        const updatedTargetEmails = [...movedEmails, ...filteredTargetEmails]
          .sort((a, b) => new Date(b?.date) - new Date(a?.date));

        await updateEmailsStateAndCache(targetFolder, updatedTargetEmails, {}, searchParams);
      }

      // Make the API call after updating UI
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/move-emails', { emailIds, targetFolderId });
      
      if (response.status !== 200 && response.status !== 207) {
        throw new Error(response.data.message || 'Failed to move emails');
      }

      // Reset selected email after successful move
      setSelectedEmail(null);
      
    } catch (error) {
      console.error('Error moving emails:', error);
      // Attempt to revert the UI state if we have the original state
      try {
        if (originalState) {
          await updateEmailsStateAndCache(currentFolder, originalState.sourceFolder, {}, searchParams);
          if (originalState.targetFolder.length > 0) {
            await updateEmailsStateAndCache(targetFolder, originalState.targetFolder, {}, searchParams);
          }
          console.log('Reverted UI state after failed email move');
        }
      } catch (revertError) {
        console.error('Failed to revert UI state:', revertError);
      }
    }
  }, [currentFolder, setSelectedEmailIds, setLastSelectedEmailId, setFocusedEmailId, setSelectedEmail, updateEmailsStateAndCache, folderEmails, searchParams]);


  const processEmails = useCallback(async () => {
    if (!isAuthenticated || !isSubscribed) {
      console.log('User not authenticated or not subscribed, skipping email processing');
      return;
    }

    if (isCheckingRef.current || isEmailProcessing()) {
      console.log('Email processing already in progress');
      return;
    }

    try {
      isCheckingRef.current = true;
      console.log('Starting email processing');
      
      const response = await axiosWithAuth.post(process.env.REACT_APP_API_URL + '/process-emails');
      
      if (response.data && response.data.job_id) {
        console.log('Got new job ID:', response.data.job_id);
        setJobId(response.data.job_id);
        setEmailProcessingStatus(response.data.job_id, 0, 'pending');
        setStatusError(false);
        setFailedAttempts(0);
        
        if (response.data.vector_store_id) {
          const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
          userInfo.vector_store_id = response.data.vector_store_id;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
        }
      }
    } catch (error) {
      console.error('Error processing emails:', error);
      clearEmailProcessingStatus();
      setStatusError(true);
    } finally {
      isCheckingRef.current = false;
    }
  }, [isAuthenticated, isSubscribed]);
  
  useEffect(() => {
    // Only handle folder navigation if authenticated, subscribed and not loading
    if (isAuthenticated && isSubscribed && !isLoading) {
      const folderParam = searchParams.get('folder');
      const path = window.location.pathname;
      
      if (folderParam) {
        setCurrentFolder(folderParam);
      }
    }
  }, [navigate, isAuthenticated, isSubscribed, isLoading, searchParams]);

  const areSelectedEmailsFlagged = useMemo(() => {
    return selectedEmailIds.length > 0 && selectedEmailIds.every(id => {
      // Determine which folders to check
      const foldersToCheck = currentFolder.startsWith('inbox_') 
        ? ['inbox_focused', 'inbox_other']
        : [currentFolder];
      
      // Check all relevant folders
      for (const folder of foldersToCheck) {
        // Search all emails in the folder (both main emails and thread emails are now flat)
        const email = folderEmails[folder]?.find(email => email?.id === id);
        if (email) {
          return email.flagged;
        }
      }
      
      return false; // If not found in any checked folders
    });
  }, [selectedEmailIds, folderEmails, currentFolder]);

  const handleActionComplete = useCallback((action, affectedEmailIds) => {
    // For delete/archive actions, use handleEmailMove or remove entirely if in deleteditems
    if (action === 'delete' || action === 'archive') {
      
      // Skip if trying to archive to archive folder
      if (!(action === 'archive' && currentFolder === 'archive')) {
        // Get current emails for potential next selection
        const currentEmails = folderEmails[currentFolder] || [];
        
        // If we need to select the next email, find it before removing any emails
        let nextEmailToSelect = null;
        
        if (affectedEmailIds.length > 0) {
          // First, identify any thread parents and thread items in the affected emails
          const affectedThreadParents = [];
          const affectedThreadItems = [];
          
          // Also identify thread conversation IDs for thread-aware selection
          const affectedConversations = new Set();
          
          affectedEmailIds.forEach(id => {
            const email = currentEmails.find(e => e?.id === id);
            if (email) {
              if (email.hasThread && email.conversationId) {
                affectedThreadParents.push(email);
                affectedConversations.add(email.conversationId);
              } else if (email.subitem) {
                affectedThreadItems.push(email);
                if (email.conversationId) {
                  affectedConversations.add(email.conversationId);
                }
              } else if (email.conversationId) {
                // Regular email but part of a conversation
                affectedConversations.add(email.conversationId);
              }
            }
          });
          
          // Sort affected emails by their position in the current list
          const affectedEmailsIndices = affectedEmailIds.map(id => {
            const index = currentEmails.findIndex(email => email?.id === id);
            return { id, index: index >= 0 ? index : Infinity };
          })
          .filter(item => item.index !== Infinity)
          .sort((a, b) => a.index - b.index);
          
          if (affectedEmailsIndices.length > 0) {
            // Get the deleted/archived email with the lowest index (highest in the list)
            const highestAffectedIndex = affectedEmailsIndices[0].index;
            
            // Find the next email after the highest affected one (lowest index)
            // If we're deleting the last email, wrap to the first email
            if (highestAffectedIndex < currentEmails.length) {
              // Try to find the next email that's not also being deleted/archived
              for (let i = highestAffectedIndex + 1; i < currentEmails.length; i++) {
                const candidateEmail = currentEmails[i];
                // Skip email if it's being deleted/archived
                if (!candidateEmail || affectedEmailIds.includes(candidateEmail.id)) {
                  continue;
                }
                
                // Skip email if it's a thread item of a being-deleted parent
                if (candidateEmail.subitem && 
                    affectedThreadParents.some(p => p.id === candidateEmail.parentId)) {
                  continue;
                }
                
                // Skip email if it's part of a conversation being entirely deleted
                if (candidateEmail.conversationId && 
                    affectedConversations.has(candidateEmail.conversationId) &&
                    // But only if all emails in the conversation are being deleted
                    !currentEmails.some(e => 
                      e.conversationId === candidateEmail.conversationId && 
                      !affectedEmailIds.includes(e.id)
                    )) {
                  continue;
                }
                
                // This email passes all checks - select it
                nextEmailToSelect = candidateEmail;
                break;
              }
              
              // If no next email found (all subsequent emails are being deleted),
              // try to find a previous email that's not being deleted
              if (!nextEmailToSelect) {
                for (let i = highestAffectedIndex - 1; i >= 0; i--) {
                  const candidateEmail = currentEmails[i];
                  // Skip email if it's being deleted/archived
                  if (!candidateEmail || affectedEmailIds.includes(candidateEmail.id)) {
                    continue;
                  }
                  
                  // Skip email if it's a thread item of a being-deleted parent
                  if (candidateEmail.subitem && 
                      affectedThreadParents.some(p => p.id === candidateEmail.parentId)) {
                    continue;
                  }
                  
                  // Skip email if it's part of a conversation being entirely deleted
                  if (candidateEmail.conversationId && 
                      affectedConversations.has(candidateEmail.conversationId) &&
                      // But only if all emails in the conversation are being deleted
                      !currentEmails.some(e => 
                        e.conversationId === candidateEmail.conversationId && 
                        !affectedEmailIds.includes(e.id)
                      )) {
                    continue;
                  }
                  
                  // This email passes all checks - select it
                  nextEmailToSelect = candidateEmail;
                  break;
                }
              }
            }
          }
        }
        
        // If we're in deleteditems and trying to delete, remove entirely instead of moving
        if (currentFolder === 'deleteditems') {
          // In deleted items, permanently delete the emails and their thread items if applicable
          
          // First, collect all email IDs that need to be deleted, including thread items
          const allIdsToDelete = new Set(affectedEmailIds);
          
          // Add thread items of any parent threads being deleted
          affectedEmailIds.forEach(id => {
            const email = currentEmails.find(e => e?.id === id);
            if (email && email.hasThread && email.conversationId) {
              // Find and add all thread items for this parent
              currentEmails.forEach(threadEmail => {
                if (threadEmail.subitem && threadEmail.parentId === email.id) {
                  allIdsToDelete.add(threadEmail.id);
                }
              });
            }
          });
          
          // Filter out all permanently deleted emails
          const updatedEmails = currentEmails.filter(email => 
            !allIdsToDelete.has(email?.id)
          );

          // Update the state and cache for deleteditems folder
          updateEmailsStateAndCache(currentFolder, updatedEmails, {}, searchParams);
        } else {          
          // For deletion/archiving, handle differently depending on whether we're dealing with threads
          
          // Step 1: Identify all thread parents in the affected emails
          const threadsToRemove = new Set();
          affectedEmailIds.forEach(id => {
            const email = currentEmails.find(e => e?.id === id);
            if (email && email.hasThread && email.conversationId) {
              threadsToRemove.add(email.conversationId);
            }
          });
          
          // Step 2: Create sets of emails to remove
          const idsToRemove = new Set(affectedEmailIds);
          
          // Add thread children if parent is being removed
          if (threadsToRemove.size > 0) {
            currentEmails.forEach(email => {
              if (email && email.conversationId && threadsToRemove.has(email.conversationId) 
                  && !idsToRemove.has(email.id)) {
                // This is a thread item of a thread being removed - add to removal list
                idsToRemove.add(email.id);
              }
            });
          }
          
          // Filter out all emails to be removed
          const filteredEmails = currentEmails.filter(email => 
            !idsToRemove.has(email?.id)
          );
          
          // Update source folder with removed emails
          updateEmailsStateAndCache(currentFolder, filteredEmails, {}, searchParams);
        }
        
        // Select the next email if we found one
        if (nextEmailToSelect) {
          setSelectedEmailIds([nextEmailToSelect.id]);
          setSelectedEmail(nextEmailToSelect);
          setFocusedEmailId(nextEmailToSelect.id);
        } else {
          // Reset selected email if no next email found
          setSelectedEmail(null);
          setSelectedEmailIds([]);
          setFocusedEmailId(null);
        }
      }
    } else {
      // Helper function to update emails in a folder
      const updateEmailsInFolder = (emails, affectedIds, action) => {
        return emails.map(email => {
          if (!email) return null;
          const isAffected = affectedIds.includes(email?.id);
          
          if (isAffected) {
            return {
              ...email,
              flagged: action === 'flag' ? true : action === 'unflag' ? false : email.flagged,
              read: action === 'mark-read' ? true : action === 'mark-unread' ? false : email.read
            };
          }
          
          return email;
        }).filter(Boolean);
      };

      // Update both inbox folders if we're in either inbox folder
      const isInboxFolder = currentFolder.startsWith('inbox_');
      if (isInboxFolder) {
        const otherInboxFolder = currentFolder === 'inbox_focused' ? 'inbox_other' : 'inbox_focused';
        const currentEmails = folderEmails[currentFolder] || [];
        const otherInboxEmails = folderEmails[otherInboxFolder] || [];
        
        // Update both inbox folders
        const updatedEmails = updateEmailsInFolder(currentEmails, affectedEmailIds, action);
        const updatedOtherInboxEmails = updateEmailsInFolder(otherInboxEmails, affectedEmailIds, action);
        
        // Update both inbox folders
        updateEmailsStateAndCache(currentFolder, updatedEmails, {}, searchParams);
        updateEmailsStateAndCache(otherInboxFolder, updatedOtherInboxEmails, {}, searchParams);
      } else {
        // For non-inbox folders
        const currentEmails = folderEmails[currentFolder] || [];
        const updatedEmails = updateEmailsInFolder(currentEmails, affectedEmailIds, action);
        updateEmailsStateAndCache(currentFolder, updatedEmails, {}, searchParams);
      }

      // Handle flagged folder updates
      if (action === 'flag' || action === 'unflag') {
        const flaggedEmails = folderEmails.flagged || [];
        // Check if flagged folder exists and has emails
        const flaggedFolderExists = 'flagged' in folderEmails && Array.isArray(folderEmails.flagged);
        const flaggedHasEmails = flaggedFolderExists && folderEmails.flagged.length > 0;
        
        if (action === 'flag') {
          const movedEmails = (folderEmails[currentFolder] || [])
            .filter(email => affectedEmailIds.includes(email?.id));
          
          // Only update flagged folder if it already has emails or we're adding new ones
          if (flaggedHasEmails || movedEmails.length > 0) {
            const movedEmailIds = movedEmails.map(email => email.id);
            const filteredFlaggedEmails = flaggedEmails.filter(email => !movedEmailIds.includes(email.id));
            const updatedFlaggedEmails = [...movedEmails, ...filteredFlaggedEmails];
            
            updateEmailsStateAndCache('flagged', updatedFlaggedEmails, {}, searchParams);
          }
        } else if (flaggedHasEmails) {
          // Only update unflag if the flagged folder has emails
          const unflaggedEmailIds = new Set(affectedEmailIds);
          const updatedFlaggedEmails = flaggedEmails.filter(email => {
            // With flat structure, just check if this email's ID is in the unflagged set
            const shouldKeep = !unflaggedEmailIds.has(email.id);
            return shouldKeep;
          });
          
          updateEmailsStateAndCache('flagged', updatedFlaggedEmails, {}, searchParams);
        }
      }
    }

    // Update unread status
    setUnreadEmails(prevUnread => {
      const newUnread = { ...prevUnread };
      affectedEmailIds.forEach(id => {
        if (action === 'mark-unread') {
          newUnread[id] = true;
        } else if (action === 'mark-read' || action === 'delete' || action === 'archive') {
          delete newUnread[id];
        }
      });
      return newUnread;
    });

    // Set showEmailList to true on mobile after any action
    if (isMobile) {
      setShowEmailList(true);
    }
  }, [
    currentFolder,
    selectedEmailIds,
    lastSelectedEmailId,
    setSelectedEmail,
    setSelectedEmailIds,
    setFocusedEmailId, 
    folderEmails,
    updateEmailsStateAndCache,
    handleEmailMove,
    setShowEmailList,
    isMobile,
    searchParams
  ]);

  const clearSearchQuery = useCallback(() => {
    setSearchQuery('');
  }
  , []);  

  useEffect(() => {
    const checkAuth = () => {
      const currentAccount = JSON.parse(localStorage.getItem('currentAccount') || '{}');
      const token = currentAccount.accessToken;
      const subscriptionStatus = localStorage.getItem('isSubscribed');
      setIsAuthenticated(!!token);
      setIsSubscribed(subscriptionStatus === 'true');
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  const handleFolderChange = useCallback((newFolder) => {
    setCurrentFolder(newFolder);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('folder', newFolder);
    navigate(`/inbox?${searchParams.toString()}`);
  }, [navigate]);

  const handleOpenProfileDialog = useCallback(() => {
    setIsProfileDialogOpen(true);
  }, []);

  const handleCloseProfileDialog = useCallback(() => {
    setIsProfileDialogOpen(false);
  }, []);

  // Add this effect to handle viewport height
  useEffect(() => {
    setViewHeight();
    window.addEventListener('resize', setViewHeight);
    return () => window.removeEventListener('resize', setViewHeight);
  }, []);

  // Add debugging for authentication state
  useEffect(() => {
    console.log('Auth state:', { isAuthenticated, isSubscribed, isLoading });
  }, [isAuthenticated, isSubscribed, isLoading]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      height: { 
        xs: 'calc(var(--vh, 1vh) * 100)', // Use dynamic viewport height on mobile
        sm: '100vh' // Use regular viewport height on desktop
      }, 
      overflow: 'hidden',
      // Add safe area insets for mobile browsers
      paddingBottom: { 
        // xs: 'env(safe-area-inset-bottom)', 
        sm: 0 
      }
    }}>
      <Paper elevation={3} sx={{ 
        width: '100%', 
        backgroundColor: '#EFEADD',
        height: '100%', 
        display: 'flex', 
        flexDirection: 'row', 
        overflow: 'hidden', 
        borderRadius: 0,
      }}>
        <Box sx={{ 
          flex: 1, 
          overflow: 'hidden', 
          display: 'flex', 
          flexDirection: 'column',
          height: '100%' // Add explicit height
        }}>
          <AuthWrapper isAuthenticated={isAuthenticated} isSubscribed={isSubscribed}>
            <Routes>
              <Route path="/auth" element={<AuthPage setIsAuthenticated={setIsAuthenticated} setIsSubscribed={setIsSubscribed} updateEmailsStateAndCache={updateEmailsStateAndCache} />} />
              <Route 
                path="/inbox" 
                element={
                  isAuthenticated && isSubscribed ? (
                    <DndProvider backend={HTML5Backend}>
                      <ViewWrapper
                        selectedEmail={selectedEmail}
                        onFolderChange={handleFolderChange}
                        setSelectedEmail={setSelectedEmail}
                        onOpenProfileDialog={handleOpenProfileDialog}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        clearSearchQuery={clearSearchQuery}
                        currentFolder={currentFolder}      
                        setCurrentFolder={setCurrentFolder}
                        isSidebarOpen={isSidebarOpen}
                        setIsSidebarOpen={setIsSidebarOpen}
                        indexingStatus={indexingStatus}
                        processEmails={processEmails}
                        selectedEmailIds={selectedEmailIds}
                        onActionComplete={handleActionComplete}
                        areSelectedEmailsFlagged={areSelectedEmailsFlagged}
                        showEmailList={showEmailList}
                        setShowEmailList={setShowEmailList}
                        isSearching={isSearching}
                        setIsSearching={setIsSearching}
                        handleEmailMove={handleEmailMove}
                        folderEmails={folderEmails}
                      >
                        <MailView
                          selectedEmail={selectedEmail}
                          isSidebarOpen={isSidebarOpen}
                          isNewChat={selectedEmail && selectedEmail.id === 'thread_newLexiChat'}
                          setSelectedEmail={setSelectedEmail}
                          clearThreadFunction={clearThreadFunction}
                          setClearThreadFunction={setClearThreadFunction}
                          currentFolder={currentFolder}
                          setCurrentFolder={setCurrentFolder}
                          searchQuery={searchQuery}
                          clearSearchQuery={clearSearchQuery}
                          processEmails={processEmails}
                          selectedEmailIds={selectedEmailIds}
                          setSelectedEmailIds={setSelectedEmailIds}
                          handleActionComplete={handleActionComplete}
                          lastSelectedEmailId={lastSelectedEmailId}
                          setLastSelectedEmailId={setLastSelectedEmailId}
                          focusedEmailId={focusedEmailId}
                          setFocusedEmailId={setFocusedEmailId}
                          unreadEmails={unreadEmails}
                          setUnreadEmails={setUnreadEmails}
                          folderEmails={folderEmails}
                          setFolderEmails={setFolderEmails}
                          showEmailList={showEmailList}
                          setShowEmailList={setShowEmailList}
                          isSearching={isSearching}
                          setIsSearching={setIsSearching}
                          areSelectedEmailsFlagged={areSelectedEmailsFlagged}
                          onRefreshTargetFolder={(refreshFn) => {
                            refreshTargetFolderRef.current = refreshFn;
                          }}
                          updateEmailsStateAndCache={updateEmailsStateAndCache}
                          handleEmailMove={handleEmailMove}
                          getCachedEmails={getCachedEmails}
                          getCacheKey={getCacheKey}
                        />
                      </ViewWrapper>
                    </DndProvider>
                  ) : null
                } 
              />


              <Route path="/" element={<Navigate to="/inbox?folder=inbox_focused" replace />} />
              <Route path="*" element={<Navigate to="/inbox?folder=inbox_focused" replace />} />
            </Routes>
          </AuthWrapper>
        </Box>
      </Paper>
      <ProfileDialog 
        open={isProfileDialogOpen} 
        onClose={handleCloseProfileDialog}
        setIsAuthenticated={setIsAuthenticated}
      />
    </Box>
  );
};

const App = () => (
  <Router>
    <EmailChatApp />
  </Router>
);

export default App; 