import React, { useEffect, useRef, useState } from 'react';

const ShimmerEffect = ({ baseColor = '#f6f7f8', highlightColor = '#edeef1' }) => {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  
    useEffect(() => {
      const updateDimensions = () => {
        if (containerRef.current) {
          setDimensions({
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
          });
        }
      };
  
      updateDimensions();
      window.addEventListener('resize', updateDimensions);
  
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);
  
    const shimmerStyles = {
      container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        overflow: 'hidden', // Ensure animations don't cause scrollbars
      },
      shimmer: {
        animation: 'shimmer 2s infinite linear',
        background: `linear-gradient(to right, ${baseColor} 0%, ${highlightColor} 20%, ${baseColor} 40%, ${baseColor} 100%)`,
        backgroundSize: '1000px 100%',
      },
      paragraph: {
        marginTop: '20px',
        animation: 'scaleWidth 4s infinite ease-in-out',
        position: 'relative',
        transformOrigin: 'left center', // Keep the left side fixed
      },
      line: {
        height: '14px',
        marginBottom: '8px',
        borderRadius: '5px',
      },
    };
  
    const createParagraph = (lineCount, index, maxWidth = 100) => {
      // Custom delay for each paragraph
      const animationDelay = `${index * 0.8}s`;
      
      return (
        <div style={{
          ...shimmerStyles.paragraph, 
          animationDelay
        }}>
          {[...Array(lineCount)].map((_, idx) => {
            const width = idx === lineCount - 1 ? `${Math.random() * 50 + 20}%` : `${Math.random() * (maxWidth - 75) + 75}%`;
            return <div key={idx} style={{...shimmerStyles.shimmer, ...shimmerStyles.line, width}} />;
          })}
        </div>
      );
    };
  
    const calculateParagraphs = () => {
      const lineHeight = 22; // 14px height + 8px margin
      const paragraphMargin = 20;
      const availableHeight = dimensions.height * 0.8 - 32; // Adjusted for 80% height
      const linesPerParagraph = 4; // Average lines per paragraph
  
      const totalLines = Math.floor(availableHeight / lineHeight);
      const paragraphCount = Math.ceil(totalLines / linesPerParagraph);
  
      return Array.from({ length: paragraphCount }, (_, index) => {
        const remainingLines = totalLines - index * linesPerParagraph;
        return Math.min(remainingLines, linesPerParagraph);
      });
    };
  
    return (
      <div ref={containerRef} style={shimmerStyles.container}>
        {calculateParagraphs().map((lineCount, index) => (
          <React.Fragment key={index}>
            {createParagraph(lineCount, index)}
          </React.Fragment>
        ))}
        <style>
          {`
            @keyframes shimmer {
              0% { background-position: -1000px 0; }
              100% { background-position: 1000px 0; }
            }
            
            @keyframes scaleWidth {
              0% { transform: scaleX(1); }
              50% { transform: scaleX(2); }
              100% { transform: scaleX(1); }
            }
          `}
        </style>
      </div>
    );
  };

export default ShimmerEffect;